import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { ProductImg } from '@components/Img';
import { formatCurrency } from '@lib/adPlacements';
import { useProductData } from '@lib/shopify/useProductData';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { ClientLogger } from '@lib/ClientLogger';
import { QuantitySelector } from '@components/Forms/QuantitySelector';
import { Button, SubmitButton, IconButton, ButtonStyles } from '@components/Buttons';
import { FormContainer } from '@src/components/Forms';
import { ShopifyUtil } from '@src/lib/shopify';
import { NavStateContext } from '@lib/navContext';
import { usePvepApi } from '@apiClient/usePvepApi';
import { useCart } from '@apiClient/use-cart';
import { Toast } from '@src/components/Toast';
import { Icon } from '@iconify/react';
import arrowTopRight from '@iconify/icons-mdi/arrow-top-right';
import { VideoPlaceHolder } from '@components/VideoPlayer/VideoPlaceHolder';
import { DatabaseVideo, BrandUtil } from '@sharedLib/index';
import classnames from 'classnames';
import deleteIcon from '@iconify/icons-wpf/delete';
import { Share } from '@components/Share/Share';
import { EcommerceType } from '@sharedLib/product';
import { scrollToRefLocation } from '@src/lib/scrolling';
import DOMPurify from 'dompurify';
import { isDivOverflowing } from '@lib/overflow';
import { useProductResource } from '@src/templates/product/useProductResource';
import { useAnalyticsCapture } from '@src/lib/AnalyticsCapture';
import { useVideoState } from '@lib/useVideoState';
import {
  product as productStyle,
  productFill,
  shareFill,
  productContainer,
  mediaObject,
  mediaObjectCompact,
  minHeightAuto,
  imgShare,
  imgFull,
  applyFlexMobile,
  productCard,
  productCardCompact,
  productContent,
  maxWidth,
  formFixCompact,
  formFix,
  productFormContainer,
  productFormContainerCompact,
  productMessage,
  productTextContainer,
  productTitle,
  productDescription,
  productDescriptionFull,
  productPrice,
  actionItems,
  selectorContainer,
  variantSelector,
  option,
  label,
  cartProductDetails,
  removeButton,
  link,
  iconDelete,
  deleteIconHeight,
  addButton,
  iconAffliate,
  buyNow,
  backButton,
  embeddedVideo,
  descriptionRevealButton,
  revealButton,
} from './styles.module.scss';

const DEBUG = false;

interface Props {
  applyPlayerStyling?: boolean | DatabaseVideo | null;
  video?: DatabaseVideo | null;
  adPlacements?: any;
  productId: string;
  navOnClick?: boolean;
  showBackButton?: boolean;
  updateParent?: () => void;
  ctaOverrideMessage?: string;
  showMessage?: boolean;
  fillParentContainer?: boolean;
  isInCart?: boolean;
  cartItemId?: string;
  initialQuantity?: number;
  initialVariant?: string;
  isCompact?: boolean;
}

// showquantity

export const ProductDetails: React.FC<Props> = ({
  applyPlayerStyling,
  video,
  productId,
  navOnClick = false,
  showBackButton = false,
  ctaOverrideMessage = null,
  showMessage = false,
  fillParentContainer = false,
  updateParent,
  isInCart = false,
  cartItemId = null,
  initialQuantity = 1,
  initialVariant = null,
  isCompact = false,
}) => {
  const content = useProductResource();
  const productData = useProductData();
  const product = productData.getProduct(productId);
  const productContainerRef = useRef<HTMLDivElement>(null);
  const analyticsCapture = useAnalyticsCapture();
  const videoState = useVideoState();

  const nav = useContext(NavStateContext);
  const navData = nav.navState.pageData;
  // @ts-ignore
  const options = navData[navData.length - 1]?.state;

  const api = usePvepApi();
  const userState = api.state;
  const cart = useCart();
  const brandInfo = BrandUtil.getSiteInfo();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [descriptionOverflowing, setDescriptionOverflowing] = useState(false);

  // update overflow state whenever the ref changes
  useEffect(() => {
    setDescriptionOverflowing(isDivOverflowing(descriptionRef));
  }, [descriptionRef.current]);

  const targetUrl = product?.targetUrl;
  const eCommerceType: EcommerceType = targetUrl ? 'immediate-affiliate' : 'cart';
  const [quantity, setQuantity] = useState<number>(initialQuantity);

  // monitor and update quantity when parent gives a new initial value -- happens when two identical cart items are merged
  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const variants = ShopifyUtil.formatVariantsForDisplay(product?.variants || []);

  const defaultVariants = Object.create({});
  const initialVariantValues = initialVariant ? initialVariant.split(' / ') : '';

  [...variants.entries()].forEach((variant, variantIndex) => {
    let choice = 0;
    variant[1].forEach((v: any, i: number) => {
      DEBUG && ClientLogger.debug('ProductDetails', 'variant loop', { v, i, options, variant });

      if (isInCart && initialVariant) {
        if (initialVariantValues && v.value.trim() === initialVariantValues[variantIndex]?.trim()) {
          choice = i;
        }
      } else if (v.value === options?.variantTitle) {
        choice = i;
      }
    });
    defaultVariants[variant[0]] = variant[1][choice].text;
    // value={options?.variantTitle}
  });

  useEffect(() => {
    setTimeout(() => scrollToRefLocation(productContainerRef), 0);
  }, [productContainerRef, nav.navState]);

  const getSelectedVariant = (values: any) => {
    const selection = Object.values(values).join(' / ');
    DEBUG && ClientLogger.debug('ProductDetails.getSelectedVariant', `selectedVariant ${selection}`);

    const variants = product?.variants || [];
    const info = variants.filter((variant: { title: string }) => variant.title === selection)[0];
    DEBUG && ClientLogger.debug('ProductDetails.getSelectedVariant', `info =`, DEBUG, info);

    const variantInfo = {
      variantId: info?.id,
      variantName: info?.title,
      price: info?.price,
    };
    return variantInfo;
  };

  const [selectedVariant, setSelectedVariant] = useState(getSelectedVariant(defaultVariants));

  let isVideo = false;
  if (product?.videoIds) {
    isVideo = true;
  }
  if (product?.variants) {
    isVideo = product.variants.findIndex(v => !!v.videoIds) > -1;
  }

  // if there is video there is no quantity selector
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  DEBUG && ClientLogger.debug('ProductTemplatePage', 'render', { isVideo, product, variants, selectedVariant, defaultVariants });

  const onChangeUpdateQuantity = async (value: number) => {
    const quantity = value;
    DEBUG && ClientLogger.debug('CartComponent.updateCartItem', `product = ${product?.title} quantity = ${quantity}`);

    // if changed on cart page, update cart
    if (isInCart) {
      const resp = await cart.updateCartItem(cartItemId || '', selectedVariant.variantId, selectedVariant.variantName, quantity);
      DEBUG && ClientLogger.debug('CartComponent.updateCartItem', `Resp = ${JSON.stringify(resp)}`);
      if (resp) setQuantity(quantity);
    } else {
      setQuantity(quantity);
    }
  };

  const updateCartVariant = async (e: React.ChangeEvent, variant: string, values: any) => {
    if (!e.target) return;

    if (e.target) {
      const target = e.target as HTMLSelectElement;
      const val = target.value;
      values[variant] = val;
      const selection = getSelectedVariant(values);

      ClientLogger.debug(
        'ProductDetails.updateCartItem',
        `isInCart=${isInCart} product = ${product?.title}
          cartItemId = ${cartItemId}
          variantId = ${selection.variantId}
          variantName = ${selection.variantName}
        `,
        DEBUG
      );

      if (isInCart) {
        // CALL API WITH UPDATED VARIANT INFO
        const resp = await cart.updateCartItem(cartItemId || '', selection.variantId, selection.variantName, quantity);

        DEBUG && ClientLogger.debug('CartComponent.updateCartItem', `Resp = ${JSON.stringify(resp)}`);

        if (resp) {
          setSelectedVariant(selection);
        } else {
          // ClientLogger.error(
          //   'ProductDetails.updateCartVariant',
          //   new ErrorFormatter({
          //     graphQLErrors: resp.errors,
          //     commonResponseErrorDetails: resp.data?.updateCartItem.errorMessages,
          //   }).displayErrors()
          // );
        }
      } else {
        setSelectedVariant(selection);
      }
    }
  };

  if (!product) {
    if (isInCart) {
      return <></>;
    }

    return <ErrorDisplay errorData={{ errors: [`Cannot find product ${productId}`] }} />;
  }

  return (
    <Formik
      onSubmit={async values => {
        const selection = getSelectedVariant(values);

        ClientLogger.debug(
          'ProductDetails.addCartItem',
          `
                      email = ${userState.email}
                      productId = ${product.id}
                      title = ${product.title}
                      description = ${product.descriptionHtml}
                      quantity = ${quantity}
                      price = ${selection?.price}
                      imageUrl = ${product?.localImageFileName}
                      variantId = ${selection?.variantId}
                      variantName = ${selection?.variantName}
            `,
          DEBUG
        );

        setSelectedVariant(selection);

        const resp = await cart.addCartItem({
          description: product.descriptionHtml || ' ',
          productId: product.id,
          title: product.title,
          quantity,
          price: String(selection.price),
          imageUrl: product?.imageOriginalSrc,
          variantId: selection?.variantId,
          variantName: selection?.variantName,
        });
        analyticsCapture.productHit('add-to-cart', product, { adStrategy: videoState.state.currentAdStrategy });
        ClientLogger.debug('ProductDetails.addCartItemResp', 'resp =', DEBUG, resp);
        if (resp) {
          if (navOnClick) {
            updateParent ? updateParent() : null;
            nav.setRoot({ path: '/cart', title: 'Cart' });
          } else {
            setToastStatus(true);
            setTimeout(() => {
              setToastStatus(false);
            }, 5000);
          }
        } else {
          // const errorData = new ErrorFormatter({
          //   graphQLErrors: resp.errors,
          //   commonResponseErrorDetails: resp.data?.addCartItem.errorMessages,
          // });
          // ClientLogger.error('ProductDetails.addCartItemResp', errorData.displayErrors());
        }
      }}
      initialValues={defaultVariants}
      render={props => {
        return (
          <div
            className={
              !isInCart
                ? classnames(
                    applyPlayerStyling || !video ? productStyle : '',
                    fillParentContainer && !isCompact ? productFill : isCompact ? shareFill : ''
                  )
                : ''
            }
          >
            <div ref={productContainerRef} className={!isInCart && !isCompact ? productContainer : ''}>
              <article className={`media ${!isCompact ? mediaObject : `${mediaObjectCompact} ${minHeightAuto}`}`}>
                <figure className="media-left is-marginless">
                  <ProductImg
                    product={product}
                    className={`image is-square ${isCompact ? imgShare : imgFull}`}
                    imgStyle={{ objectFit: 'cover' }}
                    data-cy={`product-page-product-card-image-${product.id}`}
                  />
                </figure>

                <div className={classnames('media-content', applyFlexMobile, isCompact && maxWidth)}>
                  <div className={classnames('content', applyFlexMobile)}>
                    <div className={!isCompact ? productCard : productCardCompact} data-cy={`product-page-product-card-${product.id}`}>
                      <div
                        className={`${productContent} is-marginless ${isCompact && maxWidth}`}
                        data-cy={`product-page-product-card-content-${product.id}`}
                      >
                        <FormContainer className={isCompact ? formFixCompact : formFix} onSubmit={props.handleSubmit}>
                          <div className={!isCompact ? productFormContainer : productFormContainerCompact}>
                            {showMessage && (
                              <div className={classnames(productMessage, productTitle)}>
                                This video is not a part of your collection yet
                              </div>
                            )}
                            <div className={productTextContainer}>
                              <div className={productTitle} data-cy={`product-page-product-card-title-${product.id}`}>
                                {product.title}
                              </div>
                              <div
                                className={classnames(productDescription, descriptionOpen ? productDescriptionFull : '')}
                                data-cy={`product-page-product-card-description-${product.id}`}
                                ref={descriptionRef}
                              >
                                {typeof window !== 'undefined' && (
                                  // eslint-disable-next-line react/no-danger
                                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.descriptionHtml) }} />
                                )}
                              </div>
                              {descriptionRef.current && !descriptionOverflowing && descriptionOpen && (
                                <div className={descriptionRevealButton}>
                                  <div
                                    className={revealButton}
                                    onClick={() => {
                                      setDescriptionOpen(false);
                                      setDescriptionOverflowing(true);
                                    }}
                                  >
                                    Show Less
                                  </div>
                                </div>
                              )}
                              {descriptionRef.current && descriptionOverflowing && (
                                <div className={descriptionRevealButton}>
                                  <div
                                    className={revealButton}
                                    onClick={() => {
                                      setDescriptionOpen(true);
                                      setDescriptionOverflowing(false);
                                    }}
                                  >
                                    Show More
                                  </div>
                                </div>
                              )}
                              <h1 className={productPrice} data-cy={`product-page-product-card-price-${product.id}`}>
                                {formatCurrency(selectedVariant?.price)}
                              </h1>
                            </div>
                            <div className={actionItems} data-cy={`product-page-product-card-variants-container-${product.id}`}>
                              {eCommerceType !== 'immediate-affiliate' &&
                                !isCompact &&
                                [...variants.keys()]
                                  .filter(variant => variant !== 'Title')
                                  .map(variant => {
                                    return (
                                      <div
                                        className={selectorContainer}
                                        key={variant}
                                        data-cy={`product-page-product-card-variant-selector-container-${variant}-${product.id}`}
                                      >
                                        <div className={label} data-cy={`product-page-product-card-variant-label-${variant}-${product.id}`}>
                                          {variant}
                                        </div>
                                        <Field
                                          component="select"
                                          key={variant}
                                          name={variant}
                                          className={variantSelector}
                                          onChange={async (e: React.ChangeEvent) => {
                                            // e.persist()
                                            DEBUG &&
                                              ClientLogger.debug('ProductDetails', 'variable change', {
                                                isInCart,
                                                variant,
                                                e,
                                                values: props.values,
                                              });
                                            updateCartVariant(e, variant, props.values);
                                          }}
                                          data-cy={`product-page-product-card-variant-selector-${variant}-${product.id}`}
                                        >
                                          {variants.get(variant).map((val: any) => {
                                            return (
                                              <option
                                                key={val.value}
                                                value={val.value}
                                                className={option}
                                                data-cy={`product-page-product-card-variant-option-${variant}-${product.id}`}
                                              >
                                                {val.text}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      </div>
                                    );
                                  })}
                              {eCommerceType !== 'immediate-affiliate' && !isVideo && !isCompact && (
                                <span key="quantity" className={label} data-cy={`product-page-product-card-quantity-label-${product.id}`}>
                                  <span className="mr-2">Quantity</span>
                                  <QuantitySelector
                                    name="quantity"
                                    value={quantity}
                                    updateParent={onChangeUpdateQuantity}
                                    data-cy={`product-page-product-card-quantity-selector-${product.id}`}
                                  />
                                </span>
                              )}

                              {isInCart && (
                                <div className={classnames(cartProductDetails, removeButton)}>
                                  <div
                                    data-cy="removeCartItem"
                                    className={link}
                                    onClick={async () => {
                                      DEBUG &&
                                        ClientLogger.debug(
                                          'CartComponent.removeCartItem',
                                          `variantId = ${selectedVariant.variantId}`,
                                          DEBUG
                                        );
                                      const resp = await cart.removeCartItem(selectedVariant.variantId);
                                      DEBUG && ClientLogger.debug('CartComponent.removeCartItem', `Response =${resp}`);
                                    }}
                                  >
                                    <IconButton className={`${iconDelete} mr-3`}>
                                      <Icon icon={deleteIcon} className={deleteIconHeight} />
                                    </IconButton>
                                    <b>Remove</b>
                                  </div>
                                </div>
                              )}

                              {eCommerceType === 'immediate-affiliate' && !isInCart && !isCompact && (
                                <div className={buyNow}>
                                  <Button
                                    target="_blank"
                                    href={product.targetUrlType === 'internal' ? `${brandInfo.siteUrl}${targetUrl}` : targetUrl}
                                    filled
                                    className={addButton}
                                    data-cy={`product-page-product-card-affiliate-cta-${product.id}`}
                                    onClick={() => {
                                      analyticsCapture.productHit('affiliate-click', product, {
                                        adStrategy: videoState.state.currentAdStrategy,
                                      });
                                    }}
                                  >
                                    {content.productCardCTA.affilateProduct}
                                    <IconButton className={iconAffliate}>
                                      <Icon icon={arrowTopRight} />
                                    </IconButton>
                                  </Button>
                                  <Share
                                    type="product"
                                    urlPath={product.id}
                                    product={product}
                                    productPrice={formatCurrency(selectedVariant?.price)}
                                    isCompact
                                    shareBtnClass="is-pulled-right is-small is-justify-content-flex-end"
                                  />
                                </div>
                              )}
                              {eCommerceType !== 'immediate-affiliate' && !isInCart && !isCompact && (
                                <div className={buyNow}>
                                  <SubmitButton
                                    style={ButtonStyles.Primary}
                                    data-cy={`product-page-product-card-non-affiliate-cta-${product.id}`}
                                  >
                                    {ctaOverrideMessage || content.productCardCTA.nonAffilateProduct}
                                  </SubmitButton>
                                  <Share
                                    type="product"
                                    urlPath={product.id}
                                    product={product}
                                    productPrice={formatCurrency(selectedVariant?.price)}
                                    isCompact
                                    shareBtnClass="is-pulled-right is-small is-justify-content-flex-end"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </FormContainer>
                      </div>
                    </div>

                    <Toast toastStyle="info" show={toastStatus} message={content.addProductToastMessage} />
                  </div>
                </div>
                <div />

                {showBackButton && (
                  <button
                    type="button"
                    className={backButton}
                    onClick={() => {
                      nav.back();
                    }}
                  >
                    X
                  </button>
                )}
              </article>
            </div>
            {applyPlayerStyling && video && (
              <div className={embeddedVideo}>
                <VideoPlaceHolder videoId={video.id} />
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
