import { useFluidImage } from '@src/components/Img/useFluidImage';
import { ClientLogger } from '@lib/ClientLogger';
import { ShopifyProduct } from '@sharedLib/product';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const DEBUG = false;

interface ImageProps {
  product: ShopifyProduct;
  className?: string;
  title?: string;
  imgStyle?: any;
  style?: any;
}

export const ProductImg: React.FC<ImageProps> = ({ product, className, title, imgStyle, style }) => {
  const images = useFluidImage();
  const match = `images/${product.localImageFileName}`;
  const image = images.find(f => f.relativePath === match);
  DEBUG &&
    ClientLogger.debug('ProductImg.render', `filename =${product.localImageFileName} images length=${images.length}`, {
      match,
      product,
      image,
      images,
    });
  return image ? (
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      imgStyle={imgStyle}
      className={className}
      title={title}
      alt={title || ''}
      style={style}
    />
  ) : (
    <></>
  );
};
