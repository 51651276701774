const descendingSort = (a: any, b: any, orderBy: string) => {
  if (a[orderBy] !== undefined && b[orderBy] === undefined) {
    return -1;
  }
  if (a[orderBy] === undefined && b[orderBy] !== undefined) {
    return 1;
  }
  if (a[orderBy] > b[orderBy]) {
    return -1;
  }
  if (a[orderBy] < b[orderBy]) {
    return 1;
  }

  return 0;
};

export const getTableSort = (order: 'asc' | 'desc', orderBy: string) => {
  if (order === 'desc') {
    return (a: any, b: any) => descendingSort(a, b, orderBy);
  } else {
    return (a: any, b: any) => -descendingSort(a, b, orderBy);
  }
};
