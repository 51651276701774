import React from 'react';
import { Icon } from '@iconify/react';
import {
  headerContainer,
  headerItemContainer,
  headerItemLabel,
  sortableIcon,
  sortableIconInvert,
  sortableIconHidden,
} from './styles.module.scss';
import classNames from 'classnames';

interface Props {
  items: SortableHeaderItem[];
  order: 'asc' | 'desc';
  setOrder: (newOrder: 'asc' | 'desc') => void;
  orderBy: string;
  setOrderBy: (newOrderBy: string) => void;
}

export interface SortableHeaderItem {
  label: string;
  fieldName: string;
  className?: string;
}

export const SortableHeader = ({ items, order, setOrder, orderBy, setOrderBy }: Props) => {
  return (
    <div className={headerContainer}>
      {items.map((item, i) => (
        <div
          key={i}
          className={classNames(headerItemContainer, item.className || '')}
          onClick={() => {
            setOrder(orderBy === item.fieldName && order === 'desc' ? 'asc' : 'desc');
            setOrderBy(item.fieldName);
          }}
        >
          <div className={headerItemLabel}>{item.label}</div>

          {orderBy === item.fieldName && (
            <Icon icon={'akar-icons:chevron-down'} className={classNames(sortableIcon, order === 'asc' ? sortableIconInvert : '')} />
          )}

          {orderBy !== item.fieldName && <Icon icon={'akar-icons:chevron-down'} className={classNames(sortableIconHidden)} />}
        </div>
      ))}
    </div>
  );
};
