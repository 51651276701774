import React from 'react';
import { loaderContainer, loader, loaderMessage } from './styles.module.scss';

interface Props {
  message?: string;
}

export const Loading = ({ message }: Props) => {
  return (
    <div className={loaderContainer}>
      <div className={loader} />
      <div data-cy="loader" className={loaderMessage}>
        {message || 'LOADING'}
      </div>
    </div>
  );
};
