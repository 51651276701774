import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Form } from 'formik';
import { FormError } from './FormError';
import { contents, formContainer } from './styles.module.scss';

interface Props {
  onSubmit?: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
  className?: string;
  dataCY?: any;
  children?: ReactNode;
  errorMessage?: ReactNode;
}

export const FormContainer: React.FC<Props> = ({ onSubmit, dataCY, className, children, errorMessage }) => {
  return (
    <form onSubmit={onSubmit} data-cy={dataCY} className={classnames(formContainer, className)}>
      {errorMessage && <FormError errorMessage={errorMessage} />}
      <div className={contents}>{children}</div>
    </form>
  );
};

interface FormikProps {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
  children?: ReactNode;
  errorMessage?: ReactNode;
}

export const FormikContainer: React.FC<FormikProps> = ({ className, children, errorMessage }) => {
  return (
    <Form className={classnames(formContainer, className)}>
      {errorMessage && <FormError errorMessage={errorMessage} />}
      <div className={contents}>{children}</div>
    </Form>
  );
};
