import React, { ReactNode } from 'react';
import { stepperContainer } from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const StepperContainer = ({ children }: Props) => {
  return <div className={stepperContainer}>{children}</div>;
};

export default StepperContainer;
