import React, { useContext } from 'react';
import { DatabaseVideo, BrandUtil } from '@sharedLib/index';
import { NavStateContext } from '@lib/navContext';
import classnames from 'classnames';
import {
  nonMemberFillContainer,
  fillBackground,
  backgroundDarkenOverlay,
  nonMemberCardWrapper,
  nonMemberCardTitle,
  nonMemberCardDescription,
  nonMemberCardCopy,
  loginText,
} from './styles.module.scss';

interface Props {
  video: DatabaseVideo;
}

export const NonMemberCard = ({ video }: Props) => {
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();
  const baseImageUrl = brandInfo.imageBaseUrl;
  const thumbnailUrl = video.thumbnailUrl ? `${baseImageUrl}/videos/${video.thumbnailUrl}` : `${brandInfo.siteUrl}/fallback.png`;

  return (
    <div className={nonMemberFillContainer}>
      <img src={thumbnailUrl} className={fillBackground} alt="" />
      <div className={classnames(fillBackground, backgroundDarkenOverlay)} />

      <div className={nonMemberCardWrapper}>
        <h1 className={nonMemberCardTitle}>{video?.title}</h1>
        <div className={nonMemberCardDescription}>{video?.description}</div>
        <div>
          <div className={nonMemberCardCopy}>This video is only available to members.</div>
          <div className={classnames(nonMemberCardCopy, loginText)}>
            Please <span onClick={() => nav.push({ path: '/login', state: { destination: `/video/${video.id}/` } })}>log in</span> or{' '}
            <span onClick={() => nav.push({ path: '/sign-up', state: { destination: `/video/${video.id}/` } })}>join now</span> to view
            content.
          </div>
        </div>
      </div>
    </div>
  );
};
