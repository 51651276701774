import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { offColorText, boldFont, largeText, videoListItem, smallText } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { DatabaseVideo } from '@sharedLib/index';

export const VideoJsonUploader = () => {
  const api = usePvepApi();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');
  const [videos, setVideos] = useState<DatabaseVideo[]>([]);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');

  ///
  /// Write all videos to db
  ///
  const writeAllVideosToDatabase = async (videos: DatabaseVideo[]) => {
    const errors: string[] = [];

    for (const video of videos) {
      const writeResp = await api.writeDatabaseVideo({ ...video, thumbnailUrl: '' });
      if (!writeResp.data?.writeDatabaseVideo.success) {
        errors.push(`Video ID: ${video.id} failed to upload.`);
      }
    }

    setUploadErrors(errors);
    setConfirmation('Upload complete.');
  };

  return (
    <div>
      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          await writeAllVideosToDatabase(videos);
          resetForm();
          setVideos([]);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const videosRaw: any[] = JSON.parse(dataString);
                        const dbVideos: DatabaseVideo[] = [];
                        for (const video of videosRaw) {
                          const videoUrl: string = video.metadata?.extUrl || video.url;
                          const videoType: string = video.metadata?.extSource || 'vimeo-ott';

                          if (
                            video.id === undefined ||
                            video.title === undefined ||
                            video.is_free === undefined ||
                            video.is_available === undefined ||
                            !videoUrl ||
                            !videoType
                          ) {
                            continue;
                          }

                          const dbVideo: DatabaseVideo = {
                            id: video.id as string,
                            title: video.title as string,
                            description: (video.description as string) || '',
                            url: videoUrl,
                            isAvailable: (video.is_available as boolean) || false,
                            isFree: (video.is_free as boolean) || false,
                            type: videoType,
                            thumbnailUrl: '',
                          };

                          dbVideos.push(dbVideo);
                        }
                        setVideos(dbVideos);
                        setError('');
                      } catch (e) {
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {videos.length > 0 && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                WRITE ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {videos.map(video => (
        <div className={videoListItem}>
          <div className={classNames(largeText, boldFont)}>{video.title}</div>
          <div className={smallText}>
            <span className={boldFont}>Video ID: </span>
            <span className={offColorText}>{video.id}</span>
          </div>
          <div className={smallText}>
            <span className={boldFont}>URL: </span>
            <span className={offColorText}>{video.url}</span>
          </div>
          <div className={smallText}>
            <span className={boldFont}>Type: </span>
            <span className={offColorText}>{video.type}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
