import React, { useState } from 'react';
import { videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { BrandUtil, DatabaseVideo } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';

export interface VideoBranchSeedData {
  branch: string;
  timestamp: number;
  bucket: string;
  videos: DatabaseVideo[];
}

export const VideoDataExport = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    // export data

    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos = videoResp.data.getDatabaseVideos.videos.map(video => {
        const newVid: any = video;
        delete newVid.__typename;
        delete newVid.createdAt;
        delete newVid.updatedAt;
        return newVid;
      });

      const seedData: VideoBranchSeedData = {
        branch: brandInfo.branchId,
        bucket: brandInfo.imageBucketName,
        timestamp: Date.now(),
        videos: databaseVideos,
      };

      const blob = new Blob([JSON.stringify(seedData, null, 2)], { type: 'application/json' });
      const href = URL.createObjectURL(blob);

      const downloadEl = document.createElement('a');
      downloadEl.download = `${brandInfo.branchId}-video-data-export-${Date.now()}`;
      downloadEl.href = href;
      downloadEl.click();
    }

    setLoading(false);
  };

  return (
    <div>
      <Button onClick={() => handleExport()} disabled={loading} className={videoListItem}>
        EXPORT ALL VIDEO DATA
      </Button>
    </div>
  );
};
