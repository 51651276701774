import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import { IconButton } from '@components/Buttons';
import eyeInvisibleOutlined from '@iconify/icons-ant-design/eye-invisible-outlined';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import { BrandUtil } from '@sharedLib/util';

import {
  textArea,
  text,
  fieldError,
  textAreaField,
  textField,
  textFieldAT,
  passwordFieldContainer,
  textContainer,
  showHideBtn,
  iconShowPassword,
  label,
  optional as optionalStyle,
  iconShowPasswordATExtra,
  controlAT,
} from './styles.module.scss';

interface Props {
  headerText?: string;
  fieldType: string;
  fieldName: string;
  rows?: number;
  className?: string;
  placeholder?: string;
  touched?: boolean;
  optional?: boolean;
  disabled?: boolean;
  isPasswordField?: boolean;
  handleenter?: any;
  dataCY?: any;
}

interface InputProps extends FieldProps, Props {
  id: string;
}
const Input: React.FC<InputProps> = ({ field, form, ...props }) => {
  const errorMessage = form.errors[field.name];
  const touched = form.touched[field.name];
  const { rows, className } = props;

  return (
    <>
      {rows ? (
        <textarea rows={rows} {...field} {...props} className={classnames(className, textArea)} />
      ) : (
        <input {...field} {...props} className={classnames(className, text)} />
      )}
      {
        <div data-cy="fieldError" className={fieldError}>
          {touched && errorMessage ? errorMessage : ''}
        </div>
      }
    </>
  );
};

// Create an argument to toggle between message field & standard field
export const TextField: React.FC<Props> = ({
  headerText,
  fieldType,
  fieldName,
  rows,
  className,
  placeholder,
  optional,
  disabled,
  handleenter,
  dataCY,
}) => {
  const fieldProps = {
    id: fieldName,
    name: fieldName,
    type: fieldType,
    rows,
    placeholder,
    disabled,
    'data-cy': dataCY,
  };
  const [isPasswordVisible, setVisibility] = useState(false);
  const brand = BrandUtil.getSiteInfo().brandId;
  if (fieldType === 'password') {
    if (isPasswordVisible) {
      fieldProps.type = 'text';
    } else {
      fieldProps.type = 'password';
    }
  }
  return (
    <div className={classnames(className, rows ? textAreaField : brand === 'at' ? textFieldAT : textField)}>
      {headerText ? (
        <label htmlFor={fieldName} className={classnames('label', label)}>
          {headerText}
        </label>
      ) : (
        ''
      )}

      {fieldType === 'password' ? (
        <>
          <div className={passwordFieldContainer}>
            <div className={textContainer}>
              <Field
                {...fieldProps}
                className={`control ${brand === 'at' ? controlAT : ''}`}
                onKeyDown={(event: any) => (handleenter ? handleenter(event) : '')}
                component={Input}
              />
            </div>

            <div className={classnames(showHideBtn)} onClick={() => setVisibility(!isPasswordVisible)}>
              {isPasswordVisible && (
                <IconButton className={`${iconShowPassword} ${brand === 'at' ? iconShowPasswordATExtra : ''}`}>
                  <Icon icon={eyeOutlined} height="20px" />
                </IconButton>
              )}
              {!isPasswordVisible && (
                <IconButton className={`${iconShowPassword} ${brand === 'at' ? iconShowPasswordATExtra : ''}`}>
                  <Icon icon={eyeInvisibleOutlined} height="20px" />
                </IconButton>
              )}
            </div>
          </div>
        </>
      ) : (
        <Field
          {...fieldProps}
          onKeyDown={(event: any) => (handleenter ? handleenter(event) : '')}
          className={`control ${brand === 'at' ? controlAT : ''}`}
          component={Input}
        />
      )}
      {optional && (
        <label htmlFor={fieldName} className={classnames('label', label)}>
          <span className={optionalStyle}>&emsp;Optional</span>
        </label>
      )}
    </div>
  );
};
