import React from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import tickCircleSolid from '@iconify/icons-teenyicons/tick-circle-solid';
import crossCircle from '@iconify/icons-gridicons/cross-circle';
import * as styles from './styles.module.scss';
import { toastContainer, toastIcon, toastAnimation, icon, toastHeader, toastMessage, hide } from './styles.module.scss';

type ToastStyle = 'info' | 'success' | 'danger';

const toastStyleWithIcon = [
  {
    style: 'info',
    icon: checkmarkCircle2Outline,
  },
  {
    style: 'success',
    icon: tickCircleSolid,
  },
  {
    style: 'danger',
    icon: crossCircle,
  },
];

interface Props {
  toastStyle?: ToastStyle;
  header?: string;
  message: string;
  show: boolean;
}

const Toast = ({ header, message, show, toastStyle }: Props) => {
  return (
    <div className={classNames(toastContainer, styles[toastStyle || 'info'], show ? toastAnimation : '')}>
      <div className={classNames(toastIcon, toastStyleWithIcon.map(item => item.style).includes(`${toastStyle || ''}`) ? show : hide)}>
        <Icon icon={toastStyleWithIcon.filter(item => item.style === toastStyle)[0].icon} className={icon} />
      </div>
      <div className={classNames(toastHeader, header ? show : hide)}>{header}</div>
      <div className={toastMessage}>{message}</div>
    </div>
  );
};

export default Toast;
