import React, { useContext, useEffect, useState } from 'react';
import { NavStateContext } from '@lib/navContext';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { IconButton, Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { StrategySelector } from '@components/ProductAssignments';
import {
  dropDownButton,
  headerIcon,
  rightContainer,
  contentContainer,
  dropDownHeader,
  toggleContainer,
  previewToggle,
  previewLinkButton,
} from './styles.module.scss';

interface Props {
  showStrategyPreview?: boolean;
}

export const PreviewDropdown = ({ showStrategyPreview }: Props) => {
  const api = usePvepApi();
  const userState = api.state;
  const nav = useContext(NavStateContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [previewing, setPreviewing] = useState(userState.useRuntimeData);

  return (
    <div
      className={classNames(dropdownOpen ? `dropdown is-active` : '')}
      onMouseEnter={() => {
        setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        setDropdownOpen(false);
      }}
    >
      <IconButton className={classNames(dropDownButton, headerIcon)} onTouchStart={() => setDropdownOpen(!dropdownOpen)}>
        <Icon icon={previewing ? 'icon-park-outline:preview-open' : 'icon-park-outline:preview-close-one'} height="30px" />
      </IconButton>
      <div className={classNames('dropdown-menu', rightContainer)} id="dropdown-menu" role="menu">
        <div className={classNames('dropdown-content', contentContainer)}>
          <div className={dropDownHeader}>Preview Changes</div>
          <div className={toggleContainer}>
            <IconButton
              className={previewToggle}
              onClick={async () => {
                await api.updateUserRuntime(!userState.useRuntimeData);
                setPreviewing(!previewing);
                window.location.reload();
              }}
            >
              <Icon icon={previewing ? 'mdi:toggle-switch' : 'mdi:toggle-switch-off-outline'} height="30px" />
            </IconButton>
            <div>{previewing ? 'ON' : 'OFF'}</div>
          </div>

          <Button className={previewLinkButton} onClick={() => nav.push({ path: '/db-admin' })}>
            ADMIN PANEL
          </Button>

          {showStrategyPreview && userState.useRuntimeData && <StrategySelector />}
        </div>
      </div>
    </div>
  );
};
