import { Button } from '@components/Buttons';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  generalOption,
  feedbackSelectionText,
  feedbackSelectionImage,
  feedbackSelectionTitle,
  feedbackSelection,
  feedbackMain,
  closeButton,
  logoContainer,
  flexRow,
  smallText,
  specificOptionContainer,
  radioButton,
  feedbackTextarea,
  feedbackButton,
  errorMessage,
  feedbackMessage,
} from './styles.module.scss';
import { BrandUtil } from '@sharedLib/index';
import { Formik, Field } from 'formik';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { ClientLogger } from '@src/lib/ClientLogger';

const DEBUG = false;

const FEEDBACK_CHAR_LIMIT = 500;
const CHAR_COUNTDOWN = 20;

const specificValidationSchema = yup.object().shape({
  feedbackType: yup.string().required('This is required.'),
  feedbackContent: yup
    .string()
    .required('This is required.')
    .max(FEEDBACK_CHAR_LIMIT, 'Message is too long.'),
});

interface Props {
  onClose: () => void;
  formOpen: boolean;
}

export const FeedbackForm = ({ onClose, formOpen }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const [openState, setOpenState] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const api = usePvepApi();

  const submitFeedback = async (type: string, feedback: string, details: string) => {
    setMessage('');
    setError('');
    const feedbackResp = await api.giveFeedback(type, feedback, details);
    if (feedbackResp.data?.giveFeedback.success) {
      setMessage('Thank you for your feedback!');
    } else {
      ClientLogger.error('Feedback:submitFeedback', 'Submit feedback failed.', feedbackResp.data?.giveFeedback.errorMessages);
      setError('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    if (formOpen) {
      setOpenState(undefined);
      setError('');
      setMessage('');
    }
  }, [formOpen]);

  return (
    <div className={feedbackMain}>
      <button type="button" onClick={onClose} className={classNames('delete', closeButton)} />

      {!openState && (
        <div>
          <div className={logoContainer}>
            <img src={`${brandInfo.siteUrl}/${brandInfo.feedbackConfig.logoStaticPath}`} alt="" />
          </div>
          <div className={flexRow}>
            <div className={feedbackSelection} onClick={() => setOpenState('specific')}>
              <div className={feedbackSelectionTitle}>Specific feedback</div>
              <div className={feedbackSelectionImage}>
                <img src={`${brandInfo.siteUrl}/specificfeedback.png`} alt="" />
              </div>
              <div className={feedbackSelectionText}>I'd like to give feedback on a specific part of this page.</div>
            </div>

            <div className={feedbackSelection} onClick={() => setOpenState('general')}>
              <div className={feedbackSelectionTitle}>General feedback</div>
              <div className={feedbackSelectionImage}>
                <img src={`${brandInfo.siteUrl}/generalfeedback.png`} alt="" />
              </div>
              <div className={feedbackSelectionText}>I'd like to give general feedback on the entire website.</div>
            </div>
          </div>
        </div>
      )}

      {openState === 'general' && (
        <div>
          <div className={logoContainer}>
            <img src={`${brandInfo.siteUrl}/${brandInfo.feedbackConfig.logoStaticPath}`} alt="" />
          </div>
          <div className={smallText}>{brandInfo.feedbackConfig.generalMessage}</div>
          <div>
            {brandInfo.feedbackConfig.generalItems.map((item, i) => (
              <div key={i} className={generalOption} onClick={async () => await submitFeedback('general', item, '')}>
                {item}
              </div>
            ))}

            {error && <div className={errorMessage}>{error}</div>}
            {message && <div className={feedbackMessage}>{message}</div>}
          </div>
        </div>
      )}

      {openState === 'specific' && (
        <Formik
          initialValues={{
            feedbackType: '',
            feedbackContent: '',
          }}
          validationSchema={specificValidationSchema}
          onSubmit={async values => {
            await submitFeedback('specific', values.feedbackType, values.feedbackContent);
          }}
        >
          {props => (
            <div>
              <div className={logoContainer}>
                <img src={`${brandInfo.siteUrl}/${brandInfo.feedbackConfig.logoStaticPath}`} alt="" />
              </div>
              <div className={smallText}>What type of feedback would you like to provide?</div>
              <div>
                {brandInfo.feedbackConfig.specificItems.map((item, i) => (
                  <div key={i} className={specificOptionContainer} onClick={() => props.setFieldValue('feedbackType', item)}>
                    <div>{item}</div>
                    <Field type="radio" name="feedbackType" value={item} className={radioButton} />
                  </div>
                ))}
              </div>

              {props.errors.feedbackType && <div className={errorMessage}>{props.errors.feedbackType}</div>}

              <textarea
                value={props.values.feedbackContent}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                placeholder={`Please provide your feedback here. (${FEEDBACK_CHAR_LIMIT} characters max)`}
                name="feedbackContent"
                className={feedbackTextarea}
                rows={8}
              />
              {props.values.feedbackContent.length + CHAR_COUNTDOWN >= FEEDBACK_CHAR_LIMIT &&
                props.values.feedbackContent.length <= FEEDBACK_CHAR_LIMIT && (
                  <div className={feedbackMessage}>{FEEDBACK_CHAR_LIMIT - props.values.feedbackContent.length}</div>
                )}

              {props.errors.feedbackContent && <div className={errorMessage}>{props.errors.feedbackContent}</div>}

              <div className={feedbackButton}>
                <Button type="submit" onClick={props.handleSubmit} disabled={props.isSubmitting}>
                  SUBMIT
                </Button>
              </div>

              {error && <div className={errorMessage}>{error}</div>}
              {message && <div className={feedbackMessage}>{message}</div>}
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
