import { ItemType } from './Video';

export interface Collection {
  // TODO: Rename to VideoCollection
  id: string; // Gatsby forces this to be a string - https://github.com/gatsbyjs/gatsby/pull/17072
  localSourceImage: string; // Relative path of locally copied image file
  description: string;
  is_available: boolean;
  is_featured: boolean;
  videoIds?: string[];
  collectionIds?: string[];
  useInBrands?: string[];
  metadata: any;
  name: string;
  overlayIcon?: string;
  position: number;
  season_number?: number;
  seasons_count?: number;
  short_description: string;
  slug: string;
  type: ItemType;
  tags: any;
}

export function getParentCollections(
  allVideoCollections: Collection[],
  videoOrCollectionId: string,
  resultSoFar?: Collection[]
): Collection[] {
  const result: Collection[] = resultSoFar || [];

  const collectionsOfChildren = allVideoCollections.filter(c => {
    const includedAsVideo = c.videoIds ? c.videoIds.includes(videoOrCollectionId) : false;
    const includedAsCollection = c.collectionIds ? c.collectionIds.includes(videoOrCollectionId) : false;
    const result = includedAsCollection || includedAsVideo;
    return result;
  });

  for (const collection of collectionsOfChildren) {
    const alreadyThere = result.find(r => r.id === collection.id);
    if (!alreadyThere) {
      result.push(collection);
      getParentCollections(allVideoCollections, collection.id, result);
    }
  }
  return result;
}

// given a videoId, get categories a video directly belongs to or belongs to via a child series
export function getParentCategories(
  allCategories: DatabaseCategory[],
  allSeries: DatabaseSeries[],
  allSeasons: DatabaseSeason[],
  videoId: string
): DatabaseCategory[] {
  const parentSeries = getParentSeries(allSeries, allSeasons, videoId);
  const parentSeriesIds = parentSeries.map(ser => ser.id);

  // get included category ids
  let categoryIds: string[] = [];
  for (const cat of allCategories) {
    for (const item of cat.items) {
      if (item.videoId === videoId) {
        categoryIds.push(cat.id);
      } else if (item.seriesId && parentSeriesIds.includes(item.seriesId)) {
        categoryIds.push(cat.id);
      }
    }
  }
  const categories: DatabaseCategory[] = allCategories.filter(cat => categoryIds.includes(cat.id));
  return categories;
}

// given a videoId, get series a video belongs to via a child season
export function getParentSeries(allSeries: DatabaseSeries[], allSeasons: DatabaseSeason[], videoId: string): DatabaseSeries[] {
  const parentSeasons = getParentSeasons(allSeasons, videoId);
  const parentSeasonIds = parentSeasons.map(season => season.id);

  // get included series ids
  let seriesIds: string[] = [];
  for (const series of allSeries) {
    for (const item of series.items) {
      if (parentSeasonIds.includes(item.seasonId)) {
        seriesIds.push(series.id);
      }
    }
  }
  const series: DatabaseSeries[] = allSeries.filter(series => seriesIds.includes(series.id));
  return series;
}

// given a videoId, get seasons a video belongs to
export function getParentSeasons(allSeasons: DatabaseSeason[], videoId: string): DatabaseSeason[] {
  // get included season ids
  let seasonIds: string[] = [];
  for (const season of allSeasons) {
    for (const item of season.items) {
      if (item.videoId === videoId) {
        seasonIds.push(season.id);
      }
    }
  }
  const seasons: DatabaseSeason[] = allSeasons.filter(season => seasonIds.includes(season.id));
  return seasons;
}

export interface DatabaseSeries {
  id: string;
  title: string;
  description: string;
  shortDescription: string;
  isAvailable: boolean;
  isFree: boolean;
  thumbnailUrl?: string | null;
  thumbnailData?: string | null;
  items: DatabaseSeriesItem[];
}

export interface DatabaseSeriesItem {
  id: string;
  rank: number;
  seriesId: string;
  seasonId: string;
}

export interface DatabaseSeason {
  id: string;
  title: string;
  items: DatabaseSeasonItem[];
}

export interface DatabaseSeasonItem {
  id: string;
  rank: number;
  videoId: string;
  seasonId: string;
}

export interface DatabaseCategory {
  id: string;
  title: string;
  isAvailable: boolean;
  isFree: boolean;
  isFeatured: boolean;
  rank?: number | null;
  thumbnailUrl?: string | null;
  items: DatabaseCategoryItem[];
}

export interface DatabaseCategoryItem {
  id: string;
  rank: number;
  videoId?: string | null;
  seriesId?: string | null;
  categoryId: string;
}
