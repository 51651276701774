import { apolloTracer } from '@apiClient/apollo-tracer';
import { usePvepApi } from '@apiClient/usePvepApi';
import { CartIcon } from '@components/Cart/CartIcon';
import { useLayoutResource } from '@layouts/useLayoutResource';
import { BrandUtil } from '@sharedLib/util';
import React, { FC, useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { useVideoState, VideoPlayerSize } from '@lib/useVideoState';
import { ClientLogger } from '@lib/ClientLogger';
import { Button, ButtonStyles, IconButton } from '@components/Buttons';
import { Search } from '@components/Search';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import searchIcon from '@iconify/icons-eva/search-fill';
import { Dropdown, PreviewDropdown, HamburgerDropdown } from '@src/components/Dropdown';
import { Img } from '@components/Img';
import { NavStateContext } from '@lib/navContext';
import { isBrowser } from '@lib/build';
import { isMobile, isTablet } from '@src/lib/responsive';
import {
  logo,
  transparentBackground,
  navbarContainer,
  navBarWrapper,
  navbarBrand,
  brandLogo,
  navBarItemGroup,
  iconSearch,
  loginButton,
  configuredButton,
  navBarButton,
  activeButton,
} from './styles.module.scss';

interface HeaderProps {
  hideFreeTrial?: boolean;
  hideLogin?: boolean;
  hideCart?: boolean;
  hideConfiguredButtons?: boolean;
  showStrategyPreview?: boolean;
}

const DEBUG = false;

const Header: FC<HeaderProps> = ({ hideLogin, hideCart, showStrategyPreview }) => {
  const [searchState, setSearchState] = useState<boolean>(false);
  const [mainMenuState, setMainMenuState] = useState<boolean>();
  const api = usePvepApi();
  const { isLoggedIn } = api.state;
  const userState = api.state;
  const userfullName = `${userState.firstName} ${userState.lastName}`;
  const resource = useLayoutResource();
  const nav = useContext(NavStateContext);
  const {
    logoRedirectUrl,
    hasSignup,
    videoNeedsRegistration,
    productNeedsRegistration,
    cartIconVisibility,
    headerConfig,
    branchId,
  } = BrandUtil.getSiteInfo();
  const mobile = isBrowser && isMobile();
  const tablet = isBrowser && isTablet();

  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    //set timer on mount to update every second
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timer); // cleanup the timer on unmount
  }, []);

  const videoState = useVideoState();
  if (videoState.state.videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN) {
    return <></>;
  }
  const goToSearch = () => {
    setSearchState(true);
  };

  const goToCart = () => {
    nav.setRoot({ path: '/cart', title: 'Cart' });
  };

  const goToPath = (path: string, title: string) => {
    nav.setRoot({ path, title });
  };

  const mainMenu: any = {
    items: [
      {
        itemName: userfullName,
        onClick: '#',
        header: true,
      },
      {
        itemName: 'My Account',
        onClick: '/my-account',
        header: false,
      },
      {
        itemName: 'Log Out',
        onClick: '/logout',
        header: false,
      },
    ],
  };

  if (apolloTracer.isTracing()) {
    mainMenu.items.push({
      itemName: 'Trace Output to Console',
      header: false,
      onClick: () => {
        const traceEvents = apolloTracer.getTraceEVents();
        console.log({ numberOfTraceEvents: traceEvents.length, traceEvents });
        console.log(JSON.stringify(traceEvents, null, 2));
      },
    });
  }

  const menuStyle = {
    rightAlign: true,
    topAlign: false,
  };

  const handleLogoClick = () => {
    if (logoRedirectUrl) {
      nav.setRoot({ path: logoRedirectUrl, title: `Home` });
    } else if (isLoggedIn) {
      nav.setRoot({ path: `/app`, title: `Home` });
    } else {
      nav.setRoot({ path: `/`, title: `Home` });
    }
  };

  const renderHeader = () => {
    // set items list
    let items = headerConfig.desktop;
    if (headerConfig.tablet && (tablet || mobile)) {
      items = headerConfig.tablet;
    }
    if (headerConfig.mobile && mobile) {
      items = headerConfig.mobile;
    }

    // filter based on login state
    if (isLoggedIn) {
      items = items.filter(item => !item.onlyLoggedOut);
    } else {
      items = items.filter(item => !item.onlyLoggedIn);
    }

    // filter based on location
    if (hideLogin) {
      items = items.filter(item => item.type !== 'login');
    }
    if (hideCart) {
      items = items.filter(item => item.type !== 'cart');
    }

    // filter based on time
    items = items.filter(item => {
      if (!item.displayAt) {
        return true;
      } else if (currentTime > item.displayAt) {
        return true;
      } else {
        return false;
      }
    });

    return (
      <div className={classNames('navbar', transparentBackground, navBarWrapper)} role="navigation" aria-label="main navigation">
        <div className={navbarBrand}>
          <div className={logo} onClick={() => handleLogoClick()} data-cy="headerLink">
            <Img data-cy="headerImg" childImageSharp={resource.logoImage.childImageSharp} className={brandLogo} />
          </div>
        </div>

        <div className={navBarItemGroup} data-cy="headerMenu">
          {searchState && <Search setSearchState={setSearchState} />}
          {(userState.isAdmin || userState.isManager) && isLoggedIn && <PreviewDropdown showStrategyPreview={showStrategyPreview} />}
          {items.map(item => {
            switch (item.type) {
              case 'search':
                return (
                  <IconButton dataCY="searchBtn" onClick={goToSearch} className={iconSearch}>
                    <Icon icon={searchIcon} height="30px" />
                  </IconButton>
                );

              case 'account':
                return (
                  <Dropdown
                    links={mainMenu}
                    dropDownState={Boolean(mainMenuState)}
                    setDropDownState={setMainMenuState}
                    menuStyle={menuStyle}
                  />
                );

              case 'cart':
                return <CartIcon onClick={goToCart} />;

              case 'button':
                if (!item.path) {
                  return <></>;
                }

                const containsBranch = isBrowser && window.location.pathname.includes(branchId);
                const targetUrl = containsBranch ? `/${branchId}${item.path}` : item.path;
                const isActive = isBrowser && window.location.pathname === targetUrl;

                // use the nav class (link within the system)
                return item.path?.substr(0, 5) === 'https' ? (
                  <a
                    key={item.path}
                    href={item.path}
                    className={configuredButton}
                    target={item.newTab ? '_blank' : undefined}
                    rel="noreferrer"
                  >
                    <Button className={navBarButton} style={ButtonStyles.Transparent}>
                      {item.label}
                    </Button>
                    {isActive && <div className={activeButton} />}
                  </a>
                ) : (
                  <div className={configuredButton} onClick={() => goToPath(item.path || '', item.label || '')}>
                    <Button className={navBarButton} style={ButtonStyles.Transparent}>
                      {item.label}
                    </Button>
                    {isActive && <div className={activeButton} />}
                  </div>
                );

              case 'hamburger':
                const subItems = item.subItems || [];
                return <HamburgerDropdown links={subItems} />;

              case 'login':
                return (
                  <Link to="/login" className={loginButton}>
                    <Button dataCY="login" style={ButtonStyles.Primary}>
                      Log In
                    </Button>
                  </Link>
                );
              default:
                return <></>;
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={navbarContainer} data-cy="header">
      {/* Rendering like this prevents snapshot tree test from breaking for different brands */}
      {isBrowser && renderHeader()}
    </div>
  );
};

export default Header;
