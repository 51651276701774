import React, { useContext, useEffect, useState } from 'react';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { useVideoState, VideoPlayerSize } from '@lib/useVideoState';
import { IconButton } from '@components/Buttons/IconButton';
import { Button } from '@components/Buttons';
import classNames from 'classnames';
import { BrandUtil, DatabaseVideo } from '@sharedLib/index';
import { CoordUtils } from '@lib/Coords';
import { usePlayNext } from '@lib/video-query/useVideoData';
import {
  finishedContainer,
  finishedVideoTitleGroup,
  finishedVideoTitleGroupMini,
  finishedTextMini,
  homeButton,
  homeButtonMini,
  videoUpNextContainer,
  hide,
  videoUpNextContainerMini,
  playButtonGroup,
  playButtonGroupMini,
  videoButton,
  videoButtonMini,
  cancelButton,
  cancelButtonMini,
  videoTitleGroup,
  nextTitle,
  nextTitleMini,
  nextVideoName,
  nextEpisodeNumber,
  nextVideoTextMini,
} from './styles.module.scss';
import { usePvepApi } from '@apiClient/usePvepApi';

interface Props {
  previousVideo?: DatabaseVideo | null;
  previousEpisode?: number;
}

const DEBUG = false;

interface PlayButtonProps {
  className: string;
}

const UpNextPlayButton = ({ className }: PlayButtonProps) => {
  return (
    <svg
      version="1.1"
      id="play"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      className={className}
    >
      <path
        className="stroke-solid"
        fill="none"
        stroke="#ddbe72"
        d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
        C97.3,23.7,75.7,2.3,49.9,2.5"
      />
      <path className="icon" fill="#ddbe72" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z" />
    </svg>
  );
};

export const VideoUpNext = ({ previousVideo, previousEpisode }: Props) => {
  const [countDown, setCountDown] = useState(10);
  const [manualNav, setManualNav] = useState(false);
  const videoState = useVideoState();
  const { state } = videoState;
  const { singletonPlayer } = state;
  const coords = state.coordinates;
  const videoContainerWidth = CoordUtils.toNumber(coords.size.width);
  const miniPlayerStyling = state.videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED || videoContainerWidth <= 500;
  const api = usePvepApi();
  const userState = api.state;
  const upNext = usePlayNext(
    userState.watching.videoId,
    userState.watching.seasonId,
    userState.watching.seriesId,
    userState.watching.categoryId
  );

  const playerConfig = BrandUtil.getSiteInfo().videoPlayerConfig;
  const { showEpisodeNumber } = playerConfig.upNext;

  const nav = useContext(NavStateContext);
  useEffect(() => {
    const { player } = singletonPlayer;
    ClientLogger.debug('VideoUpNext', 'useEffect', DEBUG, countDown);
    if (player) {
      player.on('ended', (_event: any) => {
        setCountDown(0);
      });
    }
    ClientLogger.debug('VideoUpNext', 'videoEnded', DEBUG, countDown);
    if (countDown <= 0) {
      if (upNext?.video && !manualNav) {
        ClientLogger.debug('VideoUpNext', 'redirectToNextVideo', DEBUG, countDown);
        onButtonClickNext();
      }
    }
  }, [countDown, manualNav]);

  const onCLickHome = () => {
    setCountDown(0);
    setManualNav(true);
    state.showingPlayNext = false;
    nav.setRoot({ path: `/app`, title: `Home` });
  };

  const onButtonClickNext = async () => {
    if (upNext?.video) {
      setCountDown(0);
      setManualNav(true);
      videoState.showPlayNext(false);
      state.showingPlayNext = false;

      // update watching location
      await api.updateUserWatching({
        ...userState.watching,
        videoId: upNext.video.id,
      });

      // The array position one behind 'featured products'
      const replacePosition = nav.navState.pageData.length - 2;
      nav.replace({ path: `/video/${upNext.video.id}/`, title: upNext.video.title || '' }, replacePosition);
    }
  };

  const onButtonClickCancel = () => {
    if (previousVideo) {
      setCountDown(0);
      setManualNav(true);
      videoState.showPlayNext(false);
      state.showingPlayNext = false;
      nav.replace({ path: `/video/${previousVideo.id}/`, title: previousVideo.title });
    }
  };

  ClientLogger.debug('VideoUpNext', 'render', DEBUG, {
    upNext,
    previousVideo,
  });

  if (!upNext || !upNext.video) {
    return (
      <div className={classNames(videoUpNextContainer, finishedContainer, miniPlayerStyling ? videoUpNextContainerMini : '')}>
        <div className={classNames(videoTitleGroup, finishedVideoTitleGroup, miniPlayerStyling ? finishedVideoTitleGroupMini : '')}>
          <div className={classNames(nextTitle, miniPlayerStyling ? nextTitleMini : '')}>Finished watching</div>
          <div className={classNames(nextVideoName, miniPlayerStyling ? finishedTextMini : '')}>{previousVideo?.title}</div>
          {showEpisodeNumber && previousEpisode && (
            <div className={classNames(nextEpisodeNumber, miniPlayerStyling ? nextVideoTextMini : '')}>Episode {previousEpisode}</div>
          )}
        </div>
        <div className={classNames(playButtonGroup, miniPlayerStyling ? playButtonGroupMini : '')}>
          <Button className={classNames(homeButton, miniPlayerStyling ? homeButtonMini : '')} onClick={onCLickHome}>
            Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(videoUpNextContainer, manualNav ? hide : '', miniPlayerStyling ? videoUpNextContainerMini : '')}>
      <div className={classNames(playButtonGroup, miniPlayerStyling ? playButtonGroupMini : '')}>
        <IconButton onClick={onButtonClickNext}>
          <UpNextPlayButton className={classNames(videoButton, miniPlayerStyling ? videoButtonMini : '')} />
        </IconButton>
        <div className={classNames(cancelButton, miniPlayerStyling ? cancelButtonMini : '')} onClick={onButtonClickCancel}>
          Cancel
        </div>
      </div>

      <div className={videoTitleGroup}>
        <div className={classNames(nextTitle, miniPlayerStyling ? nextTitleMini : '')}>Up Next</div>
        <div className={classNames(nextVideoName, miniPlayerStyling ? nextVideoTextMini : '')}>{upNext.video?.title || ''}</div>
        {showEpisodeNumber && (
          <div className={classNames(nextEpisodeNumber, miniPlayerStyling ? nextVideoTextMini : '')}>Episode {upNext.upNextEpisode}</div>
        )}
      </div>
    </div>
  );
};

export default VideoUpNext;
