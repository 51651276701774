/* eslint-disable camelcase */
import { usePvepApi } from '@apiClient/usePvepApi';
import { IconButton } from '@components/Buttons';
import { PlayerControls } from '@components/VideoPlayer/PlayerControls';
import { VideoJSPlayer } from '@components/VideoPlayer/VideoJS/VideoJSPlayer';
import { VimeoPlayer } from '@components/VideoPlayer/VimeoPlayer';
import VideoUpNext from '@components/VideoUpNext/VideoUpNext';
import closeIcon from '@iconify/icons-ion/close';
import { Icon } from '@iconify/react';
import { refreshAds, getStaticBannerProducts } from '@lib/adPlacements';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { Coords, CoordUtils } from '@lib/Coords';
import { NavStateContext } from '@lib/navContext';
import { useRefPair } from '@lib/useRefPair';
import {
  PlayerReady,
  useVideoState,
  VideoPlayerSize,
  PlayerStatus,
  OrientationState,
  getBrowserFullscreenElement,
  lockScreenOrientation,
} from '@lib/useVideoState';
import {
  useVideoData,
  getEpisodeNumberInCategory,
  getEpisodeNumberInSeason,
  getDefaultEpisodeNumber,
  getDefaultSeasonNumber,
  getSeasonNumberInSeries,
} from '@lib/video-query/useVideoData';
import { VideoSource, BrandUtil, AdWithProduct } from '@sharedLib/index';
import { StyleConstants } from '@styles/variables';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProductDetails } from '@components/ProductDetails';
import { ShopNowOverlay } from '../ShopOverlay/ShopNowOverlay';
import { NonMemberCard } from '../SignUpPrompt';
import BannerAds from './BannerAds';
import { isEmbedded } from '@src/lib/embedded';

import {
  hide,
  videoAndAdWrapper,
  popUpBorder,
  popUpHeader,
  popUpClose,
  videoPlayerMedium,
  videoPlayerLarge,
  videoPlayerSmall,
  videoPlayerNotShown,
  jsPlayer,
  jsMiniPlayer,
} from './styles.module.scss';

const DEBUG = false;
const DEBUG_ADS = false;
const DEBUG_FULLSCREEN = false;

enum PlayerControlsLocation {
  NONE,
  BOTTOM_OVERLAY,
  BOTTOM_BELOW,
}

export enum PLAYER_EVENT {
  PLAY,
  PAUSE,
  SKIP_NEXT,
  SMALL_SCREEN,
  MEDIUM_SCREEN,
  BIG_SCREEN,
  REQUEST_FULL_SCREEN,
  EXIT_FULL_SCREEN,
  // Internal Testing Tools
  CHROMELESS_TOGGLE,
  OVERLAY_TOGGLE,
}

export const VideoPlayer = () => {
  // Video State
  const videoState = useVideoState();
  const { state } = videoState; // useContext(VideoStateContext);
  const { video } = state;
  const { adPlacements } = state;
  const [getAds, setAdsRef] = useRefPair<AdWithProduct[] | null>(useRef(null));
  const [adsState, setAdsState] = useState<AdWithProduct[] | null>(null);
  const [staticBannerAds, setStaticBannerAds] = useState<AdWithProduct[] | null>(null);
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();

  const setAds = (newVal: AdWithProduct[] | null) => {
    DEBUG_ADS && ClientLogger.debug('VideoPlayer', 'setAds', { newVal, cancel: throttledAdSetting.current?.cancel });
    if (throttledAdSetting.current?.cancel) {
      DEBUG_ADS && ClientLogger.debug('VideoPlayer', 'setAds - cancelling');
      throttledAdSetting.current.cancel();
    }
    setAdsRef(newVal);
    setAdsState(newVal);
  };
  const [chromeless, setChromeless] = useState(false); // window.location.href.indexOf('vimeoplayer') === -1);
  const [playerControlsLocation, setPlayerControlsLocation] = useState<PlayerControlsLocation>(
    isBrowser && window.location.href.indexOf('player_controls_location') > -1
      ? // eslint-disable-next-line no-restricted-globals
        (Number(queryString.parse(location.search).player_controls_location) as PlayerControlsLocation)
      : PlayerControlsLocation.BOTTOM_OVERLAY
  );
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const sessionId = useRef<string | null>(null);

  const maximizableElement = React.useRef(null);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [videoAspectRatio, setVideoAspectRatio] = useState(16 / 9);
  const [hidePlayerControls, setHidePlayerControls] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(getBrowserFullscreenElement() != null);
  const api = usePvepApi();

  const userState = api.state;
  const allVideoData = useVideoData();
  const [episodeNumber, setEpisodeNumber] = useState<number | undefined>(undefined);
  const [seasonNumber, setSeasonNumber] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (video?.id) {
      checkUserWatchingVideo(video?.id);
    }
  }, [video?.id]);

  /// check/update watching location stored
  const checkUserWatchingVideo = async (videoId: string) => {
    if (userState.watching.videoId !== videoId) {
      // get default episode/season numbers if there is no location data
      const defaultEpisode = getDefaultEpisodeNumber(allVideoData.seasons, allVideoData.categories, videoId);
      let defaultSeason: { rank: number; seriesId: string } | undefined;
      if (defaultEpisode?.seasonId) {
        defaultSeason = getDefaultSeasonNumber(allVideoData.series, defaultEpisode.seasonId);
      }

      // update watching location
      await api.updateUserWatching({
        categoryId: '',
        seriesId: defaultSeason?.seriesId || '',
        seasonId: defaultEpisode?.seasonId || '',
        videoId,
      });
      setEpisodeNumber(defaultEpisode?.rank);
      setSeasonNumber(defaultSeason?.rank);
    } else {
      // set episode and season number based on watching location
      if (userState.watching.seasonId) {
        const season = allVideoData.seasons.find(sea => sea.id === userState.watching.seasonId);
        if (season) {
          setEpisodeNumber(getEpisodeNumberInSeason(season, videoId));
        }

        // if both season and series data is available, get season number
        if (userState.watching.seriesId) {
          const series = allVideoData.series.find(ser => ser.id === userState.watching.seriesId);
          if (series) {
            setSeasonNumber(getSeasonNumberInSeries(series, userState.watching.seasonId));
          }
        }
      } else if (!userState.watching.seasonId && userState.watching.categoryId) {
        // if not in a season, but in a category, get episode number from category
        const category = allVideoData.categories.find(cat => cat.id === userState.watching.categoryId);
        if (category) {
          setEpisodeNumber(getEpisodeNumberInCategory(category, videoId));
        }
      }
    }
  };

  useEffect(() => {
    videoState.setElRef(maximizableElement);
  }, []);

  //
  // detect screen orientation and fullscreen changes via event listener
  //
  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChangeEvent, true);
    document.addEventListener('fullscreenchange', onFullScreenChangeEvent, true);
    document.addEventListener('webkitfullscreenchange', onFullScreenChangeEvent, true); //safari

    return () => {
      window.removeEventListener('orientationchange', onOrientationChangeEvent, true);
      document.removeEventListener('fullscreenchange', onFullScreenChangeEvent, true);
      document.removeEventListener('webkitfullscreenchange', onFullScreenChangeEvent, true); //safari
    };
  }, []);

  //
  // orientationchange event listener callback sets videostate orientation
  //
  const onOrientationChangeEvent = () => {
    const newOrientation: OrientationState = screen.orientation
      ? { angle: screen.orientation.angle, orientation: screen.orientation.type }
      : { angle: 0, orientation: 'unknown' };
    DEBUG_FULLSCREEN &&
      ClientLogger.debug('VideoPlayer: orientationChangeEvent', 'Window orientation change detected', DEBUG_FULLSCREEN, newOrientation);
    videoState.setOrientation(newOrientation);
  };

  //
  // document fullscreenchange event listener callback sets isFullscreen state
  // used in combination with useEffect to set player size
  //
  const onFullScreenChangeEvent = () => {
    setIsFullscreen(getBrowserFullscreenElement() != null);
  };

  //
  // listen for changes in fullscreen state and set video size accordingly
  // also catches ESC presses and returns screen to normal size
  //
  useEffect(() => {
    if (!isFullscreen) {
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
    } else {
      videoState.setVideoSize(VideoPlayerSize.LARGE_FULLSCREEN);
    }
  }, [isFullscreen]);

  //
  // listen for changes in videostate's orientation and respond
  //
  useEffect(() => {
    DEBUG_FULLSCREEN &&
      ClientLogger.debug(
        'VideoPlayer: orientation UseEffect',
        'Video state orientation change detected',
        DEBUG_FULLSCREEN,
        videoState.state.orientation
      );
    const newOrientation = videoState.state.orientation;

    if (newOrientation.orientation === 'landscape-primary' || newOrientation.orientation === 'landscape-secondary') {
      if (getBrowserFullscreenElement() == null) {
        requestFullScreen();
      }
    } else {
      if (getBrowserFullscreenElement() != null) {
        exitFullScreen();
      }
    }
  }, [videoState.state.orientation]);

  //
  // Request browser full screen via videostate element ref
  //
  const requestFullScreen = () => {
    const elRef = videoState.state.elRef;
    if (!elRef || !elRef.current) {
      ClientLogger.error('VideoPlayer: requestFullScreen', 'Failed due to missing elRef');
      return;
    }

    if (elRef.current.requestFullscreen) {
      elRef.current.requestFullscreen().catch(e => ClientLogger.warning('VideoPlayer:requestFullScreen', `Fullscreen error: ${e}`));

      // @ts-ignore
    } else if (elRef.current.webkitRequestFullscreen) {
      // @ts-ignore
      elRef.current.webkitRequestFullscreen(); //safari
    } else {
      // Safari ios will only allow video element with native controls in fs
      const videoElements = document.getElementsByTagName('video');
      // @ts-ignore
      if (videoElements.length && videoElements[0].webkitEnterFullscreen) {
        // @ts-ignore
        videoElements[0].webkitEnterFullscreen(); //safari ios
      }
    }
  };

  //
  // Request full screen exit
  //
  const exitFullScreen = () => {
    if (window.document.exitFullscreen) {
      window.document.exitFullscreen();
      // @ts-ignore
    } else if (window.document.webkitExitFullscreen) {
      //@ts-ignore
      window.document.webkitExitFullscreen(); //safari
    }
  };

  useEffect(() => {
    if (video !== null) {
      switch (video.type) {
        case VideoSource.VIMEO_OTT:
          setVideoURL(
            `https://embed.vhx.tv/videos/${video.externalId || video.id}?sharing=0&vimeo=1&auto=1&playsinline=1&color=${
              StyleConstants.playerButtonHex
            }&api=1${chromeless ? '&chromeless=1' : ''}&authorization=${state.token}`
          );
          setAuthCode(state.token || '');
          break;
        case VideoSource.YOUTUBE:
        case VideoSource.CORE_VIMEO:
        case VideoSource.UPLOADED:
          setVideoURL(video.url);
          setAuthCode('free');
          break;
        default:
          ClientLogger.warning('VideoPlayer.useEffect', `Unexpected video type ${video.type}`);
      }
      if (state.readyStatus === PlayerReady.LOADING_SHOW) {
        // Session Id is user id and session start time. Device id used instead of user id if not logged in
        sessionId.current = `u:${api.state.jwt ? api.state.jwt.sub : `${api.state.deviceId} unknown-user`} s:${new Date().toISOString()}`;
        ClientLogger.debug('VideoPlayer.useEffect', 'waiting for player status', DEBUG, { videoId: video.id, title: video.title, state });
        if (video.type === VideoSource.VIMEO_OTT) {
          videoState
            .waitForPlayerStatus(
              testStatus => {
                return testStatus?.isReadyToPlay || false;
              },
              false,
              5
            )
            .then(() => {
              videoState.firstRenderDone();
            })
            .catch(() => {
              const status = videoState.getPlayerStatus(state.singletonPlayer, undefined);
              ClientLogger.error('VideoPlayer.useEffect', 'time out waiting for player ready', status);
              videoState.firstRenderDone(); // Drive on.  Man, the vimeo API is flakey
            });
        }
        // initialize ads on loading-show
        const newStatus = videoState.getPlayerStatus(state.singletonPlayer, false);
        const initialAds = refreshAds(
          adPlacements || undefined,
          newStatus && newStatus.currentTime !== undefined ? newStatus.currentTime : 0
        );
        ClientLogger.debug('VideoPlayer.useEffect', 'setting initial ads', DEBUG, { initialAds });
        const ready = state.readyStatus;
        ClientLogger.debug('VideoPlayer.useEffect', 'useEffect args', DEBUG, { video, ready, chromeless });
        DEBUG_ADS && ClientLogger.debug('VideoPlayer.useEffect', 'initialAds', { initialAds });
        setAds(initialAds);
      }

      const staticProducts = getStaticBannerProducts(adPlacements);
      setStaticBannerAds(staticProducts);
    }
  }, [video, state.readyStatus, chromeless]);

  const navigateToVideo = ({ video }: { video: { id: string | null; title: string | null; label?: string | null } | null }) => {
    const isVideoPage = window.location.pathname.includes('/video');
    if (isBrowser && video?.id && !isVideoPage) {
      nav.replaceInStack({ path: `/video/${video.id}/`, title: video?.label || video?.title || '' });
    }
  };

  // Handle user commands from our player component
  function playerControlHandler(event: PLAYER_EVENT) {
    if (state.singletonPlayer) {
      ClientLogger.debug('playerControlHandler', `Player Event = ${PLAYER_EVENT[event]}`, DEBUG);
      switch (event) {
        case PLAYER_EVENT.PLAY:
          // @ts-ignore
          vhxRef.current.play();
          videoState.play();
          return;
        case PLAYER_EVENT.PAUSE:
          // @ts-ignore
          vhxRef.current.pause();
          videoState.pause();
          return;
        case PLAYER_EVENT.BIG_SCREEN:
          videoState.setVideoSize(VideoPlayerSize.LARGE_FULLSCREEN, true);
          navigateToVideo(videoState.state);
          return;
        case PLAYER_EVENT.MEDIUM_SCREEN:
          videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED, true);
          navigateToVideo(videoState.state);
          return;
        case PLAYER_EVENT.SMALL_SCREEN:
          videoState.setVideoSize(VideoPlayerSize.SMALL_FLOAT, true);
          return;
        case PLAYER_EVENT.REQUEST_FULL_SCREEN:
          requestFullScreen();
          // lock screen to landscape considering if in initial landscape-secondary position
          lockScreenOrientation(
            videoState.state.orientation.orientation === 'landscape-secondary' ? 'landscape-secondary' : 'landscape-primary'
          );
          return;
        case PLAYER_EVENT.EXIT_FULL_SCREEN:
          exitFullScreen();
          return;
        case PLAYER_EVENT.OVERLAY_TOGGLE:
          setPlayerControlsLocation(
            PlayerControlsLocation.BOTTOM_OVERLAY === playerControlsLocation
              ? PlayerControlsLocation.BOTTOM_BELOW
              : PlayerControlsLocation.BOTTOM_OVERLAY
          );
          return;
        case PLAYER_EVENT.CHROMELESS_TOGGLE:
          setChromeless(!chromeless);
          return;
        default:
          ClientLogger.error('playerControlHandler', 'Unexpected Event');
      }
    }
  }

  const throttledAdSetting = useRef(
    throttle((data: { adPlacements: AdWithProduct[] | undefined | null; currentTime: number; playerStatus: PlayerStatus | null }) => {
      const displayAds = refreshAds(data.adPlacements || undefined, data.currentTime);
      const { videoPlayerSize } = videoState.state;
      DEBUG_ADS &&
        ClientLogger.debug('throttledAdSetting', 'time to check', {
          currentTime: data.currentTime,
          displayAds,
          data,
          videoPlayerSize,
        });
      setAds(displayAds);
      if (data.playerStatus && data.playerStatus.isPlaying && videoPlayerSize === VideoPlayerSize.NOT_SHOWN) {
        // Better stop playing
        DEBUG_ADS &&
          ClientLogger.debug('throttledAdSetting', 'turning off player', {
            currentTime: data.currentTime,
            displayAds,
            data,
            videoPlayerSize,
          });
        playerControlHandler(PLAYER_EVENT.PAUSE);
      }
    }, 2000)
  );

  const useVideoJS = video?.type === VideoSource.YOUTUBE || video?.type === VideoSource.CORE_VIMEO || video?.type === VideoSource.UPLOADED;

  const updateAds = (current_time: number, playerStatus: PlayerStatus | null) => {
    const ads = getAds();
    DEBUG_ADS && ClientLogger.debug('VideoPlayer.updateAds', '', { current_time, ads });
    if (ads) {
      throttledAdSetting.current({ adPlacements: ads, currentTime: current_time, playerStatus });
    } else {
      throttledAdSetting.current({ adPlacements, currentTime: current_time, playerStatus });
    }
  };

  // PopUpCoordinates
  const startingWidth = 380;
  const headerSize = 32;
  const [popUpCoords] = useState<Coords>({
    position: { x: 45, y: 135 },
    size: { width: startingWidth, height: startingWidth / videoAspectRatio + headerSize },
  });
  const { videoPlayerSize } = videoState.state;
  const floating = videoPlayerSize === VideoPlayerSize.SMALL_FLOAT;
  const coords = ((): Coords => {
    switch (videoPlayerSize) {
      case VideoPlayerSize.NOT_SHOWN:
        return { position: { x: 0, y: 0 }, size: { height: 0, width: 0 } };
      case VideoPlayerSize.SMALL_FLOAT:
        return popUpCoords;
      case VideoPlayerSize.MEDIUM_EMBEDDED:
      case VideoPlayerSize.SMALL_EMBEDDED:
        return state.coordinates;
      case VideoPlayerSize.LARGE_FULLSCREEN:
        return { position: { x: 0, y: 0 }, size: { height: window.innerHeight, width: window.innerWidth } };
      default:
        throw new Error('Unexpected VideoPlayerSize');
    }
  })();

  // Calculate component inclusion and sizes
  /*
  +-RnD-------------------------------------------------------------+
  | +-videoAndAdWrapper-------------------------------------------+ |
  | | +---------------------------------------------------------+ | |
  | | | Header Bar                                              | | |
  | | +--------------------------------------------------------+| | |
  | | +---------------------------------------------------------+ | |
  | | | Iframe                                                  | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | |                                                         | | |
  | | | +-------------------------------------------------------+ | |
  | | | | Player Controls                                       | | |
  | | | +-------------------------------------------------------+ | |
  | | +---------------------------------------------------------+ | |
  | | +---------------------------------------------------------+ | |
  | | | Banner Ads                                              | | |
  | | +---------------------------------------------------------+ | |
  | +-------------------------------------------------------------+ |
  +-----------------------------------------------------------------+
   */
  const videoContainerHeight = CoordUtils.toNumber(coords.size.height);
  const videoContainerWidth = CoordUtils.toNumber(coords.size.width);

  const showBannerAds = brandInfo.videoPlayerConfig.useBannerAds && videoContainerHeight >= 175;
  const adsHeight = showBannerAds ? Math.round(Math.min(videoContainerHeight * 0.1, 75)) : 0;

  // Solve for largest rectangle of required aspect ratio that fits into space given

  // Compute widest possible
  let widestRectWidth = videoContainerWidth;
  let widestRectHeight = widestRectWidth / videoAspectRatio + adsHeight;
  if (widestRectHeight > videoContainerHeight) {
    ClientLogger.debug('VideoPlayer', 'render:downsizing widest', DEBUG, {
      videoContainerWidth,
      videoContainerHeight,
      widestRectWidth,
      widestRectHeight,
    });
    widestRectHeight = videoContainerHeight - adsHeight;
    widestRectWidth = widestRectHeight * videoAspectRatio;
  }

  // Compute tallest possible
  let tallestRectHeight = videoContainerHeight;
  let tallestRectWidth = (tallestRectHeight - adsHeight) * videoAspectRatio;
  if (tallestRectWidth > videoContainerWidth) {
    ClientLogger.debug('VideoPlayer', 'render:downsizing highest', DEBUG, {
      videoContainerWidth,
      videoContainerHeight,
      tallestRectWidth,
      tallestRectHeight,
    });
    const shrinkBy = videoContainerWidth / tallestRectWidth;
    tallestRectWidth *= shrinkBy;
    tallestRectHeight *= shrinkBy;
  }

  // Use the biggest
  const useWidest = widestRectWidth * widestRectHeight > tallestRectWidth * tallestRectHeight;
  const adVideoAndAdWrapperWidth = useWidest ? widestRectWidth : tallestRectWidth;
  let adVideoAndAdWrapperHeight = useWidest ? widestRectHeight : tallestRectHeight;

  const showHeaderBar = videoPlayerSize === VideoPlayerSize.SMALL_FLOAT;
  const headerBarHeight = showHeaderBar ? headerSize : 0;
  let iframeWidth = Math.round(adVideoAndAdWrapperWidth);
  let iframeHeight = Math.round(iframeWidth / videoAspectRatio);
  // let iframeWidth = videoContainerWidth; // Avoid black line beside player by making width integer in CSS file. Was: adVideoAndAdWrapperWidth;
  // let iframeHeight = videoContainerWidth / videoAspectRatio; // adVideoAndAdWrapperWidth / videoAspectRatio;
  if (videoPlayerSize === VideoPlayerSize.SMALL_FLOAT) {
    // Adjust height to be ideal
    coords.size.height = headerBarHeight + iframeHeight + adsHeight;
    // shrink width for border
    iframeWidth -= 8;
    iframeHeight = iframeWidth / videoAspectRatio;
    adVideoAndAdWrapperHeight = coords.size.height + 4; // for border
  }
  const iFrameTop = showHeaderBar ? headerBarHeight + 1 : 0;
  const iframeLeft = (videoContainerWidth - iframeWidth) / 2;
  const adsCoords: Coords = {
    position: { x: iframeLeft, y: iframeHeight + iFrameTop },
    size: { width: iframeWidth, height: adsHeight },
  };
  DEBUG &&
    ClientLogger.debug('VideoPlayer', 'render', {
      useVideoJS,
      video,
      videoSource: videoURL,
      videoState,
      videoSize: VideoPlayerSize[videoState.state.videoPlayerSize],
      showBannerAds,
      state,
      time: new Date(),
      coords: {
        coords,
        videoContainerWidth,
        videoContainerHeight,
        widestRectWidth,
        widestRectHeight,
        tallestRectWidth,
        tallestRectHeight,
        adVideoAndAdWrapperWidth,
        adVideoAndAdWrapperHeight,
        iframeWidth,
        iframeHeight,
        useWidest,
        videoAspectRatio,
        realiFrameAspectRatio: iframeWidth / iframeHeight,
        adsHeight,
        adsCoords,
        floating,
        height: videoContainerHeight,
      },
    });

  // show non-member card if user is not a member, and the video is not ppv, free, or from youtube
  const showNonMemberCard =
    state.userNotMember && !state.video?.isFree && state.video?.type === VideoSource.VIMEO_OTT && !state.payPerViewNotPurchased;

  return (
    <span id="video-player">
      <div
        className={classnames(
          videoPlayerSize === VideoPlayerSize.NOT_SHOWN ? hide : videoAndAdWrapper,
          videoPlayerSize === VideoPlayerSize.SMALL_FLOAT ? popUpBorder : undefined
        )}
        ref={maximizableElement}
        data-cy={`video-player-wrapper-${state.video?.id}`}
      >
        {showHeaderBar ? (
          <div className={popUpHeader} style={{ width: '100%', height: headerBarHeight }}>
            <strong>{video ? video.title : ''}</strong>
            <IconButton
              className={classnames(popUpClose, 'is-pulled-right')}
              onClick={() => {
                videoState.pause();
                videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
              }}
            >
              <Icon icon={closeIcon} />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            height: videoPlayerSize === VideoPlayerSize.NOT_SHOWN ? 0 : iframeHeight,
            width: videoPlayerSize === VideoPlayerSize.NOT_SHOWN ? 0 : iframeWidth,
            left: iframeLeft,
            top: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN ? 0 : videoState.state.placeholderPosition,
          }}
          className={classnames(
            videoPlayerSize === VideoPlayerSize.SMALL_FLOAT ? videoPlayerSmall : undefined,
            videoPlayerSize === VideoPlayerSize.MEDIUM_EMBEDDED ? videoPlayerMedium : undefined,
            videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN ? videoPlayerLarge : undefined,
            videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? videoPlayerSmall : undefined,
            videoPlayerSize === VideoPlayerSize.NOT_SHOWN ? videoPlayerNotShown : undefined
          )}
          onMouseEnter={() => setHidePlayerControls(false)}
          onMouseLeave={() => setHidePlayerControls(true)}
          onTouchStart={() => {
            setHidePlayerControls(false);
            setTimeout(() => {
              setHidePlayerControls(true);
            }, 2000);
          }}
          data-cy={`video-player-placeholder-${state.video?.id}`}
        >
          {state.payPerViewNotPurchased && state.ppvInfo && (
            <ProductDetails
              applyPlayerStyling={false}
              video={state.video}
              adPlacements={state.adPlacements}
              productId={state.ppvInfo?.ppvProductId}
              navOnClick
              showBackButton
              updateParent={() => videoState.ppvPurchaseStart()}
              ctaOverrideMessage="Buy Now"
              showMessage
              fillParentContainer
            />
          )}

          {showNonMemberCard && state.video && <NonMemberCard video={state.video} />}

          {state.showingPlayNext && (
            <VideoUpNext
              previousVideo={state.video}
              previousEpisode={episodeNumber}
              data-cy={`video-player-controls-bottom-below-${state.video?.id}`}
            />
          )}
          {useVideoJS ? (
            <div className={classnames(jsPlayer, videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? jsMiniPlayer : '')}>
              {authCode ? (
                <>
                  <VideoJSPlayer
                    videoState={videoState}
                    sessionId={sessionId.current || ''}
                    src={videoURL || ''}
                    title={`video ${video ? video.title : ''}`}
                    authorization={authCode}
                    onTimer={(duration, currentTime) => {
                      const ads = getAds();
                      DEBUG_ADS && ClientLogger.debug('VideoPlayer.onTimer', 'callback fired', { duration, currentTime, ads });
                      throttledAdSetting.current({
                        currentTime: currentTime || 0,
                        adPlacements: ads,
                        playerStatus: null,
                      });
                    }}
                    onFullscreenToggle={() => {
                      const { videoPlayerSize } = videoState.state;
                      if (videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN) {
                        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED, true);
                      } else {
                        videoState.setVideoSize(VideoPlayerSize.LARGE_FULLSCREEN, true);
                      }
                    }}
                    onSizeDiscovered={(height, width) => {
                      ClientLogger.debug('VideoJSPlayer.onSizeDiscovered', 'fired', DEBUG, { height, width });
                    }}
                    playerControlHandler={playerControlHandler}
                    data-cy={`video-player-${state.video?.id}`}
                    episodeNumber={episodeNumber}
                    seasonNumber={seasonNumber}
                  />
                  {video?.type === VideoSource.YOUTUBE &&
                    playerControlsLocation === PlayerControlsLocation.BOTTOM_OVERLAY &&
                    videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED && (
                      <PlayerControls
                        playing={state.playing}
                        size={videoPlayerSize}
                        videoState={state}
                        onUserAction={playerControlHandler}
                        hidden={hidePlayerControls}
                        data-cy={`video-player-controls-bottom-overlay-${state.video?.id}`}
                        episodeNumber={episodeNumber}
                        seasonNumber={seasonNumber}
                        hideMainControls
                      />
                    )}
                </>
              ) : (
                <div>Authorizing...</div>
              )}
            </div>
          ) : (
            <>
              <VimeoPlayer
                videoState={videoState}
                sessionId={sessionId}
                src={video?.type === VideoSource.VIMEO_OTT ? videoURL || '' : ''}
                height={iframeHeight}
                width={iframeWidth}
                updatePlayerAds={updateAds}
              />

              {playerControlsLocation === PlayerControlsLocation.BOTTOM_OVERLAY && state.readyStatus !== PlayerReady.LOADING_HIDE && (
                <PlayerControls
                  playing={state.playing}
                  size={videoPlayerSize}
                  videoState={state}
                  onUserAction={playerControlHandler}
                  hidden={state.showingPlayNext || shareModalOpen ? false : hidePlayerControls}
                  hideBrowse={isEmbedded()}
                  showCart={isEmbedded()}
                  data-cy={`video-player-controls-bottom-overlay-${state.video?.id}`}
                  episodeNumber={episodeNumber}
                  seasonNumber={seasonNumber}
                  modalCallback={isOpen => setShareModalOpen(isOpen)}
                />
              )}
            </>
          )}
          {!brandInfo.videoPlayerConfig.useBannerAds && (
            <ShopNowOverlay
              staticAds={staticBannerAds}
              ads={adsState}
              video={video || undefined}
              data-cy={`video-player-banner-ads-${state.video?.id}`}
            />
          )}
        </div>
        {showBannerAds && (
          <BannerAds coords={adsCoords} ads={adsState} video={video || undefined} data-cy={`video-player-banner-ads-${state.video?.id}`} />
        )}

        {playerControlsLocation === PlayerControlsLocation.BOTTOM_BELOW && (
          <PlayerControls
            playing={state.playing}
            size={videoPlayerSize}
            videoState={state}
            onUserAction={playerControlHandler}
            hidden={hidePlayerControls}
            data-cy={`video-player-controls-bottom-below-${state.video?.id}`}
            episodeNumber={episodeNumber}
            seasonNumber={seasonNumber}
          />
        )}
      </div>
    </span>
  );
};
