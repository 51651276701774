import React from 'react';
import { pricingCardContainer, headerContainer, pricingCardTitle, priceText, detailsText } from './styles.module.scss';

interface Props {
  title: string;
  plan: string;
  details: string;
  price: string;
}

const PricingCard = ({ title, plan, details, price }: Props) => {
  return (
    <div className={pricingCardContainer}>
      <div className={headerContainer}>
        <p className={pricingCardTitle}>{title}</p>
        <p className={priceText}>{price}</p>
      </div>
      <p className={detailsText}>{plan}</p>
      <p className={detailsText}>{details}</p>
    </div>
  );
};

export default PricingCard;
