import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { filterSearchContainer, filterSearchField, filterSearchIcon } from './styles.module.scss';

interface Props {
  onChange: (value: string) => void;
  initialQuery?: string;
  placeholder?: string;
}

export const FilterSearch = ({ onChange, placeholder, initialQuery }: Props) => {
  const [query, setQuery] = useState(initialQuery || '');

  return (
    <div className={filterSearchContainer}>
      <input
        value={query}
        onChange={e => {
          setQuery(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder || 'Search'}
        className={filterSearchField}
      />
      <Icon icon={'eva:search-fill'} className={filterSearchIcon} />
    </div>
  );
};
