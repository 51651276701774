import React, { useRef, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@components/Buttons';
import {
  fileUploaderText,
  fileUploaderCustom,
  fileUploaderStyle,
  flexRow,
  fileNameRow,
  fileUploaderContainer,
  fileUploadIcon,
  fileUploaderButton,
  fileUploadIconActive,
  errorMessage,
  fileUploadTitle,
  fileIcon,
  fileNameContainer,
  deleteButton,
  fileUploaderProgress,
  fileUploaderProgressText,
} from './styles.module.scss';
import { BrandUtil } from '@sharedLib/index';
import classNames from 'classnames';
import { Icon } from '@iconify/react';

const uploadFileValidation = yup.object().shape({
  file: yup.mixed().required('A file is required'),
});

const DEBUG = false;

interface Props {
  onSuccess?: (fileName: string) => void;
  onStart?: () => void;
  onCancel?: () => void;
}

export const Uploader = ({ onSuccess, onStart, onCancel }: Props) => {
  const api = usePvepApi();
  const fileInput: any = useRef<HTMLInputElement>();
  const [uploadProgress, setUploadProgress] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [fileTypeError, setFileTypeError] = useState('');
  const [currentRequest, setCurrentRequest] = useState<XMLHttpRequest | undefined>(undefined);

  const brandInfo = BrandUtil.getSiteInfo();
  const bucketName = brandInfo.videoHostingConfig.bucketName;

  const handleUpload = async (values: any, resetForm: () => any) => {
    const urlResp = await api.getS3UploadUrl(bucketName, values.file.name, values.file.type);
    if (urlResp.data?.getS3UploadUrl.success) {
      setIsUploading(true);
      const xhr = new XMLHttpRequest();
      setCurrentRequest(xhr);
      xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
        if (e.lengthComputable) {
          const percentComplete = Math.round((e.loaded / e.total) * 100);
          const totalMB = e.total / Math.pow(10, 6);
          const loadedMB = e.loaded / Math.pow(10, 6);
          const loadedMessage = loadedMB > 1000 ? `${(loadedMB / 1000).toFixed(2)}GB` : `${loadedMB.toFixed(1)}MB`;
          const totalMessage = totalMB > 1000 ? `${(totalMB / 1000).toFixed(2)}GB` : `${totalMB.toFixed(1)}MB`;

          const message = `${loadedMessage} of ${totalMessage}`;
          setUploadProgress(message);
          setUploadPercentage(percentComplete);
        }
      };

      xhr.onreadystatechange = function onReady() {
        // eslint-disable-next-line react/no-this-in-sfc
        if (this.readyState === XMLHttpRequest.DONE) {
          setUploadProgress('');
          setIsUploading(false);
          setCurrentRequest(undefined);

          // eslint-disable-next-line react/no-this-in-sfc
          if (this.status === 200) {
            setUploadSuccess(true);
            setUploadError('');
            resetForm();
            if (onSuccess) {
              onSuccess(values.file.name);
            }
          } else {
            setUploadSuccess(false);
            // eslint-disable-next-line react/no-this-in-sfc
            setUploadError(`Upload failed, AWS returned ${this.status}: ${this.statusText}`);
          }
        }
      };

      xhr.open('PUT', urlResp.data?.getS3UploadUrl.url, true);
      xhr.setRequestHeader('Content-Type', values.file.type);
      xhr.send(values.file);
    } else {
      setUploadError(`Unable to get presigned url from AWS, returned ${JSON.stringify(urlResp.data?.getS3UploadUrl.errorMessages)}`);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          file: '' as any,
        }}
        validationSchema={uploadFileValidation}
        onSubmit={(values, { resetForm }) => {
          setUploadError('');
          setUploadSuccess(false);
          handleUpload(values, resetForm);
        }}
      >
        {props => (
          <>
            <div
              className={fileUploaderContainer}
              onDragOver={e => {
                e.preventDefault();
                setDragActive(true);
              }}
              onDragEnter={e => {
                e.preventDefault();
                setDragActive(true);
              }}
              onDragLeave={e => {
                e.preventDefault();
                setDragActive(false);
              }}
              onDrop={e => {
                e.preventDefault();
                setDragActive(false);

                if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                  const file = e.dataTransfer.files[0];
                  const fileType = file.type;
                  if (!fileType.startsWith('video/')) {
                    setFileTypeError('Upload must be a video file.');
                    return;
                  }
                  setFileTypeError('');

                  props.setFieldValue('file', e.dataTransfer.files[0]);
                  if (onStart) {
                    onStart();
                  }
                }
              }}
            >
              {!props.values.file?.name && (
                <>
                  <Icon icon="ic:round-upload" className={classNames(fileUploadIcon, dragActive ? fileUploadIconActive : '')} />

                  <div className={fileUploaderText}>Drag and drop files to upload</div>
                  <div className={fileUploaderText}>or</div>

                  <label className={fileUploaderCustom} htmlFor="file">
                    Browse
                  </label>

                  <input
                    ref={fileInput}
                    id="file"
                    name="file"
                    type="file"
                    accept="video/*"
                    onChange={event => {
                      if (event.currentTarget.files) {
                        props.setFieldValue('file', event.currentTarget.files[0]);
                        setFileTypeError('');
                        if (onStart) {
                          onStart();
                        }
                      }
                    }}
                    onBlur={props.handleBlur}
                    className={fileUploaderStyle}
                  />

                  {fileTypeError && <div className={errorMessage}>{fileTypeError}</div>}
                </>
              )}

              {props.values.file?.name && (
                <>
                  <div className={fileUploadTitle}>UPLOAD FILES</div>
                  <div className={classNames(flexRow, fileNameRow)}>
                    <Icon icon="fluent:document-20-regular" className={fileIcon} />
                    <div className={fileNameContainer}>
                      <div className={fileUploaderText}>{props.values.file.name}</div>
                      <button
                        type="button"
                        onClick={() => {
                          if (isUploading) {
                            setIsUploading(false);
                          }
                          if (currentRequest) {
                            currentRequest.abort();
                          }
                          if (onCancel) {
                            onCancel();
                          }
                          props.setFieldValue('file', '');
                        }}
                        className={classNames('delete', deleteButton)}
                      />

                      {isUploading && (
                        <>
                          <progress
                            className={classNames('progress', fileUploaderProgress)}
                            max="100"
                            value={Math.round(uploadPercentage)}
                          ></progress>
                          <div className={fileUploaderProgressText}>{uploadProgress}</div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <Button className={fileUploaderButton} type="submit" onClick={props.handleSubmit} disabled={isUploading}>
                UPLOAD
              </Button>

              {props.errors.file && props.touched.file && <div className={errorMessage}>{props.errors.file}</div>}
              {uploadError && <div className={errorMessage}>{uploadError}</div>}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
