import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { isMobile } from '@lib/responsive';
import { VideoPlayerSize, useVideoState } from '@lib/useVideoState';
import React, { KeyboardEvent, useContext, useState } from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import classnames from 'classnames';
import playArrow from '@iconify/icons-ic/play-arrow';
import pause from '@iconify/icons-ic/pause';
import skipNext from '@iconify/icons-ic/skip-next';
import fullScreen from '@iconify/icons-ic/fullscreen';
import roundCloseFullscreen from '@iconify/icons-ic/round-close-fullscreen';
import pictureInPicture from '@iconify/icons-ic/picture-in-picture';
import outlineArrowBackIos from '@iconify/icons-ic/outline-arrow-back-ios';
import pictureInPictureExitFill from '@iconify/icons-ri/picture-in-picture-exit-fill';
import { Share } from '@components/Share/Share';
import { VideoSource, BrandUtil } from '@sharedLib/index';
import { useVideoParentCollections } from '@src/lib/video-query/useVideoData';
import { PLAYER_EVENT } from '@components/VideoPlayer/VideoPlayer';
import { CartIcon } from '@components/Cart/CartIcon';
import {
  hidden as hiddenStyle,
  visible,
  playerControlContainer,
  roundTheCorners,
  iconText,
  playerControlButton,
  fullScreenIcon,
  centerText,
  smallCenterText,
  shortDescriptionText,
  theatreContainer,
  theatreButtonLeft,
  cartButtonContainer,
  cartCounter,
  cartButton,
  buttonIcon,
} from './styles.module.scss';

interface Props {
  playing: boolean;
  size: VideoPlayerSize;
  onUserAction: (event: PLAYER_EVENT) => void;
  hidden: boolean;
  episodeNumber?: number;
  seasonNumber?: number;
  videoState: any;
  hideMainControls?: boolean;
  hideFullScreenButton?: boolean;
  hideBrowse?: boolean;
  showCart?: boolean;
  modalCallback?: (isOpen: boolean) => void;
}

const iconProps = {
  height: '60px',
};

const iconPropsSmall = {
  height: '32px',
};

const DEBUG = false;
const DEBUG_BUTTONS = false;
const SHOW_PLAY = !!(isBrowser && window.location.href.indexOf('debug') > -1);
const SHOW_PLAYER_POPUP = !!(isBrowser && window.location.href.indexOf('debug') > -1);

export const PlayerControls = ({
  playing,
  size,
  onUserAction,
  hidden,
  episodeNumber,
  seasonNumber,
  videoState,
  hideMainControls = false,
  hideFullScreenButton = false,
  hideBrowse = false,
  showCart = false,
  modalCallback,
}: Props) => {
  const nav = useContext(NavStateContext);
  const videoStateHook = useVideoState();
  const seriesNavObject = nav.navState.pageData && nav.navState.pageData.filter(page => page.path.includes('series'));
  const seriesTitle = seriesNavObject && seriesNavObject[0] && seriesNavObject[0].title;
  const { useDescriptionInVideo } = BrandUtil.getSiteInfo().videoPlayerConfig;
  const videoId = videoState?.video?.id;
  const videoParents = useVideoParentCollections(videoId).series;
  // @ts-ignore
  const canUseFullscreenApi =
    isBrowser && (videoState?.elRef?.current?.requestFullscreen || videoState?.elRef?.current?.webkitRequestFullscreen);

  function keyHandler(event: KeyboardEvent) {
    DEBUG && ClientLogger.debug('PlayerControls', 'event', DEBUG, event);
  }

  const handleClick = (event: any, playerEvent: PLAYER_EVENT) => {
    if (hidden) return;

    event.stopPropagation();
    onUserAction(playerEvent);
  };

  const handleBack = () => {
    let canonicalParent;
    if (videoId) {
      const pages = [...nav.navState.pageData];
      // may be multiple video parents, check if nav history contains one of them
      if (videoParents.length) {
        canonicalParent = { label: videoParents[0].title, path: `/series/${videoParents[0].id}` };
        // check history in reverse
        for (const page of pages.reverse()) {
          if (videoParents.findIndex(collection => collection.title === page.title) !== -1) {
            canonicalParent = { label: page.title, path: page.path };
            break;
          }
        }
      }
    }

    if (canonicalParent) {
      nav.push({ path: canonicalParent.path, title: canonicalParent.label });
    } else {
      nav.push({ path: '/app', title: 'Home' });
    }
  };

  ClientLogger.debug('PlayerControls render', `playing=${playing}`, DEBUG);
  const hideOnHover = hidden ? hiddenStyle : visible;

  return (
    <>
      {!hideMainControls && (
        <div className={classnames(playerControlContainer, hideOnHover, size === VideoPlayerSize.SMALL_EMBEDDED ? roundTheCorners : '')}>
          {!hideBrowse && (
            <button
              className={classnames('is-pulled-left', playerControlButton, hideOnHover)}
              type="button"
              onClick={e => {
                if (!hidden) {
                  if (size === VideoPlayerSize.LARGE_FULLSCREEN) {
                    handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN);
                  }
                  handleBack();
                }
              }}
              onTouchStart={e => {
                if (!hidden) {
                  if (size === VideoPlayerSize.LARGE_FULLSCREEN) {
                    handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN);
                  }
                  handleBack();
                }
              }}
              onKeyDown={keyHandler}
            >
              <p className={iconText}>
                <InlineIcon icon={outlineArrowBackIos} {...iconPropsSmall} /> Browse
              </p>
            </button>
          )}
          {SHOW_PLAY ? (
            <>
              {playing ? (
                <>
                  <button
                    className={classnames('is-pulled-left', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.PAUSE)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.PAUSE)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={pause} {...iconProps} />
                  </button>
                  <button
                    className={classnames('is-pulled-left', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.SKIP_NEXT)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.SKIP_NEXT)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={skipNext} {...iconProps} />
                  </button>
                </>
              ) : (
                <button
                  data-cy="videoPlayButton"
                  className={classnames('is-pulled-left', playerControlButton, hideOnHover)}
                  type="button"
                  onClick={e => handleClick(e, PLAYER_EVENT.PLAY)}
                  onTouchStart={e => handleClick(e, PLAYER_EVENT.PLAY)}
                  onKeyDown={keyHandler}
                >
                  <Icon icon={playArrow} {...iconProps} />
                </button>
              )}
            </>
          ) : (
            ''
          )}

          <>
            {size === VideoPlayerSize.SMALL_FLOAT ? (
              <>
                {SHOW_PLAYER_POPUP ? (
                  <button
                    className={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={roundCloseFullscreen} {...iconPropsSmall} />
                  </button>
                ) : (
                  ''
                )}
                {canUseFullscreenApi && (
                  <button
                    className={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={fullScreen} {...iconPropsSmall} />
                  </button>
                )}
              </>
            ) : (
              <></>
            )}
            {size === VideoPlayerSize.MEDIUM_EMBEDDED ? (
              <>
                {!isMobile() && SHOW_PLAYER_POPUP ? (
                  <button
                    className={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.SMALL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.SMALL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={pictureInPicture} {...iconProps} />
                  </button>
                ) : (
                  ''
                )}
                {canUseFullscreenApi && (
                  <button
                    className={classnames('is-pulled-right', playerControlButton, fullScreenIcon, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={fullScreen} {...iconPropsSmall} />
                  </button>
                )}
                {showCart && (
                  <div className={cartButtonContainer}>
                    <CartIcon
                      className={cartButton}
                      counterClassName={cartCounter}
                      onClick={() => videoStateHook.setEmbeddedCartOpen(true)}
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            {size === VideoPlayerSize.LARGE_FULLSCREEN ? (
              <>
                {!isMobile() && SHOW_PLAYER_POPUP ? (
                  <button
                    className={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                    type="button"
                    onClick={e => handleClick(e, PLAYER_EVENT.SMALL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.SMALL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={pictureInPicture} {...iconProps} />
                  </button>
                ) : (
                  ''
                )}
                <button
                  className={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                  type="button"
                  onClick={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                  onTouchStart={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                  onKeyDown={keyHandler}
                >
                  <Icon icon={roundCloseFullscreen} {...iconPropsSmall} />
                </button>
              </>
            ) : (
              <></>
            )}
            {size !== VideoPlayerSize.SMALL_EMBEDDED ? (
              <Share
                type="video"
                video={videoState?.video}
                urlPath={videoState?.video?.id}
                shareBtnClass={classnames('is-pulled-right', playerControlButton, hideOnHover)}
                modalCallback={isOpen => {
                  if (modalCallback) {
                    modalCallback(isOpen);
                  }
                }}
              />
            ) : (
              <div
                className={classnames('is-pulled-right', theatreContainer, hideOnHover)}
                onClick={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
                onTouchStart={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
                onKeyDown={keyHandler}
              >
                <Icon icon={pictureInPictureExitFill} className={buttonIcon} />
              </div>
            )}

            <div
              className={classnames(
                playerControlButton,
                centerText,
                size === VideoPlayerSize.SMALL_EMBEDDED ? smallCenterText : '',
                hideOnHover
              )}
            >
              {seriesNavObject && seriesTitle ? <div>{seriesTitle}</div> : <></>}
              {!useDescriptionInVideo && seasonNumber && episodeNumber ? (
                <div>
                  Season {seasonNumber}. Episode {episodeNumber}
                </div>
              ) : (
                <></>
              )}
              {useDescriptionInVideo && videoState?.video?.description && (
                <div className={shortDescriptionText}>{videoState?.video?.description}</div>
              )}
            </div>
          </>

          {DEBUG_BUTTONS ? (
            <>
              <button
                className={classnames('is-pulled-right', playerControlButton)}
                type="button"
                onClick={e => handleClick(e, PLAYER_EVENT.CHROMELESS_TOGGLE)}
                onTouchStart={e => handleClick(e, PLAYER_EVENT.CHROMELESS_TOGGLE)}
                onKeyDown={keyHandler}
              >
                <span style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>CL</span>
              </button>
              <button
                className={classnames('is-pulled-right', playerControlButton)}
                type="button"
                onClick={e => handleClick(e, PLAYER_EVENT.OVERLAY_TOGGLE)}
                onTouchStart={e => handleClick(e, PLAYER_EVENT.OVERLAY_TOGGLE)}
                onKeyDown={keyHandler}
              >
                <span style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>OVL</span>
              </button>
            </>
          ) : (
            ''
          )}
        </div>
      )}

      {size === VideoPlayerSize.SMALL_EMBEDDED && !hideFullScreenButton && videoState.video.type !== VideoSource.VIMEO_OTT && (
        <div
          className={classnames('columns is-pulled-left is-mobile', theatreContainer, hideOnHover, theatreButtonLeft)}
          onClick={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
          onTouchStart={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
          onKeyDown={keyHandler}
        >
          <Icon icon={pictureInPictureExitFill} className={buttonIcon} />
        </div>
      )}
    </>
  );
};
