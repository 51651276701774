import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { container, checkmark, fieldError, disabled as disabledStyle, checked as checkedStyle } from './styles.module.scss';

interface Props {
  fieldName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  children?: React.ReactNode;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  errorMessage?: ReactNode | string;
}

// Create an argument to toggle between message field & standard field
export const Checkbox: React.FC<Props> = ({ fieldName, className, children, id, onChange, checked, disabled, errorMessage }) => {
  const checkedProps = !disabled && checked;

  const props = {
    name: fieldName,
    type: 'checkbox',
    value: id || fieldName,
    checked: checkedProps,
    disabled,
    onChange,
  };
  return (
    <>
      <label htmlFor={fieldName} className={container}>
        <span className={classnames(checkmark, disabled && disabledStyle, checkedProps && checkedStyle)} />
        <input data-cy={fieldName} {...props} className={className} />
        {children}
      </label>
      {errorMessage && <div className={fieldError}>{errorMessage}</div>}
    </>
  );
};
