import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { offColorText, boldFont, largeText, videoListItem, smallText, extraLargeText } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { DatabaseSeries, DatabaseSeason, DatabaseCategory } from '@sharedLib/index';

export const VideoCollectionJsonUploader = () => {
  const api = usePvepApi();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');
  const [seasons, setSeasons] = useState<DatabaseSeason[]>([]);
  const [series, setSeries] = useState<DatabaseSeries[]>([]);
  const [categories, setCategories] = useState<DatabaseCategory[]>([]);

  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');

  ///
  /// Write all videos to db
  ///
  const writeAllCollections = async (categories: DatabaseCategory[], series: DatabaseSeries[], seasons: DatabaseSeason[]) => {
    const errors: string[] = [];

    // write series/seasons
    for (const ser of series) {
      const seasonIds = ser.items.map(item => item.seasonId);
      const relatedSeasons = seasons.filter(season => seasonIds.includes(season.id));

      const writeResp = await api.writeDatabaseSeriesAndSeasons(ser, relatedSeasons);
      if (!writeResp.data?.writeDatabaseSeriesAndSeasons.success) {
        errors.push(`Series: ${ser.title} failed to upload.`);
      }
    }

    // write categories
    for (const cat of categories) {
      const writeResp = await api.writeDatabaseCategory(cat);
      if (!writeResp.data?.writeDatabaseCategory.success) {
        errors.push(`Category: ${cat.title} failed to upload.`);
      }
    }

    setUploadErrors(errors);
    setConfirmation('Upload complete.');
  };

  return (
    <div>
      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          await writeAllCollections(categories, series, seasons);
          resetForm();
          setCategories([]);
          setSeasons([]);
          setSeries([]);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const collectionsRaw: any[] = JSON.parse(dataString);
                        const allSeasons: DatabaseSeason[] = [];
                        const allSeries: DatabaseSeries[] = [];
                        const allCategories: DatabaseCategory[] = [];

                        for (const collection of collectionsRaw) {
                          if (collection.type === 'series') {
                            const seriesId = collection.id;
                            const seriesItems = collection.items
                              .map((item: any, i: number) => {
                                if (item.type === 'season') {
                                  return {
                                    id: '',
                                    rank: i,
                                    seriesId,
                                    seasonId: item.id,
                                  };
                                } else {
                                  return undefined;
                                }
                              })
                              .filter((item: any) => item !== undefined);

                            const collectionSeries: DatabaseSeries = {
                              id: seriesId,
                              title: collection.name || '',
                              description: collection.description || '',
                              shortDescription: collection.short_description || '',
                              isAvailable: collection.is_available || true,
                              isFree: collection.is_free || false,
                              items: seriesItems,
                            };

                            allSeries.push(collectionSeries);
                          } else if (collection.type === 'season') {
                            const seasonId = collection.id;
                            const seasonItems = collection.items
                              .map((item: any, i: number) => {
                                if (item.type === 'video') {
                                  return {
                                    id: '',
                                    rank: i,
                                    seasonId,
                                    videoId: item.id,
                                  };
                                } else {
                                  return undefined;
                                }
                              })
                              .filter((item: any) => item !== undefined);

                            const collectionSeason: DatabaseSeason = {
                              id: seasonId,
                              title: collection.name || '',
                              items: seasonItems,
                            };

                            allSeasons.push(collectionSeason);
                          } else if (collection.type === 'category') {
                            const categoryId = collection.id;
                            const categoryItems = collection.items
                              .map((item: any, i: number) => {
                                if (item.type === 'video') {
                                  return {
                                    id: '',
                                    rank: i,
                                    categoryId,
                                    videoId: item.id,
                                  };
                                } else if (item.type === 'series') {
                                  return {
                                    id: '',
                                    rank: i,
                                    categoryId,
                                    seriesId: item.id,
                                  };
                                } else {
                                  return undefined;
                                }
                              })
                              .filter((item: any) => item !== undefined);

                            const collectionCat: DatabaseCategory = {
                              id: categoryId,
                              title: collection.name || '',
                              isAvailable: collection.is_available || true,
                              isFree: collection.is_free || false,
                              isFeatured: collection.is_featured || false,
                              items: categoryItems,
                            };

                            allCategories.push(collectionCat);
                          }
                        }
                        setCategories(allCategories);
                        setSeasons(allSeasons);
                        setSeries(allSeries);
                        setError('');
                      } catch (e) {
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {(categories.length > 0 || seasons.length > 0 || series.length > 0) && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                WRITE ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {categories.length > 0 && <div className={classNames(extraLargeText, boldFont, videoListItem)}>Categories to Write:</div>}
      {categories.map(cat => (
        <div className={videoListItem}>
          <div className={classNames(largeText)}>{cat.title}</div>
        </div>
      ))}

      {series.length > 0 && <div className={classNames(extraLargeText, boldFont, videoListItem)}>Collections to Write:</div>}
      {series.map(ser => (
        <div className={videoListItem}>
          <div className={classNames(largeText)}>{ser.title}</div>
        </div>
      ))}
    </div>
  );
};
