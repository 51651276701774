import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { BrandUtil } from '../src-shared-lib';

export const tracerLink = new ApolloLink((operation: Operation, forward?: NextLink) => {
  const startTime = new Date();
  if (forward) {
    return forward(operation).map(result => {
      if (apolloTracer.isTracing()) {
        apolloTracer.addEvent(startTime, operation, result);
      }
      return result;
    });
  } else {
    return null;
  }
});

export interface TraceEvent {
  query: string;
  operationName: string;
  variables: any;
  startTime: Date;
  endTime: Date;
  result: any;
  duration: number;
}

const DEBUG = true;
const MAX_TO_KEEP = 100;
const STORAGE_KEY = 'alibiTechTrace';
let canAccessLocalStorage = true;
try {
  if (isBrowser) {
    window.localStorage.getItem(STORAGE_KEY);
  }
} catch (e) {
  ClientLogger.error('apolloTracer', 'cannot access local storage', e);
  canAccessLocalStorage = false;
}
const FULL_OUTPUT_EACHSTEP = true; // Handy if navigation resets the state

export class ApolloTracer {
  private tracerEvents: TraceEvent[] = [];

  private on: boolean;

  constructor() {
    if (isBrowser && !(BrandUtil.getSiteInfo().branchType === 'master') && canAccessLocalStorage) {
      this.on = window.localStorage.getItem(STORAGE_KEY) === 'on';
    } else {
      this.on = false;
    }
  }

  start() {
    this.on = true;
    this.tracerEvents = [];
    isBrowser && canAccessLocalStorage && window.localStorage.setItem(STORAGE_KEY, 'on');
    DEBUG && ClientLogger.debug('ApolloTracer.addEvent', 'tracing started');
  }

  stop() {
    this.on = false;
    isBrowser && canAccessLocalStorage && window.localStorage.removeItem(STORAGE_KEY);
    DEBUG && ClientLogger.debug('ApolloTracer.addEvent', 'tracing stopped');
  }

  isTracing(): boolean {
    return this.on;
  }

  addEvent(startTime: Date, operation: Operation, result: any): void {
    if (this.tracerEvents.length >= MAX_TO_KEEP) {
      this.tracerEvents.splice(0, 1);
    }

    const endTime = new Date();
    const traceEvent: TraceEvent = {
      variables: operation.variables,
      operationName: operation.operationName,
      query: (operation.query.loc || ({} as any)).source.body,
      startTime,
      endTime,
      result,
      duration: endTime.getTime() - startTime.getTime(),
    };
    this.tracerEvents.push(traceEvent);
    DEBUG && ClientLogger.debug('ApolloTracer.addEvent', `added currentSize=${this.tracerEvents.length}`, traceEvent);
    if (FULL_OUTPUT_EACHSTEP) {
      console.log(JSON.stringify(this.tracerEvents, null, 2));
    }
  }

  getTraceEVents(): TraceEvent[] {
    return this.tracerEvents;
  }
}

export const apolloTracer = new ApolloTracer();
