import { IconButton } from '@components/Buttons';
import cartIcon from '@iconify/icons-ion/cart';
import { Icon } from '@iconify/react';
import React from 'react';
import { ClientLogger } from '@src/lib/ClientLogger';
import { useCart } from '@apiClient/use-cart';
import { iconCart, cartItemContainer, cartItem } from './styles.module.scss';
import classNames from 'classnames';

interface IProps {
  onClick?: () => void;
  className?: string;
  counterClassName?: string;
}

const DEBUG = false;

export const CartIcon = ({ onClick, className, counterClassName }: IProps) => {
  const cart = useCart();
  const productQuantity = cart.cartItems
    .map((product: { quantity: any }) => product.quantity)
    .reduce((firstProduct: any, secondProduct: any) => firstProduct + secondProduct, 0);

  DEBUG && ClientLogger.debug('CartIcon.getCartItems', `items = ${productQuantity} `);

  return (
    <div className={classNames(iconCart, className || '')} onClick={onClick} data-cy="cartIconButton">
      <Icon icon={cartIcon} height="30px" data-cy="cartIcon" />
      {productQuantity > 0 && (
        <div className={classNames(cartItemContainer, counterClassName)} data-cy="cartItemContainer">
          <span className={cartItem} data-cy="numberOfItemsInCart">
            {productQuantity}
          </span>
        </div>
      )}
    </div>
  );
};
