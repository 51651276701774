import React, { useEffect, useState } from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import classNames from 'classnames';
import {
  noWrapButton,
  editorVideoLabel,
  adminSubText,
  flexRow,
  adminText,
  videoMetadataList,
  videoCollectionListBack,
  videoCollectionDeleteContainer,
  videoCollectionDelete,
  videoCollectionDeleteCard,
  videoListButtonContainer,
  videoCollectionListRow,
  videoCollectionListText,
  videoListOrigin,
  videoListDate,
} from './styles.module.scss';
import { DatabaseVideoForm } from './DatabaseVideoForm';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Icon } from '@iconify/react';
import { alphabeticalOrder, DatabaseVideo } from '@sharedLib/index';
import { ConfirmCard } from '@components/ConfirmCard';
import { FilterSearch } from '@components/Search';
import { SortableHeader, getTableSort } from '@components/Tables';

export const videoPlatformOptions: { [el: string]: string } = {
  'vimeo-ott': 'Vimeo OTT',
  'core-vimeo': 'Vimeo',
  youtube: 'Youtube',
  uploaded: 'Uploaded',
};

export const DatabaseVideoList = () => {
  const api = usePvepApi();
  const [videos, setVideos] = useState<DatabaseVideo[]>([]);
  const [videoFilter, setVideoFilter] = useState('');
  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);
  const [editingVideo, setEditingVideo] = useState<DatabaseVideo | undefined>(undefined);
  const [pendingRemoval, setPendingRemoval] = useState<string | undefined>(undefined);
  const [deletingError, setDeletingError] = useState('');
  const [deletingVideo, setDeletingVideo] = useState(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('title');

  useEffect(() => {
    if (!editingVideo) {
      getDatabaseVideos();
    }
  }, [editingVideo]);

  const getDatabaseVideos = async () => {
    setLoading(true);
    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos = videoResp.data.getDatabaseVideos.videos
        .map(video => {
          const newVid: any = video;
          delete newVid.__typename;
          return newVid;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setVideos(databaseVideos);
      setLoadingError('');
    } else {
      setLoadingError(
        JSON.stringify(videoResp.data?.getDatabaseVideos.errorMessages) || 'Something went wrong loading the videos from the database.'
      );
      setVideos([]);
    }
    setLoading(false);
  };

  const deleteVideo = async (videoId: string) => {
    setDeletingVideo(true);

    const deleteResp = await api.deleteDatabaseVideo(videoId);
    if (deleteResp.data?.deleteDatabaseVideo.success) {
      const newList = [...videos];
      const index = newList.findIndex(video => video.id === videoId);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setVideos(newList);
    } else {
      setDeletingError(
        JSON.stringify(deleteResp.data?.deleteDatabaseVideo.errorMessages) || 'Could not delete this collection. Please try again.'
      );
    }

    setDeletingVideo(false);
  };

  return (
    <div className={videoMetadataList}>
      {loading && <div className={adminSubText}>Loading...</div>}
      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!loading && !editingVideo && (
        <>
          <FilterSearch onChange={filter => setVideoFilter(filter)} placeholder="Search Videos" initialQuery={videoFilter} />
          <SortableHeader
            items={[
              { label: 'Title', fieldName: 'title', className: videoCollectionListText },
              { label: 'Origin', fieldName: 'type', className: videoListOrigin },
              { label: 'Creation Date', fieldName: 'createdAt', className: videoListDate },
            ]}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          {videos
            .filter(video => video.title.toLowerCase().includes(videoFilter.trim().toLowerCase()))
            .sort(getTableSort(order, orderBy))
            .map(video => (
              <div className={classNames(flexRow, videoCollectionListRow, videoListButtonContainer)} key={video.id}>
                <div className={videoCollectionListText}>
                  <div className={adminText}>{video.title}</div>
                  <div className={adminSubText}>
                    <span className={editorVideoLabel}>Video ID: </span> {video.id}
                  </div>
                </div>

                <div className={videoListOrigin}>{videoPlatformOptions[video.type] || video.type}</div>

                <div className={videoListDate}>{video.createdAt?.slice(0, 10) || '-'}</div>

                <Button onClick={() => setEditingVideo(video)} className={noWrapButton}>
                  EDIT
                </Button>

                <div
                  className={classNames(flexRow, adminSubText, editorVideoLabel, videoCollectionDeleteContainer)}
                  onClick={() => setPendingRemoval(video.id)}
                >
                  <Icon icon="fa-solid:trash-alt" className={videoCollectionDelete} />
                  <div>Delete</div>
                </div>
                <div className={videoCollectionDeleteCard}>
                  {pendingRemoval === video.id && (
                    <ConfirmCard
                      title={'Delete Video'}
                      content={'Do you really want to delete this video? This process cannot be undone.'}
                      confirmCta={'Delete'}
                      disabled={deletingVideo}
                      onConfirm={async () => {
                        await deleteVideo(pendingRemoval);
                      }}
                      onCancel={() => setPendingRemoval(undefined)}
                      errorMessage={deletingError}
                    />
                  )}
                </div>
              </div>
            ))}
        </>
      )}

      {editingVideo && (
        <>
          <div className={videoCollectionListBack}>
            <Icon icon="bi:arrow-left" onClick={() => setEditingVideo(undefined)} />
          </div>
          <DatabaseVideoForm initialStep={1} initialVideo={editingVideo} />
        </>
      )}
    </div>
  );
};
