import React, { useEffect, useState, useRef } from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { Formik, Field } from 'formik';
import { usePvepApi } from '@apiClient/usePvepApi';
import {
  noWrapButton,
  editorVideoLabel,
  adminSubText,
  flexRow,
  adminText,
  videoMetadataList,
  adminTitle,
  adminInput,
  adminPadBottom,
  collectionListEpisode,
  dragHandle,
  collectionEpisodeRow,
  dragHint,
  adminMarginBottom,
  errorMessage as errorStyle,
  collectionButtonGroup,
  applyFlex,
  removeButton,
  seasonRow,
  videoCollectionAdd,
  videoCollectionAddContainer,
  videoAddButton,
  videoAddContainer,
  thumbnailContainer,
  thumbnailDeleteButton,
  thumbnailImage,
  thumbnailInput,
  addThumbnailIcon,
  thumbnailFieldInput,
  metadataFormField,
  adminCheckbox,
  distributionRow,
} from './styles.module.scss';
import {
  alphabeticalOrder,
  ascendingOrder,
  BrandUtil,
  Util,
  DatabaseVideo,
  DatabaseSeries,
  DatabaseSeason,
  DatabaseSeasonItem,
} from '@sharedLib/index';
import { AddSeasonWidget } from './AddSeasonWidget';
import { AddVideoWidget } from './AddVideoWidget';
import cuid from 'cuid';
import md5 from 'md5';
import { ConfirmCard } from '@components/ConfirmCard';

interface DatabaseSeriesInput {
  id: string;
  title: string;
  description: string;
  shortDescription: string;
  isAvailable: boolean;
  isFree: boolean;
  thumbnailUrl?: string | null;
  thumbnailData?: string | null;
}

interface DatabaseSeasonInput {
  id: string;
  title: string;
  items: {
    id: string;
    videoId: string;
    title: string;
  }[];
}

interface Props {
  editingSeriesId?: string;
}

export const DatabaseCollectionForm = ({ editingSeriesId }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const [dragOver, setDragOver] = useState<number | undefined>(undefined);
  const [dragSeason, setDragSeason] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState('');
  const [dataError, setDataError] = useState('');

  const [initialSeries, setInitialSeries] = useState<DatabaseSeriesInput | undefined>(undefined);
  const [initialSeasons, setInitialSeasons] = useState<DatabaseSeasonInput[]>([]);
  const [existingImageUrl, setExistingImageUrl] = useState<string | undefined>(initialSeries?.thumbnailUrl || undefined);

  const [pendingRemoval, setPendingRemoval] = useState<number | undefined>(undefined);
  const [addingSeason, setAddingSeason] = useState(false);
  const [addingVideo, setAddingVideo] = useState<number | undefined>(undefined);

  const [allVideos, setAllVideos] = useState<DatabaseVideo[]>([]);

  const api = usePvepApi();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (editingSeriesId) {
      getSeriesDetails(editingSeriesId);
    } else {
      setLoading(false);
    }
  }, [editingSeriesId]);

  useEffect(() => {
    getAllVideos();
  }, []);

  const getSeriesDetails = async (seriesId: string) => {
    setLoading(true);
    const seriesResp = await api.getAllDatabaseSeriesInfo(seriesId);
    if (seriesResp.data?.getAllDatabaseSeriesInfo.success) {
      const seriesData = seriesResp.data.getAllDatabaseSeriesInfo.series;
      const seasonsData = seriesResp.data.getAllDatabaseSeriesInfo.seasons;
      const videoData = seriesResp.data.getAllDatabaseSeriesInfo.videos;

      if (seriesData && seasonsData && videoData) {
        // set series
        setInitialSeries({
          id: seriesData.id,
          title: seriesData.title,
          description: seriesData.description,
          shortDescription: seriesData.shortDescription,
          isAvailable: seriesData.isAvailable,
          isFree: seriesData.isFree,
          thumbnailUrl: seriesData.thumbnailUrl,
        });
        setExistingImageUrl(seriesData.thumbnailUrl || undefined);

        // compose season objects
        const allSeasons = seasonsData.map(season => {
          const sortedVideos = season.items
            .sort((a, b) => ascendingOrder(a.rank, b.rank))
            .map(seasonItem => {
              const videoMatch = videoData.find(video => video.id === seasonItem.videoId);
              return {
                id: seasonItem.id,
                videoId: seasonItem.videoId,
                title: videoMatch?.title || '',
              };
            });

          return {
            id: season.id,
            title: season.title,
            items: sortedVideos,
          };
        });
        setInitialSeasons(allSeasons);

        setLoadingError('');
      } else {
        setLoadingError('Database did not return the data for this collection.');
      }
    } else {
      setLoadingError(
        JSON.stringify(seriesResp.data?.getAllDatabaseSeriesInfo.errorMessages) ||
          'Something went wrong loading this collection from the database.'
      );
    }
    setLoading(false);
  };

  const getAllVideos = async () => {
    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos = videoResp.data.getDatabaseVideos.videos
        .map(video => {
          const newVid: any = video;
          delete newVid.__typename;
          return newVid;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setAllVideos(databaseVideos);
    } else {
      setAllVideos([]);
    }
  };

  const writeSeriesAndSeasons = async (values: {
    title: string;
    description: string;
    isAvailable: boolean;
    isFree: boolean;
    thumbnailUrl?: string | null;
    thumbnailData?: string | null;
    seasons: DatabaseSeasonInput[];
    file: any;
  }) => {
    const seriesId = initialSeries?.id || cuid();

    // if new thumbnail data, and on cloud, use presigned url to avoid lambda size limits
    // if local, send b64 thumbnail data to be written to filesystem
    if (values.thumbnailUrl && values.thumbnailData && !Util.isLocal()) {
      setUploadProgress('Uploading thumbnail...');

      const dataString = values.thumbnailData.replace(/^data:image\/[a-z]+;base64,/, '');

      // generate image name
      const hash = md5(dataString);
      const imageExt = values.thumbnailUrl.split('.').pop() || '';
      const imageFileName = `${seriesId}_${hash}.${imageExt}`;
      const s3KeyPath = `${brandInfo.branchId}/series`;
      const contentType = Util.extToMime(imageExt);

      // get presigned upload url
      const urlResp = await api.getS3UploadUrl(brandInfo.imageBucketName, `${s3KeyPath}/${imageFileName}`, contentType);
      if (!urlResp.data?.getS3UploadUrl.success || !urlResp.data.getS3UploadUrl.url) {
        setDataError('Error trying to save data: Failed to get upload url for thumbnail.');
        setUploadProgress('');
        return;
      }

      const url = urlResp.data?.getS3UploadUrl.url || '';
      const resp = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: values.file,
      });

      if (resp.status !== 200) {
        setDataError('Error trying to save data: Failed to upload thumbnail.');
        setUploadProgress('');
        return;
      }

      setUploadProgress('Compressing thumbnail...');

      // compress image
      const compressResp = await api.compressS3Image(brandInfo.imageBucketName, s3KeyPath, imageFileName, contentType);
      if (!compressResp.data?.compressS3Image.success) {
        setDataError('Error trying to save data: Failed to compress thumbnail image.');
        setUploadProgress('');
        return;
      }

      setUploadProgress('');

      // don't send image data to server, it's already been uploaded
      // send hashed filename as thumbnailurl to be recorded in the db
      values.thumbnailData = undefined;
      values.thumbnailUrl = compressResp.data.compressS3Image.thumbnailUrl;
    }

    // create series object
    const seriesData: DatabaseSeries = {
      id: seriesId,
      title: values.title,
      description: values.description,
      shortDescription: '',
      isAvailable: values.isAvailable,
      isFree: values.isFree,
      thumbnailUrl: values.thumbnailUrl,
      thumbnailData: values.thumbnailData,
      items: [],
    };

    // create seasons objects which belong to this series
    // because seasonIds may be blank now, we cannot generate the seriesItems
    // this will be sorted out on the backend
    const seasonData: DatabaseSeason[] = values.seasons.map(season => {
      const seasonItems: DatabaseSeasonItem[] = season.items.map((item, i) => {
        return {
          id: item.id,
          rank: i,
          seasonId: season.id,
          videoId: item.videoId,
        };
      });

      const seas: DatabaseSeason = {
        id: season.id,
        title: season.title,
        items: seasonItems,
      };

      return seas;
    });

    const writeResp = await api.writeDatabaseSeriesAndSeasons(seriesData, seasonData);
    if (writeResp.data?.writeDatabaseSeriesAndSeasons.success && writeResp.data.writeDatabaseSeriesAndSeasons.seriesId) {
      setErrorMessage('');
      setMessage('Data saved successfully');
      await getSeriesDetails(writeResp.data.writeDatabaseSeriesAndSeasons.seriesId);
    } else {
      setErrorMessage(
        JSON.stringify(writeResp.data?.writeDatabaseSeriesAndSeasons.errorMessages) || 'Something went wrong. Please try again'
      );
      setMessage('');
    }
  };

  return (
    <div className={videoMetadataList}>
      {loading && <div className={adminSubText}>Loading...</div>}
      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!loading && !loadingError && (
        <Formik
          initialValues={{
            title: initialSeries?.title || '',
            description: initialSeries?.description || '',
            isAvailable: initialSeries ? initialSeries.isAvailable : true,
            isFree: initialSeries?.isFree || false,
            seasons: initialSeasons,
            thumbnail: initialSeries?.thumbnailUrl,
            thumbnailData: initialSeries?.thumbnailData,
            file: '',
          }}
          enableReinitialize
          validateOnChange={false}
          onSubmit={async values => {
            await writeSeriesAndSeasons(values);
          }}
        >
          {props => (
            <>
              <div className={flexRow}>
                <div className={adminTitle}>{initialSeries ? 'EDIT COLLECTION' : 'ADD COLLECTION'}</div>
                <div className={classNames(applyFlex, collectionButtonGroup)}>
                  <div>
                    <Button className={noWrapButton} onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                      SAVE ALL
                    </Button>
                  </div>
                  {uploadProgress && <div>{uploadProgress}</div>}
                  {dataError && <div className={errorStyle}>{dataError}</div>}
                  {errorMessage && <div className={errorStyle}>{errorMessage}</div>}
                  {message && <div>{message}</div>}
                </div>
              </div>
              <div className={adminPadBottom}>
                <div>Title</div>
                <Field
                  className={adminInput}
                  value={props.values.title}
                  onChange={props.handleChange}
                  id="title"
                  name="title"
                  placeholder="Title"
                />
                {props.touched.title && props.errors.title && <div className={errorStyle}>{props.errors.title}</div>}
              </div>
              <div className={adminPadBottom}>
                <div>Description</div>
                <Field
                  className={adminInput}
                  value={props.values.description}
                  onChange={props.handleChange}
                  id="description"
                  name="description"
                  placeholder="Description"
                  as="textarea"
                  rows="4"
                />
              </div>

              <div>
                <div className={adminTitle}>Distribution</div>
              </div>
              <div className={distributionRow}>
                <div>
                  <div>Visibility</div>

                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isAvailable"
                      checked={props.values.isAvailable}
                      className={adminCheckbox}
                      onChange={() => {
                        if (!props.values.isAvailable) {
                          props.setFieldValue('isAvailable', true);
                        }
                      }}
                    />
                    <div>Public</div>
                  </label>

                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isAvailable_private"
                      checked={!props.values.isAvailable}
                      className={adminCheckbox}
                      onChange={() => {
                        if (props.values.isAvailable) {
                          props.setFieldValue('isAvailable', false);
                        }
                      }}
                    />
                    <div>Private</div>
                  </label>
                </div>

                <div>
                  <div>Availability</div>
                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isFree"
                      checked={props.values.isFree}
                      className={adminCheckbox}
                      onChange={() => {
                        if (!props.values.isFree) {
                          props.setFieldValue('isFree', true);
                        }
                      }}
                    />
                    <div>Anyone</div>
                  </label>

                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isFree_private"
                      checked={!props.values.isFree}
                      className={adminCheckbox}
                      onChange={() => {
                        if (props.values.isFree) {
                          props.setFieldValue('isFree', false);
                        }
                      }}
                    />
                    <div>Subscribers</div>
                  </label>
                </div>
              </div>

              <div>
                <div className={adminTitle}>Thumbnail</div>
              </div>
              <div className={distributionRow}>
                {existingImageUrl && !props.values.thumbnailData && (
                  <div className={thumbnailContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setFieldValue('thumbnailUrl', '');
                        props.setFieldValue('thumbnailData', '');
                        setExistingImageUrl(undefined);
                      }}
                      className={classNames(`delete`, thumbnailDeleteButton)}
                    />
                    <img className={thumbnailImage} src={`${brandInfo.imageBaseUrl}/series/${existingImageUrl}`} alt="" />
                  </div>
                )}
                {!existingImageUrl && !props.values.thumbnailData && (
                  <div className={classNames(thumbnailInput, thumbnailContainer)} onClick={() => fileUploadRef.current?.click()}>
                    <Icon icon="bi:plus-circle" className={addThumbnailIcon} />
                    <div className={adminSubText}>ADD THUMBNAIL</div>
                  </div>
                )}
                {props.values.thumbnailData && (
                  <div className={thumbnailContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setFieldValue('thumbnailUrl', '');
                        props.setFieldValue('thumbnailData', '');
                      }}
                      className={classNames(`delete`, thumbnailDeleteButton)}
                    />
                    <img className={thumbnailImage} src={props.values.thumbnailData} alt="" />
                  </div>
                )}

                <Field
                  innerRef={fileUploadRef}
                  className={thumbnailFieldInput}
                  onChange={(event: any) => {
                    if (event.target.files && event.target.files.length > 0) {
                      const file = event.currentTarget.files[0];
                      props.setFieldValue('file', file);

                      const reader = new FileReader();

                      reader.onload = function readFile(event) {
                        props.setFieldValue('thumbnailUrl', file.name);
                        props.setFieldValue('thumbnailData', event.target?.result || '');
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  id="fileUpload"
                  type="file"
                  name="fileUpload"
                  accept="image/png, image/jpeg, image/webp"
                />
                {props.touched.thumbnailData && props.errors.thumbnailData && (
                  <div className={errorStyle}>{props.errors.thumbnailData}</div>
                )}
              </div>

              <div className={adminPadBottom}>
                <div className={adminTitle}>Seasons</div>
              </div>

              {props.values.seasons.map((season, i) => (
                <div className={adminPadBottom} key={i}>
                  <div className={adminText}>{season.title}</div>

                  <div className={classNames(flexRow, seasonRow)}>
                    <div className={classNames(editorVideoLabel, adminSubText, adminPadBottom)}>
                      Drag episodes to change their order in the slider.
                    </div>
                    <div className={classNames(flexRow, adminSubText, editorVideoLabel, removeButton)} onClick={() => setPendingRemoval(i)}>
                      <Icon icon="fa-solid:trash-alt" />
                      <div>Delete</div>
                    </div>
                    {pendingRemoval === i && (
                      <ConfirmCard
                        title={'Delete Season'}
                        content={'Do you really want to delete this season? This process cannot be undone.'}
                        confirmCta={'Delete'}
                        onConfirm={() => {
                          const newSeasons = [...props.values.seasons];
                          newSeasons.splice(i, 1);
                          props.setFieldValue('seasons', newSeasons);
                          setPendingRemoval(undefined);
                        }}
                        onCancel={() => setPendingRemoval(undefined)}
                      />
                    )}
                  </div>

                  {season.items.map((episode, j) => (
                    <div
                      className={classNames(adminMarginBottom, flexRow, collectionEpisodeRow)}
                      key={j}
                      onDragOver={e => {
                        e.preventDefault();
                      }}
                      onDragEnter={e => {
                        e.preventDefault();
                        setDragOver(j);
                      }}
                      onDrop={e => {
                        e.preventDefault();
                        setDragOver(undefined);
                        const index = parseInt(e.dataTransfer.getData('text'));
                        if (index === j) {
                          return;
                        } else {
                          let tempItems = [...season.items];
                          const draggedItem = tempItems.splice(index, 1);
                          tempItems = [...tempItems.slice(0, j), ...draggedItem, ...tempItems.slice(j)];
                          const tempSeason: DatabaseSeasonInput = { ...season, items: tempItems };
                          const tempSeries: DatabaseSeasonInput[] = [...props.values.seasons];
                          tempSeries[i] = tempSeason;
                          props.setFieldValue('seasons', tempSeries);
                        }
                      }}
                    >
                      <div className={classNames(adminText)}>{j + 1}</div>
                      <div
                        className={classNames(collectionListEpisode, flexRow)}
                        draggable
                        onDragStart={e => {
                          e.dataTransfer.setData('text', j.toString());
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '0.2';
                          setDragSeason(i);
                        }}
                        onDragEnd={e => {
                          e.preventDefault();
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '1';
                          setDragOver(undefined);
                          setDragSeason(undefined);
                        }}
                      >
                        <div>{episode.title}</div>
                        <div className={dragHandle}>
                          <Icon icon="akar-icons:drag-vertical" />
                        </div>
                      </div>

                      <div
                        className={classNames(flexRow, adminSubText, editorVideoLabel, removeButton)}
                        onClick={() => {
                          const videos = [...season.items];
                          videos.splice(j, 1);
                          const tempSeason: DatabaseSeasonInput = { ...season, items: videos };
                          const tempSeries: DatabaseSeasonInput[] = [...props.values.seasons];
                          tempSeries[i] = tempSeason;
                          props.setFieldValue('seasons', tempSeries);
                        }}
                      >
                        <Icon icon="fa-solid:trash-alt" />
                        <div>Remove</div>
                      </div>

                      {dragOver === j && dragSeason === i && (
                        <div className={dragHint}>
                          <Icon icon="akar-icons:arrow-left-thick" />
                        </div>
                      )}
                    </div>
                  ))}

                  {addingVideo !== i && (
                    <div className={videoAddContainer} onClick={() => setAddingVideo(i)}>
                      <Icon icon="bi:plus-circle" className={videoAddButton} />
                      <div className={adminSubText}>ADD VIDEO</div>
                    </div>
                  )}

                  {addingVideo === i && (
                    <AddVideoWidget
                      videos={allVideos}
                      onCancel={() => setAddingVideo(undefined)}
                      onSubmit={(id, title) => {
                        const videos = season.items;
                        const newVideos = [
                          ...videos,
                          {
                            id: '',
                            videoId: id,
                            title,
                          },
                        ];
                        const tempSeason: DatabaseSeasonInput = { ...season, items: newVideos };
                        const tempSeries: DatabaseSeasonInput[] = [...props.values.seasons];
                        tempSeries[i] = tempSeason;
                        props.setFieldValue('seasons', tempSeries);
                        setAddingVideo(undefined);
                      }}
                    />
                  )}
                </div>
              ))}

              {!addingSeason && (
                <div className={videoCollectionAddContainer} onClick={() => setAddingSeason(true)}>
                  <Icon icon="bi:plus-circle" className={videoCollectionAdd} />
                  <div className={adminSubText}>ADD SEASON</div>
                </div>
              )}

              {addingSeason && (
                <AddSeasonWidget
                  onCancel={() => setAddingSeason(false)}
                  onSubmit={title => {
                    const newSeason: DatabaseSeasonInput = {
                      id: '',
                      title,
                      items: [],
                    };
                    const newSeasons = [...props.values.seasons, newSeason];
                    props.setFieldValue('seasons', newSeasons);
                    setAddingSeason(false);
                  }}
                />
              )}
            </>
          )}
        </Formik>
      )}
    </div>
  );
};
