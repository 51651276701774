import * as React from 'react';

import { ErrorData, ErrorFormatter } from '@lib/ErrorFormatter';
import { ClientLogger } from '@lib/ClientLogger';

interface IProps {
  errorData: ErrorData;
  consoleError?: boolean;
}

// To do add some formatting

export function ErrorDisplay(props: IProps) {
  const errorFormatter = new ErrorFormatter(props.errorData);
  if (props.consoleError) {
    ClientLogger.error('ErrorDisplay', errorFormatter.displayErrors());
  }
  return <div>{errorFormatter.displayErrors()}</div>;
}
