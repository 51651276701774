import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { formatCurrency } from '@lib/adPlacements';
import { useProductData } from '@lib/shopify/useProductData';
import { ShopifyProduct } from '@sharedLib/product';
import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { Button } from '@components/Buttons';
import { ProductImg } from '@components/Img';
import { ShopifyUtil } from '@lib/shopify/ShopifyUtil';
import classnames from 'classnames';
import { usePvepApi } from '@apiClient/usePvepApi';
import { CartServices } from '@apiClient/cart-services';
import {
  buttonWatchStream,
  buttonFreeTrial,
  cardContents,
  cardContentsLarge,
  imageLarge,
  image,
  detailsAndButton,
  detailsAndButtonLarge,
  details,
  title,
  productDetailsWrapper,
  productDetails,
  productPrice,
} from './styles.module.scss';

interface PPVProductCardProps {
  product?: ShopifyProduct;
  isLarge?: boolean;
  freeTrialButtonText?: string;
}

const DEBUG = false;

const PPVProductCard = ({ product, isLarge, freeTrialButtonText }: PPVProductCardProps) => {
  const nav = useContext(NavStateContext);
  const api = usePvepApi();
  const user = api.state;
  const shopifyId = product?.id || '';
  const productData = useProductData();
  const shopifyProduct = productData.getProduct(shopifyId);
  const info = shopifyProduct?.variants[0];
  const videoId = shopifyProduct?.videoIds;

  DEBUG && ClientLogger.debug('PPVProductCard.userHasPurchasedItem', `email = ${user.email} variantId = ${info?.id} videoId = ${videoId} `);
  const [userHasPurchasedItem, setUserHasPurchasedItem] = useState<boolean>(false);

  // on mount, check if user has purchased the ppv
  useEffect(() => {
    async function getPurchaseResults() {
      const itemPurchaseResults = user.email ? await CartServices.userHasPurchasedItem(user.email, undefined, shopifyId) : false;
      DEBUG && ClientLogger.debug('PPVProductCard.itemPurchaseResults', 'itemPurchaseResults=', DEBUG, itemPurchaseResults);

      if (itemPurchaseResults) {
        if (itemPurchaseResults.data) {
          setUserHasPurchasedItem(itemPurchaseResults.data.userHasPurchasedItem.purchaseStatus);
        } else {
          ClientLogger.error('PPVProductCard.itemPurchaseResults', `Expected return data from userHasPurchasedItem`, itemPurchaseResults);
        }
      }
    }
    getPurchaseResults();
  }, []);

  const onClickFreeTrial = async (clickedProduct: ShopifyProduct) => {
    DEBUG && ClientLogger.debug('PPVProductCard', 'onClickFreeTrial', { clickedProduct, isLarge });
    // when we implement variants in these tickets, this will need to be updated
    if (clickedProduct) {
      if (isLarge) {
        const resp = await api.cartServices.addCartItem({
          email: user.email,
          description: shopifyProduct?.description,
          productId: shopifyProduct?.id,
          title: shopifyProduct?.title,
          quantity: 1,
          price: String(shopifyProduct?.maxVariantPrice),
          imageUrl: JSON.stringify(shopifyProduct?.imageOriginalSrc),
          variantId: info?.id,
          variantName: info?.title,
        });

        DEBUG && ClientLogger.debug('HomePage.addCartItemResp', 'resp =', DEBUG, resp);
        nav.setRoot({ path: '/cart' });
      } else {
        nav.setRoot({ path: `/sign-up?productId=${clickedProduct.id}` });
      }
    } else {
      nav.setRoot({ path: '/sign-up' });
    }
  };

  if (!shopifyProduct || !product) {
    return <ErrorDisplay errorData={{ errors: [`Cannot find product ${shopifyId}`] }} />;
  }

  const showTime = ShopifyUtil.extractMetafield(product, 'Show_Time');
  const showDate = ShopifyUtil.extractMetafield(product, 'Show_Date');

  return (
    <span className={classnames(cardContents, isLarge ? cardContentsLarge : '')}>
      <ProductImg product={product} className={classnames(isLarge ? imageLarge : image)} imgStyle={{ objectFit: 'cover' }} />
      <div className={classnames(detailsAndButton, isLarge ? detailsAndButtonLarge : '')}>
        <div className={details}>
          <div className={title}>{product.title}</div>
          <div className={productDetailsWrapper}>
            <div className={productDetails}>{showDate}</div>
            <div className={productDetails}>{showTime}</div>
          </div>
          {!userHasPurchasedItem && <div className={productPrice}>{formatCurrency(product.maxVariantPrice)}</div>}
        </div>
        {userHasPurchasedItem && (
          <Link to={`/video/${videoId}`}>
            <Button className={classnames(buttonFreeTrial, buttonWatchStream)}>Watch Stream</Button>
          </Link>
        )}
        {!userHasPurchasedItem && (
          <Button className={buttonFreeTrial} onClick={() => onClickFreeTrial(product)}>
            {freeTrialButtonText}
          </Button>
        )}
      </div>
    </span>
  );
};

export default PPVProductCard;
