import React, { useContext, useState, useRef } from 'react';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import Select from 'react-select';
import { Button, ButtonStyles } from '@components/Buttons';
import queryString from 'query-string';
import { usePvepApi } from '@apiClient/usePvepApi';
import classNames from 'classnames';
import { ClientLogger } from '@src/lib/ClientLogger';
import md5 from 'md5';
import { NavStateContext } from '@lib/navContext';
import { Icon } from '@iconify/react';
import { BrandUtil, Util, DatabaseVideo } from '@sharedLib/index';
import { Uploader } from './Uploader';
import cuid from 'cuid';
import {
  noWrapButton,
  editorVideoLabel,
  adminSubText,
  editorFirstColumn,
  adminText,
  videoMetadataForm,
  videoMetadataFormField,
  videoMetadataFormRow,
  videoMetadataFormError,
  adminInput,
  fieldDescription,
  videoUrlLabel,
  videoUrlRow,
  videoUrlField,
  videoUrlPlatform,
  videoUrlMargin,
  videoUrlTitle,
  videoUrlRejectButton,
  videoUrlError,
  videoUrlPad,
  videoConfirmGroup,
  videoMetadataFormDistributionTitle,
  adminCheckbox,
  metadataFormField,
  applyFlex,
  fullWidth,
  videoMetadataDistributionColumn,
  thumbnailContainer,
  thumbnailFieldInput,
  thumbnailImage,
  thumbnailDeleteButton,
  addThumbnailIcon,
  thumbnailInput,
  errorMessage as errorStyle,
  previewCard,
  previewTitle,
  previewText,
  videoMetadataTitleContainer,
  previewContainer,
} from './styles.module.scss';

const videoMetadataSchema = () => {
  return yup.object().shape({
    id: yup.string(),
    title: yup.string().required('This is required'),
    description: yup
      .string()
      .required('This is required')
      .max(300, '300 characters max'),
  });
};

const videoUrlSchema = yup.object().shape({
  url: yup
    .string()
    .required('This field is required.')
    .test('protocolMatch', 'Not a valid url, please include https.', val => val !== undefined && Boolean(val.match(/^(http|https):\/\//))),
  platform: yup.string().required('This field is required.'),
});

interface ExternalVideoData {
  title?: string;
  description?: string;
  shortDescription?: string;
  seriesId?: number;
  seasonNumber?: number;
  episodeNumber?: number;
  videoId?: string;
  url?: string;
  source?: string;
}

interface Props {
  initialStep?: number;
  initialVideo?: DatabaseVideo;
}

export const DatabaseVideoForm = ({ initialStep, initialVideo }: Props) => {
  const [currentStep, setCurrentStep] = useState(initialStep || 0);
  const [urlError, setUrlError] = useState('');
  const [dataFromUrl, setDataFromUrl] = useState<ExternalVideoData | undefined>(undefined);
  const [initialVideoData, setInitialVideoData] = useState<DatabaseVideo | undefined>(initialVideo || undefined);
  const [existingImageUrl, setExistingImageUrl] = useState<string | undefined>(initialVideo?.thumbnailUrl || undefined);
  const [dataError, setDataError] = useState('');
  const [dataSuccess, setDataSuccess] = useState('');
  const [previewId, setPreviewId] = useState('');
  const [uploadProgress, setUploadProgress] = useState('');
  const [videoUploading, setVideoUploading] = useState(false);

  const brandInfo = BrandUtil.getSiteInfo();
  const api = usePvepApi();
  const nav = useContext(NavStateContext);
  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  const platformOptions = [
    {
      value: 'core-vimeo',
      label: 'Vimeo',
    },
    {
      value: 'youtube',
      label: 'Youtube',
    },
  ];

  if (brandInfo.usesVimeoOtt) {
    platformOptions.push({
      value: 'vimeo-ott',
      label: 'Vimeo-OTT',
    });
  }

  const getExternalVideoData = async (platform: string, url: string) => {
    let videoId = '';
    switch (platform) {
      case 'vimeo-ott':
        // Go reverse through url parts to retrieve the numerical video id
        // Example URL: https://develop-habitat.vhx.tv/admin/manage/videos/1839011/general
        if (!url.match(/(vhx.tv)/)) {
          setUrlError('URL invalid: Expected link from vhx.tv');
          return;
        }
        const vimeoOttParts = url.split('/').reverse();
        for (const part of vimeoOttParts) {
          if (Number.isInteger(parseInt(part))) {
            videoId = part;
            break;
          }
        }
        break;
      case 'core-vimeo':
        // Go reverse through url parts to retrieve the numerical video id
        // Example URL: https://vimeo.com/555252697 or https://vimeo.com/manage/videos/531787271
        if (!url.match(/(vimeo.com)/)) {
          setUrlError('URL invalid: Expected link from vimeo.com');
          return;
        }
        const vimeoCoreParts = url.split('/').reverse();
        for (const part of vimeoCoreParts) {
          if (Number.isInteger(parseInt(part))) {
            videoId = part;
            break;
          }
        }
        break;
      case 'youtube':
        // Check for video id in query parameters
        // Example URL: https://www.youtube.com/watch?v=VjopV7Mamws
        if (!url.match(/(youtube.com)/)) {
          setUrlError('URL invalid: Expected link from youtube.com');
          return;
        }
        const youtubeParts = url.split('?');
        if (youtubeParts.length !== 2) {
          setUrlError('URL invalid: Expected video ID in parameters');
          return;
        }
        const parsed = queryString.parse(youtubeParts[1]);
        if (parsed.v) {
          videoId = parsed.v as string;
        }
        break;
      default:
        ClientLogger.error('VideoMetadataForm.getExternalVideoData', `Unexpected platform: ${platform}`);
        return;
    }

    // check video ID was found
    if (!videoId) {
      setUrlError('URL invalid: Could not retrieve video ID');
      return;
    }
    setUrlError('');

    const externalDataResp = await api.getExternalVideoData(platform, videoId);
    if (externalDataResp.data?.getExternalVideoData.success) {
      const { video } = externalDataResp.data.getExternalVideoData;
      setDataFromUrl({
        title: video?.title || '',
        description: video?.description || '',
        videoId,
        url: video?.url || url,
        source: platform,
      });
    } else {
      const err = externalDataResp.data?.getExternalVideoData?.errorMessages;
      if (err?.length) {
        setUrlError(err[0].errorMessage || 'Something went wrong, please try again.');
      } else {
        setUrlError('Something went wrong, please try again.');
      }
    }
  };

  const writeVideoToDatabase = async (videoData: DatabaseVideo, file: any) => {
    // if new thumbnail data, and on cloud, use presigned url to avoid lambda size limits
    // if local, send b64 thumbnail data to be written to filesystem
    if (videoData.thumbnailData && !Util.isLocal()) {
      setUploadProgress('Uploading thumbnail...');

      const dataString = videoData.thumbnailData.replace(/^data:image\/[a-z]+;base64,/, '');

      // generate image name
      const hash = md5(dataString);
      const imageExt = videoData.thumbnailUrl.split('.').pop() || '';
      const imageFileName = `${videoData.id}_${hash}.${imageExt}`;
      const s3KeyPath = `${brandInfo.branchId}/videos`;
      const contentType = Util.extToMime(imageExt);

      // get presigned upload url
      const urlResp = await api.getS3UploadUrl(brandInfo.imageBucketName, `${s3KeyPath}/${imageFileName}`, contentType);
      if (!urlResp.data?.getS3UploadUrl.success || !urlResp.data.getS3UploadUrl.url) {
        setDataError('Error trying to save data: Failed to get upload url for thumbnail.');
        setUploadProgress('');
        return;
      }

      const url = urlResp.data?.getS3UploadUrl.url || '';
      const resp = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      });

      if (resp.status !== 200) {
        setDataError('Error trying to save data: Failed to upload thumbnail.');
        setUploadProgress('');
        return;
      }

      setUploadProgress('Compressing thumbnail...');

      // compress image
      const compressResp = await api.compressS3Image(brandInfo.imageBucketName, s3KeyPath, imageFileName, contentType);
      if (!compressResp.data?.compressS3Image.success) {
        setDataError('Error trying to save data: Failed to compress thumbnail image.');
        setUploadProgress('');
        return;
      }

      setUploadProgress('');

      // don't send image data to server, it's already been uploaded
      // send hashed filename as thumbnailurl to be recorded in the db
      videoData.thumbnailData = undefined;
      videoData.thumbnailUrl = compressResp.data.compressS3Image.thumbnailUrl;
    }

    const writeResp = await api.writeDatabaseVideo(videoData);
    if (writeResp.data?.writeDatabaseVideo.success) {
      setDataError('');
      setDataSuccess('Data has been saved successfully.');
      setPreviewId(videoData.id);
    } else {
      setDataError(`Error trying to save data: ${JSON.stringify(writeResp.data?.writeDatabaseVideo.errorMessages)}`);
      setDataSuccess('');
      setPreviewId('');
    }
  };

  return (
    <div className={videoMetadataForm}>
      {currentStep === 0 && (
        <>
          <div className={videoUrlTitle}>Upload a video to your platform from your computer.</div>
          <Uploader
            onSuccess={fileName => {
              setInitialVideoData({
                id: '',
                externalId: '',
                title: fileName,
                description: '',
                url: fileName,
                isAvailable: true,
                isFree: false,
                type: 'uploaded',
                thumbnailUrl: '',
              });
              setCurrentStep(currentStep + 1);
              setVideoUploading(false);
            }}
            onStart={() => setVideoUploading(true)}
            onCancel={() => setVideoUploading(false)}
          />

          {!videoUploading && (
            <Formik
              initialValues={{
                url: '',
                platform: '',
              }}
              validationSchema={videoUrlSchema}
              onSubmit={async values => {
                setDataSuccess('');
                setDataError('');
                await getExternalVideoData(values.platform, values.url);
              }}
            >
              {props => (
                <div>
                  <div className={videoUrlRow}>
                    <div className={videoUrlTitle}>
                      To add a video using Vimeo or Youtube, copy the URL into the form below and hit submit.
                    </div>
                  </div>
                  <div className={videoUrlRow}>
                    <label className={videoUrlLabel}>Video URL:</label>
                    <div className={videoUrlField}>
                      <Field
                        className={adminInput}
                        value={props.values.url}
                        onChange={props.handleChange}
                        id="url"
                        name="url"
                        placeholder="https://www.example.com"
                      />
                    </div>
                  </div>
                  <div className={classNames(videoUrlError, videoUrlMargin)}>
                    {props.errors.url && props.touched.url ? props.errors.url : ''}
                  </div>

                  <div className={videoUrlRow}>
                    <label className={videoUrlLabel}>Platform: </label>
                    <div className={videoUrlPlatform}>
                      <Select
                        value={platformOptions.filter(opt => opt.value === props.values.platform)}
                        name="platform"
                        onChange={opt => {
                          props.setFieldValue(`platform`, opt?.value);
                        }}
                        isClearable={false}
                        options={platformOptions}
                        classNamePrefix="selectForm"
                      />
                    </div>
                  </div>

                  <div className={classNames(videoUrlError, videoUrlMargin)}>
                    {props.errors.platform && props.touched.platform ? props.errors.platform : ''}
                  </div>

                  <Button
                    onClick={() => props.handleSubmit()}
                    disabled={props.isSubmitting}
                    className={classNames(noWrapButton, videoUrlMargin)}
                  >
                    SUBMIT
                  </Button>

                  <div className={classNames(videoUrlError, videoUrlMargin)}>{urlError || ''}</div>

                  {dataFromUrl && (
                    <div className={videoConfirmGroup}>
                      <div className={videoUrlRow}>
                        <label className={videoUrlLabel}>Confirm:</label>
                        <div className={adminText}>{dataFromUrl.title}</div>
                      </div>
                      <div className={videoUrlRow}>
                        <div className={classNames(adminSubText, videoUrlMargin)}>
                          <span className={editorVideoLabel}>External Video ID:</span> {dataFromUrl.videoId}
                        </div>
                      </div>

                      <div className={classNames(videoUrlRow, videoUrlPad)}>
                        <div className={videoUrlMargin}>Is this the video you wish to add?</div>
                      </div>

                      <div className={classNames(videoUrlRow, videoUrlPad)}>
                        <Button
                          onClick={() => setDataFromUrl(undefined)}
                          style={ButtonStyles.Transparent}
                          className={classNames(noWrapButton, videoUrlMargin, videoUrlRejectButton)}
                        >
                          NO
                        </Button>
                        <Button
                          onClick={() => {
                            setInitialVideoData({
                              id: '',
                              externalId: dataFromUrl?.videoId || '',
                              title: dataFromUrl?.title || '',
                              description: dataFromUrl?.description || '',
                              url: dataFromUrl?.url || '',
                              isAvailable: true,
                              isFree: false,
                              type: props.values.platform,
                              thumbnailUrl: '',
                            });
                            setCurrentStep(currentStep + 1);
                          }}
                          className={classNames(noWrapButton)}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Formik>
          )}
        </>
      )}
      {currentStep === 1 && initialVideoData && (
        <Formik
          initialValues={{
            ...initialVideoData,
            file: '',
          }}
          enableReinitialize
          validationSchema={videoMetadataSchema()}
          validateOnMount
          onSubmit={async values => {
            const videoData: DatabaseVideo = {
              id: values.id,
              externalId: values.externalId,
              title: values.title,
              label: values.label,
              description: values.description,
              url: values.url,
              isAvailable: values.isAvailable,
              isFree: values.isFree,
              type: values.type,
              thumbnailUrl: values.thumbnailUrl,
              thumbnailData: values.thumbnailData,
            };

            if (!videoData.id) {
              // get unique video id
              const videoIdResp = await api.getUniqueVideoId();
              if (!videoIdResp.data?.getUniqueVideoId.success) {
                setDataError('Error trying to save data: unable to get unique video id, please try again.');
                return;
              }
              videoData.id = videoIdResp.data.getUniqueVideoId.id;
            }

            await writeVideoToDatabase(videoData, values.file);
            setInitialVideoData(videoData);
          }}
        >
          {props => (
            <div>
              <div className={videoMetadataFormRow}>
                <div className={editorFirstColumn}>
                  <div className={adminText}>{initialVideoData.title}</div>
                  {initialVideoData.id && (
                    <div className={adminSubText}>
                      <span className={editorVideoLabel}>Video ID: </span>
                      {initialVideoData.id}
                    </div>
                  )}
                </div>
                <div className={applyFlex}>
                  <Button
                    disabled={props.isSubmitting || !props.isValid}
                    type="submit"
                    className={noWrapButton}
                    onClick={props.handleSubmit}
                  >
                    SAVE ALL
                  </Button>
                  {uploadProgress && <div>{uploadProgress}</div>}
                  {dataSuccess && (
                    <>
                      <div>{dataSuccess}</div>
                      <Button
                        style={ButtonStyles.Greyscale}
                        onClick={async () => {
                          await api.updateUserRuntime(true);
                          nav.push({ path: `/video/${previewId}` });
                        }}
                        className={noWrapButton}
                      >
                        Preview Video
                      </Button>
                    </>
                  )}
                  {dataError && <div>{dataError}</div>}
                </div>
              </div>

              <div className={videoMetadataTitleContainer}>
                <div className={videoMetadataFormField}>
                  <label htmlFor="title">File Name</label>
                  <Field
                    className={adminInput}
                    value={props.values.title}
                    onChange={props.handleChange}
                    id="title"
                    name="title"
                    placeholder="File Name"
                  />
                  <div className={fieldDescription}>Input the name as you would like it to appear on the dashboard.</div>
                  {props.errors.title && props.touched.title && <div className={videoMetadataFormError}>{props.errors.title}</div>}
                </div>

                <div className={videoMetadataFormField}>
                  <label htmlFor="label">Label</label>
                  <Field
                    className={adminInput}
                    value={props.values.label}
                    onChange={props.handleChange}
                    id="label"
                    name="label"
                    placeholder="Label"
                  />
                  <div className={fieldDescription}>Input the name as you would like it to appear in the hover state for your users.</div>
                  {props.errors.label && props.touched.label && <div className={videoMetadataFormError}>{props.errors.label}</div>}
                </div>
              </div>

              <div className={videoMetadataFormRow}>
                <div className={videoMetadataFormField}>
                  <label htmlFor="description">Description</label>
                  <Field
                    className={adminInput}
                    value={props.values.description}
                    onChange={props.handleChange}
                    id="description"
                    name="description"
                    as="textarea"
                    rows="8"
                    placeholder="300 characters max"
                  />
                  <div className={fieldDescription}>This description will appear in the hover state for your video.</div>
                  <div className={videoMetadataFormError}>{props.errors.description ? props.errors.description : ' '}</div>
                </div>

                <div>
                  <label>Preview</label>
                  <div className={previewContainer}>
                    {existingImageUrl && !props.values.thumbnailData && (
                      <img className={thumbnailImage} src={`${brandInfo.imageBaseUrl}/videos/${existingImageUrl}`} alt="" />
                    )}
                    {props.values.thumbnailData && <img className={thumbnailImage} src={props.values.thumbnailData} alt="" />}

                    <div className={previewCard}>
                      <div className={previewTitle}>{props.values.label || 'Label'}</div>
                      <div className={previewText}>{props.values.description || 'Description'}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames(videoMetadataFormRow, videoMetadataFormDistributionTitle)}>Thumbnail</div>

              <div className={videoMetadataFormRow}>
                {existingImageUrl && !props.values.thumbnailData && (
                  <div className={thumbnailContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setFieldValue('thumbnailUrl', '');
                        props.setFieldValue('thumbnailData', '');
                        setExistingImageUrl(undefined);
                      }}
                      className={classNames(`delete`, thumbnailDeleteButton)}
                    />
                    <img className={thumbnailImage} src={`${brandInfo.imageBaseUrl}/videos/${existingImageUrl}`} alt="" />
                  </div>
                )}
                {!existingImageUrl && !props.values.thumbnailData && (
                  <div className={classNames(thumbnailInput, thumbnailContainer)} onClick={() => fileUploadRef.current?.click()}>
                    <Icon icon="bi:plus-circle" className={addThumbnailIcon} />
                    <div className={adminSubText}>ADD THUMBNAIL</div>
                  </div>
                )}
                {props.values.thumbnailData && (
                  <div className={thumbnailContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setFieldValue('thumbnailUrl', '');
                        props.setFieldValue('thumbnailData', '');
                      }}
                      className={classNames(`delete`, thumbnailDeleteButton)}
                    />
                    <img className={thumbnailImage} src={props.values.thumbnailData} alt="" />
                  </div>
                )}

                <Field
                  innerRef={fileUploadRef}
                  className={thumbnailFieldInput}
                  onChange={(event: any) => {
                    if (event.target.files && event.target.files.length > 0) {
                      const file = event.currentTarget.files[0];
                      props.setFieldValue('file', file);

                      const reader = new FileReader();

                      reader.onload = function readFile(event) {
                        props.setFieldValue('thumbnailUrl', file.name);
                        props.setFieldValue('thumbnailData', event.target?.result || '');
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  id="fileUpload"
                  type="file"
                  name="fileUpload"
                  accept="image/png, image/jpeg, image/webp"
                />
                {props.touched.thumbnailData && props.errors.thumbnailData && (
                  <div className={errorStyle}>{props.errors.thumbnailData}</div>
                )}
              </div>

              <div className={classNames(videoMetadataFormRow, videoMetadataFormDistributionTitle)}>Distribution</div>

              <div className={videoMetadataFormRow}>
                <div className={classNames(applyFlex, fullWidth, videoMetadataDistributionColumn)}>
                  <div className={videoMetadataFormField}>
                    <label htmlFor="isAvailable">Visibility</label>
                    <label className={metadataFormField}>
                      <Field
                        type="checkbox"
                        name="isAvailable"
                        checked={props.values.isAvailable}
                        className={adminCheckbox}
                        onChange={() => {
                          props.setFieldValue('isAvailable', true);
                        }}
                      />
                      <div>Public</div>
                    </label>

                    <label className={metadataFormField}>
                      <Field
                        type="checkbox"
                        name="isPrivate"
                        checked={!props.values.isAvailable}
                        onChange={() => {
                          props.setFieldValue('isAvailable', false);
                        }}
                        className={adminCheckbox}
                      />
                      <div>Private (Admin only)</div>
                    </label>
                  </div>

                  <div className={videoMetadataFormField}>
                    <label htmlFor="isFree">Availability</label>
                    <label className={metadataFormField}>
                      <Field
                        type="checkbox"
                        name="isFree"
                        checked={props.values.isFree}
                        className={adminCheckbox}
                        onChange={() => {
                          props.setFieldValue('isFree', true);
                        }}
                      />
                      <div>Anyone</div>
                    </label>

                    <label className={metadataFormField}>
                      <Field
                        type="checkbox"
                        name="availableSubscribers"
                        checked={!props.values.isFree}
                        onChange={() => {
                          props.setFieldValue('isFree', false);
                        }}
                        className={adminCheckbox}
                      />
                      <div>Subscribers</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
