import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { popupContainer, popupOverlay, popup, closeButton, popupBlockedCard, popupBlockedText } from './styles.module.scss';

interface Props {
  closeModal: () => void;
}

export const PopupBlocked = ({ closeModal }: Props) => {
  // two-pass render attempt to avoid gatsby hydration issues
  const [isClient, setClient] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <div className={popupContainer}>
      <div className={popupOverlay} onClick={closeModal} />
      <div className={popup}>
        <div className={popupBlockedCard}>
          <div className={popupBlockedText}>
            We noticed that your browser currently blocks pop-up windows. Please change your settings to allow the checkout to appear in a
            new window.
          </div>
          <button type="button" onClick={closeModal} className={classNames('delete is-pulled-right mb-4 mt-0', closeButton)} />
        </div>
      </div>
    </div>
  );
};
