import React from 'react';

interface ToggleButtonProps {
  text: string;
  currentState: string;
  setState: (value: string) => void;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ text, currentState, setState }) => {
  return (
    <button
      className="button toggle-button"
      type="button"
      onClick={() => {
        if (currentState === 'medium') {
          setState('small');
        } else {
          setState('medium');
        }
      }}
    >
      {text}
    </button>
  );
};
