import { NavStateContext } from '@lib/navContext';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { IconButton } from '@components/Buttons';
import profileIcon from '@iconify/icons-mdi/account-circle';

import {
  dropDownButton,
  iconProfile,
  rightContainer,
  leftContainer,
  contentContainer,
  dropDownHeader,
  dropDownItem,
} from './styles.module.scss';

interface Props {
  links: {
    items: {
      itemName: string;
      onClick: (() => void) | string;
      header: boolean;
    }[];
  };
  menuStyle: {
    rightAlign: boolean;
    topAlign: boolean;
  };
  dropDownState: boolean;
  setDropDownState: (on: boolean) => void;
}

export const Dropdown = ({ links, dropDownState, setDropDownState, menuStyle }: Props) => {
  const { items } = links;
  const nav = useContext(NavStateContext);
  return (
    <div
      key={items[0].itemName}
      className={classNames(dropDownState ? `dropdown is-active` : '')}
      onMouseEnter={() => {
        setDropDownState(true);
      }}
      onMouseLeave={() => {
        setDropDownState(false);
      }}
    >
      <IconButton className={classNames(dropDownButton, iconProfile)} onTouchStart={() => setDropDownState(true)}>
        <Icon icon={profileIcon} height="30px" />
      </IconButton>
      <div className={classNames('dropdown-menu', menuStyle.rightAlign ? rightContainer : leftContainer)} id="dropdown-menu" role="menu">
        <div className={classNames('dropdown-content', contentContainer)}>
          {items.map(item => {
            return (
              <div
                key={item.itemName}
                className={classNames(item.header ? dropDownHeader : dropDownItem)}
                onClick={() => {
                  if (typeof item.onClick === 'function') {
                    item.onClick();
                  } else if (item.onClick !== '#') {
                    nav.setRoot({ path: item.onClick });
                  }
                }}
              >
                {item.itemName}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
