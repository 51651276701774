import { NavStateContext } from '@lib/navContext';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { Button } from '@components/Buttons';
import { isBrowser } from '@lib/build';
import { DatabaseVideo, DatabaseSeries } from '@sharedLib/index';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  buttonText as buttonTextStyle,
  infoWrapper,
  hideTiny,
  info,
  shadow,
  button,
  innerButton,
  header,
  description,
} from './styles.module.scss';

const DEBUG = false;

interface Props {
  item: DatabaseVideo | DatabaseSeries;
  type: 'video' | 'series';
}

export const InfoArea = ({ item, type }: Props) => {
  const nav = useContext(NavStateContext);

  const onClick = () => {
    const navData = nav.navState.pageData;
    if (isBrowser) {
      if (type === 'video') {
        if (navData[navData.length - 1].path.split('/').includes('video')) {
          nav.replace({ path: `/video/${item.id}/`, title: item.title });
        } else {
          nav.push({ path: `/video/${item.id}/`, title: item.title });
        }
      } else if (type === 'series') {
        if (navData[navData.length - 1]?.path.split('/').includes('series')) {
          nav.replace({ path: `/series/${item.id}/`, title: item.title });
        } else {
          nav.push({ path: `/series/${item.id}/`, title: item.title });
        }
      }
    }
  };

  return (
    <div className={classnames(infoWrapper)}>
      <div className={classnames(info)}>
        <div className={classnames(header, shadow)}>{item.title}</div>
        <div className={classnames(description, shadow, hideTiny)}>{item.description}</div>
        <Button className={button} onClick={onClick}>
          <div className={innerButton}>
            <span className={buttonTextStyle}>{type === 'video' ? 'Watch Now' : 'More Details'}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};
