import { useFluidImage } from '@src/components/Img/useFluidImage';
import { ClientLogger } from '@lib/ClientLogger';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

interface ImageProps {
  id: string | number;
  className?: string;
  title?: string;
}

const DEBUG = false;

export const VideoImg: React.FC<ImageProps> = ({ id, className, title }) => {
  const images = useFluidImage();
  const image = images.find(f => f.name === String(id));
  DEBUG && ClientLogger.debug('VideoImg.render', `id =${id} images length=${images.length} image=${JSON.stringify(image)})}`);
  return image ? (
    <GatsbyImage image={image.childImageSharp.gatsbyImageData} className={className} title={title} alt={title || ''} />
  ) : (
    <></>
  );
};
