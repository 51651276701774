/* eslint-disable camelcase */
import { ErrorDisplay } from '@components/Forms/ErrorDisplay';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import { ClientLogger } from '@lib/ClientLogger';
import { useRefPair } from '@lib/useRefPair';
import { VideoSource, Util } from '@sharedLib/index';
import throttle from 'lodash.throttle';
import moment from 'moment';
import React, { useLayoutEffect, useRef } from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import { VideoStateHook } from '@src/lib/useVideoState';
import 'video.js/dist/video-js.css';
import 'videojs-mux';
import 'videojs-contrib-eme';
import 'videojs-youtube';
import './videojs-styles.scss'; // to overrwrite videojs default css settings
import { PLAYER_EVENT } from '@components/VideoPlayer/VideoPlayer';
import { VideoJSPlayerControls } from './VideoJSPlayerControls';

const DEBUG = false;

interface IProps {
  videoState: VideoStateHook;
  sessionId: string;
  src: string;
  title: string;
  authorization: string;
  onTimer?: (duration?: number, currentTime?: number) => void;
  onFullscreenToggle?: () => void;
  onSizeDiscovered?: (height: number, width: number) => void;
  autoPlay?: boolean;
  playerControlHandler: (event: PLAYER_EVENT) => void;
  episodeNumber?: number;
  seasonNumber?: number;
}

export function VideoJSPlayer(props: IProps) {
  const { sessionId, videoState, playerControlHandler, episodeNumber, seasonNumber } = props;
  const { state } = videoState;
  const { video } = state;
  const videoId = video?.id;
  const [getDuration, setDuration] = useRefPair<number>(useRef(0));
  const [getSeekCount, setSeekCount] = useRefPair<number>(useRef(0));
  const [getPauseCount, setPauseCount] = useRefPair<number>(useRef(0));
  const [getLastPosition, setLastPosition] = useRefPair<number>(useRef(0));
  const [getStartOfElapsedTime, setStartOfElapsedTime] = useRefPair<Date>(useRef(new Date()));
  const analyticsCapture = useAnalyticsCapture();

  const recordDurationWatch = (data: { current_time?: number; duration?: number }) => {
    ClientLogger.debug('VideoPlayer.recordDurationWatch', 'time to check', DEBUG, { data });
    if (!data.current_time || !data.duration) {
      ClientLogger.warning('VideoJSPlayer.recordDurationWatch', `Missing data. data=${JSON.stringify(data)}`);
      return;
    }
    const newNow = new Date();
    analyticsCapture.videoWatchedEvent({
      endPosition: data.current_time,
      startPosition: getLastPosition(),
      totalDuration: data.duration,
      videoSessionId: sessionId,
      videoId,
      videoName: video?.title,
      elapsedTime: moment(newNow).diff(getStartOfElapsedTime()) / 1000,
      adStrategy: videoState.state.currentAdStrategy,
    });
    setStartOfElapsedTime(newNow);
    setLastPosition(data.current_time);
  };

  const throttledDurationWatch = useRef(
    throttle((data: { current_time: number; duration: number }) => {
      recordDurationWatch(data);
    }, 120000)
  );

  const throttledTime = useRef(
    throttle(() => {
      if (props.onTimer) {
        props.onTimer(getDuration(), player?.currentTime());
        DEBUG && ClientLogger.debug('VideoJSPlayer.throttledTime', `timeupdate - ads call back`);
      }
      const currentTime = player?.currentTime() || 0;
      const duration = getDuration();
      DEBUG &&
        ClientLogger.debug('VideoJSPlayer.throttledTime', `timeupdate`, {
          currentTime,
          duration,
        });
      throttledDurationWatch.current({ current_time: currentTime, duration });
    }, 1000)
  );

  const throttledSeeking = useRef(
    throttle((event: any) => {
      analyticsCapture.videoEvent({
        videoSessionId: sessionId,
        videoId,
        videoName: video?.title,
        event,
        isFullscreen: player?.isFullscreen(),
        position: player?.currentTime(),
        adStrategy: videoState.state.currentAdStrategy,
      });
    }, 1500)
  );

  const useCorePlayer = video?.type === VideoSource.CORE_VIMEO || video?.type === VideoSource.UPLOADED;
  const videoNode = useRef(null);
  let player: videojs.Player | undefined;
  const options: VideoJsPlayerOptions = {
    autoplay: props.autoPlay,
    controls: useCorePlayer,
    fluid: true,
  };

  useLayoutEffect(() => {
    player = videojs(videoNode.current || '', options);
    player.ready(() => {
      ClientLogger.debug('VideoJSPlayer.useLayoutEffect', `Player is ready`, DEBUG);
      if (!player) {
        throw new Error('Cant happen but this line shuts up typescript');
      }
      // @ts-ignore
      if (player.eme && typeof player.eme === 'function') {
        // @ts-ignore
        player.eme();
        ClientLogger.debug('VideoJSPlayer.useLayoutEffect', `Initialized eme`, DEBUG);
      } else {
        ClientLogger.error('VideoJSPlayer.useLayoutEffect', 'Did not load eme');
      }
      // @ts-ignore
      if (videojs.Hls) {
        ClientLogger.debug('VideoJSPlayer.useLayoutEffect', `Initialized Hls with authorization code ${props.authorization}`, DEBUG);
        // @ts-ignore
        videojs.Hls.xhr.beforeRequest = function beforeRequest(options) {
          options.headers = {
            Authorization: `Bearer ${props.authorization}`,
          };
          return options;
        };
      } else {
        ClientLogger.error('VideoJSPlayer.useLayoutEffect', 'Did not load Hls');
      }
      switch (video?.type) {
        case VideoSource.YOUTUBE: {
          player.src({
            src: video?.url || '',
            type: 'video/youtube',
          });

          videoState.setPlayer({ player, videoSource: VideoSource.YOUTUBE });
          break;
        }
        case VideoSource.CORE_VIMEO: {
          player.src({
            src: video?.url || '',
            type: 'video/mp4',
          });

          videoState.setPlayer({ player, videoSource: VideoSource.CORE_VIMEO });
          break;
        }
        case VideoSource.UPLOADED: {
          const url = video?.url || '';
          const queryTrimmed = url.split('?')[0];
          const ext = queryTrimmed.split('.').pop();

          player.src({
            src: url,
            type: Util.extToMime(ext || 'mp4'),
          });

          videoState.setPlayer({ player, videoSource: VideoSource.UPLOADED });
          break;
        }
        case VideoSource.VIMEO_OTT: {
          player.src({
            //        src: props.src,
            //      type: 'application/x-mpegURL',
            src: '//vjs.zencdn.net/v/oceans.mp4',
            type: 'video/mp4',
          });
          break;
        }
        default: {
          ClientLogger.warning('VideoJSPlayer.useLayoutEffect', `Unexpected video type ${video?.type}`);
        }
      }
      player.on('loadeddata', event => {
        const height = player?.videoHeight();
        const width = player?.videoWidth();
        ClientLogger.debug('VideoJSPlayer.useLayoutEffect', 'player.on.loadeddata', DEBUG, {
          event,
          height,
          width,
        });
        if (typeof height !== 'number' || typeof width !== 'number') {
          ClientLogger.error('VideoJSPlayer.useLayoutEffect.player.on.loadeddata', 'Could not read height/width');
          return;
        }
        if (props.onSizeDiscovered) {
          props.onSizeDiscovered(height, width);
        }
      });

      // remove extra control bar components
      const controlBar = player.getChild('ControlBar');
      if (controlBar) {
        const fsComponent = controlBar.getChild('FullscreenToggle');
        if (fsComponent) {
          controlBar.removeChild(fsComponent);
        }
        const pipComponent = controlBar.getChild('PictureInPictureToggle');
        if (pipComponent) {
          controlBar.removeChild(pipComponent);
        }
      }

      player.on('play', event => {
        ClientLogger.debug('VideoJSPlayer.on.play', 'event fired', DEBUG, { event, player });
      });
      player.on('pause', event => {
        ClientLogger.debug('VideoJSPlayer.on.pause', 'event fired', DEBUG, { event, player, pauseCount: getPauseCount() });
        setPauseCount(getPauseCount() + 1);
      });
      player.on('seeked', event => {
        ClientLogger.debug('VideoJSPlayer.on.seeked', 'event fired', DEBUG, { event, player, seekCount: getSeekCount() });
        setSeekCount(getSeekCount() + 1);
      });
      player.on('durationchange', function durationChange() {
        ClientLogger.debug('VideoJSPlayer.on.durationchange', 'event fired', DEBUG, { event, player });
        const durationResult = player?.duration();
        if (durationResult && durationResult > 0) {
          setDuration(durationResult);
        }
      });
      player.on('FullscreenTogglePvep.toggle', function fullscreenToggle() {
        ClientLogger.debug('VideoJSPlayer.on.FullscreenTogglePvep.toggle', 'event fired', DEBUG, { event, player });
        if (props.onFullscreenToggle) {
          props.onFullscreenToggle();
        }
      });
      player.on('timeupdate', function timeUpdate() {
        throttledTime.current();
        if (player && !state.showingPlayNext && useCorePlayer && player.remainingTime() <= 10 && player.remainingTime() >= 0) {
          DEBUG &&
            ClientLogger.debug(
              'VideoJSPlayer.on',
              `timeupdate showingPlayNext ${state.showingPlayNext} remainingTime ${player.remainingTime()}`,
              DEBUG
            );
          state.showingPlayNext = true;
          videoState.showPlayNext(true);
        }
      });
      player.on('error', (event: any) => {
        ClientLogger.error('VideoJSPlayer.on', 'error', event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('fullscreenchange', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'fullscreenchange', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('loadedmetadata', (_event: any) => {
        // Very noisy ClientLogger.debug('VideoJSPlayer.on', 'loadedmetadata', DEBUG, event);
      });
      player.on('loadeddata', (_event: any) => {
        // Very noisy ClientLogger.debug('VideoJSPlayer.on', 'loadeddata', DEBUG, event);
      });
      player.on('loadstart', (_event: any) => {
        // Very noisy ClientLogger.debug('VideoJSPlayer.on', 'loadstart', DEBUG, event);
      });
      player.on('waiting', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'waiting', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('startbuffering', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'startbuffering', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('endbuffering', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'endbuffering', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('seeking', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'seeking', DEBUG, event);
        throttledSeeking.current(event);
      });
      player.on('seeked', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'seeked', DEBUG, event);
        setStartOfElapsedTime(new Date());
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('resume', (event: any) => {
        setStartOfElapsedTime(new Date());
        ClientLogger.debug('VideoJSPlayer.on', 'resume', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('play', (event: any) => {
        setStartOfElapsedTime(new Date());
        ClientLogger.debug('VideoJSPlayer.on', 'play', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
        if (state.showingPlayNext && useCorePlayer) {
          state.showingPlayNext = false;
          videoState.showPlayNext(false);
        }
      });
      player.on('pause', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'pause', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
      player.on('ended', (event: any) => {
        ClientLogger.debug('VideoJSPlayer.on', 'ended', DEBUG, event);
        analyticsCapture.videoEvent({
          videoSessionId: sessionId,
          videoId,
          videoName: video?.title,
          event,
          isFullscreen: player?.isFullscreen(),
          position: player?.currentTime(),
          adStrategy: videoState.state.currentAdStrategy,
        });
      });
    });
  }, [videoId]);

  // youtube player variables passed to videojs
  const youtubeOptions = {
    ytControls: 2, // show/hide youtube controls
    rel: 0, // related videos setting
  };

  DEBUG && ClientLogger.debug('VideoJSPlayer', 'render', { video });

  switch (video?.type) {
    case VideoSource.UPLOADED:
    case VideoSource.CORE_VIMEO: {
      return (
        <>
          <div data-vjs-player key={`vimeo-video-${videoId}`}>
            <video className="video-js vjs-default-skin" id={`vimeo-vid1-${videoId}`} ref={videoNode} autoPlay playsInline />
          </div>
          <VideoJSPlayerControls
            playing={state.playing}
            size={state.videoPlayerSize}
            videoState={videoState}
            data-cy={`video-player-controls-bottom-overlay-${state.video?.id}`}
            episodeNumber={episodeNumber}
            seasonNumber={seasonNumber}
            onUserAction={playerControlHandler}
          />
        </>
      );
    }
    case VideoSource.YOUTUBE: {
      return (
        <div data-vjs-player key={`youtube-video-${videoId}`}>
          <video
            className="video-js vjs-default-skin"
            id={`youtube-vid1-${videoId}`}
            ref={videoNode}
            autoPlay
            playsInline
            data-setup={`{
              "techOrder": ["youtube"],
              "sources": [{ "type": "video/youtube", "src": "${video?.url}"}],
              "youtube": ${JSON.stringify(youtubeOptions)}
            }`}
          />
        </div>
      );
    }
    case VideoSource.VIMEO_OTT: {
      return (
        <div data-vjs-player key={`video-${videoId}`}>
          <video ref={videoNode} className="video-js" playsInline />
        </div>
      );
    }
    default:
      return <ErrorDisplay message="Unexpected Video Source" />;
  }
}
