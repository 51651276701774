import React, { useContext } from 'react';
import { Button } from '@components/Buttons';
import { NavStateContext } from '@lib/navContext';

import classnames from 'classnames';
import { promptWrapper, promptTextWrapper, promptText, nonMemberText, promptButton } from './styles.module.scss';

interface Props {
  primaryText?: string;
  secondaryText?: string;
  ctaText?: string;
  destinationUrl?: string;
}

export const SignUpPrompt = ({ ctaText = 'JOIN NOW', primaryText = 'Welcome!', secondaryText, destinationUrl }: Props) => {
  const nav = useContext(NavStateContext);

  return (
    <div className={promptWrapper}>
      <div className={promptTextWrapper}>
        <h1 className={promptText}>{primaryText}</h1>
        <h1 className={classnames(promptText, nonMemberText)}>{secondaryText}</h1>
      </div>

      <Button
        className={promptButton}
        onClick={() => nav.push({ path: '/sign-up', title: 'Sign Up', state: { destination: destinationUrl } })}
      >
        {ctaText}
      </Button>
    </div>
  );
};
