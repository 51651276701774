import { formatCurrency } from '@lib/adPlacements';
import { ClientLogger } from '@lib/ClientLogger';
import { Coords, CoordUtils } from '@lib/Coords';
import { NavStateContext } from '@lib/navContext';
import { ShopifyUtil } from '@lib/shopify';
import React, { FC, useContext } from 'react';
import { ProductImg } from '@components/Img';
import { IconButton } from '@components/Buttons/IconButton';
import { goToProduct } from '@components/products/Products';
import { Video, DatabaseVideo, ShopifyProduct, AdWithProduct } from '@sharedLib/index';
import { VideoPlayerSize, useVideoState } from '@src/lib/useVideoState';
import {
  adBox,
  adContentWrapper,
  adImage,
  adInfo,
  productTitle,
  productPrice,
  adBannerContainer,
  adBoxWrapper,
} from './styles.module.scss';

interface VideoAdPlacementProps {
  ads: AdWithProduct[] | null;
  video?: DatabaseVideo;
  coords: Coords;
}

const DEBUG = false;

interface BannerAdProps {
  shopifyProduct: ShopifyProduct;
  display?: 'none' | 'block' | 'featured' | string;
  posIndex: number;
  totalWidth: number;
  height: number;
}

interface InternalProductLinkProps {
  video?: DatabaseVideo;
  shopifyProduct?: ShopifyProduct;
  index: number;
}

const BannerAd = ({ shopifyProduct, posIndex, height, totalWidth }: BannerAdProps) => {
  const videoState = useVideoState();
  const { videoPlayerSize } = videoState.state;
  const innerMargin = videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? 5 : 10;
  const adBoxPadding = 0;
  const betweenTextAndImage = 3;
  const marginWidths = innerMargin * 4;
  const paddingWidths = adBoxPadding * 6 + betweenTextAndImage * 3;
  const width =
    videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED
      ? (totalWidth - marginWidths - paddingWidths - 15) / 3
      : (totalWidth - marginWidths - paddingWidths) / 3;
  const imageWidth = width * 0.25;
  const textPadding = 5;
  const textWidth = width - imageWidth - textPadding;
  const verticalPadding = 4;
  const titleFontSize = (height - verticalPadding) * 0.25;
  const priceFontSize = (height - verticalPadding) * 0.2;
  const price = ShopifyUtil.getPrice(shopifyProduct);
  DEBUG && ClientLogger.debug('BannerAd', 'render', { posIndex, shopifyProduct, height, totalWidth, width, imageWidth, textWidth });
  return (
    <div
      className={adBox}
      style={{
        marginLeft: posIndex === 1 ? '0' : `${innerMargin}px`,
        marginRight: posIndex === 3 ? '0' : `${innerMargin}px`,
        marginTop: `${verticalPadding}px`,
        marginBottom: `${verticalPadding}px`,
        padding: `${adBoxPadding}px`,
        lineHeight: 1,
        height: `${height - 2 * verticalPadding}px`,
      }}
      key={shopifyProduct.handle}
      data-cy-banner-ad-product={shopifyProduct.id}
    >
      <div className={adContentWrapper}>
        <div
          style={{
            width: `${imageWidth}px`,
            height: `${height - verticalPadding * 2 - adBoxPadding * 2}px`,
            marginRight: `${betweenTextAndImage}px`,
          }}
        >
          <ProductImg
            product={shopifyProduct}
            className={adImage}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </div>

        <div className={adInfo}>
          <div
            className={productTitle}
            style={
              height < 30
                ? { fontSize: `${titleFontSize * 1.2}px`, width: textWidth, padding: `0 ${textPadding}px` }
                : { fontSize: `${titleFontSize}px`, width: textWidth, padding: `0 ${textPadding}px` }
            }
          >
            {shopifyProduct.title}
          </div>
          <div
            className={productPrice}
            style={height < 30 ? { display: 'none' } : { fontSize: `${priceFontSize}px`, width: textWidth, padding: `0 ${textPadding}px` }}
          >
            {formatCurrency(price)}
          </div>
        </div>
      </div>
    </div>
  );
};

const InternalProductLink: FC<InternalProductLinkProps> = ({ video, children, shopifyProduct, index }) => {
  const nav = useContext<any>(NavStateContext);
  const isAffiliate = shopifyProduct && shopifyProduct.eCommerceType === 'immediate-affiliate';

  DEBUG && ClientLogger.debug('InternalProductLink', 'render', { shopifyProduct, isAffiliate });

  if (!shopifyProduct) {
    return <>{children}</>;
  }

  return (
    <>
      {isAffiliate && shopifyProduct ? (
        <a href={shopifyProduct.targetUrl} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <IconButton
          onClick={() => {
            goToProduct({ nav, video, shopifyProduct, index });
          }}
        >
          {children}
        </IconButton>
      )}
    </>
  );
};

const BannerAds = ({ ads, video, coords }: VideoAdPlacementProps) => {
  ClientLogger.debug('BannerAds', 'render', DEBUG, { ads, coords });
  const videoState = useVideoState();
  const { videoPlayerSize } = videoState.state;

  if (!coords.size.height) {
    return <></>;
  }

  const fitToWidth = CoordUtils.toNumber(coords.size.width);
  const fitToHeight = CoordUtils.toNumber(coords.size.height);

  let posIndex = 0;
  return (
    <div
      key={video ? video.id : ''}
      className={` ${adBannerContainer}`}
      style={{
        left: coords.position.x,
        top: coords.position.y,
        width: coords.size.width,
        height: coords.size.height,
        borderBottomLeftRadius: videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? 7 : 0,
        borderBottomRightRadius: videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? 7 : 0,
        paddingLeft: videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? 5 : 0,
        paddingRight: videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? 5 : 0,
      }}
    >
      {ads &&
        ads.map(adPlacement => {
          if (!adPlacement) {
            return null;
          }

          const { product } = adPlacement;

          if (!adPlacement || !adPlacement.display || adPlacement.display === 'none') {
            return null;
          }
          // eslint-disable-next-line no-plusplus
          posIndex++;
          return (
            <div key={adPlacement.id} className={adBoxWrapper}>
              <InternalProductLink video={video} shopifyProduct={product} index={posIndex}>
                <BannerAd key={product.handle} shopifyProduct={product} posIndex={posIndex} height={fitToHeight} totalWidth={fitToWidth} />
              </InternalProductLink>
            </div>
          );
        })}
    </div>
  );
};

export default BannerAds;
