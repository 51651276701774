import React, { useContext, useEffect, useState } from 'react';
import { NavStateContext } from '@lib/navContext';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { IconButton, Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import {
  dropDownButton,
  headerIcon,
  rightContainer,
  contentContainer,
  dropDownHeader,
  toggleContainer,
  previewToggle,
  previewLinkButton,
  hamburgerItem,
  hamburgerContainer,
} from './styles.module.scss';

interface Props {
  links: {
    label: string;
    path: string;
    displayAt?: number;
  }[];
}

export const HamburgerDropdown = ({ links }: Props) => {
  const api = usePvepApi();
  const nav = useContext(NavStateContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    //set timer on mount to update every second
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timer); // cleanup the timer on unmount
  }, []);

  const items = links.filter(item => {
    if (!item.displayAt) {
      return true;
    } else if (currentTime > item.displayAt) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <div
      className={classNames(dropdownOpen ? `dropdown is-active` : '')}
      onMouseEnter={() => {
        setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        setDropdownOpen(false);
      }}
    >
      <IconButton className={classNames(dropDownButton, headerIcon)} onTouchStart={() => setDropdownOpen(!dropdownOpen)}>
        <Icon icon={'charm:menu-hamburger'} height="30px" />
      </IconButton>
      <div className={classNames('dropdown-menu', rightContainer, hamburgerContainer)} id="dropdown-menu" role="menu">
        <div className={classNames('dropdown-content', contentContainer)}>
          {items.map((link, i) => (
            <div key={i} className={hamburgerItem} onClick={() => nav.push({ path: link.path, title: link.label })}>
              {link.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
