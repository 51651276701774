import React from 'react';

interface IconProps {
  name: string;
  src?: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({ name, src, className }) =>
  src ? <img className={className} src={src} alt={`Icon ${name}`} /> : <span className={className}>icon</span>;
