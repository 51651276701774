import { useProductData } from '@lib/shopify/useProductData';
import React from 'react';
import { Img } from '@components/Img';
import PPVProductCard from '@components/PPVProductCard';
import { banner, bannerImage, cardContainer, bannerHeader } from './styles.module.scss';
import { useHomeResource } from '@src/pageComponents/Home/useHomeResources';

const StreamBanner = () => {
  const productData = useProductData();
  const pageData = useHomeResource();

  const product = productData.getLandingPageProduct();
  return (
    <div className={banner}>
      <Img childImageSharp={pageData.heroImage.childImageSharp} className={bannerImage} />
      <div className={cardContainer}>
        <div className={bannerHeader}>Stage</div>
        {/* For now, we will enforce that only one shopify product at a time is 'headliner', and swap manually. A better approach needs to be implemented long-term */}
        <PPVProductCard product={product} isLarge freeTrialButtonText={pageData.content} />
      </div>
      <Img childImageSharp={pageData.heroImage.childImageSharp} className={bannerImage} />
    </div>
  );
};

export default StreamBanner;
