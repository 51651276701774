import React from 'react';
import { quantitySelector } from './styles.module.scss';

interface Props {
  name: string;
  value: number;
  updateParent: (q: number) => void;
}

export const QuantitySelector: React.FC<Props> = ({ name, value, updateParent }) => {
  const onChangeUpdateQuantity = async (e: any) => {
    const quantity = Number.parseInt(e.target.value) >= 1 ? Number.parseInt(e.target.value) : 1;
    updateParent(quantity);
  };

  const increment = () => {
    const quantity = value + 1;
    updateParent(quantity);
  };

  const decrement = () => {
    if (value !== 1) {
      const quantity = value - 1;
      updateParent(quantity);
    }
  };

  return (
    <div className={quantitySelector}>
      <button data-cy="decrement" type="button" onClick={() => decrement()}>
        -
      </button>
      <input name={name} type="number" value={value} onChange={e => onChangeUpdateQuantity(e)} />
      <button data-cy="increment" type="button" onClick={() => increment()}>
        +
      </button>
    </div>
  );
};
