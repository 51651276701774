import React from 'react';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import { IconButton } from '@components/Buttons';
import { BrandUtil, ShopifyProduct, DatabaseVideo, DatabaseSeries } from '@sharedLib/index';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import logoFacebook from '@iconify/icons-ion/logo-facebook';
import logoEmbed from '@iconify/icons-ion/code-outline';
import logoEmail from '@iconify/icons-ant-design/mail-outlined';
import linkedInFilled from '@iconify/icons-ant-design/linkedin-filled';
import facebookIcon from '@iconify/icons-mdi/facebook';
import instagramIcon from '@iconify/icons-entypo-social/instagram-with-circle';
import twitterIcon from '@iconify/icons-ant-design/twitter-circle-filled';
import linkedinIcon from '@iconify/icons-entypo-social/linkedin-with-circle';
import { iconSocialNonModal, icon, centeredIconContainer, iconContainer, iconSocial, iconContainerNonModal } from './styles.module.scss';

interface Props {
  isCentered: boolean;
  shareUrl?: string;
  isModal?: boolean;
  isSeries?: boolean;
  video?: DatabaseVideo | DatabaseSeries;
  product?: ShopifyProduct;
  containerClass?: string;
  iconClass?: string;
  showText?: boolean;
}
interface PropsSeries {
  isSeries?: boolean;
}

export const SocialIconsSeriesOrFooter = ({ isSeries }: PropsSeries) => {
  const socialMedia = BrandUtil.getBrandInfoFromBranch()?.socialMedia;
  const { facebook, twitter, instagram, linkedIn } = socialMedia;
  const classIcon = isSeries ? `${iconSocialNonModal}` : `${icon}`;
  const onClickFacebook = () => {
    if (typeof window !== 'undefined') {
      window.open(facebook?.url);
    }
  };

  const onClickInstagram = () => {
    if (typeof window !== 'undefined') {
      window.open(instagram?.url);
    }
  };

  const onClickTwitter = () => {
    if (typeof window !== 'undefined') {
      window.open(twitter?.url);
    }
  };

  const onClickLinkedin = () => {
    if (typeof window !== 'undefined') {
      window.open(linkedIn?.url);
    }
  };
  return (
    <>
      {facebook?.useInFooterAndSeries && (
        <IconButton onClick={onClickFacebook} className={classIcon}>
          <Icon height="32px" icon={facebookIcon} />
        </IconButton>
      )}
      {instagram?.useInFooterAndSeries && (
        <IconButton onClick={onClickInstagram} className={classIcon}>
          <Icon height="32px" icon={instagramIcon} />
        </IconButton>
      )}
      {twitter?.useInFooterAndSeries && (
        <IconButton onClick={onClickTwitter} className={classIcon}>
          <Icon height="32px" icon={twitterIcon} />
        </IconButton>
      )}
      {linkedIn?.useInFooterAndSeries && (
        <IconButton onClick={onClickLinkedin} className={classIcon}>
          <Icon height="32px" icon={linkedinIcon} />
        </IconButton>
      )}
    </>
  );
};

export const SocialMediaIcons = ({ isCentered, shareUrl, video, product, containerClass, iconClass, showText = false }: Props) => {
  const analyticsCapture = useAnalyticsCapture();
  const onClick = (url: any, linkPlatform: string) => {
    if (url) {
      window.open(url + shareUrl, '_blank', 'left=200,width=500,height=500');
    }
    if (shareUrl) {
      const urlParts = shareUrl.split('/');
      const isVideo = urlParts.includes('video');
      const isProduct = urlParts.includes('product');
      const isSearch = urlParts.includes('search');

      let shareType;
      let videoId;
      let productId;
      let searchString;
      let videoName;
      let productName;
      if (isVideo) {
        videoName = video?.title;
        videoId = video?.id;
        shareType = 'video';
      } else if (isProduct) {
        productName = product?.title;
        productId = product?.id;
        shareType = 'product';
      } else if (isSearch) {
        searchString = urlParts.pop()?.substring(3);
        shareType = 'search';
      }

      analyticsCapture.shareEvent({
        type: shareType || '',
        videoId,
        productId,
        searchString,
        shareMethod: linkPlatform,
        videoName,
        productName,
      });
    }
  };

  const socialMedia = BrandUtil.getBrandInfoFromBranch()?.socialMedia;
  const { embed, facebook, twitter, instagram, linkedIn, email } = socialMedia;

  return (
    <>
      {shareUrl ? (
        <div className={classnames(containerClass, isCentered ? centeredIconContainer : iconContainer)}>
          {embed?.useInShare && (
            <IconButton className={classnames(iconSocial, iconClass)} onClick={() => onClick}>
              <Icon icon={logoEmbed} height="40px" />
              {showText && <p>Embed</p>}
            </IconButton>
          )}
          {facebook?.useInShare && (
            <IconButton
              className={classnames(iconSocial, iconClass)}
              onClick={() => onClick('https://www.facebook.com/sharer/sharer.php?u=', 'facebook')}
            >
              <Icon icon={logoFacebook} height="40px" />
              {showText && <p>Facebook</p>}
            </IconButton>
          )}
          {instagram?.useInShare && (
            <IconButton className={classnames(iconSocial, iconClass)} onClick={() => onClick}>
              <Icon icon={instagramIcon} height="40px" />
              {showText && <p> Instagram</p>}
            </IconButton>
          )}
          {twitter?.useInShare && (
            <IconButton
              className={classnames(iconSocial, iconClass)}
              onClick={() => onClick('https://twitter.com/intent/tweet?url=', 'twitter')}
            >
              <Icon icon={twitterIcon} height="40px" />
              {showText && <p>Twitter</p>}
            </IconButton>
          )}
          {linkedIn?.useInShare && (
            <IconButton
              className={classnames(iconSocial, iconClass)}
              onClick={() => onClick('https://www.linkedin.com/shareArticle?mini=true&url=', 'linkedin')}
            >
              <Icon icon={linkedInFilled} height="40px" />
              {showText && <p>LinkedIn</p>}
            </IconButton>
          )}
          {email?.useInShare && (
            <IconButton
              className={classnames(iconSocial, iconClass)}
              onClick={() => onClick('mailto:info@example.com?&subject=&cc=&bcc=&body=', 'email')}
            >
              <Icon icon={logoEmail} height="40px" />
              {showText && <p>Email</p>}
            </IconButton>
          )}
        </div>
      ) : (
        <div className={classnames(iconContainerNonModal)}>
          <SocialIconsSeriesOrFooter isSeries />
        </div>
      )}
    </>
  );
};
