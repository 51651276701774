import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { offColorText, boldFont, largeText, videoListItem, smallText } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { VideoBranchSeedData } from './VideoDataExport';
import { DatabaseVideo } from '@sharedLib/index';

export const VideoDataImport = () => {
  const api = usePvepApi();

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');
  const [videos, setVideos] = useState<DatabaseVideo[]>([]);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');
  const [branchMessage, setBranchMessage] = useState<{ branch: string; timestamp: number; bucket: string } | undefined>(undefined);

  ///
  /// Write all videos to db
  ///
  const seedVideoDatabase = async (sourceBranch: string, sourceBucket: string, videos: DatabaseVideo[]) => {
    const seedResp = await api.seedVideoDatabase(sourceBranch, sourceBucket, videos);
    if (seedResp.data?.seedVideoDatabase.success) {
      setConfirmation('Seeding complete.');
    } else {
      setConfirmation('');
      setUploadErrors([
        'Something has gone wrong when attempting to seed this database.',
        JSON.stringify(seedResp.data?.seedVideoDatabase.errorMessages),
      ]);
    }
  };

  return (
    <div>
      <div className={classNames(videoListItem, boldFont)}>
        WARNING: Using this tool will overwrite all current video data. It will also overwrite any video data used in video collections. It
        is to be used to seed new branches and in local development.
      </div>

      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          if (branchMessage?.branch) {
            await seedVideoDatabase(branchMessage?.branch, branchMessage.bucket, videos);
          }
          resetForm();
          setVideos([]);
          setBranchMessage(undefined);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const videoDataRaw: VideoBranchSeedData = JSON.parse(dataString);

                        const dbVideos: DatabaseVideo[] = [];
                        for (const video of videoDataRaw.videos) {
                          if (
                            video.id === undefined ||
                            video.title === undefined ||
                            video.description === undefined ||
                            video.isAvailable === undefined ||
                            video.isFree === undefined ||
                            video.type === undefined ||
                            video.url === undefined ||
                            video.thumbnailUrl === undefined
                          ) {
                            continue;
                          }
                          dbVideos.push(video);
                        }

                        setVideos(dbVideos);

                        setBranchMessage({ branch: videoDataRaw.branch, timestamp: videoDataRaw.timestamp, bucket: videoDataRaw.bucket });
                        setError('');
                      } catch (e) {
                        setBranchMessage(undefined);
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {videos.length > 0 && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                IMPORT ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {branchMessage && (
        <div className={videoListItem}>
          <div className={largeText}>
            <div>
              This data is from branch: <span className={boldFont}>{branchMessage.branch}</span>
            </div>

            <div>
              This data was exported on: <span className={boldFont}>{new Date(branchMessage.timestamp).toString()}</span>
            </div>
          </div>
        </div>
      )}

      {videos.map(video => (
        <div className={videoListItem}>
          <div className={classNames(largeText, boldFont)}>{video.title}</div>
          <div className={smallText}>
            <span className={boldFont}>Video ID: </span>
            <span className={offColorText}>{video.id}</span>
          </div>
          <div className={smallText}>
            <span className={boldFont}>URL: </span>
            <span className={offColorText}>{video.url}</span>
          </div>
          <div className={smallText}>
            <span className={boldFont}>Type: </span>
            <span className={offColorText}>{video.type}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
