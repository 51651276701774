import { ClientLogger } from '@lib/ClientLogger';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { VideoCard } from '@components/VideoCard';
import { DivButton } from '@components/Buttons';
import { isMobile, isTablet } from '@lib/responsive';
import rightOutlined from '@iconify/icons-ant-design/right-outline';
import leftOutlined from '@iconify/icons-ant-design/left-outline';
import { Icon } from '@iconify/react';
import useScrollButtons from '@lib/useScrollButtons';
import { BrandUtil } from '@sharedLib/util';
import { DatabaseVideo, DatabaseSeries } from '@sharedLib/index';

import {
  videoListContainerHorizontal,
  videoListContainer,
  textHeader,
  videoListGrid,
  shiftUpLeft,
  left,
  scrollButton,
  shiftUpRight,
  right,
  buttonIcon,
} from './styles.module.scss';

interface Props {
  items: {
    item: DatabaseSeries | DatabaseVideo;
    type: 'series' | 'video';
  }[];
  onClick: (videoId: string, title: string) => void;
  onClickCollection?: (collectionId: number | string, title: string) => void;
  horizontal?: boolean;
  header?: boolean;
  fullwidth?: boolean;
  small?: boolean;
  name: string;
}
const DEBUG = false;

export const VideoList = ({ horizontal, header, fullwidth, small, name, onClick, onClickCollection, items }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const mobile = isMobile();
  const tablet = isTablet();
  let roundScrollToNearest = 315; //px
  if (tablet) {
    roundScrollToNearest = 165; //px
  }

  const videoListElement = useRef<HTMLDivElement>(null);
  const { showScrollButtons, onScrollButtonClick } = useScrollButtons(videoListElement, {
    scrollPercentage: 0.65,
    roundToNearest: roundScrollToNearest,
  });

  DEBUG && ClientLogger.debug('VideoList', 'render', { items, name });

  return (
    <div
      className={classNames(horizontal && videoListContainerHorizontal, !horizontal && videoListContainer)}
      style={fullwidth ? { padding: 0 } : {}}
    >
      {header && items.length > 0 && <h4 className={textHeader}>{name}</h4>}

      <div ref={videoListElement} className={classNames(horizontal && videoListGrid)}>
        {items.length > 0 &&
          items
            .filter(sliderItem => sliderItem.item.isAvailable)
            .map((sliderItem, i) => {
              const baseImageUrl = brandInfo.imageBaseUrl;
              const isVideo = sliderItem.type === 'video';
              const thumbnailUrl = sliderItem.item.thumbnailUrl
                ? `${baseImageUrl}/${isVideo ? 'videos' : 'series'}/${sliderItem.item.thumbnailUrl}`
                : `${brandInfo.siteUrl}/fallback.png`;

              return (
                <VideoCard
                  small={small}
                  item={sliderItem.item}
                  key={i}
                  type={sliderItem.type}
                  imageUrl={thumbnailUrl}
                  onClickVideo={onClick}
                  onClickSeries={(seriesId, title) => onClickCollection && onClickCollection(seriesId, title)}
                />
              );
            })}
      </div>
      {showScrollButtons && !mobile && (
        <>
          <DivButton
            className={classNames(scrollButton, fullwidth ? shiftUpLeft : left)}
            onClick={() => {
              onScrollButtonClick(true);
            }}
          >
            <Icon icon={leftOutlined} className={buttonIcon} />
          </DivButton>
          <DivButton
            className={classNames(scrollButton, fullwidth ? shiftUpRight : right)}
            onClick={() => {
              onScrollButtonClick(false);
            }}
          >
            <Icon icon={rightOutlined} className={buttonIcon} />
          </DivButton>
        </>
      )}
    </div>
  );
};
