import React, { useState } from 'react';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';

export const BranchCommit = () => {
  const api = usePvepApi();
  const [loading, setLoading] = useState(false);
  const [rebuildBranchError, setRebuildBranchError] = useState('');
  const [rebuildBranchSuccess, setRebuildBranchSuccess] = useState('');

  ///
  /// Create an empty commit to trigger a branch rebuild
  ///
  const handleBranchRebuild = async () => {
    setLoading(true);
    const resp = await api.createEmptyCommit();
    if (resp.data?.createEmptyCommit.success) {
      setRebuildBranchError('');
      setRebuildBranchSuccess('The request was successful, a new pipeline has started in Gitlab');
    } else {
      setRebuildBranchSuccess('');
      ClientLogger.error('BranchCommit:handleBranchRebuild', JSON.stringify(resp.data?.createEmptyCommit.errorMessages));
      setRebuildBranchError(
        `The request could not be fulfilled, please try again. The following error was returned: ${JSON.stringify(
          resp.data?.createEmptyCommit.errorMessages
        )}`
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Button onClick={() => handleBranchRebuild()} disabled={loading}>
        REBUILD THIS BRANCH
      </Button>
      {rebuildBranchError && <div>{rebuildBranchError}</div>}
      {rebuildBranchSuccess && <div>{rebuildBranchSuccess}</div>}
    </>
  );
};
