export const VIDEO_ID_META_FIELD_NAME = 'video_id';
export const TARGET_URL_META_FIELD_NAME = 'target_url';
export type EcommerceType = 'immediate-affiliate' | 'cart' | 'carted-affiliate';

export interface CartState {
  success: boolean;
  state: string;
  message: {
    header: string;
    info: string;
  };
}

export interface ShopifyMetaField {
  id: string;
  key: string;
  value: any;
  type: string;
}

export interface ShopifyVariant {
  id: string;
  title: string;
  localImageFileName: string;
  imageOriginalSrc: string;
  selectedOptions: { name: string; value: string }[];
  metafields: ShopifyMetaField[];
  availableForSale: boolean;
  price: number;
  videoIds?: string;
}

export interface ShopifyProductOption {
  id: string;
  name: string;
  values: string[];
}

export interface ShopifyProduct {
  id: string;
  productNumber: number;
  title: string;
  description: string;
  descriptionHtml: string;
  imageOriginalSrc: string;
  localImageFileName: string;
  handle: string;
  options: ShopifyProductOption[];
  metafields: ShopifyMetaField[];
  variants: ShopifyVariant[];
  productType: string;
  availableForSale: boolean;
  vendor: string;
  collectionIds: string[];
  targetUrl?: string;
  targetUrlType?: string;
  videoIds?: string;
  eCommerceType: EcommerceType;
  tags?: string[];
  adTags?: string[];
  maxVariantPrice: number;
  alternateCta?: string;
}

export interface Product {
  productId: string;
  title: string;
  description: string;
  imageUrl: string;
  price: string;
}

export interface ProductVariant extends Product {
  variantId: string;
  variantName: string;
}

export interface ProductCollection {
  id: string;
  title: string;
}

export interface CartItem extends ProductVariant {
  id: string;
  quantity: number;
}

export type CartItemProps = Omit<CartItem, 'id'>;

export const RECOMMENDED_PRODUCT_PLACEHOLDER: ShopifyProduct = {
  id: '98EDB271-9D11-4900-9799-0F891A5F2CD0',
  productNumber: 0,
  collectionIds: [],
  metafields: [],
  availableForSale: false,
  description: 'Placeholder for recommended product.',
  descriptionHtml: 'Placeholder for recommended product.',
  handle: '',
  options: [],
  productType: '',
  title: 'Recommended Product',
  variants: [],
  vendor: '',
  imageOriginalSrc: '',
  localImageFileName: '',
  eCommerceType: 'immediate-affiliate',
  maxVariantPrice: 0,
  tags: [],
};
