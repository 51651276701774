import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { boldFont, largeText, videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Strategy } from '@sharedLib/index';

export const StrategyImport = () => {
  const api = usePvepApi();

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');

  const [strategies, setStrategies] = useState<Strategy[]>([]);

  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');
  const [branchMessage, setBranchMessage] = useState<{ branch: string; timestamp: number } | undefined>(undefined);

  ///
  /// Write all strategies to db
  ///
  const seedStrategies = async (strategies: Strategy[]) => {
    const seedResp = await api.seedStrategies(strategies);
    if (seedResp.data?.seedStrategies.success) {
      setConfirmation('Seeding complete.');
    } else {
      setConfirmation('');
      setUploadErrors([
        'Something has gone wrong when attempting to seed this database.',
        JSON.stringify(seedResp.data?.seedStrategies.errorMessages),
      ]);
    }
  };

  return (
    <div>
      <div className={classNames(videoListItem, boldFont)}>
        WARNING: Using this tool will overwrite all current strategy data. It is to be used to seed new branches and in local development.
      </div>

      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          if (branchMessage?.branch) {
            await seedStrategies(strategies);
          }
          resetForm();
          setStrategies([]);
          setBranchMessage(undefined);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const strategyDataRaw = JSON.parse(dataString);

                        if (!strategyDataRaw.strategies) {
                          return;
                        }

                        setStrategies(strategyDataRaw.strategies);
                        setBranchMessage({
                          branch: strategyDataRaw.branch,
                          timestamp: strategyDataRaw.timestamp,
                        });
                        setError('');
                      } catch (e) {
                        setBranchMessage(undefined);
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {strategies.length > 0 && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                IMPORT ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {strategies.length > 0 && <div className={videoListItem}>This file contains {strategies.length} strategies.</div>}

      {branchMessage && (
        <div className={videoListItem}>
          <div className={largeText}>
            <div>
              This data is from branch: <span className={boldFont}>{branchMessage.branch}</span>
            </div>

            <div>
              This data was exported on: <span className={boldFont}>{new Date(branchMessage.timestamp).toString()}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
