import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { usePvepApi } from '@apiClient/usePvepApi';
import { useVideoState } from '@lib/useVideoState';
import * as styles from './styles.module.scss';
import '../react-select-styles.scss';
import classNames from 'classnames';
const DEBUG = false;

export const StrategySelector = () => {
  const api = usePvepApi();
  const videoState = useVideoState();
  const adStrategies = videoState.state.adStrategies || [];
  const [currentStrategy, setCurrentStrategy] = useState(videoState.state.currentAdStrategy?.strategyId || '');

  const selectOptions: { value: string; label: string }[] = [];
  adStrategies.map(strategy => {
    if (selectOptions.findIndex(opt => opt.value === strategy.strategyId) === -1) {
      selectOptions.push({
        value: strategy.strategyId,
        label: strategy.strategyTitle,
      });
    }
  });

  useEffect(() => {
    setCurrentStrategy(videoState.state.currentAdStrategy?.strategyId || '');
  }, [videoState.state.currentAdStrategy]);

  return (
    <div className={styles.strategySelectorContainer}>
      {videoState.state.currentAdStrategy && (
        <div>
          <div className={styles.strategySelectorTitle}>Now Viewing:</div>
          <div className={styles.strategySelectorExperiment}>{videoState.state.currentAdStrategy.experimentTitle}</div>
        </div>
      )}
      <Select
        value={selectOptions.filter(opt => opt.value === currentStrategy)}
        name={`currentStrategy`}
        onChange={async opt => {
          setCurrentStrategy(opt?.value || '');
          const adStrategy = adStrategies.find(str => str.strategyId === opt?.value);
          await api.updateAdStrategyPreview(adStrategy?.experimentId || '', adStrategy?.strategyId || '');
          window.location.reload();
        }}
        options={selectOptions}
        className={styles.strategySelector}
        classNamePrefix="productSelect"
      />
    </div>
  );
};
