/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import { InternalLoadStep, PlayerReady, VideoPlayerSize, VideoStateHook, PlayerStatus } from '@lib/useVideoState';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import { ClientLogger } from '@lib/ClientLogger';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import moment from 'moment';
import { VideoSource, DatabaseVideo } from '@sharedLib/index';
import { iframeMiniPlayer, iframe, loading } from './styles.module.scss';

const DEBUG = false;
const DEBUG_ADS = false;

interface Props {
  videoState: VideoStateHook;
  sessionId: React.MutableRefObject<string | null>;
  src?: string;
  height: number;
  width: number;
  updatePlayerAds(current_time: number, playerStatus: PlayerStatus | null): void;
}

export const VimeoPlayer = (props: Props) => {
  const { videoState, sessionId, src, height, width, updatePlayerAds } = props;
  const { state } = videoState;
  const { video, readyStatus, videoPlayerSize } = state;
  const analyticsCapture = useAnalyticsCapture();

  // watch durations for analytics data
  const lastPosition = useRef<number>(0);
  const startOfElapsedTime = useRef<Date>(new Date());

  const resetDuration = () => {
    startOfElapsedTime.current = new Date();
  };

  const recordDurationWatch = (data: { current_time: number; duration: number; video: DatabaseVideo }) => {
    DEBUG && ClientLogger.debug('VideoPlayer.recordDurationWatch', 'time to check', DEBUG, { data, video: video || 'missing' });
    const newNow = new Date();
    analyticsCapture.videoWatchedEvent({
      endPosition: data.current_time,
      startPosition: lastPosition.current,
      totalDuration: data.duration,
      videoSessionId: sessionId.current,
      videoId: video?.id,
      videoName: video?.title,
      elapsedTime: moment(newNow).diff(startOfElapsedTime.current) / 1000,
      adStrategy: videoState.state.currentAdStrategy,
    });
    startOfElapsedTime.current = newNow;
    lastPosition.current = data.current_time;
  };

  const throttledDurationWatch = useRef(
    throttle((data: { current_time: number; duration: number; video: DatabaseVideo }) => {
      recordDurationWatch(data);
    }, 120000)
  );

  const throttledSeeking = useRef(
    throttle((event: any, currentTime: any) => {
      analyticsCapture.videoEvent({
        videoSessionId: sessionId.current,
        videoId: video?.id,
        videoName: video?.title,
        event,
        isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
        position: currentTime,
        adStrategy: videoState.state.currentAdStrategy,
      });
    }, 1500)
  );

  DEBUG && ClientLogger.debug('Vimeo Player', 'render', { video });

  // Wait for VHX library to appear, then set up listeners
  useEffect(() => {
    if (state.internalLoadStep === InternalLoadStep.SETUP && video?.type === VideoSource.VIMEO_OTT) {
      const { player } = state.singletonPlayer;
      DEBUG &&
        ClientLogger.debug('VimeoPlayer.useEffect state.internalLoadStep === InternalLoadStep.SETUP', 'Found player', DEBUG, {
          player,
          video,
        });
      if (player) {
        setTimeout(() => {
          const newStatus = videoState.getPlayerStatus(state.singletonPlayer);

          if (!newStatus || newStatus.isReadyToPlay === undefined) {
            ClientLogger.error('VimeoPlayer.useEffect', 'not ready', { newStatus });
          }

          DEBUG && ClientLogger.debug('VimeoPlayer', 'Found player', DEBUG, { player, newStatus, video });

          // Display ads at time index
          player.on('timeupdate', (event: any, current_time: number, duration: number) => {
            const veryNewStatus = videoState.getPlayerStatus(state.singletonPlayer);
            DEBUG && ClientLogger.debug('vhx.on', 'timeupdate', { event, current_time, ads: state.adPlacements, video });
            throttledDurationWatch.current({ current_time, duration, video });
            updatePlayerAds(current_time, veryNewStatus);
          });

          player.on('error', (event: any) => {
            ClientLogger.error('vhx.on', 'error', event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('fullscreenchange', (event: any) => {
            // const videoPlayerSize = videoState.state.videoPlayerSize;
            DEBUG && ClientLogger.debug('vhx.on', 'fullscreenchange', { event, video });
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('loadedmetadata', (_event: any) => {
            // Very noisy ClientLogger.debug('vhx.on', 'loadedmetadata', DEBUG, event);
          });
          player.on('loadeddata', (_event: any) => {
            // Very noisy ClientLogger.debug('vhx.on', 'loadeddata', DEBUG, event);
          });
          player.on('loadstart', (_event: any) => {
            // Very noisy ClientLogger.debug('vhx.on', 'loadstart', DEBUG, event);
          });
          player.on('waiting', (event: any) => {
            DEBUG && ClientLogger.debug('vhx.on', 'waiting', DEBUG, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('startbuffering', (event: any) => {
            DEBUG && ClientLogger.debug('vhx.on', 'startbuffering', DEBUG, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('endbuffering', (event: any) => {
            DEBUG && ClientLogger.debug('vhx.on', 'endbuffering', DEBUG, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('seeking', (event: any, current_time: number) => {
            DEBUG && ClientLogger.debug('vhx.on', 'seeking', DEBUG, event);
            const veryNewStatus = videoState.getPlayerStatus(state.singletonPlayer);
            DEBUG_ADS && ClientLogger.debug('vhx.on', 'seeking', { event, current_time, ads: state.adPlacements });
            updatePlayerAds(current_time, veryNewStatus);
            throttledSeeking.current(event, player.currentTime());
          });
          player.on('seeked', (event: any, current_time: number) => {
            const veryNewStatus = videoState.getPlayerStatus(state.singletonPlayer);
            DEBUG_ADS && ClientLogger.debug('vhx.on', 'seeked', event);
            updatePlayerAds(current_time, veryNewStatus);
            resetDuration();
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('resume', (event: any) => {
            resetDuration();
            DEBUG && ClientLogger.debug('vhx.on', 'resume', DEBUG, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('play', (event: any) => {
            resetDuration();
            DEBUG && ClientLogger.debug('vhx.on', 'play', { event, video }, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });

            if (state.showingPlayNext) {
              state.showingPlayNext = false;
              videoState.showPlayNext(false);
            }
          });
          player.on('timeupdate', (event: any) => {
            // Very noisy ClientLogger.debug('vhx.on', `timeupdate.remainingTime - ${player.remainingTime()}`, DEBUG, event);
            if (!state.showingPlayNext && player.remainingTime() <= 10 && player.remainingTime() >= 0) {
              DEBUG &&
                ClientLogger.debug(
                  'vhx.on',
                  `timeupdate showingPlayNext ${state.showingPlayNext} remainingTime ${player.remainingTime()}`,
                  { event, video }
                );
              state.showingPlayNext = true;
              videoState.showPlayNext(true);
            }
          });
          player.on('pause', (event: any) => {
            DEBUG && ClientLogger.debug('vhx.on', 'pause', DEBUG, { event, video });
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          player.on('ended', (event: any) => {
            DEBUG && ClientLogger.debug('vhx.on', 'ended', DEBUG, event);
            analyticsCapture.videoEvent({
              videoSessionId: sessionId.current,
              videoId: video?.id,
              videoName: video?.title,
              event,
              isFullscreen: videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN,
              position: player.currentTime(),
              adStrategy: videoState.state.currentAdStrategy,
            });
          });
          videoState.setupDone();
        }, 0);
      } else {
        throw new Error('VimeoPlayer.useEffect - could not get player');
      }
    }
    return () => {
      DEBUG && ClientLogger.debug('VimeoPlayer.useEffect', 'Clean up', DEBUG, { src, video });
      // Clean up old player
      const { player, videoSource } = state.singletonPlayer;
      if (video) {
        if (player && videoSource === VideoSource.VIMEO_OTT && state.internalLoadStep === InternalLoadStep.READY) {
          // player.stop();
          try {
            player.dispose();
          } catch (e) {
            ClientLogger.error('VimeoPlayer.useEffect', 'clean up threw error:', e);
          }
        }
      }
    };
  }, [readyStatus, state.internalLoadStep]);

  return (
    <>
      {readyStatus === PlayerReady.LOADING_SHOW ? <div className={loading}>loading...</div> : <></>}
      <iframe
        style={{
          height: `${height}px`,
          width: `${width}px`,
          display: readyStatus === PlayerReady.LOADING_HIDE ? 'none' : undefined,
        }}
        className={classNames(videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? iframeMiniPlayer : iframe)}
        allowFullScreen={false}
        id="video"
        src={video?.type === VideoSource.VIMEO_OTT ? src : ''}
        frameBorder="0"
        title={`video ${video ? video.title : ''}`}
        allow="autoplay; fullscreen 'none'"
        data-cy={`video-player-iframe-${video?.id}`}
      />
    </>
  );
};
