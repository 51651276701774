import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  feedbackContentSpacer,
  feedbackContainer,
  feedbackTab,
  feedbackContainerOpen,
  feedbackContentContainer,
  feedbackContainerMobile,
  feedbackContainerMobileHidden,
  feedbackModal,
  feedbackModalOpen,
} from './styles.module.scss';
import { FeedbackForm } from './FeedbackForm';
import { isMobile } from '@src/lib/responsive';

const DEBUG = false;

export const Feedback = () => {
  const [tabOpen, setTabOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const mobile = isMobile();

  ///
  /// two-pass render to try avoid Gatsby hydrate -- it does not seem to like different structure for mobile
  ///
  useEffect(() => {
    setFirstRender(true);
  }, []);

  return (
    <>
      {firstRender && !mobile && (
        <div className={classNames(feedbackContainer, tabOpen ? feedbackContainerOpen : '')}>
          <div className={feedbackContentSpacer}></div>
          <div className={feedbackContentContainer}>
            <div
              className={feedbackTab}
              onClick={() => {
                setTabOpen(!tabOpen);
              }}
            >
              Feedback
            </div>
            <FeedbackForm formOpen={tabOpen} onClose={() => setTabOpen(false)} />
          </div>
        </div>
      )}
      {firstRender && mobile && (
        <>
          <div className={classNames(feedbackContainerMobile, tabOpen ? feedbackContainerMobileHidden : '')}>
            <div className={feedbackContentSpacer}></div>
            <div className={feedbackContentContainer}>
              <div
                className={feedbackTab}
                onClick={() => {
                  setTabOpen(!tabOpen);
                }}
              >
                Feedback
              </div>
            </div>
          </div>

          <div className={classNames(feedbackModal, tabOpen ? feedbackModalOpen : '')}>
            <div className={feedbackContentSpacer}></div>
            <FeedbackForm formOpen={tabOpen} onClose={() => setTabOpen(false)} />
          </div>
        </>
      )}
    </>
  );
};
