import React from 'react';
import SearchEpisodes from './SearchEpisode';
import SearchProducts from './SearchProduct';
import { searchResultsContainer } from './styles.module.scss';

interface Props {
  query: string;
  episodeHits: [];
  productHits: [];
  numberOfEpisodeHits: number;
  numberOfProductHits: number;
  dataCY?: string;
}

const SearchResult = ({ episodeHits, productHits, numberOfEpisodeHits, numberOfProductHits, query, dataCY }: Props) => {
  return (
    <div data-cy={dataCY} className={searchResultsContainer}>
      <SearchEpisodes query={query} episodeHits={episodeHits} numberOfEpisodeHits={numberOfEpisodeHits} />
      <SearchProducts query={query} productHits={productHits} numberOfProductHits={numberOfProductHits} />
    </div>
  );
};

export default SearchResult;
