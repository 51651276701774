import React, { useEffect, useRef, useState } from 'react';
import { ProductImg } from '@components/Img';
import { AdWithProduct } from '@sharedLib/index';
import classNames from 'classnames';
import {
  bannerContainer,
  bannerContainerInternal,
  productImageContainer,
  customScrollButton,
  bannerEndFade,
  bannerStartFade,
  customCloseButton,
  featuredProductsContainer,
  newItemNotification,
  newItemNotificationShow,
  newItemNotificationHide,
  productImageContainerActive,
} from './styles.module.scss';
import HorizontalScrollButton from '../Buttons/HorizontalScrollButton';
import { ProductOverlay } from './ProductOverlay';

interface FeaturedProductsBannerProps {
  staticAds: AdWithProduct[] | null;
  featuredAds: AdWithProduct[];
  closeButtonCallback(): void;
  productSelectCallback: (productId: string) => void;
  addToCartCallback: () => void;
  internalProductCards: boolean;
}

export const FeaturedProductsBanner = ({
  staticAds,
  featuredAds,
  closeButtonCallback,
  productSelectCallback,
  addToCartCallback,
  internalProductCards,
}: FeaturedProductsBannerProps) => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [newNotification, setNewNotification] = useState(false);
  const [openIds, setOpenIds] = useState<string[]>([]);

  // clear open cards if no longer in use
  useEffect(() => {
    if (!internalProductCards) {
      setOpenIds([]);
    }
  }, [internalProductCards]);

  // if a new featured ad is received, but the banner is scrolled, set notification
  useEffect(() => {
    const scrollLeft = bannerRef.current?.scrollLeft;
    if (scrollLeft && scrollLeft > 0) {
      setNewNotification(true);
    }
  }, [featuredAds.length]);

  // if the scroll position is at the beginning of the banner, remove the notification
  useEffect(() => {
    if (newNotification && bannerRef.current?.scrollLeft === 0) {
      setNewNotification(false);
    }
  }, [bannerRef.current?.scrollLeft]);

  // scroll to beginning of banner
  const resetBannerScroll = () => {
    if (bannerRef.current) {
      setNewNotification(false);
      bannerRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  // open and close internal product cards if they are in use
  const toggleInternalCardState = (e: React.MouseEvent, productId: string, isFeaturedAd?: boolean) => {
    const ids = [...openIds];
    const searchId = isFeaturedAd ? `featured-${productId}` : productId;
    const openIndex = openIds.findIndex(id => id === searchId);

    if (openIndex === -1) {
      setOpenIds([...openIds, searchId]);

      // scroll to clicked element
      if (bannerRef.current) {
        const el = e.target as HTMLDivElement;
        const leftPos = el.getBoundingClientRect().left;
        const currentPos = bannerRef.current.scrollLeft;
        const targetScroll = currentPos + leftPos - 70;

        bannerRef.current.scrollTo({
          left: targetScroll,
          behavior: 'smooth',
        });
      }
    } else {
      ids.splice(openIndex, 1);
      setOpenIds(ids);
    }
  };

  // if using internal product cards, toggle their state
  // if not, call the parent callback
  const handleProductClick = (e: React.MouseEvent, productId: string, isFeaturedAd?: boolean) => {
    if (internalProductCards) {
      toggleInternalCardState(e, productId, isFeaturedAd);
    } else {
      productSelectCallback(productId);
    }
  };

  return (
    <div className={classNames(featuredProductsContainer)}>
      <div
        className={classNames(newItemNotification, newNotification ? newItemNotificationShow : newItemNotificationHide)}
        onClick={() => resetBannerScroll()}
      >
        NEW ITEMS
      </div>
      <div className={bannerStartFade} />
      <HorizontalScrollButton elementRef={bannerRef} className={customScrollButton} position="left" />
      <HorizontalScrollButton elementRef={bannerRef} className={customScrollButton} position="right" />
      <div ref={bannerRef} className={bannerContainer}>
        <div className={bannerContainerInternal}>
          {featuredAds.map(item => {
            const isOpen = openIds.includes(`featured-${item.productId}`);
            return (
              <React.Fragment key={`featured-${item.id}`}>
                <div
                  data-cy={`product-image-container-${item.id}`}
                  className={classNames(productImageContainer, isOpen ? productImageContainerActive : '')}
                  onClick={e => handleProductClick(e, item.productId, true)}
                >
                  <ProductImg product={item.product} />
                </div>
                {internalProductCards && <ProductOverlay key={`featured-${item.productId}`} productId={item.productId} open={isOpen} />}
              </React.Fragment>
            );
          })}
          {staticAds?.map(item => {
            const isOpen = openIds.includes(item.productId);
            return (
              <React.Fragment key={item.id}>
                <div
                  className={classNames(productImageContainer, isOpen ? productImageContainerActive : '')}
                  onClick={e => handleProductClick(e, item.productId)}
                  data-cy={`product-image-container-${item.id}`}
                >
                  <ProductImg product={item.product} />
                </div>
                {internalProductCards && (
                  <ProductOverlay
                    key={item.productId}
                    productId={item.productId}
                    open={isOpen}
                    addToCartCallback={() => addToCartCallback()}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className={bannerEndFade} />
      <button type="button" onClick={() => closeButtonCallback()} className={classNames('delete', customCloseButton)} />
    </div>
  );
};
