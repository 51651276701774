import React, { ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { countdownContainer, countdownTicker } from './styles.module.scss';

interface Props {
  children?: ReactNode;
  revealElement?: ReactNode;
  initialElement?: ReactNode;
  className?: string;
  tickerClassname?: string;
  limit: number;
  hideTimer?: boolean;
  onTimeout?: () => void;
}

export const Countdown = ({ initialElement, revealElement, className, tickerClassname, limit, hideTimer, onTimeout }: Props) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    //set timer on mount to update every second
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timer); // cleanup the timer on unmount
  }, []);

  //
  // two-pass render to try avoid Gatsby hydrate
  //
  useEffect(() => {
    setFirstRender(true);
  }, []);

  let timeLeft = limit - currentTime;
  const daysLeft = Math.floor(timeLeft / 24 / 60 / 60 / 1000);
  timeLeft = timeLeft - daysLeft * 24 * 60 * 60 * 1000;

  const hoursLeft = Math.floor(timeLeft / 60 / 60 / 1000);
  timeLeft = timeLeft - hoursLeft * 60 * 60 * 1000;

  const minutesLeft = Math.floor(timeLeft / 60 / 1000);
  timeLeft = timeLeft - minutesLeft * 60 * 1000;

  const secondsLeft = Math.floor(timeLeft / 1000);

  const padLeft = (num: number, length: number): string => {
    let newStr = num.toString();
    for (let i = 0; i < length - num.toString().length; i++) {
      newStr = '0' + newStr;
    }
    return newStr;
  };

  if (Boolean(currentTime) && limit < currentTime && onTimeout) {
    onTimeout();
  }

  return (
    <>
      {firstRender && (
        <div className={classnames(className, countdownContainer)}>
          {initialElement !== undefined && !(Boolean(currentTime) && limit < currentTime) && <>{initialElement}</>}
          {!hideTimer && limit >= currentTime && (
            <div className={classnames(tickerClassname, countdownTicker)}>
              <div>{`${padLeft(daysLeft, 2)}:${padLeft(hoursLeft, 2)}:${padLeft(minutesLeft, 2)}:${padLeft(secondsLeft, 2)}`}</div>
            </div>
          )}

          {Boolean(currentTime) && limit < currentTime && <>{revealElement}</>}
        </div>
      )}
    </>
  );
};
