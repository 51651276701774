import { usePvepApi } from '@apiClient/usePvepApi';
import { AuthClientUtil } from '@lib/AuthClientUtil';
import { isBrowser } from '@lib/build';
import { MeStateContext } from '@lib/meContext';
import { useVideoState, VideoPlayerSize } from '@lib/useVideoState';
import classNames from 'classnames';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ClientLogger } from '@lib/ClientLogger';
import { CartContext } from '@src/lib/clientStorage/cartContext';
import { Toast } from '@src/components/Toast';
import { BrandUtil } from '@sharedLib/index';
import Header from './Header';
import Footer from './Footer';
import LayoutRoot from './LayoutRoot';
import LayoutMain from './LayoutMain';
import { Feedback } from '@components/Feedback';

import {
  mainEmbedded,
  mainNoScroll,
  mainScroll,
  innerMain,
  embeddedTitle,
  embeddedSubtitle,
  embeddedTitleContainer,
} from './styles.module.scss';

interface IndexLayoutProps {
  noMargins?: boolean;
  landing?: boolean;
  children?: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideFreeTrial?: boolean;
  hideLogin?: boolean;
  hideCart?: boolean;
  hideConfiguredButtons?: boolean;
  hideFeedback?: boolean;
  showStrategyPreview?: boolean;
  loginState?: boolean;
  needsLogin?: boolean;
  needsSubscription?: boolean;
  hideScrollBar?: boolean;
  noVideo?: boolean;
  noSocialOnFooter?: boolean;
  embeddedMode?: boolean;
}

const DEBUG = false;
const DEBUG_VIDEO = false;

const IndexLayout = ({
  hideScrollBar,
  noMargins,
  landing,
  children,
  hideFooter,
  hideHeader,
  hideFreeTrial,
  hideLogin,
  hideCart,
  hideConfiguredButtons,
  hideFeedback,
  showStrategyPreview,
  needsLogin,
  needsSubscription,
  noVideo,
  noSocialOnFooter,
  embeddedMode,
}: IndexLayoutProps) => {
  const location = 'IndexLayout';
  ClientLogger.debug(location, `needsLogin=${needsLogin} needsSubscription=${needsSubscription} noVideo=${noVideo}`, isBrowser && DEBUG);
  const api = usePvepApi();
  const videoState = useVideoState();
  const meContext = useContext(MeStateContext);
  const brandInfo = BrandUtil.getSiteInfo();

  useEffect(() => {
    if (needsLogin) {
      ClientLogger.debug(location, `calling isLoggedIn`, isBrowser && DEBUG, JSON.stringify(api.state, null, 2));
      api.isLoggedIn(
        () => {
          AuthClientUtil.redirectToLogin();
        },
        () => {
          AuthClientUtil.logout('/');
          videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
          videoState.pause();
        }
      );
    }
  }, [needsLogin]);

  useEffect(() => {
    if (needsSubscription) {
      DEBUG && ClientLogger.debug(location, `check me for accountStatus`, { meContext });
      if (!meContext.me) {
        meContext.readMe().then(me => {
          DEBUG && ClientLogger.debug(location, `read me done`, { me });
        });
      } else if (meContext.me.accountStatus !== 'active') {
        if (!api.state.isAdmin) {
          ClientLogger.debug(location, `redirecting to subscribe`, isBrowser && DEBUG, JSON.stringify(api.state, null, 2));
          AuthClientUtil.navigateToRegister();
        } else {
          ClientLogger.warning(location, 'Not subscribed.  But user is admin, so not redirecting');
        }
      }
    }
  }, [needsSubscription, meContext.me]);
  useEffect(() => {
    ClientLogger.debug(location, `useEffect video manager`, DEBUG_VIDEO, {
      noVideo,
      size: videoState.state.videoPlayerSize,
      video: videoState.state.video,
      playing: videoState.state.playing,
    });
    if (noVideo) {
      videoState.pause();
      ClientLogger.debug(location, `useEffect video turned off`, DEBUG_VIDEO, {
        size: videoState.state.videoPlayerSize,
        video: videoState.state.video,
        playing: videoState.state.playing,
      });
      if (videoState.state.videoPlayerSize !== VideoPlayerSize.NOT_SHOWN) {
        videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
      }
    }
  }, [noVideo, videoState.state.videoPlayerSize]);

  const cartContext = useContext(CartContext);
  const { isLoggedIn } = api.state;
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>({
    header: '',
    info: '',
  });
  const [toastStyle, setToastStyle] = useState<any>('info');

  useEffect(() => {
    if (isLoggedIn) {
      if (cartContext.cartState?.success) {
        setToastMessage(cartContext.cartState?.message);
        setToastStatus(true);
        if (cartContext.cartState?.state === 'completed') setToastStyle('success');
        else if (cartContext.cartState?.state === 'failed') setToastStyle('danger');
        setTimeout(() => {
          setToastStatus(false);
        }, 5000);
      }
    }
  }, [cartContext.cartState]);

  return (
    <LayoutRoot>
      {!hideHeader && (
        <Header
          hideFreeTrial={hideFreeTrial}
          hideLogin={hideLogin || !brandInfo.hasSignup}
          hideCart={hideCart}
          hideConfiguredButtons={hideConfiguredButtons}
          showStrategyPreview={showStrategyPreview}
        />
      )}
      {embeddedMode && (
        <div className={embeddedTitleContainer}>
          <div className={embeddedTitle}>Watch. Shop. Share.</div>
          <div className={embeddedSubtitle}>powered by Alibi Technologies</div>
        </div>
      )}
      <div className={classNames(embeddedMode ? mainEmbedded : hideScrollBar ? mainNoScroll : mainScroll)}>
        <LayoutMain footerStyle={landing}>
          <div className={innerMain}>
            <Toast show={toastStatus} toastStyle={toastStyle} header={toastMessage.header} message={toastMessage.info} />
            {brandInfo.feedbackConfig.hasFeedback && !hideFeedback && <Feedback />}
            {children}
          </div>
        </LayoutMain>
        {!hideFooter && <Footer noMargins={noMargins} landing={landing} noSocialOnFooter={noSocialOnFooter} />}
      </div>
    </LayoutRoot>
  );
};

export default IndexLayout;
