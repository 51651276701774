/* eslint-disable camelcase */
import { CartServices } from '@apiClient/cart-services';
import { TokenRefreshLink } from '@apiClient/token-refresh-link';
import { tracerLink } from '@apiClient/./apollo-tracer';
import { UserMessagesService } from '@apiClient/user-messages-service';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { ClientLogger } from '@lib/ClientLogger';
import { BrandUtil, Util } from '@sharedLib/util';
import { DatabaseVideo, DatabaseCategory, Strategy, ExperimentInput } from '@sharedLib/index';
import { NetworkStatus, InMemoryCache, ApolloClient, ApolloQueryResult, ApolloLink, FetchResult, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { isBrowser } from '@lib/build';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { ErrorCode } from './gql-types';
import { QueryType, MutationType } from './api-types';

interface JsonWebToken {
  email: string;
  roles: string[];
  email_verified: boolean;
  exp: number;
  sub: string | undefined; // FusionAuth unique user Id
}

interface AppSession {
  id: string;
  updated: number;
}
export interface UserState {
  isLoggedIn: boolean;
  isAdmin: boolean;
  isManager: boolean;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  loopCount: number;
  jwt: JsonWebToken | undefined;
  watching: {
    categoryId: string;
    seriesId: string;
    seasonId: string;
    videoId: string;
  };
  appSession: AppSession;
  deviceId: string;
  useRuntimeData: boolean;
  useAdStrategyPreview?: {
    experimentId: string;
    strategyId: string;
  };
}

const blankUserState: UserState = {
  isLoggedIn: false,
  isAdmin: false,
  isManager: false,
  firstName: '',
  lastName: '',
  email: '',
  token: '',
  loopCount: 0,
  jwt: { email: '', roles: [], email_verified: false, exp: NaN, sub: undefined },
  watching: {
    categoryId: '',
    seriesId: '',
    seasonId: '',
    videoId: '',
  },
  appSession: {
    id: '',
    updated: 0,
  },
  deviceId: '',
  useRuntimeData: false,
};

export interface ApolloClientContext {
  noAuth?: boolean;
}

const localStorageKeyDeviceId = 'deviceId';
const siteMetadata = BrandUtil.getSiteInfo();
const localStorageKey = `pvep-user-${siteMetadata.branchId}`;
const DEBUG = false;
export const serverURL = siteMetadata.serverUrl;

const isBuilding = !isBrowser;
const mockApollo = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  query: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  mutate: () => {},
};

let canAccessLocalStorage = true;
try {
  if (isBrowser) {
    window.localStorage.getItem(localStorageKey);
  }
} catch (e) {
  ClientLogger.error('usePvepApi', 'cannot access local storage', e);
  canAccessLocalStorage = false;
}

// Get initial state from local storage if there
const storedUserState = isBrowser && canAccessLocalStorage ? window.localStorage.getItem(localStorageKey) : undefined;
ClientLogger.debug('usePvepApi load', 'LOADING', DEBUG, { storedUserState });
let userContextInitial: UserState;

function setAndSaveDeviceId(): string {
  const deviceId = uuidv4();
  if (isBrowser && canAccessLocalStorage) {
    window.localStorage.setItem(localStorageKeyDeviceId, deviceId);
  }
  return deviceId;
}

if (storedUserState) {
  userContextInitial = JSON.parse(storedUserState);
  if (!userContextInitial.deviceId) {
    // No device id on existing user state - must have been created by earlier version.  Add it and update it
    let deviceId = '';

    // try get previously stored device id
    if (isBrowser && canAccessLocalStorage) {
      deviceId = window.localStorage.getItem(localStorageKeyDeviceId) || '';
    }
    // no device id stored, create new one
    if (!deviceId) {
      deviceId = setAndSaveDeviceId();
    }
    userContextInitial.deviceId = deviceId;
    isBrowser && canAccessLocalStorage && window.localStorage.setItem(localStorageKey, JSON.stringify(userContextInitial));
  }
} else {
  let deviceId = isBrowser && canAccessLocalStorage ? window.localStorage.getItem(localStorageKeyDeviceId) : undefined;
  if (!deviceId) {
    deviceId = setAndSaveDeviceId();
  }
  userContextInitial = blankUserState;
  userContextInitial.deviceId = deviceId;
}
ClientLogger.debug('usePvepApi load', 'LOADING', DEBUG, { userContextInitial });
export const currentUserState = { current: userContextInitial };
const singletonApolloClient = { current: undefined as ApolloClient<any> | undefined };

function isAdmin(jwt: JsonWebToken | undefined): boolean {
  if (!jwt) {
    return false;
  }
  const roles = jwt.roles;
  return roles.findIndex(r => r === 'admin') > -1;
}

function isManager(jwt: JsonWebToken | undefined): boolean {
  if (!jwt) {
    return false;
  }
  const roles = jwt.roles;
  return roles.findIndex(r => r === 'manager') > -1;
}

export function tokenExpiryReadable(userState: UserState): string {
  return `${new Date((userState.jwt?.exp || 0) * 1000).toLocaleDateString()}} ${new Date(
    (userState.jwt?.exp || 0) * 1000
  ).toLocaleTimeString()}}`;
}

export interface ErrorDetails {
  errorCode: ErrorCode;
  errorMessage?: string | null; // String
}

export interface CommonResponse {
  success: boolean;
  errorMessages?: ErrorDetails[] | null;
}

export function ErrorText(errorMessages?: ErrorDetails[] | null): string[] {
  if (errorMessages) {
    return errorMessages.map(ed => {
      switch (ed.errorCode) {
        case ErrorCode.NOT_CODED:
          return ed.errorMessage || '';
        case ErrorCode.EMAIL_ADDRESS_IN_USE:
          return 'That email address is already taken';
        case ErrorCode.EMAIL_ADDRESS_INVALID:
          return 'That email address was invalid';
        case ErrorCode.INVALID_COUPON_CODE:
          return 'Invalid promo code';
        default:
          return ed.errorMessage || 'Missing message for Error Code.  Update src/lib/apolloclient.ts';
      }
    });
  }
  return [];
}

export function ApolloErrorsToErrorDetails(errors?: readonly GraphQLError[] | undefined | null): ErrorDetails[] {
  if (errors) {
    return errors.map(e => {
      return { errorCode: ErrorCode.NOT_CODED, errorMessage: e.message };
    });
  }
  return [];
}

export function ApolloErrorsToErrorStrings(errors?: readonly GraphQLError[] | undefined | null): string[] {
  if (errors) {
    return errors.map(e => {
      return e.message;
    });
  }
  return [];
}

export const getClient = (serverUrlCustom?: string): ApolloClient<any> => {
  DEBUG && ClientLogger.debug('usePvepApi', 'getClient', { serverUrlCustom });
  if (isBuilding) {
    return (mockApollo as unknown) as ApolloClient<any>;
  }
  if (singletonApolloClient.current !== undefined) {
    return singletonApolloClient.current;
  }
  singletonApolloClient.current = new ApolloClient({
    link: ApolloLink.from([
      new TokenRefreshLink(),
      tracerLink,
      onError(({ graphQLErrors, networkError, response, operation }) => {
        ClientLogger.error('ApolloClient', 'graphQLErrors', { graphQLErrors, networkError, response, operation });
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            ClientLogger.error('usePvepApi', `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          });
        }
        if (networkError) {
          if (networkError.name.indexOf('Error writing result to store for query') > -1) {
            ClientLogger.log('ApolloClient', 'Error writing result to store for query detected. Assumed to be a faulty cache.');
          }
          ClientLogger.log('ApolloClient', 'Network error detected. Assumed to be a faulty connection.');
          //          forward(operation);
          // return Observable.of({
          //   data: {},
          //   errors: [networkError],
          //   loading: false,
          //   networkStatus: NetworkStatus.error,
          //   stale: false,
          // });
        }
      }),
      createHttpLink({
        uri: serverUrlCustom || serverURL,
        credentials: 'include',
      }),
    ]),
    cache: new InMemoryCache(),
  });
  return singletonApolloClient.current;
};

// Moved here to avoid circular dependency between usePvepApi and useAnalyticsCapture
const identity = (userState: UserState) => {
  // Set Identity for analytics
  const location = 'AnalyticsCapture.identity';
  if (isBrowser) {
    const branchType = BrandUtil.getSiteInfo().branchType;
    const branch = Util.getBranch();
    const trackUser = branchType === 'master' || branchType === 'staging';
    if (trackUser) {
      DEBUG && ClientLogger.debug(location, `Setting identity (real)`);
      window.analytics.identify(userState.jwt ? userState.jwt.sub : undefined, {
        // @ts-ignore - seems to be something wrong with types
        email: userState.email,
        firstName: userState.firstName,
        lastName: userState.lastName,
        brandId: Util.getBrandId(),
        gitBranch: branch,
      });
    } else {
      // Reduce MTU (Monthly Tracked Users) created during Cypress tests
      const userId = `test-user+${Util.getBranch()}@test.com`;
      DEBUG && ClientLogger.debug(location, `Setting identity (fake) userId=${userId}`);
      window.analytics.identify(userId, {
        // @ts-ignore - seems to be something wrong with types
        email: userId,
        firstName: 'Mr.',
        lastName: branch,
        brandId: Util.getBrandId(),
        gitBranch: branch,
      });
    }
  }
};

export const usePvepApi = () => {
  DEBUG && ClientLogger.debug('usePvepApi', 'call', JSON.stringify(currentUserState.current));
  const decodeToken = (token: string | undefined) => (token ? jwtDecode<JwtPayload>(token) : undefined);

  function calcAppSession(userId: string | undefined): AppSession {
    return {
      id: `u:${userId || 'Unknown user'}, d:${getDeviceId() || 'Unknown device'}, s:${uuidv4()}`,
      updated: Date.now(),
    };
  }

  function calcStateFromToken(curState: UserState, token: string | undefined): UserState {
    const appSession = curState.appSession?.id ? curState.appSession : calcAppSession(curState.jwt?.sub);
    const jwt: any | undefined = token ? decodeToken(token) : undefined;
    return {
      ...curState,
      appSession,
      token: token || curState.token,
      jwt,
      ...{ isLoggedIn: !!jwt, isAdmin: isAdmin(jwt), isManager: isManager(jwt) },
    };
  }

  function setUserState(userStateNew: UserState, newToken?: string): Promise<boolean> {
    ClientLogger.debug('usePvepApi.setUserState', 'called with userStateNew = ', DEBUG, userStateNew);
    const userState = calcStateFromToken(userStateNew, newToken || userStateNew.token);
    // setUserState(userState);
    ClientLogger.debug('usePvepApi.setUserState', 'storing to state', DEBUG, userState);
    currentUserState.current = userState;

    // Set Identity for analytics
    identity(userState);

    // Do localStorage write in promise for improved performance
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (isBrowser && canAccessLocalStorage) {
          window.localStorage.setItem(localStorageKey, JSON.stringify(userState));
          ClientLogger.debug(
            'usePvepApi.setUserStateWithLocalStorage',
            'test read back',
            DEBUG,
            window.localStorage.getItem(localStorageKey)
          );
        }
        ClientLogger.debug('usePvepApi.setUserStateWithLocalStorage', 'completed localStorage write', DEBUG, userState);
        resolve(true);
      } catch (e) {
        ClientLogger.error('usePvepApi.setUserStateWithLocalStorage', 'error writing to local storage', e);
        reject(e);
      }
    });
  }

  const getJwtFromRefresh = async (logOutCallback?: () => void): Promise<boolean> => {
    ClientLogger.debug('usePvepApi.getJwtFromRefresh', 'getJwtFromRefresh. user = ', DEBUG, currentUserState.current);
    const tokenRequestResponse = await exchangeRefreshTokenForJwtTokenCall();
    ClientLogger.debug('usePvepApi.getJwtFromRefresh', 'tokenRequestResponse', DEBUG, tokenRequestResponse);
    const { data } = tokenRequestResponse;
    if (data && data.exchangeRefreshTokenForJwtToken.success) {
      setUserState({ ...currentUserState.current, loopCount: 0 }, data.exchangeRefreshTokenForJwtToken.accessToken);
      return true;
    }
    ClientLogger.error(`usePvepApi.getJwtFromRefresh`, `Data not returned from exchangeRefreshTokenForJwtTokenCall`, tokenRequestResponse);

    if (logOutCallback) {
      ClientLogger.log('AuthClientUtils.getJwtFromRefresh', 'logging out...');
      logOutCallback();
    }
    return false;
  };

  // try get stored device id, first from state, then localstorage
  const getDeviceId = (): string => {
    let deviceId = currentUserState.current.deviceId;
    if (!deviceId && isBrowser && canAccessLocalStorage) {
      deviceId = window.localStorage.getItem(localStorageKeyDeviceId) || '';
    }
    return deviceId;
  };

  // Private methods
  const exchangeRefreshTokenForJwtTokenCall = async (): Promise<FetchResult<QueryType['exchangeRefreshTokenForJwtToken']>> => {
    return getClient().query({
      query: gql`
        query exchangeRefreshTokenForJwtToken {
          exchangeRefreshTokenForJwtToken {
            authServiceResponse
            accessToken
            success
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
      context: { noAuth: true },
    });
  };

  const isVhxSubscribed = async (): Promise<ApolloQueryResult<QueryType['isSubscribed']>> => {
    return getClient().query({
      query: gql`
        query CheckSubscription {
          isSubscribed {
            isSubscribed
            subscription
            commonResponse {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });
  };

  const exchangeProviderCodeForAuthCall = async (
    token: string,
    provider: 'FACEBOOK' | 'GOOGLE'
  ): Promise<FetchResult<QueryType['exchangeProviderCodeForAuth']>> => {
    return getClient().query({
      query: gql`
        query exchangeProviderCodeForAuth($token: String!, $provider: String!) {
          exchangeProviderCodeForAuth(token: $token, provider: $provider) {
            email
            firstName
            lastName
            token
            authServiceResponse
            success
            errorMessages {
              errorCode
              errorMessage
            }
            isSignup
          }
        }
      `,
      variables: { token, provider },
      fetchPolicy: 'no-cache',
      context: { noAuth: true },
    });
  };

  const register = async (
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ): Promise<FetchResult<MutationType['signup']>> => {
    return getClient().mutate({
      variables: { firstname, lastname, email, password },
      mutation: gql`
        mutation RegisterUser($firstname: String!, $lastname: String!, $email: String!, $password: String!) {
          signup(firstname: $firstname, lastname: $lastname, email: $email, password: $password) {
            token
            commonResponse {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        }
      `,
      context: { noAuth: true },
    });
  };

  const unsubscribe = async (email: string) => {
    try {
      return await getClient().mutate({
        variables: {
          email,
        },
        mutation: gql`
          mutation unsubscribeUser($email: String!) {
            unsubscribe(email: $email) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    } catch (error) {
      ClientLogger.error('updateUserInfoCall', 'Catch', error);
      return error;
    }
  };

  const updateUserInfoCall = async (
    firstname: string,
    lastname: string,
    email: string
  ): Promise<FetchResult<MutationType['updateUserInfo']>> => {
    try {
      return await getClient().mutate({
        variables: {
          email,
          firstname,
          lastname,
        },
        mutation: gql`
          mutation updateUserInfo($firstname: String!, $lastname: String!, $email: String!) {
            updateUserInfo(firstname: $firstname, lastname: $lastname, email: $email) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    } catch (error) {
      ClientLogger.error('updateUserInfoCall', 'Catch', error);
      return error as any;
    }
  };

  const updateUserPasswordCall = async (
    email: string,
    password: string,
    currentPassword: string
  ): Promise<FetchResult<MutationType['updateUserPassword']>> => {
    try {
      return await getClient().mutate({
        variables: {
          email,
          password,
          currentPassword,
        },
        mutation: gql`
          mutation updateUserPassword($email: String!, $password: String!, $currentPassword: String!) {
            updateUserPassword(email: $email, password: $password, currentPassword: $currentPassword) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    } catch (error) {
      ClientLogger.error('updateUserPasswordCall', 'Catch', error);
      return error as any;
    }
  };

  const submitContactFormCall = async (email: string, message: string, name?: string, country?: string, phoneNumber?: string) => {
    try {
      return await getClient().mutate({
        variables: {
          name,
          email,
          country,
          phoneNumber,
          message,
        },
        mutation: gql`
          mutation submitContactForm($name: String, $email: String!, $country: String, $phoneNumber: String, $message: String!) {
            submitContactForm(name: $name, email: $email, country: $country, phoneNumber: $phoneNumber, message: $message) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        context: { noAuth: true },
      });
    } catch (error) {
      ClientLogger.error('submitContactFormCall', 'Catch', error);
      return error;
    }
  };

  const getWatchList = async (email: string): Promise<FetchResult<QueryType['getWatchList']>> => {
    try {
      return await getClient().query({
        variables: {
          email,
        },
        query: gql`
          query getWatchList($email: String!) {
            getWatchList(email: $email) {
              items {
                id
              }
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      ClientLogger.error('getWatchList', 'Catch', error);
      return error as any;
    }
  };

  const getUserQueryOptions = <V>(pollInterval: number, skip: boolean, variables: V): QueryHookOptions<any, any> => {
    return { pollInterval, skip, client: getClient(), variables };
  };

  return {
    state: currentUserState.current,
    cartServices: CartServices,
    userMessagesService: UserMessagesService,
    getUserQueryOptions,
    checkSubscribed: async (navigateHomeCallback?: () => void, navigateToSubscribeCallback?: () => void): Promise<boolean> => {
      try {
        ClientLogger.debug('usePvepApi.checkSubscribed', 'calling server', DEBUG);
        if (!currentUserState.current.isLoggedIn) {
          ClientLogger.warning(
            'usePvepApi.checkSubscribed',
            `Checking subscription when not logged in. userState = ${JSON.stringify(currentUserState.current)}`
          );
          if (navigateHomeCallback) {
            navigateHomeCallback();
          }
          return false;
        }
        const isSubscribedResponse = await isVhxSubscribed();
        ClientLogger.debug('usePvepApi.checkSubscribed', 'done isVhxSubscribed', DEBUG, { isSubscribedResponse });
        if (isBrowser) {
          if (isSubscribedResponse.data) {
            ClientLogger.debug(
              'usePvepApi.checkSubscribed',
              `isVhxSubscribedResponse ${isSubscribedResponse.data.isSubscribed.isSubscribed}`,
              DEBUG,
              { isSubscribedResponse }
            );
            setUserState({
              ...currentUserState.current,
              loopCount: 0,
            });
            if (isSubscribedResponse.data.isSubscribed.isSubscribed && navigateHomeCallback) {
              navigateHomeCallback();
            } else if (navigateToSubscribeCallback) {
              navigateToSubscribeCallback();
            }
            return isSubscribedResponse.data.isSubscribed.isSubscribed;
          }
          ClientLogger.error(
            'usePvepApi.pollServerForSubscriptionStatus',
            'isSubscribedResponse.data is blank',
            isSubscribedResponse.errors
          );
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        ClientLogger.error('usePvepApi.checkSubscribed', 'Error Caught', error);
        return false;
      }
    },
    getWatchList: async (email: string) => {
      ClientLogger.debug('usePvepApi.getWatchList', 'request', DEBUG);
      const resp = await getWatchList(email);
      ClientLogger.debug('usePvepApi.getWatchListResp', `response = ${JSON.stringify(resp)}`, DEBUG);
      if (!resp.data?.getWatchList.success) {
        return false;
      }
      return true;
    },
    getJwtFromRefresh,

    isLoggedIn: (redirectIfNotCallback?: () => void, logoutCallback?: () => void): boolean => {
      if (!isBrowser) {
        return false;
      }

      ClientLogger.debug('usePvepApi.isLoggedIn', 'isLoggedIn. user = ', DEBUG, JSON.stringify(currentUserState.current, null, 2));

      if (!currentUserState.current.isLoggedIn) {
        // If we’re not logged in, redirect to the login page.
        ClientLogger.debug('usePvepApi.isLoggedIn', `detected not logged in`, DEBUG);
        if (redirectIfNotCallback) {
          ClientLogger.debug('usePvepApi.isLoggedIn', `calling redirectIfNotCallback`, DEBUG);
          redirectIfNotCallback();
        }
        return false;
      }

      // Check for expired JWT
      const { jwt } = currentUserState.current;
      const expiry = jwt ? moment.unix(jwt.exp) : moment().subtract(1, 'day');
      const now = moment();
      const expired = expiry.isBefore(now);
      if (expired) {
        ClientLogger.debug('usePvepApi.isLoggedIn', 'Checked token expiry and it has expired', DEBUG, {
          expired,
          expiry,
          now,
          jwt,
        });
        getJwtFromRefresh(logoutCallback);
        return false;
      }
      ClientLogger.debug('usePvepApi.isLoggedIn', 'Checked token expiry. It is OK', DEBUG, {
        expired,
        expiry,
        now,
        jwt,
      });

      ClientLogger.debug('usePvepApi.isLoggedIn', `returning true`, DEBUG);
      return true;
    },

    login: async (email: string, password: string): Promise<FetchResult<MutationType['login']>> => {
      DEBUG && ClientLogger.debug('usePvepApi', `login`, { email, password });
      const loginResp: FetchResult<MutationType['login']> = await getClient().mutate({
        variables: { email, password },
        mutation: gql`
          mutation login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              email
              firstName
              lastName
              token
              commonResponse {
                success
                errorMessages {
                  errorCode
                  errorMessage
                }
              }
            }
          }
        `,
        context: { noAuth: true },
      });

      if (loginResp.data?.login.commonResponse.success) {
        const decodedToken: any = loginResp.data.login.token ? decodeToken(loginResp.data.login.token) : '';
        setUserState(
          {
            ...currentUserState.current,
            email: loginResp.data.login.email,
            firstName: loginResp.data.login.firstName,
            lastName: loginResp.data.login.lastName,
            token: loginResp.data.login.token,
            appSession: calcAppSession(decodedToken?.sub),
          },
          loginResp.data.login.token
        );
      }

      return loginResp;
    },

    verifyEmail: async (verificationId: string): Promise<FetchResult<MutationType['verifyEmail']>> => {
      ClientLogger.debug('usePvepApi', `verifyEmail ${{ verificationId }}`, DEBUG);
      const verifyEmailResp = await getClient().mutate({
        variables: { verificationId },
        mutation: gql`
          mutation verifyEmail($verificationId: String) {
            verifyEmail(verificationId: $verificationId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        context: { noAuth: true },
      });
      return verifyEmailResp;
    },

    logout: async (): Promise<FetchResult<MutationType['logout']>> => {
      DEBUG && ClientLogger.debug('usePvepApi', `logout `);
      const logoutResp: FetchResult<MutationType['logout']> = await getClient().mutate({
        mutation: gql`
          mutation logout {
            logout {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        context: { noAuth: true },
      });
      canAccessLocalStorage && window.localStorage.setItem(localStorageKey, '');
      await setUserState(blankUserState);
      return logoutResp;
    },

    resendEmailVerification: async (): Promise<FetchResult<QueryType['resendEmailVerification']>> => {
      ClientLogger.debug('usePvepApi', `resendEmailVerification called`, DEBUG);
      const resendVerificationResp = await getClient().query({
        query: gql`
          query resendEmailVerification {
            resendEmailVerification {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
      return resendVerificationResp;
    },

    forgotPassword: async (email: string): Promise<FetchResult<MutationType['forgotPassword']>> => {
      ClientLogger.debug('usePvepApi', `forgotPassword ${{ email }}`, DEBUG);
      const forgotPasswordResp = await getClient().mutate({
        variables: { email },
        mutation: gql`
          mutation forgotPassword($email: String) {
            forgotPassword(email: $email) {
              changePasswordId
            }
          }
        `,
        context: { noAuth: true },
      });

      return forgotPasswordResp;
    },

    updateUserPasswordFromChangePasswordId: async (
      password: string,
      changePasswordId: any
    ): Promise<FetchResult<MutationType['updateUserPasswordFromChangePasswordId']>> => {
      const forgotPasswordResp = await getClient().mutate({
        variables: { password, changePasswordId },
        mutation: gql`
          mutation updateUserPasswordFromChangePasswordId($password: String, $changePasswordId: String) {
            updateUserPasswordFromChangePasswordId(password: $password, changePasswordId: $changePasswordId) {
              success
            }
          }
        `,
        context: { noAuth: true },
      });

      return forgotPasswordResp;
    },

    loginWithProvider: async (
      response: any,
      provider: 'FACEBOOK' | 'GOOGLE'
    ): Promise<FetchResult<QueryType['exchangeProviderCodeForAuth']>> => {
      const token = provider === 'GOOGLE' ? response.code : response.accessToken;
      const location = 'usePvepApi.loginWithProvider';
      DEBUG && ClientLogger.debug(location, `started with ${JSON.stringify({ token, provider })}`);
      const tokenRequestResponse = await exchangeProviderCodeForAuthCall(token, provider);
      DEBUG && ClientLogger.debug(location, `Response ${JSON.stringify({ tokenRequestResponse })}`);
      const exchangeProviderCodeForAuth = tokenRequestResponse?.data?.exchangeProviderCodeForAuth;
      if (exchangeProviderCodeForAuth && exchangeProviderCodeForAuth.success) {
        setUserState(
          {
            ...currentUserState.current,
            firstName: exchangeProviderCodeForAuth.firstName,
            lastName: exchangeProviderCodeForAuth.lastName,
            email: exchangeProviderCodeForAuth.email,
            token: exchangeProviderCodeForAuth.token,
          },
          exchangeProviderCodeForAuth.token
        );
      } else {
        ClientLogger.error(
          location,
          `Error signing in with provider ${JSON.stringify({ errorMessages: exchangeProviderCodeForAuth?.errorMessages })}`
        );
      }

      return tokenRequestResponse;
    },

    registerWithEmail: async (
      firstname: string,
      lastname: string,
      email: string,
      password: string
    ): Promise<FetchResult<MutationType['signup']>> => {
      ClientLogger.debug('usePvepApi.registerWithEmail', `registering ${firstname} ${lastname}, ${email}`, DEBUG);
      const resp = await register(firstname, lastname, email, password);
      ClientLogger.debug('usePvepApi.registerWithEmail', `resp`, DEBUG, resp);
      ClientLogger.debug('usePvepApi.registerWithEmail', `resp`, DEBUG, resp.data);
      if (resp.errors) {
        ClientLogger.error('usePvepApi.registerWithEmail', 'Error returned from server', resp.errors);
      }
      if (resp.data) {
        setUserState(
          {
            ...currentUserState.current,
            firstName: firstname,
            lastName: lastname,
            email,
            token: resp.data.signup.token,
            loopCount: 0,
          },
          resp.data.signup.token
        );
        return resp;
      }
      return {
        data: {
          signup: {
            token: '',
            firstName: '',
            lastName: '',
            email: '',
            commonResponse: {
              success: false,
              errorMessages: ApolloErrorsToErrorDetails(resp.errors) as any,
              __typename: 'CommonResponse',
            },
            __typename: 'SignUpResponse',
          },
        },
      };
    },

    unsubscribeUser: unsubscribe,

    updateUserIdentity: async (
      firstname: string,
      lastname: string,
      email: string
    ): Promise<FetchResult<MutationType['updateUserInfo']>> => {
      ClientLogger.debug('usePvepApi.updateUserInfo', `fname = ${firstname} lname = ${lastname}`, DEBUG);
      const resp = await updateUserInfoCall(firstname, lastname, email);
      ClientLogger.debug('usePvepApi.updateUserInfoResp', `response = ${JSON.stringify(resp)}`, DEBUG);
      if (resp.data && resp.data.updateUserInfo.success) {
        setUserState({ ...currentUserState.current, firstName: firstname, lastName: lastname, email });
      }
      return resp;
    },

    updateUserRuntime: async (useRuntimeData: boolean): Promise<boolean> => {
      ClientLogger.debug('usePvepApi.updateUserRuntime', `useRuntimeData = ${useRuntimeData} `, DEBUG);
      const resp = await setUserState({ ...currentUserState.current, useRuntimeData });
      ClientLogger.debug('usePvepApi.updateUserRuntime', `response = ${JSON.stringify(resp)}`, DEBUG);
      return resp;
    },

    updateAdStrategyPreview: async (experimentId: string, strategyId: string): Promise<boolean> => {
      ClientLogger.debug('usePvepApi.updateAdStrategyPreview', `experimentId=${experimentId} strategyId=${strategyId}`, DEBUG);
      const resp = await setUserState({ ...currentUserState.current, useAdStrategyPreview: { strategyId, experimentId } });
      ClientLogger.debug('usePvepApi.updateAdStrategyPreview', `response = ${JSON.stringify(resp)}`, DEBUG);
      return resp;
    },

    updateUserWatching: async (watchingData: {
      categoryId: string;
      seriesId: string;
      seasonId: string;
      videoId: string;
    }): Promise<boolean> => {
      const resp = await setUserState({
        ...currentUserState.current,
        watching: {
          ...watchingData,
        },
      });
      return resp;
    },

    updateUserSession: async (id: string, updated: number): Promise<boolean> => {
      const appSession = { id, updated };
      const resp = await setUserState({
        ...currentUserState.current,
        appSession,
      });
      return resp;
    },

    getDeviceId,

    updateUserPassword: async (
      email: string,
      password: string,
      currentPassword: string
    ): Promise<FetchResult<MutationType['updateUserPassword']>> => {
      ClientLogger.debug('usePvepApi.updateUserPassword', `current password = ${currentPassword} new password = ${password}`, DEBUG);
      const resp = await updateUserPasswordCall(email, password, currentPassword);
      ClientLogger.debug('usePvepApi.updateUserPasswordResp', `response = ${JSON.stringify(resp)}`, DEBUG);
      return resp;
    },

    softDeleteUserAccount: async (
      email: string,
      reasonsForDeletion: string[]
    ): Promise<FetchResult<MutationType['softDeleteUserAccount']>> => {
      DEBUG && ClientLogger.debug('usePvepApi.softDeleteUserAccount', `email = ${email}`, DEBUG);
      DEBUG && ClientLogger.debug('usePvepApi.softDeleteUserAccount', `deleteReasons = ${reasonsForDeletion}`, DEBUG);
      const userInput = {
        email,
        deleteAccountReasons: reasonsForDeletion,
      };
      const resp = await getClient().mutate({
        variables: { userInput },
        mutation: gql`
          mutation softDeleteUserAccount($userInput: UserDeleteInput) {
            softDeleteUserAccount(userInput: $userInput) {
              success
            }
          }
        `,
      });
      DEBUG && ClientLogger.debug('usePvepApi.softDeleteUserAccount', `response = ${JSON.stringify(resp)}`, DEBUG);
      return resp;
    },

    createVhxSubscriber: async (code: string): Promise<FetchResult<MutationType['subscribe']>> => {
      ClientLogger.debug('usePvepApi.createVhxSubscriber', `called with = ${JSON.stringify({ code })}`, DEBUG);
      const resp: FetchResult<MutationType['subscribe']> = await getClient().mutate({
        variables: {
          code,
        },
        mutation: gql`
          mutation SubscribeToVhx($code: String!) {
            subscribe(code: $code) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
      ClientLogger.debug('usePvepApi.createVhxSubscriber', `SubscribeToVhx response = ${JSON.stringify(resp)}`, DEBUG);
      if (resp.data && resp.data.subscribe.success) {
        setUserState({ ...currentUserState.current });
      } else {
        ClientLogger.error('usePvepApi.createVhxSubscriber', `SubscribeToVhx failed ${JSON.stringify(resp)}`, resp.errors);
      }
      return resp;
    },

    getCouponCodes: async (): Promise<ApolloQueryResult<QueryType['coupons']>> => {
      return getClient().query({
        query: gql`
          query GetCoupons {
            coupons {
              code
            }
          }
        `,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
    },

    createCouponCode: async (code: string): Promise<FetchResult<MutationType['createOneCoupon']>> => {
      try {
        return await getClient().mutate({
          variables: {
            code,
          },
          mutation: gql`
            mutation createOneCoupon($code: String!) {
              createOneCoupon(code: $code) {
                success
                errorMessages {
                  errorCode
                  errorMessage
                }
              }
            }
          `,
          errorPolicy: 'all',
        });
      } catch (error) {
        return error as any;
      }
    },

    deleteOneCoupon: async (code: string): Promise<FetchResult<MutationType['deleteOneCoupon']>> => {
      try {
        return await getClient().mutate({
          variables: {
            code,
          },
          mutation: gql`
            mutation deleteOneCoupon($code: String!) {
              deleteOneCoupon(code: $code) {
                success
                errorMessages {
                  errorCode
                  errorMessage
                }
              }
            }
          `,
        });
      } catch (error) {
        return error as any;
      }
    },

    getPPVData: async (videoId: number, email?: string): Promise<ApolloQueryResult<QueryType['getPPVData']>> => {
      return await getClient().query({
        variables: {
          videoId,
          email,
        },
        query: gql`
          query getPPVData($videoId: Int!, $email: String) {
            getPPVData(videoId: $videoId, email: $email) {
              success
              ppvProductId
              ppvProductTitle
              ppvVariantId
              ppvVariantTitle
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        context: { noAuth: !email },
      });
    },

    getVideoToken: async (videoId: number): Promise<ApolloQueryResult<QueryType['getVideoToken']>> => {
      return await getClient().query({
        variables: {
          videoId,
        },
        query: gql`
          query getVideoToken($videoId: Int!) {
            getVideoToken(videoId: $videoId) {
              success
              token
              ppvProductId
              ppvProductTitle
              ppvVariantId
              ppvVariantTitle
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
    },

    getMe: async (): Promise<ApolloQueryResult<QueryType['me']>> => {
      try {
        DEBUG &&
          ClientLogger.debug(`usePvepApi.getMe`, 'called', true, {
            userState: currentUserState.current,
            exp: tokenExpiryReadable(currentUserState.current),
          });
        const ret = await getClient().query({
          query: gql`
            query me {
              me(email: "${currentUserState.current.email}") {
                email
                firstname
                id
                lastname
                phone
                subscriptionChannel
                subscriptionExpiry
                vhxId
                accountStatus
              }
            }
          `,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
        });
        return ret;
      } catch (e) {
        ClientLogger.error('me', 'Error caught', e);
        return {
          data: {} as any,
          errors: [e as any],
          loading: false,
          networkStatus: NetworkStatus.error,
        };
      }
    },

    getServerInfo: async (serverUrls?: string): Promise<ApolloQueryResult<QueryType['serverInfo']>> => {
      try {
        const ret = await getClient(serverUrls).query({
          query: gql`
            query serverInfo {
              serverInfo {
                branch
                branchType
                environment
                buildId
                commit
                dbConnectTest
                serverBuildDate
                awsAccessKeyId
                isAdmin
                isLoggedIn
              }
            }
          `,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          context: { noAuth: true },
        });
        return ret;
      } catch (e) {
        ClientLogger.error('getServerInfo', 'Error caught', e);
        return {
          data: {} as any,
          errors: [e as any],
          loading: false,
          networkStatus: NetworkStatus.error,
        };
      }
    },

    consoleTest: async (): Promise<FetchResult<CommonResponse>> => {
      return getClient().mutate({
        mutation: gql`
          mutation consoleTest {
            consoleTest {
              success
            }
          }
        `,
      });
    },

    requestMetadataUpdate: async (
      onlyOneBrand?: string,
      justProducts?: boolean,
      justVideos?: boolean,
      justLabels?: boolean,
      skipImages?: boolean
    ): Promise<FetchResult<MutationType['requestMetadataUpdate']>> => {
      DEBUG &&
        ClientLogger.debug(
          'usePvepApi.requestMetadataUpdate',
          `called with ${JSON.stringify({ onlyOneBrand, justProducts, justVideos, justLabels, skipImages })}`,
          DEBUG
        );
      return await getClient().mutate({
        variables: {
          onlyOneBrand,
          justProducts,
          justVideos,
          justLabels,
          skipImages,
        },
        mutation: gql`
          mutation requestMetadataUpdate(
            $onlyOneBrand: String
            $justProducts: Boolean
            $justVideos: Boolean
            $justLabels: Boolean
            $skipImages: Boolean
          ) {
            requestMetadataUpdate(
              onlyOneBrand: $onlyOneBrand
              justProducts: $justProducts
              justVideos: $justVideos
              justLabels: $justLabels
              skipImages: $skipImages
            ) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    },

    rebuildBranch: async (): Promise<FetchResult<MutationType['rebuildBranch']>> => {
      DEBUG && ClientLogger.debug('usePvepApi.rebuildBranch', `called`, DEBUG);
      return await getClient().mutate({
        mutation: gql`
          mutation rebuildBranch {
            rebuildBranch {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    },

    createEmptyCommit: async (): Promise<FetchResult<MutationType['createEmptyCommit']>> => {
      DEBUG && ClientLogger.debug('usePvepApi.createEmptyCommit', `called`, DEBUG);
      return await getClient().mutate({
        mutation: gql`
          mutation createEmptyCommit {
            createEmptyCommit {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
      });
    },

    submitContactForm: async (email: string, message: string, name?: string, country?: string, phoneNumber?: string) => {
      ClientLogger.debug(
        'usePvepApi.submitContactForm',
        `name = ${name} email = ${email} country = ${country} phoneNumber = ${phoneNumber} message = ${message}`,
        DEBUG
      );
      const resp = await submitContactFormCall(email, message, name, country, phoneNumber);
      ClientLogger.debug('usePvepApi.submitContactFormResp', `response = ${JSON.stringify(resp)}`, DEBUG);
      return resp;
    },

    submitEmailListForm: async (
      firstName: string,
      lastName: string,
      email: string,
      language: string
    ): Promise<FetchResult<MutationType['submitEmailListForm']>> => {
      DEBUG &&
        ClientLogger.debug(
          'usePvepApi.submitEmailListForm',
          `first name = ${firstName} last name = ${lastName} email = ${email} language = ${language}`,
          DEBUG
        );
      try {
        return await getClient().mutate({
          variables: {
            firstName,
            lastName,
            email,
            language,
          },
          mutation: gql`
            mutation submitEmailListForm($firstName: String, $lastName: String, $email: String, $language: String) {
              submitEmailListForm(firstName: $firstName, lastName: $lastName, email: $email, language: $language) {
                success
                errorMessages {
                  errorCode
                  errorMessage
                }
              }
            }
          `,
          context: { noAuth: true },
        });
      } catch (error) {
        ClientLogger.error('usePvepApi.submitEmailListForm', 'Catch', error);
        return error as any;
      }
    },

    getS3UploadUrl: async (bucket: string, fileName: string, fileType: string): Promise<FetchResult<QueryType['getS3UploadUrl']>> => {
      return getClient().query({
        query: gql`
          query getS3UploadUrl($bucket: String!, $fileName: String!, $fileType: String!) {
            getS3UploadUrl(bucket: $bucket, fileName: $fileName, fileType: $fileType) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              url
            }
          }
        `,
        variables: { bucket, fileName, fileType },
      });
    },

    getS3BucketContents: async (bucket: string, brandId: string): Promise<FetchResult<QueryType['getS3BucketContents']>> => {
      return getClient().query({
        query: gql`
          query getS3BucketContents($bucket: String!, $brandId: String!) {
            getS3BucketContents(bucket: $bucket, brandId: $brandId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              contents {
                fileName
                size
              }
            }
          }
        `,
        variables: { bucket, brandId },
        fetchPolicy: 'no-cache',
      });
    },

    getVideoBucketContents: async (): Promise<FetchResult<QueryType['getVideoBucketContents']>> => {
      return getClient().query({
        query: gql`
          query getVideoBucketContents {
            getVideoBucketContents {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              contents {
                fileName
                size
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    deleteS3Video: async (fileName: string): Promise<FetchResult<MutationType['deleteS3Video']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteS3Video($fileName: String!) {
            deleteS3Video(fileName: $fileName) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { fileName },
        fetchPolicy: 'no-cache',
      });
    },

    getSignedVideoUrl: async (url: string): Promise<FetchResult<QueryType['getSignedVideoUrl']>> => {
      return getClient().query({
        query: gql`
          query getSignedVideoUrl($url: String!) {
            getSignedVideoUrl(url: $url) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              url
            }
          }
        `,
        variables: { url },
        fetchPolicy: 'no-cache',
      });
    },

    startObjectRecognition: async (bucket: string, fileName: string): Promise<FetchResult<MutationType['startObjectRecognition']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation startObjectRecognition($bucket: String!, $fileName: String!) {
            startObjectRecognition(bucket: $bucket, fileName: $fileName) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              jobId
            }
          }
        `,
        variables: { bucket, fileName },
        fetchPolicy: 'no-cache',
      });
    },

    getObjectRecognitionData: async (
      jobId: string,
      brandId: string,
      fileName: string
    ): Promise<FetchResult<QueryType['getObjectRecognitionData']>> => {
      return getClient().query({
        query: gql`
          query getObjectRecognitionData($jobId: String, $brandId: String!, $fileName: String!) {
            getObjectRecognitionData(jobId: $jobId, brandId: $brandId, fileName: $fileName) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { jobId, brandId, fileName },
        fetchPolicy: 'no-cache',
      });
    },

    getSQSMessages: async (queueUrl: string): Promise<FetchResult<QueryType['getSQSMessages']>> => {
      return getClient().query({
        query: gql`
          query getSQSMessages($queueUrl: String) {
            getSQSMessages(queueUrl: $queueUrl) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              messages {
                id
                body
              }
            }
          }
        `,
        variables: { queueUrl },
        fetchPolicy: 'no-cache',
      });
    },

    getObjectRecognitionJobs: async (brandId?: string): Promise<FetchResult<QueryType['getObjectRecognitionJobs']>> => {
      return getClient().query({
        query: gql`
          query getObjectRecognitionJobs($brandId: String) {
            getObjectRecognitionJobs(brandId: $brandId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              jobs {
                jobId
                jobType
                createdAt
                updatedAt
                status
                brandId
                payload
              }
            }
          }
        `,
        variables: { brandId },
        fetchPolicy: 'no-cache',
      });
    },

    queryObjectRecognitionStatus: async (jobIds: string[]): Promise<FetchResult<QueryType['queryObjectRecognitionStatus']>> => {
      return getClient().query({
        query: gql`
          query queryObjectRecognitionStatus($jobIds: [String]) {
            queryObjectRecognitionStatus(jobIds: $jobIds) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              jobs {
                jobId
                jobStatus
              }
            }
          }
        `,
        variables: { jobIds },
        fetchPolicy: 'no-cache',
      });
    },

    getProcessedObjectData: async (brandId: string, fileName: string): Promise<FetchResult<QueryType['getProcessedObjectData']>> => {
      return getClient().query({
        query: gql`
          query getProcessedObjectData($brandId: String, $fileName: String) {
            getProcessedObjectData(brandId: $brandId, fileName: $fileName) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              labels {
                name
                timeStamps
                instances {
                  boxes {
                    top
                    left
                    width
                    height
                  }
                  timeStamp
                }
              }
              timeline {
                timeStamp
                labels
              }
            }
          }
        `,
        variables: { brandId, fileName },
        fetchPolicy: 'no-cache',
      });
    },

    getProductCollectionData: async (): Promise<
      FetchResult<QueryType['getProductCollectionsFromShopify'] & QueryType['getIncludedProductCollections']>
    > => {
      return getClient().query({
        query: gql`
          query getProductCollectionData {
            getIncludedProductCollections {
              success
              productCollections {
                id
                title
              }
            }
            getProductCollectionsFromShopify {
              success
              productCollections {
                id
                title
              }
            }
          }
        `,
      });
    },

    writeIncludedProductCollections: async (content: string): Promise<FetchResult<MutationType['writeIncludedProductCollections']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeIncludedProductCollections($content: String) {
            writeIncludedProductCollections(content: $content) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { content },
        fetchPolicy: 'no-cache',
      });
    },

    getVideoData: async (): Promise<FetchResult<QueryType['getVideosFile']>> => {
      return getClient().query({
        query: gql`
          query getVideosFile {
            getVideosFile {
              success
              errorMessages {
                errorMessage
              }
              videos {
                id
                title
                description
                short_description
                season_id
                series_id
                episode
                url
                categories
                is_available
                is_free
                thumbnail {
                  source
                  fileName
                }
                seriesData {
                  seriesId
                  seasonId
                  episodeId
                }
                metadata {
                  extUrl
                  extSource
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
    },

    updateVideoData: async (
      id: string,
      title: string,
      description: string,
      shortDescription: string,
      url: string,
      categories: string[],
      is_available: boolean,
      is_free: boolean,
      seriesData: [],
      metadata: {
        extUrl: string;
        extSource: string;
      },
      thumbnail?: {
        previousFileName: string;
        fileName: string;
        data: string;
      }
    ): Promise<FetchResult<MutationType['updateVideo']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation updateVideo($video: VideoInput) {
            updateVideo(VideoInput: $video) {
              success
              errorMessages {
                errorMessage
              }
            }
          }
        `,
        variables: {
          video: {
            id,
            title,
            name: title,
            description,
            short_description: shortDescription,
            url,
            seriesData,
            categories,
            metadata: {
              extUrl: metadata.extUrl,
              extSource: metadata.extSource,
            },
            thumbnail: thumbnail
              ? {
                  data: thumbnail.data,
                  fileName: thumbnail.fileName,
                  previousFileName: thumbnail.previousFileName,
                }
              : null,
            is_available,
            is_free,
          },
        },
        fetchPolicy: 'no-cache',
      });
    },

    getCollections: async (): Promise<FetchResult<QueryType['getCollections']>> => {
      return getClient().query({
        query: gql`
          query getCollections {
            getCollections {
              success
              errorMessages {
                errorMessage
              }
              series {
                id
                name
                description
                localThumbnail
                items {
                  id
                  name
                  items {
                    id
                    title
                    description
                  }
                }
              }
              categories {
                id
                name
                description
                items {
                  id
                  type
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
    },

    saveSeries: async (
      title: string,
      description: string,
      base64Thumbnail: string,
      fileName: string
    ): Promise<FetchResult<MutationType['saveSeries']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation saveSeries($collection: AddSeriesInput) {
            saveSeries(AddSeriesInput: $collection) {
              success
              errorMessages {
                errorMessage
              }
            }
          }
        `,
        variables: {
          collection: {
            title,
            description,
            thumbnail: {
              previousFileName: fileName,
              fileName,
              data: base64Thumbnail,
            },
          },
        },
        fetchPolicy: 'no-cache',
      });
    },

    saveSeason: async (series_id: string, title?: string, description?: string): Promise<FetchResult<MutationType['saveSeason']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation saveSeason($season: SeasonInput) {
            saveSeason(SeasonInput: $season) {
              success
              id
              errorMessages {
                errorMessage
              }
            }
          }
        `,
        variables: {
          season: {
            series_id,
            title,
            description,
            items: [],
            id: '',
          },
        },
        fetchPolicy: 'no-cache',
      });
    },

    getExternalVideoData: async (platform: string, videoId: string): Promise<FetchResult<QueryType['getExternalVideoData']>> => {
      return getClient().query({
        query: gql`
          query getExternalVideoData($platform: String!, $videoId: String!) {
            getExternalVideoData(platform: $platform, videoId: $videoId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              video {
                title
                description
                shortDescription
                seriesId
                seasonNumber
                episodeNumber
                url
              }
            }
          }
        `,
        variables: {
          platform,
          videoId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    saveCategory: async (title: string): Promise<FetchResult<MutationType['saveCategory']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation saveCategory($title: String) {
            saveCategory(title: $title) {
              id
              success
              errorMessages {
                errorMessage
              }
            }
          }
        `,
        variables: {
          title,
        },
        fetchPolicy: 'no-cache',
      });
    },

    updateSeries: async (seriesData: {
      id: string;
      title: string;
      description: string;
      seasons: {
        id: string;
        items: string[];
      }[];
      categoryIds: string[];
      thumbnail?: {
        previousFileName: string;
        fileName: string;
        data: string;
      };
    }): Promise<FetchResult<MutationType['updateSeries']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation updateSeries($seriesData: SeriesInput!) {
            updateSeries(seriesData: $seriesData) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          seriesData,
        },
        fetchPolicy: 'no-cache',
      });
    },

    getVideoImageFile: async (fileName: string): Promise<FetchResult<QueryType['getVideoImageFile']>> => {
      return getClient().query({
        query: gql`
          query getVideoImageFile($fileName: String!) {
            getVideoImageFile(fileName: $fileName) {
              success
              errorMessages {
                errorMessage
                errorCode
              }
              imageData
            }
          }
        `,
        variables: {
          fileName,
        },
        fetchPolicy: 'no-cache',
      });
    },

    refreshFourIDataToken: async (): Promise<FetchResult<QueryType['refreshFourIDataToken']>> => {
      return getClient().query({
        query: gql`
          query refreshFourIDataToken {
            refreshFourIDataToken {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              accessToken
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
    },

    getStrategy: async (strategyId: string): Promise<FetchResult<QueryType['getStrategy']>> => {
      return getClient().query({
        query: gql`
          query getStrategy($strategyId: String!) {
            getStrategy(strategyId: $strategyId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              strategy {
                id
                title
                bannerInterval
                individualAssignments {
                  id
                  name
                  adType
                  productId
                  startTimeIndex
                  source
                  rank
                  status
                  isRecommendation
                }
                multipleAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
                collectionAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
              }
            }
          }
        `,
        variables: {
          strategyId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    getStrategies: async (): Promise<FetchResult<QueryType['getStrategies']>> => {
      return getClient().query({
        query: gql`
          query getStrategies {
            getStrategies {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              strategies {
                id
                title
                bannerInterval
                individualAssignments {
                  id
                  name
                  adType
                  productId
                  startTimeIndex
                  source
                  rank
                  status
                  isRecommendation
                }
                multipleAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
                collectionAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    writeStrategy: async (strategy: Strategy): Promise<FetchResult<MutationType['writeStrategy']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeStrategy($strategy: StrategyInput!) {
            writeStrategy(strategy: $strategy) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              strategyId
            }
          }
        `,
        variables: {
          strategy,
        },
        fetchPolicy: 'no-cache',
      });
    },

    duplicateStrategy: async (strategyId: string): Promise<FetchResult<MutationType['duplicateStrategy']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation duplicateStrategy($strategyId: String!) {
            duplicateStrategy(strategyId: $strategyId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              strategyId
            }
          }
        `,
        variables: {
          strategyId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    deleteStrategy: async (strategyId: string): Promise<FetchResult<MutationType['deleteStrategy']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteStrategy($strategyId: String!) {
            deleteStrategy(strategyId: $strategyId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          strategyId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    seedStrategies: async (strategies: Strategy[]): Promise<FetchResult<MutationType['seedStrategies']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation seedStrategies($strategies: [StrategyInput!]!) {
            seedStrategies(strategies: $strategies) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          strategies,
        },
        fetchPolicy: 'no-cache',
      });
    },

    getExperiments: async (): Promise<FetchResult<QueryType['getExperiments']>> => {
      return getClient().query({
        query: gql`
          query getExperiments {
            getExperiments {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              experiments {
                id
                title
                videoId
                videoCollectionId
                items {
                  id
                  experimentId
                  strategyId
                  rangePercentage
                  ranges {
                    id
                    experimentItemId
                    start
                    end
                  }
                }
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    getExperimentDataByVideo: async (videoId: string): Promise<FetchResult<QueryType['getExperimentDataByVideo']>> => {
      return getClient().query({
        query: gql`
          query getExperimentDataByVideo($videoId: String!) {
            getExperimentDataByVideo(videoId: $videoId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              experiment {
                id
                title
                videoId
                videoCollectionId
                items {
                  id
                  experimentId
                  strategyId
                  rangePercentage
                  ranges {
                    id
                    experimentItemId
                    start
                    end
                  }
                }
              }
              strategies {
                id
                title
                bannerInterval
                individualAssignments {
                  id
                  name
                  adType
                  productId
                  startTimeIndex
                  source
                  rank
                  status
                  isRecommendation
                }
                multipleAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
                collectionAssignments {
                  id
                  name
                  adType
                  productCollectionId
                  rank
                }
              }
            }
          }
        `,
        variables: {
          videoId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    writeExperiment: async (experiment: ExperimentInput): Promise<FetchResult<MutationType['writeExperiment']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeExperiment($experiment: ExperimentInput!) {
            writeExperiment(experiment: $experiment) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              experimentId
            }
          }
        `,
        variables: {
          experiment,
        },
        fetchPolicy: 'no-cache',
      });
    },

    deleteExperiment: async (experimentId: string): Promise<FetchResult<MutationType['deleteExperiment']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteExperiment($experimentId: String!) {
            deleteExperiment(experimentId: $experimentId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          experimentId,
        },
        fetchPolicy: 'no-cache',
      });
    },

    seedExperiments: async (experiments: ExperimentInput[]): Promise<FetchResult<MutationType['seedExperiments']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation seedExperiments($experiments: [ExperimentInput!]!) {
            seedExperiments(experiments: $experiments) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          experiments,
        },
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseVideos: async (): Promise<FetchResult<QueryType['getDatabaseVideos']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseVideos {
            getDatabaseVideos {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              videos {
                id
                externalId
                createdAt
                updatedAt
                title
                label
                description
                shortDescription
                isAvailable
                isFree
                type
                url
                thumbnailUrl
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseVideo: async (videoId: string): Promise<FetchResult<QueryType['getDatabaseVideo']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseVideo($videoId: String!) {
            getDatabaseVideo(videoId: $videoId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              video {
                id
                externalId
                createdAt
                updatedAt
                title
                label
                description
                shortDescription
                isAvailable
                isFree
                type
                url
                thumbnailUrl
              }
            }
          }
        `,
        variables: { videoId },
        fetchPolicy: 'no-cache',
      });
    },

    getUniqueVideoId: async (): Promise<FetchResult<QueryType['getUniqueVideoId']>> => {
      return getClient().query({
        query: gql`
          query getUniqueVideoId {
            getUniqueVideoId {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              id
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    writeDatabaseVideo: async (video: DatabaseVideo): Promise<FetchResult<MutationType['writeDatabaseVideo']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeDatabaseVideo($video: DatabaseVideoInput!) {
            writeDatabaseVideo(video: $video) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: {
          video,
        },
        fetchPolicy: 'no-cache',
      });
    },

    deleteDatabaseVideo: async (videoId: string): Promise<FetchResult<MutationType['deleteDatabaseVideo']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteDatabaseVideo($videoId: String!) {
            deleteDatabaseVideo(videoId: $videoId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { videoId },
        fetchPolicy: 'no-cache',
      });
    },

    seedVideoDatabase: async (
      sourceBranch: string,
      sourceBucket: string,
      videos: DatabaseVideo[]
    ): Promise<FetchResult<MutationType['seedVideoDatabase']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation seedVideoDatabase($videos: [DatabaseVideoInput!]!, $sourceBranch: String!, $sourceBucket: String!) {
            seedVideoDatabase(videos: $videos, sourceBranch: $sourceBranch, sourceBucket: $sourceBucket) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { videos, sourceBranch, sourceBucket },
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseSeries: async (): Promise<FetchResult<QueryType['getDatabaseSeries']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseSeries {
            getDatabaseSeries {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              series {
                id
                title
                description
                shortDescription
                isAvailable
                isFree
                thumbnailUrl
                items {
                  id
                  seriesId
                  seasonId
                  rank
                }
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    getAllDatabaseSeriesInfo: async (seriesId: string): Promise<FetchResult<QueryType['getAllDatabaseSeriesInfo']>> => {
      return getClient().query({
        query: gql`
          query getAllDatabaseSeriesInfo($seriesId: String!) {
            getAllDatabaseSeriesInfo(seriesId: $seriesId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              series {
                id
                title
                description
                shortDescription
                isAvailable
                isFree
                thumbnailUrl
                items {
                  id
                  seriesId
                  seasonId
                  rank
                }
              }
              seasons {
                id
                title
                items {
                  id
                  videoId
                  seasonId
                  rank
                }
              }
              videos {
                id
                createdAt
                updatedAt
                title
                label
                description
                shortDescription
                isAvailable
                isFree
                url
                thumbnailUrl
                type
              }
            }
          }
        `,
        variables: { seriesId },
        fetchPolicy: 'no-cache',
      });
    },

    writeDatabaseSeries: async (series: any): Promise<FetchResult<MutationType['writeDatabaseSeries']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeDatabaseSeries($series: DatabaseSeriesInput!) {
            writeDatabaseSeries(series: $series) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { series },
        fetchPolicy: 'no-cache',
      });
    },

    writeDatabaseSeriesAndSeasons: async (
      series: any,
      seasons: any[]
    ): Promise<FetchResult<MutationType['writeDatabaseSeriesAndSeasons']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeDatabaseSeriesAndSeasons($series: DatabaseSeriesInput!, $seasons: [DatabaseSeasonInput!]!) {
            writeDatabaseSeriesAndSeasons(series: $series, seasons: $seasons) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              seriesId
            }
          }
        `,
        variables: { series, seasons },
        fetchPolicy: 'no-cache',
      });
    },

    deleteDatabaseSeries: async (seriesId: string): Promise<FetchResult<MutationType['deleteDatabaseSeries']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteDatabaseSeries($seriesId: String!) {
            deleteDatabaseSeries(seriesId: $seriesId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { seriesId },
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseSeasons: async (): Promise<FetchResult<QueryType['getDatabaseSeasons']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseSeasons {
            getDatabaseSeasons {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              seasons {
                id
                title
                items {
                  id
                  videoId
                  seasonId
                  rank
                }
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseCategories: async (): Promise<FetchResult<QueryType['getDatabaseCategories']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseCategories {
            getDatabaseCategories {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              categories {
                id
                title
                isAvailable
                isFree
                isFeatured
                thumbnailUrl
                rank
                items {
                  id
                  categoryId
                  seriesId
                  videoId
                  rank
                }
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'no-cache',
      });
    },

    getDatabaseCategoryById: async (categoryId: string): Promise<FetchResult<QueryType['getDatabaseCategoryById']>> => {
      return getClient().query({
        query: gql`
          query getDatabaseCategoryById($categoryId: String!) {
            getDatabaseCategoryById(categoryId: $categoryId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              category {
                id
                title
                isAvailable
                isFree
                isFeatured
                thumbnailUrl
                rank
                items {
                  id
                  categoryId
                  seriesId
                  videoId
                  rank
                }
              }
            }
          }
        `,
        variables: { categoryId },
        fetchPolicy: 'no-cache',
      });
    },

    writeDatabaseCategory: async (category: any): Promise<FetchResult<MutationType['writeDatabaseCategory']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation writeDatabaseCategory($category: DatabaseCategoryInput!) {
            writeDatabaseCategory(category: $category) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              categoryId
            }
          }
        `,
        variables: { category },
        fetchPolicy: 'no-cache',
      });
    },

    reorderDatabaseCategories: async (categories: DatabaseCategory[]): Promise<FetchResult<MutationType['reorderDatabaseCategories']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation reorderDatabaseCategories($categories: [DatabaseCategoryInput!]!) {
            reorderDatabaseCategories(categories: $categories) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { categories },
        fetchPolicy: 'no-cache',
      });
    },

    deleteDatabaseCategory: async (categoryId: string): Promise<FetchResult<MutationType['deleteDatabaseCategory']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation deleteDatabaseCategory($categoryId: String!) {
            deleteDatabaseCategory(categoryId: $categoryId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { categoryId },
        fetchPolicy: 'no-cache',
      });
    },

    seedVideoCollectionDatabase: async (
      sourceBranch: string,
      sourceBucket: string,
      categories: any[],
      series: any[],
      seasons: any[]
    ): Promise<FetchResult<MutationType['seedVideoCollectionDatabase']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation seedVideoCollectionDatabase(
            $sourceBranch: String!
            $sourceBucket: String!
            $categories: [DatabaseCategoryInput!]!
            $series: [DatabaseSeriesInput!]!
            $seasons: [DatabaseSeasonInput!]!
          ) {
            seedVideoCollectionDatabase(
              sourceBranch: $sourceBranch
              sourceBucket: $sourceBucket
              categories: $categories
              series: $series
              seasons: $seasons
            ) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { sourceBranch, sourceBucket, categories, series, seasons },
        fetchPolicy: 'no-cache',
      });
    },

    giveFeedback: async (type: string, feedback: string, details: string): Promise<FetchResult<MutationType['giveFeedback']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation giveFeedback($type: String!, $feedback: String!, $details: String!) {
            giveFeedback(type: $type, feedback: $feedback, details: $details) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
            }
          }
        `,
        variables: { type, feedback, details },
        fetchPolicy: 'no-cache',
        context: { noAuth: true },
      });
    },

    compressS3Image: async (
      bucket: string,
      keyPath: string,
      fileName: string,
      contentType: string
    ): Promise<FetchResult<MutationType['compressS3Image']>> => {
      return getClient().mutate({
        mutation: gql`
          mutation compressS3Image($bucket: String!, $keyPath: String!, $fileName: String!, $contentType: String!) {
            compressS3Image(bucket: $bucket, keyPath: $keyPath, fileName: $fileName, contentType: $contentType) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              thumbnailUrl
            }
          }
        `,
        variables: { bucket, keyPath, fileName, contentType },
        fetchPolicy: 'no-cache',
      });
    },

    getRecommendedProducts: async (anonymousId: string, isLoggedIn: boolean): Promise<FetchResult<QueryType['getRecommendedProducts']>> => {
      return getClient().query({
        query: gql`
          query getRecommendedProducts($anonymousId: String!) {
            getRecommendedProducts(anonymousId: $anonymousId) {
              success
              errorMessages {
                errorCode
                errorMessage
              }
              recommendations {
                id
              }
            }
          }
        `,
        variables: { anonymousId },
        context: { noAuth: !isLoggedIn },
        fetchPolicy: 'no-cache',
      });
    },
  };
};
