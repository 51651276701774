import React, { useState } from 'react';
import {
  tabItemLabel,
  hideText,
  panelTabsContainerClosed,
  panelTabToggleClosed,
  panelTabToggle,
  activeTabStyle,
  tabItemContainer,
  tabItemIcon,
  tabGroupLabel,
  tabGroupContainer,
  panelTabsContainer,
} from './styles.module.scss';
import classNames from 'classnames';
import { Icon } from '@iconify/react';

const DEBUG = false;

interface Props {
  groups: PanelTabGroup[];
  onTabClick: (id: string) => void;
  activeTab: string;
}

export interface PanelTabItem {
  label: string;
  icon: React.ReactNode;
  id: string;
}

export interface PanelTabGroup {
  items: PanelTabItem[];
  label: string;
}

export const PanelTabs = ({ groups, onTabClick, activeTab }: Props) => {
  const [open, setOpen] = useState(true);

  return (
    <div className={classNames(panelTabsContainer, open ? '' : panelTabsContainerClosed)}>
      <div className={classNames(panelTabToggle, open ? '' : panelTabToggleClosed)} onClick={() => setOpen(!open)}>
        <Icon icon="heroicons-solid:chevron-double-left" />
      </div>

      {groups.map((group, i) => (
        <div key={i} className={tabGroupContainer}>
          <div className={classNames(tabGroupLabel, open ? '' : hideText)}>{group.label}</div>

          {group.items.map(item => (
            <div
              key={item.id}
              onClick={() => onTabClick(item.id)}
              className={classNames(tabItemContainer, activeTab === item.id ? activeTabStyle : '')}
            >
              <div className={tabItemIcon}>{item.icon}</div>
              <div className={classNames(tabItemLabel, open ? '' : hideText)}>{item.label}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
