import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { emptyButtonFull, emptyButton } from './styles.module.scss';

interface IconButtonProps {
  children: ReactNode;
  onClick?: () => void;
  className?: any;
  fullWidth?: boolean;
  dataCY?: string;
  onTouchStart?: () => void;
}

export const IconButton = ({ className, onClick, fullWidth, dataCY, children, onTouchStart }: IconButtonProps) => {
  const fullWidthStyle = fullWidth ? emptyButtonFull : null;
  return (
    <div
      data-cy={dataCY}
      className={classNames(emptyButton, fullWidthStyle, className)}
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      onTouchStart={onTouchStart}
    >
      {children}
    </div>
  );
};
