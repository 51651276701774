/* eslint-disable camelcase */
import React, { ChangeEvent, useRef, useState, useEffect, useContext } from 'react';
import IndexLayout from '@layouts/index';
import { Img } from '@components/Img';
import { Button, ButtonStyles, IconButton } from '@components/Buttons';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { User } from '@apiClient/gql-types';
import { MeStateContext } from '@lib/meContext';
import { ClientLogger } from '@lib/ClientLogger';
import { usePvepApi } from '@apiClient/usePvepApi';
import { BrandUtil } from '@sharedLib/index';
import { SubscribeForm } from './SubscribeForm/SubscribeForm';
import { NavStateContext } from '@lib/navContext';
import { CmsCollectionFile } from 'netlify-cms-core';
import { usePageImage } from '@src/lib/usePageImage';
import { Countdown } from '@components/Countdown';
import { isMobile, isTablet } from '@src/lib/responsive';
import { isBrowser } from '@lib/build';

import {
  backgroundVideo,
  volumeControlsContainer,
  volumeControlsGroup,
  volumeSlider,
  muteButton,
  speakerIcon,
  landingGreeting,
  containerLanding,
  bottomTextContainer,
  innerWrapper,
  greetingText,
  greetingTextGroup,
  greetingTextLarge,
  allCaps,
  subscribeSection,
  subscribeText,
  subscribeButton,
  subscribeModal,
  subscribeModalBackground,
  modalCard,
  bannerSection,
  bannerText,
  bannerImage as bannerImageStyle,
  countdownTimer,
  countdownText,
  countdownComponent,
  homepageText,
  backgroundContainerTop,
  backgroundContainerBottom,
  greetingImageContainer,
  videoContainer,
  homepageSubtitle,
  countdownTimerTicker,
  backgroundNotch,
  footerImageContainer,
  footerTextContainer,
  footerTextHighlight,
  footerText,
  headlinerContainer,
  ticketsButton,
  mediaImageOne,
  mediaImageOneContainer,
  footerContainer,
  festivalDateContainer,
  festivalDate,
  festivalYear,
  locationTimer,
  headlinerTimer,
  mediaImageTwoContainer,
  mediaImagesContainer,
  mediaImageRow,
  mediaImageThreeContainer,
  mediaContainer,
  mediaImageFourContainer,
  mediaImageFiveContainer,
  mediaImageSixContainer,
  mediaImageSubRow,
  mediaPartnerText,
  mediaExclusiveTitle,
  mediaTimer,
  mediaPartnersContainer,
  mediaImageSevenContainer,
  volunteerHeaderContainer,
  volunteerFooterContainer,
  mediaImageEightContainer,
  mediaImageNineContainer,
  mediaImageSubRowOne,
  mediaImageSubRowTwo,
  mediaImageTenContainer,
  mediaImageElevenContainer,
} from './styles.module.scss';

const SHOW_CONTINUE_WATCHING = false;
const DEBUG = false;

///
/// CMS model
///
export const LandingPageCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/ftl/pages/LandingPage/landingPage.md`,
  label: 'Landing Page',
  name: 'landing_page',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/ftl/pages/LandingPage/LandingPage.tsx',
    },
    {
      label: 'Slogan',
      name: 'slogan',
      widget: 'text',
      required: true,
    },
    {
      label: 'Greeting',
      name: 'greeting',
      widget: 'image',
    },
    {
      label: 'Subscribe Text',
      name: 'subscribeText',
      widget: 'string',
    },
    {
      label: 'Subscribe CTA',
      name: 'subscribeCTA',
      widget: 'string',
    },
    {
      label: 'Banner Text',
      name: 'bannerText',
      widget: 'string',
    },
    {
      label: 'Banner',
      name: 'banner',
      widget: 'image',
    },
  ],
};

interface Props {
  slogan: string;
  greeting: string;
  subscribeText: string;
  subscribeCTA: string;
  bannerText: string;
  banner: string;
}

const LandingPage = (props: IPageProps<Props>) => {
  const pageData = props.pageContext.frontMatter;
  const greetingImage = usePageImage(pageData.greeting);
  const bannerImage = usePageImage(pageData.banner);
  const pageText = pageData.slogan.split('\n').filter(v => v.trim() !== '');
  const mobile = isBrowser && isMobile();
  const tablet = isBrowser && isTablet();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isMuted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0.3);
  const handleVolumechange = (e: ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      videoRef.current.volume = Number(e.target.value);
      setVolume(Number(e.target.value));
    }
  };
  const api = usePvepApi();
  const nav = useContext(NavStateContext);
  const userState = api.state;
  const meContext = useContext(MeStateContext);
  const brandInfo = BrandUtil.getSiteInfo();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const locationMessage = 'IndexPage.useEffect';
    DEBUG && ClientLogger.debug(locationMessage, 'useEffect', { userState, props });
    function checkSubscription(me: User) {
      if (me.accountStatus === 'active') {
        DEBUG && ClientLogger.debug(locationMessage, 'checkSubscription Navigating to /app');
        nav.setRoot({ path: '/app', title: 'Home' });
        if (SHOW_CONTINUE_WATCHING) {
          DEBUG && ClientLogger.debug(locationMessage, 'Convert code success. Get WatchingList');
          api
            .getWatchList(userState.email)
            .then(response => {
              DEBUG && ClientLogger.debug(locationMessage, `getWatchList Response = ${JSON.stringify(response)}`);
              nav.setRoot({ path: '/app', title: 'Home' });
            })
            .catch(error => {
              ClientLogger.error(locationMessage, 'getWatchList returns error', error);
              nav.setRoot({ path: '/app', title: 'Home' });
            });
        }
      } else {
        DEBUG && ClientLogger.debug(locationMessage, 'isLoggedIn = true. isSubscribed = false Navigating to /subscribe');
        nav.setRoot({ path: '/subscribe' });
      }
    }

    // Auto load the home screen if user is logged in  and brand  has  videoNeedsRegistration === false,
    if (userState.isLoggedIn && brandInfo.videoNeedsRegistration) {
      if (!meContext.me) {
        api
          .getMe()
          .then(meResp => {
            if (meResp.data?.me) {
              meContext.setMe(meResp.data.me);
              checkSubscription(meResp.data.me);
            } else {
              ClientLogger.error(locationMessage, `Expected to retrieve me userStart = ${JSON.stringify(userState)}`);
              nav.setRoot({ path: '/login', title: 'Login' });
            }
          })
          .catch(e => {
            ClientLogger.error(locationMessage, `getMe failed`, e);
            nav.setRoot({ path: '/login', title: 'Login' });
          });
      } else {
        checkSubscription(meContext.me);
      }
    }
  }, [userState, meContext.me]);

  const getRevealVideoElement = (): React.ReactNode => {
    return (
      <div className={videoContainer}>
        <video ref={videoRef} className={backgroundVideo} playsInline={true} autoPlay={true} muted={isMuted} loop={true}>
          <source
            src="https://player.vimeo.com/progressive_redirect/playback/683523379/rendition/1080p?loc=external&signature=3fd4cb22c71291ac38d7d10c4d9030d175ab3d8685f9d2515065d9a0a6983236"
            type="video/mp4"
          />
        </video>
        <div className={volumeControlsContainer}>
          <div className={volumeControlsGroup}>
            <IconButton className={muteButton} onClick={() => setMuted(!isMuted)}>
              <Icon className={speakerIcon} icon={isMuted ? 'ion:volume-mute' : 'ion:volume-high'} />
            </IconButton>
            {!isMuted && (
              <input
                className={volumeSlider}
                value={volume}
                type="range"
                min={0}
                max={1}
                step={0.1}
                onChange={e => handleVolumechange(e)}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <IndexLayout noVideo>
      <div className={backgroundContainerTop}>
        <div className={homepageText}>Toronto's downtown music & arts festival</div>

        <div className={volunteerHeaderContainer}>
          {!tablet && !mobile && (
            <img src="./FTL_assets/volunteerdesktop.png" alt="" onClick={() => nav.push({ path: '/volunteers', title: 'Volunteers' })} />
          )}
        </div>

        <div className={backgroundNotch} />

        <div className={greetingImageContainer}>
          <img className={landingGreeting} src="./FTL_assets/fieldtriplogolarge.png" alt="" />
        </div>
      </div>

      <div className={backgroundContainerBottom}>
        {isBrowser && (
          <>
            <Countdown
              hideTimer
              limit={new Date('Mar 22, 2022 10:30:00 GMT-0400').valueOf()}
              revealElement={
                <div className={festivalDateContainer}>
                  <div className={festivalDate}>SATURDAY JULY 9TH</div>
                  <div className={festivalYear}>2022</div>
                </div>
              }
            />

            <Countdown
              className={headlinerTimer}
              hideTimer
              limit={new Date('Mar 22, 2022 10:30:00 GMT-0400').valueOf()}
              revealElement={
                <div className={headlinerContainer}>
                  {!mobile && <img src="./FTL_assets/lineupFull.png" alt="" />}
                  {mobile && <img src="./FTL_assets/lineupMobile.png" alt="" />}
                </div>
              }
            />

            <Countdown
              hideTimer
              limit={new Date('Mar 23, 2022 10:00:00 GMT-0400').valueOf()}
              revealElement={
                <Button
                  className={ticketsButton}
                  style={ButtonStyles.Primary}
                  onClick={() => nav.push({ path: '/tickets', title: 'Tickets' })}
                >
                  BUY TICKETS
                </Button>
              }
            />

            <div className={countdownComponent}>
              <Countdown
                className={countdownTimer}
                tickerClassname={countdownTimerTicker}
                limit={new Date('Mar 22, 2022 10:00:00 GMT-0400').valueOf()}
                initialElement={<div className={countdownText}>Join us for our launch event</div>}
                revealElement={getRevealVideoElement()}
              />
            </div>

            <Countdown
              className={locationTimer}
              hideTimer
              limit={new Date('Mar 22, 2022 10:30:00 GMT-0400').valueOf()}
              revealElement={
                <>
                  <div className={volunteerFooterContainer}>
                    {(tablet || mobile) && (
                      <img
                        src="./FTL_assets/volunteermobile.png"
                        alt=""
                        onClick={() => nav.push({ path: '/volunteers', title: 'Volunteers' })}
                      />
                    )}
                  </div>

                  <div className={classNames(homepageText, homepageSubtitle)}>Historic Fort York & Garrison Common</div>
                </>
              }
            />
          </>
        )}
      </div>

      <div className={footerTextContainer}>
        <div className={footerText}>
          FAMILY <span className={footerTextHighlight}>/</span> ART <span className={footerTextHighlight}>/</span> FOOD DISCOVERY
        </div>
      </div>

      <div className={footerContainer}>
        <div className={footerImageContainer}>
          <img src="./FTL_assets/ftlfooter.png" alt="" />
        </div>
      </div>

      <div className={mediaContainer}>
        <div className={mediaImagesContainer}>
          <div>
            <div className={mediaExclusiveTitle}>EXCLUSIVE MEDIA PARTNER</div>
            <div className={mediaImageOneContainer}>
              <a href={`https://indie88.com/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner1.png" alt="" />
              </a>
            </div>
          </div>

          <div>
            <div className={mediaImageTwoContainer}>
              <a href={`https://www.alibientertainment.ca/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner2.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={mediaPartnersContainer}>
        <div className={mediaImageRow}>
          <div className={mediaImageSubRow}>
            <div className={mediaPartnerText}>FIELD TRIP PARTNERS</div>
          </div>

          <div className={mediaImageSubRow}>
            <div className={mediaImageThreeContainer}>
              <img className={mediaImageOne} src="./FTL_assets/mediapartner3.png" alt="" />
            </div>

            <div className={mediaImageFourContainer}>
              <a href={`https://www.tdmusic.com/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner4.png" alt="" />
              </a>
            </div>

            <div className={mediaImageFiveContainer}>
              <a href={`https://collectiveartsbrewing.com/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner5.png" alt="" />
              </a>
            </div>

            <div className={mediaImageSixContainer}>
              <a href={`https://townbrewery.ca/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner6.png" alt="" />
              </a>
            </div>
          </div>

          <div className={classNames(mediaImageSubRow, mediaImageSubRowOne)}>
            <div className={mediaImageNineContainer}>
              <a href={`https://exclaim.ca/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner9.png" alt="" />
              </a>
            </div>

            <div className={mediaImageEightContainer}>
              <a href={`https://www.blundstone.ca/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner8.png" alt="" />
              </a>
            </div>

            <div className={mediaImageSevenContainer}>
              <a href={`https://www.westendphoenix.com/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner7.png" alt="" />
              </a>
            </div>
          </div>

          <div className={classNames(mediaImageSubRow, mediaImageSubRowTwo)}>
            <div className={mediaImageTenContainer}>
              <a href={`https://cityseltzer.ca/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner10.png" alt="" />
              </a>
            </div>

            <div className={mediaImageElevenContainer}>
              <a href={`https://harmlessharvest.com/`} target="_blank" rel="noreferrer">
                <img className={mediaImageOne} src="./FTL_assets/mediapartner11.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={subscribeSection}>
        <div data-cy="subscribeText" className={subscribeText}>
          {pageData.subscribeText}
        </div>
        <Button dataCY="subscribeCTA" className={subscribeButton} onClick={() => setShowModal(true)}>
          {pageData.subscribeCTA}
        </Button>
      </div>

      {showModal && (
        <div className={subscribeModal}>
          <div className={subscribeModalBackground} onClick={() => setShowModal(false)} />
          <div className={modalCard}>
            <SubscribeForm imgUrl={greetingImage} successCallback={() => setShowModal(false)} />
          </div>
        </div>
      )}

      <div className={bannerSection}>
        <div data-cy="bannerText" className={bannerText}>
          {pageData.bannerText}
        </div>
        <Img childImageSharp={bannerImage.childImageSharp} className={bannerImageStyle} />
      </div>
    </IndexLayout>
  );
};

export default LandingPage;
