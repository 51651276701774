import { formatCurrency } from '@lib/adPlacements';
import React, { useContext, useRef } from 'react';
import { isMobile } from '@src/lib/responsive';
import { ShopifyProduct } from '@sharedLib/product';
import { DivButton } from '@components/Buttons';
import { ProductImg } from '@components/Img';
import classNames from 'classnames';
import useScrollButtons from '@lib/useScrollButtons';
import rightOutlined from '@iconify/icons-ant-design/right-outline';
import leftOutlined from '@iconify/icons-ant-design/left-outline';
import { Icon } from '@iconify/react';
import { NavStateContext } from '@src/lib/navContext';
import {
  productBox,
  productNameWrapper,
  productPriceWrapper,
  containerImage,
  productImage,
  containerInfo,
  productName,
  productPrice,
  searchListContainerHorizontal,
  textHeader,
  searchListGrid,
  left,
  scrollButton,
  right,
  buttonIcon,
} from './styles.module.scss';

interface Props {
  query: string;
  productHits: [];
  numberOfProductHits: number;
}

interface ProductProps {
  shopifyProduct: ShopifyProduct;
  query: string;
}

const Product = ({ shopifyProduct }: ProductProps) => {
  const nav = useContext(NavStateContext);
  const firstVariant = shopifyProduct.variants.length > 0 && shopifyProduct.variants[0];
  const price = firstVariant ? firstVariant.price : undefined;

  const goToProductPage = () => {
    const navData = nav.navState.pageData;
    if (navData.length > 0 && navData[navData.length - 1].path.split('/').includes('product')) {
      nav.replace({ path: `/product/${shopifyProduct.id.replace('Shopify__Product__', '')}`, title: shopifyProduct.title });
    } else {
      nav.push({ path: `/product/${shopifyProduct.id.replace('Shopify__Product__', '')}`, title: shopifyProduct.title });
    }
  };

  return (
    <div className={`box ${productBox}`} onClick={goToProductPage}>
      <DivButton className={containerImage}>
        <ProductImg product={shopifyProduct} className={productImage} imgStyle={{ objectFit: 'contain' }} />
      </DivButton>
      <div className={classNames(containerInfo)}>
        <div className={productNameWrapper}>
          <p className={productName}>{shopifyProduct.title}</p>
        </div>
        <div className={productPriceWrapper}>
          <p className={productPrice}>{formatCurrency(price)}</p>
        </div>
      </div>
    </div>
  );
};

const SearchProducts = ({ productHits, numberOfProductHits, query }: Props) => {
  const useMobile = isMobile();

  const productListElement = useRef<HTMLDivElement>(null);

  const { showScrollButtons, onScrollButtonClick } = useScrollButtons(productListElement, { scrollPercentage: 0.65, roundToNearest: 180 });
  return (
    <div key="searchProducts" className={classNames(searchListContainerHorizontal)}>
      <header className={textHeader}>Products - {numberOfProductHits} found</header>
      {Boolean(numberOfProductHits) && (
        <div data-cy="searchProducts" ref={productListElement} className={searchListGrid}>
          {productHits.map((hit: any) => {
            return <Product query={query} shopifyProduct={hit.node} key={hit.node.id} />;
          })}
        </div>
      )}
      {showScrollButtons && !useMobile && Boolean(numberOfProductHits) && (
        <>
          <DivButton
            className={classNames(scrollButton, left)}
            onClick={() => {
              onScrollButtonClick(true);
            }}
          >
            <Icon icon={leftOutlined} className={buttonIcon} />
          </DivButton>
          <DivButton
            className={classNames(scrollButton, right)}
            onClick={() => {
              onScrollButtonClick(false);
            }}
          >
            <Icon icon={rightOutlined} className={buttonIcon} />
          </DivButton>
        </>
      )}
    </div>
  );
};

export default SearchProducts;
