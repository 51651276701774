import React, { useEffect, useState } from 'react';
import { Strategy, alphabeticalOrder, DatabaseVideo, Experiment } from '@sharedLib/index';
import classNames from 'classnames';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as styles from './styles.module.scss';
import { FilterSearch } from '@components/Search';
import { ExperimentEditor } from './ExperimentEditor';
const DEBUG = false;

export const ExperimentVideoList = () => {
  const api = usePvepApi();

  const [videoFilter, setVideoFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState('');

  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [videoExperimentData, setVideoExperimentData] = useState<{ video: DatabaseVideo; experiment?: Experiment }[]>([]);

  useEffect(() => {
    getListData();
  }, []);

  ///
  /// get data from database
  ///
  const getListData = async () => {
    setLoading(true);

    const videoResp = await getDatabaseVideos();
    if (!videoResp.success) {
      setLoading(false);
      setLoadingError(videoResp.errorMessage || '');
      return;
    }

    const stratResp = await getStrategies();
    if (!stratResp.success) {
      setLoading(false);
      setLoadingError(stratResp.errorMessage || '');
      return;
    }

    const expResp = await getExperiments();
    if (!expResp.success) {
      setLoading(false);
      setLoadingError(expResp.errorMessage || '');
      return;
    }

    // add experiments to repective video objects
    const videoExperiments = videoResp.videos.map(vid => {
      const experiment = expResp.experiments.find(exp => exp.videoId === vid.id);
      return {
        video: vid,
        experiment,
      };
    });
    setVideoExperimentData(videoExperiments);
    setStrategies(stratResp.strategies);
    setLoading(false);
  };

  ///
  /// get videos from database
  ///
  const getDatabaseVideos = async (): Promise<{ success: boolean; videos: DatabaseVideo[]; errorMessage?: string }> => {
    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos: DatabaseVideo[] = videoResp.data.getDatabaseVideos.videos
        .map(video => {
          const newVid: any = video;
          delete newVid.__typename;
          return newVid;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      return {
        success: true,
        videos: databaseVideos,
      };
    } else {
      const errors = videoResp.data?.getDatabaseVideos.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      return {
        success: false,
        errorMessage: errorMessage || 'Something went wrong loading the videos from the database.',
        videos: [],
      };
    }
  };

  ///
  /// get strategies from database
  ///
  const getStrategies = async (): Promise<{ success: boolean; strategies: Strategy[]; errorMessage?: string }> => {
    const strategyResp = await api.getStrategies();
    if (strategyResp.data?.getStrategies.success) {
      const databaseStrategies: Strategy[] = strategyResp.data.getStrategies.strategies
        .map(strat => {
          const ias = strat.individualAssignments.map(ia => {
            const newIa: any = ia;
            delete newIa.__typename;
            return newIa;
          });

          const mas = strat.multipleAssignments.map(ma => {
            const newMa: any = ma;
            delete newMa.__typename;
            return newMa;
          });

          const cas = strat.collectionAssignments.map(ca => {
            const newCa: any = ca;
            delete newCa.__typename;
            return newCa;
          });

          strat.individualAssignments = ias;
          strat.multipleAssignments = mas;
          strat.collectionAssignments = cas;
          const newStrat: any = strat;
          delete newStrat.__typename;
          return newStrat;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      return {
        success: true,
        strategies: databaseStrategies,
      };
    } else {
      const errors = strategyResp.data?.getStrategies.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      return {
        success: true,
        errorMessage: errorMessage || 'Something went wrong loading the strategies from the database.',
        strategies: [],
      };
    }
  };

  ///
  /// get experiments from database
  ///
  const getExperiments = async (): Promise<{ success: boolean; experiments: Experiment[]; errorMessage?: string }> => {
    const experimentResp = await api.getExperiments();
    if (experimentResp.data?.getExperiments.success) {
      const databaseExperiments: Experiment[] = experimentResp.data.getExperiments.experiments.map(exp => {
        const items = exp.items.map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          return newItem;
        });
        exp.items = items;

        const newExp: any = exp;
        delete newExp.__typename;
        return newExp;
      });
      return {
        success: true,
        experiments: databaseExperiments,
      };
    } else {
      const errors = experimentResp.data?.getExperiments.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      return {
        success: false,
        errorMessage: errorMessage || 'Something went wrong loading the experiments from the database.',
        experiments: [],
      };
    }
  };

  return (
    <div className={''}>
      {loading && <div>Loading...</div>}
      {!loading && loadingError && <div>{loadingError}</div>}
      {!loading && (
        <div className={styles.listContainer}>
          <FilterSearch onChange={filter => setVideoFilter(filter)} placeholder="Search Videos" initialQuery={videoFilter} />

          <div className={styles.listRow}>
            <div className={classNames(styles.titleText, styles.listTitleColumn)}>Videos</div>
            <div className={styles.titleText}>Strategies</div>
          </div>

          {videoExperimentData
            .filter(entry => entry.video.title.toLowerCase().includes(videoFilter.trim().toLowerCase()))
            .map(entry => (
              <div className={styles.listRow} key={entry.video.id}>
                <div className={styles.listTitleColumn}>
                  <div className={styles.titleText}>{entry.video.title}</div>
                  <div className={styles.subtitleText}>
                    <span className={styles.darkenedText}>Video ID: </span> {entry.video.id}
                  </div>
                </div>
                <div className={''}>
                  <ExperimentEditor
                    experiment={entry.experiment}
                    strategies={strategies}
                    videoId={entry.video.id}
                    defaultTitle={`${entry.video.title} Assignment`}
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
