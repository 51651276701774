import React, { useEffect, useState } from 'react';
import { usePlayNext } from '@lib/video-query/useVideoData';
import { ClientLogger } from '@lib/ClientLogger';
import { DatabaseVideo } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';

interface Props {
  video: DatabaseVideo;
}

export const CastController = ({ video }: Props) => {
  const [currentVideo, setCurrentVideo] = useState(video);

  const [isConnected, setConnected] = useState(false);
  const [playerState, setPlayerState] = useState(null);
  const [remotePlayer, setRemotePlayer] = useState<cast.framework.RemotePlayer | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [remotePlayerController, setRemotePlayerController] = useState<cast.framework.RemotePlayerController | null>(null);
  const api = usePvepApi();
  const userState = api.state;
  const upNext = usePlayNext(
    userState.watching.videoId,
    userState.watching.seasonId,
    userState.watching.seriesId,
    userState.watching.categoryId
  );

  // initialize on mount and stop on unmount
  useEffect(() => {
    initializeCastPlayer();

    return () => {
      stopSrc();
    };
  }, []);

  // detect connection and video changes and load/stop accordingly
  useEffect(() => {
    if (isConnected) {
      castSrc(currentVideo.url, currentVideo.title);
    } else {
      stopSrc();
    }
  }, [isConnected, currentVideo]);

  // detect playback and change videos if current one is ended
  useEffect(() => {
    if (duration && currentTime >= duration) {
      if (upNext?.video) {
        setCurrentVideo(upNext.video);
      } else {
        stopSrc();
      }
    }
  }, [currentTime, duration]);

  // initialize player via remoteplayercontroller and listeners
  const initializeCastPlayer = () => {
    const options = {
      receiverApplicationId: 'CC1AD845',
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    };
    cast.framework.CastContext.getInstance().setOptions(options);

    const newRemotePlayer = new cast.framework.RemotePlayer();
    const newRemotePlayerController = new cast.framework.RemotePlayerController(newRemotePlayer);

    //listen for changes in connection
    newRemotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, e => {
      const connected = e.value;
      setConnected(Boolean(connected));
    });

    //listen for changes in state
    newRemotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, e => {
      setPlayerState(e.value);
    });

    //listen for changes in duration
    newRemotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.DURATION_CHANGED, e => {
      setDuration(e.value);
    });

    //listen for changes in current time / playback
    newRemotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, e => {
      setCurrentTime(e.value);
    });
    setRemotePlayer(newRemotePlayer);
    setRemotePlayerController(newRemotePlayerController);
  };

  // attempt to load the video to be cast
  const castSrc = (src: string, title: string) => {
    const mediaInfo = new chrome.cast.media.MediaInfo(src, 'video/mp4');
    mediaInfo.streamType = chrome.cast.media.StreamType.BUFFERED;
    mediaInfo.metadata = new chrome.cast.media.TvShowMediaMetadata();
    mediaInfo.metadata.title = title;

    const request = new chrome.cast.media.LoadRequest(mediaInfo);
    request.autoplay = true;
    const session = cast.framework.CastContext.getInstance().getCurrentSession();

    if (session) {
      session.loadMedia(request);
    } else {
      ClientLogger.error('CastController:castSrc', 'No session found for casting');
    }
  };

  // stop the current session
  const stopSrc = () => {
    const session = cast.framework.CastContext.getInstance().getCurrentSession();
    if (session && remotePlayerController) {
      remotePlayerController.stop();
    }
  };

  return (
    <div>
      {/* @ts-ignore */}
      <google-cast-launcher></google-cast-launcher>
    </div>
  );
};
