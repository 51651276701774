import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@src/lib/ClientLogger';
import { Button } from '@components/Buttons';
import { Img } from '@components/Img';

import classNames from 'classnames';

import {
  formContainer,
  formImage,
  nameGroupContainer,
  nameGroup,
  firstName,
  formGroup,
  formLabel,
  textField,
  contactButtonWrapper,
  successMessage,
  error,
} from './styles.module.scss';

const DEBUG = false;

const emailListSchema = (error: ContactUsFormErrors) => {
  return yup.object().shape({
    firstName: yup.string().required(error.emptyFirstNameField),
    lastName: yup.string().required(error.emptyLastNameField),
    email: yup
      .string()
      .trim()
      .email(error.invalidEmailError)
      .required(error.emptyEmailField),
  });
};

interface ContactUsFormErrors {
  emptyFirstNameField: string;
  emptyLastNameField: string;
  emptyEmailField: string;
  invalidEmailError: string;
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const errors = {
  emptyFirstNameField: 'Please enter your first name',
  emptyLastNameField: 'Please enter your last name',
  emptyEmailField: 'Please enter your email',
  invalidEmailError: 'Please enter a correct email format',
};

interface Props {
  imgUrl?: {
    childImageSharp: any;
  };
  successCallback?: () => void;
}

export const SubscribeForm = ({ imgUrl, successCallback }: Props) => {
  const api = usePvepApi();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={emailListSchema(errors)}
      onSubmit={async (values, actions) => {
        ClientLogger.debug('SubscribeForm.onSubmit', '', DEBUG, values);
        const resp = await api.submitEmailListForm(values.firstName.trim(), values.lastName.trim(), values.email.trim(), '');
        if (resp.data?.submitEmailListForm?.success) {
          actions.resetForm();
          setShowFailure(false);
          setShowSuccess(true);
          if (successCallback) {
            setTimeout(() => {
              successCallback();
            }, 1500);
          }
        } else {
          setShowSuccess(false);
          setShowFailure(true);
        }
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <div className={formContainer}>
            {imgUrl && <Img childImageSharp={imgUrl.childImageSharp} className={formImage} />}

            <div className={formGroup}>
              <div className={nameGroupContainer}>
                <div className={classNames(nameGroup, firstName)}>
                  <label htmlFor="firstName" className={formLabel}>
                    First Name
                  </label>
                  <input
                    value={props.values.firstName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="firstName"
                    className={textField}
                  />
                  {props.errors.firstName && props.touched.firstName && <div className={error}>{props.errors.firstName}</div>}
                </div>
                <div className={nameGroup}>
                  <label htmlFor="lastName" className={formLabel}>
                    Last Name
                  </label>
                  <input
                    value={props.values.lastName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="lastName"
                    className={textField}
                  />
                  {props.errors.lastName && props.touched.lastName && <div className={error}>{props.errors.lastName}</div>}
                </div>
              </div>
            </div>

            <div className={formGroup}>
              <label htmlFor="email" className={formLabel}>
                Email
              </label>
              <input
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                name="email"
                className={textField}
              />
              {props.errors.email && props.touched.email && <div className={error}>{props.errors.email}</div>}
            </div>

            <div className={contactButtonWrapper}>
              <Button type="submit" disabled={props.isSubmitting || !props.isValid}>
                Subscribe
              </Button>
            </div>

            {showSuccess && <div className={successMessage}>Your subscription was successful. Thank you!</div>}

            {showFailure && <div className={error}>Something went wrong, please try again.</div>}
          </div>
        </form>
      )}
    </Formik>
  );
};
