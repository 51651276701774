import React from 'react';
import { BrandUtil } from '@sharedLib/util';

interface Props {
  icon: string;
}

const BookIcon = (toolTip: string) => {
  return (
    <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{toolTip}</title>
      <path
        d="M45 32.2C45 32.2 41.85 28 34.5 28C27.15 28 24 32.2 24 32.2V61.6C24 61.6 27.15 59.5 34.5 59.5C41.85 59.5 45 61.6 45 61.6M45 32.2V61.6M45 32.2C45 32.2 48.15 28 55.5 28C62.85 28 66 32.2 66 32.2V61.6C66 61.6 62.85 59.5 55.5 59.5C48.15 59.5 45 61.6 45 61.6M87 45C87 68.196 68.196 87 45 87C21.804 87 3 68.196 3 45C3 21.804 21.804 3 45 3C68.196 3 87 21.804 87 45Z"
        stroke="currentColor"
        strokeWidth="4.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const StarIcon = (toolTip: string) => {
  return (
    <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{toolTip}</title>
      <path
        d="M86 44C86 67.196 67.196 86 44 86C20.804 86 2 67.196 2 44C2 20.804 20.804 2 44 2C67.196 2 86 20.804 86 44Z"
        stroke="currentColor"
        strokeWidth="3.89984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1947 24.2343C42.8186 22.5886 45.18 22.5886 45.8059 24.2343L49.8422 35.4151C50.125 36.1561 50.8445 36.6494 51.6479 36.6494H61.5671C63.4 36.6494 64.1995 38.9308 62.7585 40.0481L55.6998 46.399C55.3836 46.6421 55.1525 46.9791 55.0397 47.3616C54.9269 47.7442 54.9382 48.1526 55.0719 48.5283L57.6497 59.4537C58.2776 61.2086 56.2458 62.7159 54.7092 61.6357L45.1215 55.5519C44.7931 55.3212 44.4016 55.1973 44.0003 55.1973C43.599 55.1973 43.2074 55.3212 42.8791 55.5519L33.2913 61.6357C31.7567 62.7159 29.723 61.2067 30.3509 59.4537L32.9286 48.5283C33.0624 48.1526 33.0736 47.7442 32.9608 47.3616C32.848 46.9791 32.617 46.6421 32.3008 46.399L25.2421 40.0481C23.7991 38.9308 24.6025 36.6494 26.4315 36.6494H36.3508C36.7413 36.6507 37.1231 36.5334 37.4455 36.313C37.7679 36.0926 38.0158 35.7795 38.1564 35.4151L42.1927 24.2343H42.1947Z"
        stroke="currentColor"
        strokeWidth="3.89984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const VideoCardIcon = ({ icon }: Props) => {
  const getToolTip = () => {
    const brandId = BrandUtil.getSiteInfo().brandId;

    switch (brandId) {
      case 'ftl':
        return {
          starToolTip: 'New Release',
          bookToolTip: 'Field Trip Archive',
        };
      default:
        return {
          starToolTip: 'New Release',
          bookToolTip: 'Archive',
        };
    }
  };

  const getIcon = () => {
    switch (icon) {
      case 'star':
        return StarIcon(getToolTip().starToolTip);
      case 'book':
        return BookIcon(getToolTip().bookToolTip);
      default:
        return null;
    }
  };
  return getIcon();
};
