import { CmsField } from 'netlify-cms-core';

export interface IFrontMatterBase {
  slug: string;
  template: string;
  defaultContentBrandId?: string;
}

export interface IPageProps<FM> {
  pageContext: { frontMatter: FM };
}

export function defaultFields(template: string | 'none', slug: string): CmsField[] {
  return [
    {
      label: 'Template Key',
      name: 'templateKey',
      widget: 'hidden',
      default: template,
    },
    {
      label: 'Slug',
      name: 'slug',
      widget: 'hidden',
      default: slug,
    },
    {
      label: 'Get Defaults from Brand Id',
      name: 'defaultContentBrandId',
      widget: 'hidden', // Should this be explicit
      default: 'at',
    },
  ];
}
