import React, { useState } from 'react';
import { videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { BrandUtil, Strategy, alphabeticalOrder } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';

export interface StrategySeedData {
  branch: string;
  timestamp: number;
  strategies: Strategy[];
}

export const StrategyExport = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);

    // export data
    const strategies: Strategy[] = [];
    const strategyResp = await api.getStrategies();
    if (strategyResp.data?.getStrategies.success) {
      const databaseStrategies: Strategy[] = strategyResp.data.getStrategies.strategies
        .map(strat => {
          const ias = strat.individualAssignments.map(ia => {
            const newIa: any = ia;
            delete newIa.__typename;
            return newIa;
          });

          const mas = strat.multipleAssignments.map(ma => {
            const newMa: any = ma;
            delete newMa.__typename;
            return newMa;
          });

          const cas = strat.collectionAssignments.map(ca => {
            const newCa: any = ca;
            delete newCa.__typename;
            return newCa;
          });

          strat.individualAssignments = ias;
          strat.multipleAssignments = mas;
          strat.collectionAssignments = cas;
          const newStrat: any = strat;
          delete newStrat.__typename;
          return newStrat;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));

      strategies.push(...databaseStrategies);
    }

    const seedData: StrategySeedData = {
      branch: brandInfo.branchId,
      timestamp: Date.now(),
      strategies,
    };

    const blob = new Blob([JSON.stringify(seedData, null, 2)], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const downloadEl = document.createElement('a');
    downloadEl.download = `${brandInfo.branchId}-strategies-${Date.now()}`;
    downloadEl.href = href;
    downloadEl.click();

    setLoading(false);
  };

  return (
    <div>
      <Button onClick={() => handleExport()} disabled={loading} className={videoListItem}>
        EXPORT STRATEGIES
      </Button>
    </div>
  );
};
