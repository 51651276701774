import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from './GoogleLogin';

interface GoogleLoginButtonProps {
  text?: string;
  className?: string;
  disabled?: boolean;
  onSuccess: (response: any) => void;
  onFailure: (message?: string | null) => void;
}

export const GoogleLoginButton = ({ text, className, disabled, onSuccess, onFailure }: GoogleLoginButtonProps) => {
  const variableName = `GATSBY_GOOGLE_ID`;
  const googleClientId = process.env.GATSBY_GOOGLE_ID;
  if (!googleClientId) {
    throw new Error(`Missing process.env.${variableName}`);
  }
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <GoogleLogin text={text} className={className} disabled={disabled} onSuccess={onSuccess} onFailure={onFailure} />
    </GoogleOAuthProvider>
  );
};
