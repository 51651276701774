import { ClientLogger } from '@lib/ClientLogger';
import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface ImageProps {
  id?: string;
  name?: string;
  publicURL?: string;
  src?: string;
  title?: string;
  childImageSharp?: {
    gatsbyImageData: IGatsbyImageData;
  };
  extension?: string;
  className?: string;
  style?: any;
  alt?: string;
  absolutePath?: string;
  imgStyle?: any;
}

export const Img: React.FC<ImageProps> = ({ childImageSharp, publicURL, src, extension, children, title, ...restProps }) => {
  const url = publicURL || src;
  if (extension === 'svg' || (!childImageSharp && url)) {
    if (url?.indexOf('ctfassets') === -1 && extension !== 'svg') {
      ClientLogger.warning('Img', `Non optimized URL ${url}`);
    }
    return <img src={publicURL || src} alt={title} {...restProps} />;
  }

  if (childImageSharp) {
    const { gatsbyImageData } = childImageSharp;
    return <GatsbyImage image={gatsbyImageData} alt={title || ''} {...{ ...restProps, children, title }} />;
  }

  return null;
};
