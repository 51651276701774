import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  editorVideoLabel,
  adminSubText,
  flexRow,
  adminText,
  videoMetadataList,
  videoCollectionDeleteContainer,
  videoCollectionDelete,
  videoCollectionDeleteCard,
  videoListButtonContainer,
  videoCollectionListRow,
  videoCollectionListText,
} from './styles.module.scss';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Icon } from '@iconify/react';
import { alphabeticalOrder } from '@sharedLib/index';
import { ConfirmCard } from '@components/ConfirmCard';

export const UploadedVideoList = () => {
  const api = usePvepApi();
  const [videos, setVideos] = useState<string[]>([]);
  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);
  const [pendingRemoval, setPendingRemoval] = useState<number | undefined>(undefined);
  const [deletingError, setDeletingError] = useState('');
  const [deletingVideo, setDeletingVideo] = useState(false);

  useEffect(() => {
    getUploadedVideos();
  }, []);

  const getUploadedVideos = async () => {
    setLoading(true);
    const videoResp = await api.getVideoBucketContents();
    if (videoResp.data?.getVideoBucketContents.success) {
      const uploadedVideos = videoResp.data.getVideoBucketContents.contents
        .map(video => video.fileName || '')
        .sort((a, b) => alphabeticalOrder(a, b));
      setVideos(uploadedVideos);
      setLoadingError('');
    } else {
      setLoadingError(
        JSON.stringify(videoResp.data?.getVideoBucketContents.errorMessages) || 'Something went wrong loading the uploaded videos.'
      );
      setVideos([]);
    }
    setLoading(false);
  };

  const deleteVideo = async (key: number) => {
    setDeletingVideo(true);

    const videoToDelete = videos[key];

    const deleteResp = await api.deleteS3Video(videoToDelete);
    if (deleteResp.data?.deleteS3Video.success) {
      const newList = [...videos];
      newList.splice(key, 1);
      setVideos(newList);
    } else {
      setDeletingError(JSON.stringify(deleteResp.data?.deleteS3Video.errorMessages) || 'Could not delete this video. Please try again.');
    }
    setDeletingVideo(false);
  };

  return (
    <div className={videoMetadataList}>
      {loading && <div className={adminSubText}>Loading...</div>}
      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!loading &&
        videos.map((video, i) => (
          <div className={classNames(flexRow, videoCollectionListRow, videoListButtonContainer)} key={i}>
            <div className={videoCollectionListText}>
              <div className={adminText}>{video}</div>
            </div>
            <div
              className={classNames(flexRow, adminSubText, editorVideoLabel, videoCollectionDeleteContainer)}
              onClick={() => setPendingRemoval(i)}
            >
              <Icon icon="fa-solid:trash-alt" className={videoCollectionDelete} />
              <div>Delete</div>
            </div>
            <div className={videoCollectionDeleteCard}>
              {pendingRemoval === i && (
                <ConfirmCard
                  title={'Delete Video'}
                  content={'Do you really want to delete this video? This process cannot be undone.'}
                  confirmCta={'Delete'}
                  disabled={deletingVideo}
                  onConfirm={async () => {
                    await deleteVideo(pendingRemoval);
                    setPendingRemoval(undefined);
                  }}
                  onCancel={() => setPendingRemoval(undefined)}
                  errorMessage={deletingError}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
