import React from 'react';
import { DivButton } from '@components/Buttons';
import classNames from 'classnames';
import { DatabaseSeries, DatabaseVideo } from '@sharedLib/index';
import { VideoPlayButton } from '@components/VideoPreviewCard/VideoPlayButton';
import {
  smallVideoCard,
  videoCard,
  videoThumbnail,
  videoOverlay,
  videoActions,
  smallVideoTitle,
  videoTitle,
  smallVideoButton,
  videoButton,
  smallVideoDescription,
  videoDescription,
} from './styles.module.scss';

const DEBUG = false;

interface Props {
  type: 'series' | 'video';
  item: DatabaseSeries | DatabaseVideo;
  imageUrl: string;
  onClickVideo: (videoId: string, title: string) => void;
  onClickSeries: (seriesId: string, title: string) => void;
  small?: boolean;
}

export const VideoCard = ({ type, item, imageUrl, onClickSeries, onClickVideo, small }: Props) => {
  const onButtonClick = (id: string) => {
    if (type === 'series') {
      onClickSeries(id, item.title);
    } else if (type === 'video') {
      const castItem = item as DatabaseVideo;
      onClickVideo(id, castItem.label || castItem.title);
    }
  };

  const getItemTitle = (item: DatabaseSeries | DatabaseVideo) => {
    if (type === 'video') {
      const castItem = item as DatabaseVideo;
      return castItem.label || castItem.title;
    }

    return item.title;
  };

  return (
    <div className={classNames(small ? smallVideoCard : videoCard)} data-cy-video-card-id={item.id}>
      <img className={videoThumbnail} src={imageUrl} title={getItemTitle(item)} alt={getItemTitle(item)} />

      <DivButton className={videoOverlay} onClick={() => onButtonClick(item.id)}>
        <div className={videoActions}>
          <p className={classNames(small ? smallVideoTitle : videoTitle)}>{getItemTitle(item)}</p>
          {type === 'video' && (
            <VideoPlayButton
              onClick={() => onClickVideo(item.id, getItemTitle(item))}
              className={classNames(small ? smallVideoButton : videoButton)}
              iconProps={{ height: '60px' }}
            />
          )}
          <p className={classNames(small ? smallVideoDescription : videoDescription)}>{item.description}</p>
        </div>
      </DivButton>
    </div>
  );
};
