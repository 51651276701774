import React from 'react';

interface DropdownProps {
  values: {
    text: string;
    value: string | number;
  }[];
  selected: string | number;
  onChange: (val: string) => void;
  className?: string;
}

const Dropdown = ({ values, selected, onChange, className }: DropdownProps) => {
  return (
    <div className={`select ${className || ''}`}>
      <select
        onChange={ev => {
          onChange(ev.target.value);
        }}
      >
        {values.map(val => {
          return (
            <option key={val.value} value={val.value} selected={selected === val.value}>
              {val.text}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Dropdown;
