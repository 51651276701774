import { ApolloLink, Observable, Operation, NextLink, FetchResult } from '@apollo/client';
import { AuthClientUtil } from '@lib/AuthClientUtil';
import { OperationQueuing } from './queuing';
import { ClientLogger } from '@lib/ClientLogger';
import { ApolloClientContext, usePvepApi, UserState, tokenExpiryReadable } from './usePvepApi';

const DEBUG = false;

// Adopted from https://github.com/newsiberian/apollo-link-token-refresh/tree/master/src
export class TokenRefreshLink extends ApolloLink {
  private queue: OperationQueuing;

  private fetching: boolean;

  private static isJwtExpired(curState: UserState): boolean {
    ClientLogger.debug('TokenRefreshLink.checkJwtExpiration', '', DEBUG, { curState });
    const expiry = curState?.jwt?.exp;
    if (!expiry) {
      return true;
    }
    const curDate = Date.now();
    const cur = curDate / 1000;
    const retVal = expiry < cur;
    ClientLogger.debug('TokenRefreshLink.checkJwtExpiration', '', DEBUG, {
      expiryReadable: new Date(expiry * 1000),
      curDate,
      cur,
      expiry,
      retVal,
    });
    return retVal;
  }

  constructor() {
    super();
    this.queue = new OperationQueuing();
    this.fetching = false;
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = usePvepApi();
    if (typeof forward !== 'function') {
      throw new Error('[Token Refresh Link]: Token Refresh Link is non-terminating link and should not be the last in the composed chain');
    }
    // If token does not exists, which could means that this is a not registered
    // user request, or if it is does not expired -- act as always

    const context: ApolloClientContext = operation.getContext();

    if (context.noAuth) {
      DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'No Auth Needed for this request. Forwarding', { operation, context });
      const ctx = {
        headers: {
          credentials: 'include',
        },
      };
      operation.setContext(ctx);
      return forward(operation);
    }
    const { token } = api.state;
    const ctx = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        credentials: 'include',
      },
    };
    DEBUG &&
      ClientLogger.debug('TokenRefreshLink', 'setContext', {
        token,
        exp: tokenExpiryReadable(api.state),
        state: api.state,
        ctx,
      });
    operation.setContext(ctx);

    if (!TokenRefreshLink.isJwtExpired(api.state)) {
      DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Jwt is OK. Forwarding', { operation, context });
      return forward(operation);
    }
    DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Jwt is not OK. Requesting', { operation, context });

    if (!this.fetching) {
      this.fetching = true;
      ClientLogger.debug('TokenRefreshLink.request', 'Calling exchangeRefreshTokenForJwtToken', DEBUG);
      api
        .getJwtFromRefresh()
        .then(success => {
          if (success) {
            ClientLogger.debug('TokenRefreshLink.request', `successful exchangeRefreshTokenForJwtToken`, DEBUG);
          } else {
            ClientLogger.warning('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Token not renewed');
            AuthClientUtil.logout('/login');
          }
        })
        .catch(e => {
          ClientLogger.error('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Error renewing token', e);
          AuthClientUtil.logout('/login');
        })
        .finally(() => {
          this.fetching = false;
          this.queue.consumeQueue();
        });
    }

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }
}
