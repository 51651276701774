import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { formatCurrency } from '@lib/adPlacements';
import { useProductData } from '@lib/shopify/useProductData';
import React, { useState, useContext, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { ClientLogger } from '@lib/ClientLogger';
import { QuantitySelector } from '@components/Forms/QuantitySelector';
import { SubmitButton, ButtonStyles, Button } from '@components/Buttons';
import { ShopifyUtil } from '@src/lib/shopify';
import { NavStateContext } from '@lib/navContext';
import { useCart } from '@apiClient/use-cart';
import { Icon } from '@iconify/react';
import { BrandUtil, ShopifyProduct } from '@sharedLib/index';
import { SocialMediaIcons } from '@components/SocialMediaIcons/SocialMediaIcons';
import classNames from 'classnames';
import { isBrowser } from '@lib/build';
import { useVideoState } from '@lib/useVideoState';
import { useAnalyticsCapture } from '@src/lib/AnalyticsCapture';
import {
  productOverlay,
  productOverlayOpen,
  productOverlayContainer,
  productOverlayTitle,
  productOverlayPrice,
  productOverlayShareContainer,
  productOverlayShareOpen,
  productOverlayShare,
  productOverlayVariant,
  productOverlayVariantContainer,
  productOverlaySubtitle,
  productOverlayButton,
  productOverlayContent,
  productOverlayIcon,
  productOverlayForm,
  productOverlayButtonContainer,
  productOverlayShareIcon,
  productOverlayShareIcons,
  variantSelector,
  productOverlayContentGroup,
  productOverlayShareIconContainer,
  lastVariant,
  productOverlayDescriptionContainer,
  productOverlayAffiliateContentGroup,
} from './styles.module.scss';

const DEBUG = false;

interface Props {
  productId: string;
  open: boolean;
  addToCartCallback?: () => void;
}

export const ProductOverlay = ({ productId, open, addToCartCallback }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const productData = useProductData();
  const product = productData.getProduct(productId);
  const nav = useContext(NavStateContext);
  const navData = nav.navState.pageData;
  const options = navData[navData.length - 1]?.state;
  const cart = useCart();
  const analyticsCapture = useAnalyticsCapture();
  const videoState = useVideoState();

  const [quantity, setQuantity] = useState<number>(1);
  const [shareOpen, setShareOpen] = useState(false);

  const variants = ShopifyUtil.formatVariantsForDisplay(product?.variants || []);

  //
  // determine default variants
  //
  const defaultVariants = Object.create({});
  [...variants.entries()].forEach(variant => {
    let choice = 0;
    variant[1].forEach((v: any, i: number) => {
      DEBUG && ClientLogger.debug('ProductDetails', 'variant loop', { v, i, options, variant });

      if (v.value === options?.variantTitle) {
        choice = i;
      }
    });
    defaultVariants[variant[0]] = variant[1][choice].text;
  });

  //
  // get selected variant from variant parts (values)
  //
  const getSelectedVariant = (values: any) => {
    const selection = Object.values(values).join(' / ');
    DEBUG && ClientLogger.debug('ProductOverlay.getSelectedVariant', `selectedVariant ${selection}`);

    const variants = product?.variants || [];
    const info = variants.filter((variant: { title: string }) => variant.title === selection)[0];
    DEBUG && ClientLogger.debug('ProductOverlay.getSelectedVariant', `info =`, DEBUG, info);

    const variantInfo = {
      variantId: info?.id,
      variantName: info?.title,
      price: info?.price,
    };
    return variantInfo;
  };
  const [selectedVariant, setSelectedVariant] = useState(getSelectedVariant(defaultVariants));

  //
  // update selected variant and quantity if productId is changed without component unmounting
  //
  useEffect(() => {
    const selected = getSelectedVariant(defaultVariants);
    setSelectedVariant(selected);
    setQuantity(1);
  }, [productId]);

  //
  // update quantity
  //
  const onChangeUpdateQuantity = async (value: number) => {
    const quantity = value;
    DEBUG && ClientLogger.debug('ProductOverlay.updateQuantity', `product = ${product?.title} quantity = ${quantity}`);
    setQuantity(quantity);
  };

  //
  // update variant selected
  //
  const updateVariant = async (e: React.ChangeEvent, variant: string, values: any) => {
    if (!e.target) return;

    if (e.target) {
      const target = e.target as HTMLSelectElement;
      const val = target.value;
      values[variant] = val;
      const selection = getSelectedVariant(values);
      setSelectedVariant(selection);
    }
  };

  //
  // handle form submit
  //
  const handleFormSubmit = async (values: any) => {
    const selection = getSelectedVariant(values);
    DEBUG && ClientLogger.debug('ProductOverlay.formSubmit', 'resp =', { values, selection });

    const resp = await cart.addCartItem({
      description: product?.descriptionHtml || ' ',
      productId: product?.id || '',
      title: product?.title || '',
      quantity,
      price: String(selection.price),
      imageUrl: product?.imageOriginalSrc || '',
      variantId: selection?.variantId,
      variantName: selection?.variantName,
    });
    if (product) {
      analyticsCapture.productHit('add-to-cart', product, { adStrategy: videoState.state.currentAdStrategy });
    }
    DEBUG && ClientLogger.debug('ProductOverlay.addCartItemResp', 'resp =', resp);
    if (resp) {
      addToCartCallback && addToCartCallback();
    }
  };

  if (!product) {
    return <></>;
  }

  // key in Formik allows react to reinitialize form when productId changes,
  // was causing strange variant bugs due to not unmounting between products
  return (
    <div className={classNames(productOverlay, open ? productOverlayOpen : '')}>
      {product.eCommerceType === 'cart' && (
        <Formik key={productId} onSubmit={async values => handleFormSubmit(values)} initialValues={defaultVariants}>
          {props => (
            <form onSubmit={e => props.handleSubmit(e)} className={productOverlayForm}>
              <div className={productOverlayContainer}>
                <div className={productOverlayContent}>
                  <div className={productOverlayContentGroup}>
                    <div className={productOverlayTitle}>{product.title}</div>
                    <div className={productOverlayPrice}>{formatCurrency(selectedVariant.price)}</div>

                    <div className={productOverlayButtonContainer}>
                      <div className={productOverlayButton}>
                        <SubmitButton style={ButtonStyles.Primary} data-cy={`product-overlay-product-card-non-affiliate-cta-${productId}`}>
                          ADD TO CART
                        </SubmitButton>
                      </div>
                    </div>
                  </div>

                  <div className={productOverlayVariantContainer}>
                    {[...variants.keys()]
                      .filter(variant => variant !== 'Title')
                      .map(variant => {
                        return (
                          <div
                            className={productOverlayVariant}
                            key={variant}
                            data-cy={`product-overlay-product-card-variant-selector-container-${variant}-${product.id}`}
                          >
                            <div
                              className={productOverlaySubtitle}
                              data-cy={`product-overlay-product-card-variant-label-${variant}-${product.id}`}
                            >
                              {variant}
                            </div>
                            <Field
                              component="select"
                              key={variant}
                              name={variant}
                              className={variantSelector}
                              onChange={async (e: React.ChangeEvent) => updateVariant(e, variant, props.values)}
                              data-cy={`product-overlay-product-card-variant-selector-${variant}-${product.id}`}
                            >
                              {variants.get(variant).map((val: any) => {
                                return (
                                  <option
                                    key={val.value}
                                    value={val.value}
                                    className=""
                                    data-cy={`product-overlay-product-card-variant-option-${variant}-${product.id}`}
                                  >
                                    {val.text}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                        );
                      })}
                    <div className={classNames(productOverlayVariant, lastVariant)}>
                      <div className={productOverlaySubtitle}>Quantity</div>
                      <QuantitySelector
                        name="quantity"
                        value={quantity}
                        updateParent={onChangeUpdateQuantity}
                        data-cy={`product-overlay-product-card-quantity-selector-${productId}`}
                      />
                    </div>
                  </div>
                  <div className={productOverlayShareIconContainer}>
                    <div className={productOverlayShareIcon} onClick={() => setShareOpen(!shareOpen)}>
                      <Icon icon="fa-solid:share" />
                    </div>
                  </div>
                </div>

                <div className={classNames(productOverlayShare, shareOpen ? productOverlayShareOpen : '')}>
                  <div className={classNames(productOverlayShareContainer, shareOpen ? '' : '')}>
                    <div className={productOverlaySubtitle}>Share</div>
                    <SocialMediaIcons
                      isCentered
                      shareUrl={`${isBrowser && brandInfo.siteUrl}/product/${productId}`}
                      product={product}
                      iconClass={productOverlayIcon}
                      containerClass={productOverlayShareIcons}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      {product.eCommerceType === 'immediate-affiliate' && (
        <div className={productOverlayContainer}>
          <div className={productOverlayContent}>
            <div className={productOverlayAffiliateContentGroup}>
              <div className={productOverlayTitle}>{product.title}</div>
              <div className={productOverlayDescriptionContainer}>{product.description}</div>

              <div className={productOverlayPrice}>{formatCurrency(product.maxVariantPrice)}</div>

              <div className={productOverlayButtonContainer}>
                <div className={productOverlayButton}>
                  <a
                    href={product.targetUrlType === 'internal' ? `${brandInfo.siteUrl}${product.targetUrl}` : product.targetUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      analyticsCapture.productHit('affiliate-click', product, { adStrategy: videoState.state.currentAdStrategy });
                    }}
                  >
                    <Button style={ButtonStyles.Primary} data-cy={`product-overlay-product-card-affiliate-cta-${productId}`}>
                      {product.alternateCta || 'BUY NOW'}
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div className={productOverlayShareIconContainer}>
              <div className={productOverlayShareIcon} onClick={() => setShareOpen(!shareOpen)}>
                <Icon icon="fa-solid:share" />
              </div>
            </div>
          </div>

          <div className={classNames(productOverlayShare, shareOpen ? productOverlayShareOpen : '')}>
            <div className={classNames(productOverlayShareContainer, shareOpen ? '' : '')}>
              <div className={productOverlaySubtitle}>Share</div>
              <SocialMediaIcons
                isCentered
                shareUrl={`${isBrowser && brandInfo.siteUrl}/product/${productId}`}
                product={product}
                iconClass={productOverlayIcon}
                containerClass={productOverlayShareIcons}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
