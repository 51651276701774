import { formatCurrency } from '@lib/adPlacements';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button, DivButton } from '@components/Buttons';
import { ProductImg } from '@components/Img';
import { Video, ShopifyProduct, DatabaseVideo, AdWithProduct } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext, NavStateType } from '@lib/navContext';
import { useVideoResource } from '@src/templates/video/useVideoResource';
import {
  productBox,
  containerImage,
  productImage,
  containerInfo,
  productName,
  productPrice,
  productBuyNow,
  headerText,
  productList,
  spaceForVideo,
} from './styles.module.scss';

const DEBUG = false;

interface ProductCardProps {
  shopifyProduct: ShopifyProduct;
  pageVideo?: DatabaseVideo | undefined;
  index: number;
}

interface OnClickProductProps {
  video?: DatabaseVideo;
  shopifyProduct: ShopifyProduct;
  index: number;
  nav: NavStateType;
  variantTitle?: string;
}

interface ProductsProps {
  pageVideo?: DatabaseVideo | undefined;
  featuredProducts?: AdWithProduct[] | null;
  productCollection?: ShopifyProduct[];
}

export const goToProduct = ({ nav, video, shopifyProduct, variantTitle }: OnClickProductProps) => {
  const title = video?.label || video?.title || '';
  const id = video ? video.id : 0;
  if (isBrowser) {
    DEBUG &&
      ClientLogger.debug('Product.goToProduct', 'called', {
        navState: nav.navState,
        shopifyProduct,
      });
    const navData = nav.navState.pageData;
    if (navData.length > 0 && navData[navData.length - 1].path.split('/').includes('product')) {
      nav.replace({
        path: `/product/${shopifyProduct.id}`,
        title: shopifyProduct.title,
        state: {
          videoTitle: title,
          videoId: id,
          variantTitle,
        },
      });
    } else {
      nav.push({
        path: `/product/${shopifyProduct.id}`,
        title: shopifyProduct.title,
        state: {
          videoTitle: title,
          videoId: id,
          variantTitle,
        },
      });
    }
  }
};

const ProductCard = ({ shopifyProduct, pageVideo, index }: ProductCardProps) => {
  const nav = useContext<any>(NavStateContext);
  const content = useVideoResource();

  const onClick = () => {
    goToProduct({ nav, video: pageVideo || undefined, shopifyProduct, index });
  };
  if (!shopifyProduct) {
    return null;
  }
  const isAffiliate = shopifyProduct.eCommerceType === 'immediate-affiliate';
  const firstVariant = shopifyProduct.variants?.length > 0 && shopifyProduct.variants[0];
  const price = firstVariant ? firstVariant.price : undefined;
  DEBUG && ClientLogger.debug('ProductCard', 'render', { shopifyProduct, index, firstVariant, price });

  return (
    <div className={`box ${productBox}`} data-cy={`product-card-${shopifyProduct.id}`}>
      <DivButton className={containerImage} onClick={onClick}>
        <ProductImg
          product={shopifyProduct}
          className={productImage}
          imgStyle={{ objectFit: 'contain' }}
          data-cy={`product-card-image-${shopifyProduct.id}`}
        />
      </DivButton>
      <div className={classNames(containerInfo, isAffiliate)}>
        <DivButton className={productName} onClick={onClick} data-cy={`product-card-title-${shopifyProduct.id}`}>
          {shopifyProduct.title}
        </DivButton>
        <DivButton className={productPrice} onClick={onClick} data-cy={`product-card-price-${shopifyProduct.id}`}>
          {formatCurrency(price)}
        </DivButton>
        <Button onClick={onClick} className={productBuyNow} data-cy={`product-card-cta-${shopifyProduct.id}`}>
          {content.miniProductCardCTA}
        </Button>
      </div>
    </div>
  );
};

const Products = ({ pageVideo, featuredProducts, productCollection }: ProductsProps) => {
  DEBUG && ClientLogger.debug('Products', 'render', DEBUG, { featuredProducts });
  return (
    <div>
      <div className={headerText}>Featured Products</div>
      <div className={classNames(productList, spaceForVideo)}>
        {featuredProducts &&
          featuredProducts.map((ad, index) => {
            if (!ad) {
              return null;
            }

            return <ProductCard key={ad.id} {...{ shopifyProduct: ad.product }} pageVideo={pageVideo} index={index} />;
          })}
        {productCollection &&
          productCollection.map((product, index) => {
            if (!product) {
              return null;
            }

            return <ProductCard key={product.id} {...{ shopifyProduct: product }} index={index} />;
          })}
      </div>
    </div>
  );
};

export default Products;
