import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { tabText } from './styles.module.scss';

interface TabsProps {
  children: React.ReactNode[];
  labels: string[];
  className?: string;
  tabRootClassName?: string;
  activeClassName?: string;
  onTabChanged?: (currentTab: number, newTab: number) => void;
}

type TabsHandle = {
  changeActiveTab: (tabLabel: string) => void;
};

export const Tabs = forwardRef<TabsHandle, TabsProps>(
  ({ children, labels, className, tabRootClassName, activeClassName, onTabChanged }, ref) => {
    const [activeTab, setActiveTab] = useState(0);

    useImperativeHandle(ref, () => ({
      changeActiveTab,
    }));

    const changeActiveTab = (tabLabel: string) => {
      setActiveTab(labels.indexOf(tabLabel));
      onTabChanged && onTabChanged(activeTab, labels.indexOf(tabLabel));
    };

    return (
      <>
        <div className={className || ''}>
          {labels.map((label, i) => (
            <div
              key={i}
              onClick={() => changeActiveTab(labels[i])}
              className={classNames(tabText, tabRootClassName, (activeTab === i && activeClassName) || '')}
            >
              {label}
            </div>
          ))}
        </div>

        {children[activeTab]}
      </>
    );
  }
);
