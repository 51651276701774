import { Button, ButtonStyles } from '@components/Buttons';
import React from 'react';
import classNames from 'classnames';
import { deleteCard, deleteCardContent, deleteCardButton, deleteCardSpacer, noWrapButton, cardText } from './styles.module.scss';

interface Props {
  className?: string;
  title?: string;
  content?: string;
  confirmCta?: string;
  disabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  errorMessage?: string;
}

const DEBUG = false;

export const ConfirmCard = ({ onConfirm, onCancel, className, title, content, confirmCta, disabled, errorMessage }: Props) => {
  return (
    <div className={classNames('card', deleteCard, className || '')}>
      <div className="card-content">
        <div className={classNames(deleteCardContent, deleteCardSpacer)}>
          <div className={cardText}>{title || 'Confirm'}</div>
          <div>{content || 'Please confirm the action.'}</div>
        </div>
        <div className={deleteCardContent}>
          <Button className={classNames(noWrapButton, deleteCardButton)} onClick={onConfirm} disabled={disabled}>
            {confirmCta || 'Confirm'}
          </Button>
          <Button className={noWrapButton} style={ButtonStyles.Greyscale} onClick={onCancel} disabled={disabled}>
            Cancel
          </Button>
        </div>
        {errorMessage && <div className={deleteCardContent}>{errorMessage}</div>}
      </div>
    </div>
  );
};
