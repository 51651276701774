export enum UserMessageType {
  ORDER_RECEIVED = 'ORDER_RECEIVED',
}

export interface OrderReceivedPayload {
  lineItems: {
    variantId: string;
    ppvVideoIdPurchased?: string;
  }[];
}
