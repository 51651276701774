import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, FieldArray, getIn, Field } from 'formik';
import { Button } from '@components/Buttons';
import {
  IndividualAssignment,
  formatFromSeconds,
  formatToSeconds,
  MultipleAssignment,
  Strategy,
  RECOMMENDED_PRODUCT_PLACEHOLDER,
} from '@sharedLib/index';
import { useProductData } from '@lib/shopify/useProductData';
import { ProductImg } from '@components/Img';
import Select, { components } from 'react-select';
import { Icon } from '@iconify/react';
import deleteIcon from '@iconify/icons-wpf/delete';
import cuid from 'cuid';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as styles from './styles.module.scss';
import '../react-select-styles.scss';
import classNames from 'classnames';
const DEBUG = false;

interface Props {
  initialStrategy?: Strategy;
  onClose: () => void;
}

interface FormEditAssignment {
  productId: string;
  startTimeString: string;
  status: 'active' | 'paused';
  id: string;
}

const editAssignmentValidation = yup.object().shape({
  title: yup.string().required('This is required.'),
  individualAssignments: yup.array().of(
    yup.object().shape({
      productId: yup.string().required('Please select a product'),
      startTimeString: yup
        .string()
        .required('Please enter a start time')
        .test('form-match', 'Incorrect format.', value => {
          if (value === undefined) {
            return false;
          }

          const colonMatch = (value.match(/:/g) || []).length < 3;
          const inputMatch = (value.match(/^[:0-9]*$/g) || []).length > 0;
          let valueMatches = true;
          value.split(':').map(val => {
            if (!(Number.isInteger(parseInt(val)) && parseInt(val) < 60)) {
              valueMatches = false;
            }
          });
          return valueMatches && colonMatch && inputMatch;
        }),
      status: yup
        .string()
        .oneOf(['active', 'paused'])
        .required('Please select an option'),
    })
  ),
  multipleAssignmentsBanner: yup.array().of(yup.string()),
  multipleAssignmentInterval: yup.number().when('multipleAssignmentsBanner', {
    is: (value: any) => value && value.length,
    then: yup
      .number()
      .required('Interval is required.')
      .integer('Interval must be an integer.')
      .positive('Interval must be positive.'),
  }),
});

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="akar-icons:search" className={styles.searchIcon} />
    </components.DropdownIndicator>
  );
};

export const StrategyEditor = ({ initialStrategy, onClose }: Props) => {
  const [currentStrategy, setCurrentStrategy] = useState<Strategy | undefined>(initialStrategy);
  const initialIndividualAssignments = currentStrategy?.individualAssignments || [];
  const initialMultipleAssignments = currentStrategy?.multipleAssignments || [];

  const api = usePvepApi();

  const [publishSuccess, setPublishSuccess] = useState('');
  const [publishError, setPublishError] = useState('');
  const [dragOver, setDragOver] = useState<number | undefined>(undefined);

  const productData = useProductData();
  const allProductData = productData.getAllProducts();
  const allProductCollections = productData.getAllProductCollections();
  const collectionSelectOptions = allProductCollections.map(collection => {
    return {
      value: collection.id,
      label: collection.title,
    };
  });
  const individualSelectOptions = allProductData.map(product => {
    return {
      value: product.id,
      label: product.title,
    };
  });
  const activeOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' },
  ];

  ///
  /// Publish assignments
  ///
  const writeStrategy = async (
    title: string,
    editedFormAssignments: FormEditAssignment[],
    bannerProductCollections: string[],
    staticProductCollections: string[],
    featuredProductCollections: string[],
    bannerInterval?: number | null
  ) => {
    const individualAssignments = mapToIndividualAssignments(editedFormAssignments);
    const multipleAssignments = mapToMultipleAssignments(bannerProductCollections, staticProductCollections, featuredProductCollections);

    const strategy: Strategy = {
      id: currentStrategy?.id || cuid(),
      title,
      bannerInterval,
      individualAssignments,
      multipleAssignments,
      collectionAssignments: currentStrategy?.collectionAssignments || [],
    };

    const writeStrategyResp = await api.writeStrategy(strategy);
    if (!writeStrategyResp.data?.writeStrategy.success) {
      const errors = writeStrategyResp.data?.writeStrategy.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setPublishError(errorMessage || 'Something went wrong, please try again.');
      setPublishSuccess('');
      return;
    }

    setCurrentStrategy(strategy);
    setPublishSuccess('Published successfully');
  };

  ///
  /// Map form data to individual assignments
  ///
  const mapToIndividualAssignments = (editedAssignments: FormEditAssignment[]) => {
    const newIndividualAssignments: IndividualAssignment[] = editedAssignments.map(assign => {
      return {
        adType: 'banner',
        id: assign.id,
        name: productData.getProduct(assign.productId)?.title || '',
        rank: 1,
        productId: assign.productId,
        startTimeIndex: formatToSeconds(assign.startTimeString),
        status: assign.status,
        isRecommendation: assign.productId === RECOMMENDED_PRODUCT_PLACEHOLDER.id || undefined,
      };
    });

    return newIndividualAssignments;
  };

  ///
  /// Map form data to multiple assignments
  ///
  const mapToMultipleAssignments = (
    bannerProductCollections: string[],
    staticProductCollections: string[],
    featuredProductCollections: string[]
  ) => {
    // new timed banner assignments
    const newMultipleAssignments: MultipleAssignment[] = bannerProductCollections.map((collectionId, i) => {
      const assignmentId = initialMultipleAssignments.find(
        existing => existing.productCollectionId === collectionId && existing.adType === 'banner'
      )?.id;
      return {
        adType: 'banner',
        id: assignmentId || cuid(),
        name: allProductCollections.find(pc => pc.id === collectionId)?.title || '',
        rank: i + 1,
        productCollectionId: collectionId,
      };
    });

    // new static banner assignments
    const newStaticAssignments: MultipleAssignment[] = staticProductCollections.map((collectionId, i) => {
      const assignmentId = initialMultipleAssignments.find(
        existing => existing.productCollectionId === collectionId && existing.adType === 'banner_static'
      )?.id;
      return {
        adType: 'banner_static',
        id: assignmentId || cuid(),
        name: allProductCollections.find(pc => pc.id === collectionId)?.title || '',
        rank: i + 1,
        productCollectionId: collectionId,
      };
    });

    // new featured banner assignments
    const newFeaturedAssignments: MultipleAssignment[] = featuredProductCollections.map((collectionId, i) => {
      const assignmentId = initialMultipleAssignments.find(
        existing => existing.productCollectionId === collectionId && existing.adType === 'featured'
      )?.id;
      return {
        adType: 'featured',
        id: assignmentId || cuid(),
        name: allProductCollections.find(pc => pc.id === collectionId)?.title || '',
        rank: i + 1,
        productCollectionId: collectionId,
      };
    });

    return [...newMultipleAssignments, ...newStaticAssignments, ...newFeaturedAssignments];
  };

  ///
  /// get initial individual assignments for the form
  ///
  const getInitialAssignments = (): FormEditAssignment[] => {
    if (initialIndividualAssignments.length) {
      const initialAssignments = [...initialIndividualAssignments].map(a => {
        return {
          productId: a.productId,
          startTimeString: formatFromSeconds(a.startTimeIndex),
          status: a.status,
          id: a.id,
        };
      });
      return initialAssignments;
    } else {
      //no initial assignments, so populate an empty card to start off
      return [
        {
          productId: '',
          startTimeString: '00:00:00',
          status: 'active',
          id: cuid(),
        },
      ];
    }
  };

  return (
    <>
      <div className={styles.backButton}>
        <Icon icon="bi:arrow-left" onClick={() => onClose()} />
      </div>
      <Formik
        initialValues={{
          title: currentStrategy?.title || '',
          individualAssignments: getInitialAssignments(),
          multipleAssignmentsBanner: initialMultipleAssignments
            .filter(assign => assign.adType === 'banner')
            .map(assign => assign.productCollectionId),
          multipleAssignmentInterval: currentStrategy?.bannerInterval || 15,
          multipleAssignmentsStatic: initialMultipleAssignments
            .filter(assign => assign.adType === 'banner_static')
            .map(assign => assign.productCollectionId),
          multipleAssignmentsFeatured: initialMultipleAssignments
            .filter(assign => assign.adType === 'featured')
            .map(assign => assign.productCollectionId),
        }}
        validationSchema={editAssignmentValidation}
        validateOnChange={false}
        onSubmit={async (values, { resetForm }) => {
          await writeStrategy(
            values.title,
            values.individualAssignments,
            values.multipleAssignmentsBanner,
            values.multipleAssignmentsStatic,
            values.multipleAssignmentsFeatured,
            Number(values.multipleAssignmentInterval)
          );

          // reinitialize form with new, sorted values
          resetForm({
            values: {
              title: values.title,
              individualAssignments: values.individualAssignments
                .sort((a, b) => formatToSeconds(a.startTimeString) - formatToSeconds(b.startTimeString))
                .map(ea => {
                  return {
                    productId: ea.productId,
                    startTimeString: ea.startTimeString,
                    status: ea.status,
                    id: ea.id,
                  };
                }),
              multipleAssignmentsBanner: values.multipleAssignmentsBanner,
              multipleAssignmentInterval: values.multipleAssignmentInterval,
              multipleAssignmentsStatic: values.multipleAssignmentsStatic,
              multipleAssignmentsFeatured: values.multipleAssignmentsFeatured,
            },
          });
        }}
      >
        {props => (
          <>
            <div className={styles.editorTextContainer}>
              <div className={styles.titleText}>{currentStrategy ? 'Editing Strategy' : 'Add Strategy'}</div>

              <div className={styles.editorPublishContainer}>
                <Button type="submit" onClick={props.handleSubmit} disabled={props.isSubmitting} className={styles.noWrapButton}>
                  SAVE
                </Button>
                {publishSuccess && <div>{publishSuccess}</div>}
                {publishError && <div>{publishError}</div>}
                {props.errors.individualAssignments && (
                  <div className={styles.assignmentEditorError}>{`Error in individual assignments.`}</div>
                )}
              </div>
            </div>

            <div className={styles.titleText}>Title</div>
            <div className={styles.editorItemContainer}>
              <div className={styles.editorItem}>
                <Field
                  className={styles.textInput}
                  value={props.values.title}
                  onChange={props.handleChange}
                  id="title"
                  name="title"
                  placeholder="Title"
                />
              </div>
              {props.touched.title && props.errors.title && <div className={styles.assignmentEditorError}>{props.errors.title}</div>}
            </div>

            <div className={styles.titleText}>1. Fallback product collections</div>
            <div className={styles.editorText}>These collections populate the banner throughout the video.</div>

            <div className={styles.editorItemContainer}>
              <div className={styles.editorItem}>
                <Select
                  value={props.values.multipleAssignmentsStatic.map(mas => {
                    return {
                      value: mas,
                      label: allProductCollections.find(pc => pc.id === mas)?.title || '',
                    };
                  })}
                  name={`multipleAssignmentsStatic`}
                  onChange={opts => {
                    props.setFieldValue(
                      `multipleAssignmentsStatic`,
                      opts.map(opt => opt.value)
                    );
                  }}
                  isMulti
                  isClearable={false}
                  options={collectionSelectOptions}
                  className={styles.editorSelect}
                  classNamePrefix="productSelect"
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>

            <div className={styles.titleText}>2. Timed product collections</div>
            <div className={styles.editorText}>
              These collections will show up as new items in your banner after the specified interval. The default interval is 15 seconds.
            </div>
            <div className={styles.editorItemContainer}>
              <div className={styles.editorItem}>
                <Select
                  value={props.values.multipleAssignmentsBanner.map(mab => {
                    return {
                      value: mab,
                      label: allProductCollections.find(pc => pc.id === mab)?.title || '',
                    };
                  })}
                  name={`multipleAssignmentsBanner`}
                  onChange={opts => {
                    props.setFieldValue(
                      `multipleAssignmentsBanner`,
                      opts.map(opt => opt.value)
                    );
                  }}
                  isMulti
                  isClearable={false}
                  options={collectionSelectOptions}
                  className={styles.editorSelect}
                  classNamePrefix="productSelect"
                  components={{ DropdownIndicator }}
                />

                <div className={styles.timerContainer}>
                  <input
                    className={styles.timerInput}
                    value={props.values.multipleAssignmentInterval}
                    onChange={e => {
                      const isNumeric = e.target.value.match(/^[0-9]+$/);
                      if (e.target.value.length && !isNumeric) {
                        return;
                      }
                      props.handleChange(e);
                    }}
                    id={'multipleAssignmentInterval'}
                    name={'multipleAssignmentInterval'}
                  />

                  <div className={styles.timerLabel}>s</div>
                </div>
              </div>

              {props.touched.multipleAssignmentInterval && props.errors.multipleAssignmentInterval && (
                <div className={styles.assignmentEditorError}>{props.errors.multipleAssignmentInterval}</div>
              )}
            </div>

            <div className={styles.titleText}>3. Individual products</div>
            <div className={styles.editorText}>These products take priority in your banner and will override any timed collections.</div>
            <div className={styles.editorTableContainer}>
              <div className={styles.editorSidebar}>
                <div className={styles.editorSidebarText}>Name</div>
                <div className={styles.editorSidebarText}>Timecode</div>
                <div className={styles.editorSidebarText}>Status</div>
              </div>
              <div className={styles.editorTable}>
                <FieldArray name="individualAssignments">
                  {arrayHelpers => (
                    <>
                      {props.values.individualAssignments.map((assignment, i) => {
                        const product = productData.getProduct(assignment.productId);
                        return (
                          <div
                            className={styles.editorEntry}
                            key={i}
                            onDragOver={e => {
                              e.preventDefault();
                            }}
                            onDragEnter={e => {
                              e.preventDefault();
                              setDragOver(i);
                            }}
                            onDrop={e => {
                              e.preventDefault();
                              setDragOver(undefined);
                              const index = parseInt(e.dataTransfer.getData('text'));
                              if (index === i) {
                                return;
                              } else if (index > i) {
                                arrayHelpers.insert(i, props.values.individualAssignments[index]);
                                arrayHelpers.remove(index + 1);
                              } else {
                                arrayHelpers.insert(i + 1, props.values.individualAssignments[index]);
                                arrayHelpers.remove(index);
                              }
                            }}
                          >
                            {dragOver === i && (
                              <div className={styles.entryDragOver}>
                                <Icon icon="bi:arrow-right" />
                              </div>
                            )}
                            <div className={styles.titleText}>PRODUCT {i + 1}</div>
                            <div
                              className={styles.editorColumn}
                              draggable
                              onDragStart={e => {
                                e.dataTransfer.setData('text', i.toString());
                                const el = e.target as HTMLDivElement;
                                el.style.opacity = '0.2';
                              }}
                              onDragEnd={e => {
                                e.preventDefault();
                                const el = e.target as HTMLDivElement;
                                el.style.opacity = '1';
                                setDragOver(undefined);
                              }}
                            >
                              <div>
                                <div className={styles.publishedIcon}>
                                  {initialIndividualAssignments.findIndex(published => published.id === assignment.id) !== -1 && (
                                    <>
                                      <div>Published</div>
                                      <Icon icon="bi:check-circle-fill" />
                                    </>
                                  )}
                                </div>

                                <Select
                                  value={individualSelectOptions.filter(
                                    opt => opt.value === props.values.individualAssignments[i].productId
                                  )}
                                  name={`individualAssignments.${i}.productId`}
                                  onChange={opt => {
                                    props.setFieldValue(`individualAssignments.${i}.productId`, opt?.value);
                                  }}
                                  options={individualSelectOptions}
                                  className={styles.assignmentEditorSelect}
                                  classNamePrefix="productSelect"
                                  components={{ DropdownIndicator }}
                                />
                                <div className={styles.assignmentEditorError}>
                                  {getIn(props.touched, `individualAssignments.${i}.productId`)
                                    ? getIn(props.errors, `individualAssignments.${i}.productId`)
                                    : ' '}
                                </div>

                                <input
                                  value={props.values.individualAssignments[i].startTimeString}
                                  name={`individualAssignments.${i}.startTimeString`}
                                  onChange={props.handleChange}
                                  className={styles.assignmentEditorTimecode}
                                />
                                <div className={styles.assignmentEditorError}>
                                  {getIn(props.errors, `individualAssignments.${i}.startTimeString`) || ' '}
                                </div>

                                <Select
                                  value={activeOptions.filter(opt => opt.value === props.values.individualAssignments[i].status)}
                                  name={`individualAssignments.${i}.status`}
                                  onChange={opt => {
                                    props.setFieldValue(`individualAssignments.${i}.status`, opt?.value);
                                  }}
                                  options={activeOptions}
                                  className={styles.assignmentEditorSelect}
                                  classNamePrefix="productSelect"
                                />
                                <div className={styles.assignmentEditorError}>
                                  {getIn(props.errors, `individualAssignments.${i}.status`) || ' '}
                                </div>
                              </div>

                              {product && (
                                <div className={styles.assignmentImageContainer}>
                                  <ProductImg product={product} />
                                </div>
                              )}

                              {!product && initialIndividualAssignments.findIndex(published => published.id === assignment.id) !== -1 && (
                                <div className={styles.warningIconContainer}>
                                  <Icon className={styles.warningIcon} icon={'ant-design:warning-filled'} />
                                  <div>Missing product</div>
                                </div>
                              )}

                              <div className={styles.assignmentEditorRemove} onClick={() => arrayHelpers.remove(i)}>
                                <Icon icon={deleteIcon} />
                                <div>Remove</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className={styles.addAssignmentColumn}>
                        <Icon
                          icon="bi:plus-circle"
                          className={styles.addAssignmentButton}
                          onClick={() =>
                            arrayHelpers.push({
                              productId: '',
                              startTimeString: '00:00:00',
                              status: 'active',
                              id: cuid(),
                            })
                          }
                        />
                        <div>ADD NEW</div>
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className={styles.titleText}>4. Featured products</div>
            <div className={styles.editorText}>These collections appear in the featured products section below the video.</div>
            <div className={styles.editorItemContainer}>
              <div className={styles.editorItem}>
                <Select
                  value={props.values.multipleAssignmentsFeatured.map(mab => {
                    return {
                      value: mab,
                      label: allProductCollections.find(pc => pc.id === mab)?.title || '',
                    };
                  })}
                  name={`multipleAssignmentsFeatured`}
                  onChange={opts => {
                    props.setFieldValue(
                      `multipleAssignmentsFeatured`,
                      opts.map(opt => opt.value)
                    );
                  }}
                  isMulti
                  isClearable={false}
                  options={collectionSelectOptions}
                  className={styles.editorSelect}
                  classNamePrefix="productSelect"
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
