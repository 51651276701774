import React from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { noWrapButton, adminInput, adminPadBottom, errorMessage as errorStyle } from './styles.module.scss';

interface Props {
  onSubmit: (title: string) => void;
  onCancel: () => void;
}

const seasonSchema = yup.object().shape({
  title: yup.string().required('This is required.'),
});

export const AddSeasonWidget = ({ onSubmit, onCancel }: Props) => {
  return (
    <Formik
      initialValues={{
        title: '',
      }}
      validationSchema={seasonSchema}
      onSubmit={values => {
        onSubmit(values.title);
      }}
    >
      {props => (
        <div>
          <div className={adminPadBottom}>
            <div>Add Season</div>
            <Field
              className={adminInput}
              value={props.values.title}
              onChange={props.handleChange}
              id="title"
              name="title"
              placeholder="Title"
            />
            {props.touched.title && props.errors.title && <div className={errorStyle}>{props.errors.title}</div>}
          </div>

          <Button className={noWrapButton} onClick={props.handleSubmit}>
            ADD SEASON
          </Button>
          <Button style={ButtonStyles.Greyscale} className={noWrapButton} onClick={() => onCancel()}>
            CANCEL
          </Button>
        </div>
      )}
    </Formik>
  );
};
