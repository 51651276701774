import React, { RefObject, useEffect, useState } from 'react';
import { DivButton } from '@components/Buttons';
import rightOutlined from '@iconify/icons-ant-design/right-outline';
import leftOutlined from '@iconify/icons-ant-design/left-outline';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import useScrollButtons from '@src/lib/useScrollButtons';
import { buttonIcon, left, right, scrollButton } from './styles.module.scss';

interface HorizontalScrollButtonProps {
  position: 'left' | 'right';
  className?: string;
  elementRef: RefObject<HTMLDivElement>;
}

export const HorizontalScrollButton = ({ position, className, elementRef }: HorizontalScrollButtonProps) => {
  const { onScrollButtonClick } = useScrollButtons(elementRef, {
    scrollPercentage: 0.65,
    roundToNearest: 315,
  });
  return position === 'right' ? (
    <DivButton
      className={classNames(scrollButton, right, className)}
      onClick={() => {
        onScrollButtonClick(false);
      }}
    >
      <Icon icon={rightOutlined} className={buttonIcon} />
    </DivButton>
  ) : (
    <DivButton
      className={classNames(scrollButton, left, className)}
      onClick={() => {
        onScrollButtonClick(true);
      }}
    >
      <Icon icon={leftOutlined} className={buttonIcon} />
    </DivButton>
  );
};

export default HorizontalScrollButton;
