import React, { useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { Typography } from '@components/Typography/Typography';
import Background from '@src/components/Background';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@src/lib/navContext';
import { useCart } from '@apiClient/use-cart';
import { useVideoState, VideoPlayerSize, VideoStateContext } from '@lib/useVideoState';
import { isMobile, isTablet } from '@src/lib/responsive';
import { Button } from '@src/components/Buttons';
import { VideoPlaceHolder } from '@src/components/VideoPlayer/VideoPlaceHolder';
import { ProductDetails } from '@components/ProductDetails';
import { Loading } from '@src/components/Loading/Loading';
import { CartItem, VideoSource } from '@sharedLib/index';
import { CartContext } from '@src/lib/clientStorage/cartContext';
import { PopupBlocked } from '@components/PopUps/PopupBlocked';
import {
  cartBackground,
  pageContainer,
  cartContainer,
  textHeader,
  emptyCartBox,
  emptyCartMessage,
  emptyCartButton,
  fullWidth,
  mediaObject,
  checkoutContainerAndVideoColumn,
  checkoutContainer,
  checkoutSummary,
  checkoutInfo,
  checkoutTotal,
  checkoutButton,
  confirmText,
  orderConfirm,
  detailsAndButton,
  detailsAndButtonOrder,
  alignToBottom,
} from './styles.module.scss';
import { scrollToRefLocation } from '@src/lib/scrolling';

const DEBUG = false;

interface Props {
  successCallback?: () => void;
  noVideo?: boolean;
  noNav?: boolean;
  noBackground?: boolean;
  skipScroll?: boolean;
}

const CartWidget = ({ successCallback, noVideo = false, skipScroll = false, noBackground = false }: Props) => {
  const goToHomePage = () => {
    nav.setRoot({ path: '/app', title: 'Home' });
  };

  const [displayThankYou, setDisplayThankYou] = useState(false);
  const isMobileScreenSize = isMobile();
  const isTabletScreenSize = isTablet();
  const videoState = useVideoState();
  const { state } = useContext(VideoStateContext);
  const { videoPlayerSize } = state;
  const [openCartStatus, setOpenCartStatus] = useState<boolean>(false);
  const [pollTrigger, setPollTrigger] = useState(0);
  const [popUpLaunched, setPopUpLaunched] = useState(false);
  const [popupBlocked, setPopUpBlocked] = useState(false);
  const textHeaderRef = useRef<HTMLDivElement>(null);
  const cart = useCart();

  const cartItems: CartItem[] = cart.cartItems || [];
  DEBUG && ClientLogger.debug('CartWidget.getCartItems', `items = ${cartItems} `);

  const cartItemsPriceList = cartItems.map((item: { price: React.ReactText; quantity: number }) => +item.price * item.quantity) || [];
  const subTotal =
    cartItemsPriceList.length !== 0 ? cartItemsPriceList.reduce((firstItem: any, secondItem: any) => firstItem + secondItem) : 0;
  const shipping = 0;
  const tax = 0.13 * subTotal;
  const total = subTotal + shipping + tax;
  const nav = useContext(NavStateContext);

  useEffect(() => {
    if (!skipScroll) {
      setTimeout(() => scrollToRefLocation(textHeaderRef), 0);
    }
  }, [textHeaderRef, nav.navState]);

  useEffect(() => {
    const showVideoIfNonMember = state.userNotMember ? state.video?.isFree || state.video?.type !== VideoSource.VIMEO_OTT : true;
    if (state.payPerViewNotPurchased || !showVideoIfNonMember) {
      // No video embed should occur if PPV has not been purchased
      DEBUG &&
        ClientLogger.debug(
          'CartComponent',
          `useEffect: setting to NOT_SHOWN due to ppvnotpurchased=${state.payPerViewNotPurchased} usernotmember=${state.userNotMember}`
        );
      videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
    }

    if (videoPlayerSize === VideoPlayerSize.NOT_SHOWN) {
      DEBUG && ClientLogger.debug('CartComponent', 'useEffect: videoPlayerSize NOT_SHOWN');
    } else if (videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN) {
      DEBUG && ClientLogger.debug('CartComponent', 'useEffect: maintaining fullscreen');
    } else if (videoPlayerSize !== VideoPlayerSize.MEDIUM_EMBEDDED && !state.payPerViewNotPurchased && showVideoIfNonMember) {
      DEBUG && ClientLogger.debug('CartComponent', 'useEffect: setting to MEDIUM_EMBEDDED');
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
    }
  }, [videoPlayerSize, isMobileScreenSize, isTabletScreenSize, videoState, state.payPerViewNotPurchased, nav]);

  useEffect(() => {
    DEBUG && ClientLogger.debug('CartWidget.useEffect', 'waitForOrderCompletion check', { popUpLaunched, pollTrigger, cart });
    if (cart && popUpLaunched) {
      cart.waitForOrderCompletion().then(displayThankYou => {
        if (successCallback) {
          successCallback();
        } else {
          setDisplayThankYou(displayThankYou);
        }
        setPopUpLaunched(false);
      });
    }
  }, [pollTrigger, popUpLaunched]);

  DEBUG && ClientLogger.debug('CartWidget', 'render', { displayThankYou });

  const cartContext = useContext(CartContext);
  const content = cartContext.content;

  if (!isBrowser) {
    return <></>;
  }

  if (cart.loading) {
    return <Loading />;
  }

  return (
    <div className={cartBackground}>
      {!noBackground && displayThankYou && <Background />}
      <div className={pageContainer}>
        {displayThankYou ? (
          <>
            <div className={cartContainer}>
              <div className={classNames(orderConfirm, detailsAndButtonOrder)}>
                <Typography variant="h1" className={confirmText}>
                  {content.orderStatus.thankYouMessage}
                </Typography>
                <Typography variant="h1" className={confirmText}>
                  {content.orderStatus.orderCompletedMessage}
                </Typography>
              </div>
            </div>

            <div className={classNames(checkoutContainerAndVideoColumn, alignToBottom)}>
              {!noVideo && <VideoPlaceHolder videoId={state.video?.id || ''} />}
            </div>
          </>
        ) : (
          <>
            {popupBlocked && <PopupBlocked closeModal={() => setPopUpBlocked(false)} />}

            <div className={cartContainer}>
              {cartItems.length === 0 ? (
                <>
                  <article className={`media ${mediaObject}`}>
                    <div className="media-content">
                      <div className="content">
                        <div className={classNames(detailsAndButton)}>
                          <div className={`${emptyCartBox}`}>
                            <div className={emptyCartMessage}>{content.emptyCart.emptyCartMessage}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </>
              ) : (
                <>
                  {cartItems &&
                    [...cartItems]
                      .sort((itemA, itemB) => {
                        const itemIdA = itemA.variantId;
                        const itemIdB = itemB.variantId;
                        if (itemIdA > itemIdB) {
                          return 1;
                        }
                        if (itemIdA < itemIdB) {
                          return -1;
                        }
                        return 0;
                      }) // This type of logic belongs elsewhere
                      .map(item => {
                        DEBUG && ClientLogger.debug('CartComponent.render', 'product loop', DEBUG, item);
                        const itemId: any = item.id;
                        return (
                          <ProductDetails
                            productId={item.productId}
                            isInCart
                            cartItemId={itemId}
                            initialQuantity={item.quantity}
                            key={itemId}
                            initialVariant={item.variantName}
                          />
                        );
                      })}
                </>
              )}
            </div>

            <div className={checkoutContainerAndVideoColumn}>
              {!noVideo && <VideoPlaceHolder videoId={state.video?.id || ''} />}
              <div ref={textHeaderRef} className={textHeader}>
                {content.header}
              </div>
              <div className={checkoutContainer}>
                {cartItems.length !== 0 && (
                  <>
                    <div className={checkoutSummary}>
                      <div className={checkoutInfo}>
                        <div>Subtotal</div>
                        <div>{`$${subTotal.toFixed(2)}`}</div>
                      </div>
                      <div className={checkoutInfo}>
                        <div>Shipping & Handling</div>
                        <div>TBD</div>
                      </div>
                      <div className={checkoutInfo}>
                        <div>GST/HST</div>
                        <div>{`$${tax.toFixed(2)}`}</div>
                      </div>
                      <div className={checkoutTotal}>
                        <div>Estimated total</div>
                        <div>{`$${total.toFixed(2)}`}</div>
                      </div>
                    </div>

                    <div className={checkoutButton}>
                      <Button
                        onClick={async () => {
                          const popupSucceeded = await cart.startCheckout();
                          if (!popupSucceeded) {
                            setPopUpBlocked(true);
                          } else {
                            setOpenCartStatus(true);
                            setPollTrigger(pollTrigger + 1);
                            setPopUpLaunched(true);
                          }
                        }}
                      >
                        Proceed to Checkout
                      </Button>
                      {openCartStatus && !popupBlocked && <p>Do not close popup window until purchase is complete</p>}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {cart.error && <ErrorDisplay errorData={cart.error} consoleError />}
    </div>
  );
};

export default CartWidget;
