import { usePvepApi } from '@apiClient/usePvepApi';
import { ErrorData } from '@lib/ErrorFormatter';
import { OrderReceivedPayload, UserMessageType } from '@sharedLib/message';
import { ClientLogger } from '@src/lib/ClientLogger';
import { ClientStorage } from '@src/lib/clientStorage/ClientStorage';
import { useQuery } from '@apollo/client';
import { QuerygetCartItemsArgs } from '@apiClient/gql-types';
import { QueryType } from '@apiClient/api-types';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '@src/lib/clientStorage/cartContext';
import { isBrowser } from '@src/lib/build';
import { CartItem, CartItemProps } from '@sharedLib/product';
import { useProductData } from '@lib/shopify/useProductData';

const DEBUG = false;

export const useCart = () => {
  const api = usePvepApi();
  const userState = api.state;
  const { isLoggedIn, email } = userState;
  const cartContext = useContext(CartContext);
  const [error, setError] = useState<ErrorData | undefined>(undefined);

  const allProductData = useProductData().getAllProducts();
  useEffect(() => {
    checkAndFilterCartItems();
  }, []);

  const checkAndFilterCartItems = async () => {
    const cartItems = cartContext.cartData;
    for (const cartItem of cartItems) {
      const product = allProductData.find(p => p.id === cartItem.productId);
      if (!product) {
        await removeCartItem(cartItem.variantId);
        await updateCartItemsContext();
      }
    }
  };

  DEBUG && ClientLogger.debug('useCart', 'render', { isLoggedIn, email, cartContext, error, userState });

  const updateCartItemsContext = async (): Promise<CartItem[]> => {
    if (isLoggedIn) {
      DEBUG && ClientLogger.debug('UseCart.updateCartItemsContext', `Fetching data from serverCart`);
      const cartItemsInServerCart = useQueryResult.data?.getCartItems.products || [];
      DEBUG && ClientLogger.debug('UseCart.updateCartItemsContext', `itemsInServerCart`, DEBUG, cartItemsInServerCart);
      cartContext.updateCartData(cartItemsInServerCart);
      return cartItemsInServerCart;
    } else {
      DEBUG && ClientLogger.debug('UseCart.updateCartItemsContext', `Fetching data from browserCart`);
      const cartItemsInBrowserCart = await ClientStorage.getCartItems();
      DEBUG && ClientLogger.debug('UseCart.updateCartItemsContext', `itemsInBrowserCart`, DEBUG, cartItemsInBrowserCart);
      cartContext.updateCartData(cartItemsInBrowserCart);
      return cartItemsInBrowserCart;
    }
  };

  // add selected cart item to cart
  const addCartItem = async ({ description, productId, title, quantity, price, imageUrl, variantId, variantName }: CartItemProps) => {
    if (!isLoggedIn) {
      // Add products to client storage
      DEBUG && ClientLogger.debug('UseCart', 'addProductToBrowserCart');

      const resp = await ClientStorage.addToCart({
        description,
        productId,
        title,
        quantity,
        price,
        imageUrl,
        variantId,
        variantName,
      });
      DEBUG && ClientLogger.debug('UseCart.addBrowserCartItemResp', 'resp =', DEBUG, resp);

      if (!resp) {
        ClientLogger.error('UseCart.addBrowserCartItemResp', `Unable to addCartItem to Browser Cart`, resp);
        return false;
      }
      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp =`, DEBUG, updateCart);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }
      return true;
    } else {
      DEBUG && ClientLogger.debug('UseCart', 'addProductToServerCart');
      const resp = await api.cartServices.addCartItem({
        email,
        description,
        productId,
        title,
        quantity,
        price,
        imageUrl,
        variantId,
        variantName,
      });
      DEBUG && ClientLogger.debug('UseCart.addServerCartItemResp', `resp =`, DEBUG, resp);

      if (!resp.data?.addCartItem.success) {
        ClientLogger.error('UseCart.addServerCartItemResp', `Unable to addCartItem to Server Cart`, resp);
        return false;
      }

      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp =`, DEBUG, updateCart);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }

      return true;
    }
  };

  // remove selected cart item from cart
  const removeCartItem = async (variantId: string) => {
    if (isLoggedIn) {
      DEBUG && ClientLogger.debug('UseCart.removeCartItemInServerCart', `email = ${email} variantId = ${variantId}`);

      const resp = await api.cartServices.removeCartItem(email, variantId);

      DEBUG && ClientLogger.debug('UseCart.removeCartItemInServerCart', 'Response =', DEBUG, resp);

      if (!resp.data?.removeCartItem.success) {
        ClientLogger.error('UseCart.removeCartItemInServerCartResp', `Unable to remove cart item from Server Cart`, resp);
        return false;
      }

      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp =`, DEBUG, updateCart);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }
      return true;
    } else {
      const resp = await ClientStorage.removeCartItemInBrowserCart(variantId);
      DEBUG && ClientLogger.debug('UseCart.removeCartItemInBrowserCartResp', 'resp =', DEBUG, resp);

      if (!resp) {
        ClientLogger.error('UseCart.removeCartItemInBrowserCartResp', `Unable to remove cart item from Browser Cart`, resp);
        return false;
      }
      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp = ${updateCart?.length}`);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }
      return true;
    }
  };

  // update quantity for selected cart item in cart
  const updateCartItem = async (cartItemId: string, variantId: string, variantName: string, quantity: number) => {
    if (isLoggedIn) {
      DEBUG &&
        ClientLogger.debug(
          'UseCart.updateCartItemInServerCart',
          ` cartItemId = ${cartItemId}
          variantId = ${variantId}
        `,
          DEBUG
        );
      const resp = await api.cartServices.updateCartItem({
        email,
        itemId: cartItemId,
        variantId,
        quantity,
      });
      DEBUG && ClientLogger.debug('UseCart.updateCartItemInServerCart', `Resp = ${JSON.stringify(resp)}`);

      if (!resp.data?.updateCartItem.success) {
        ClientLogger.error('UseCart.updateCartItemInServerCartResp', `Unable to update cart item in Server Cart`, resp);
        return false;
      }

      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp = ${updateCart?.length}`);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }

      return true;
    } else {
      const resp = await ClientStorage.updateCartItemInBrowserCart(cartItemId, variantId, variantName, quantity);
      DEBUG && ClientLogger.debug('UseCart.updateCartItemInBrowserCartResp', 'resp =', DEBUG, resp);

      if (!resp) {
        ClientLogger.error('UseCart.updateCartItemInBrowserCartResp', `Unable to update cart item in Browser Cart`, resp);
        return false;
      }
      const updateCart = await updateCartItemsContext();

      DEBUG && ClientLogger.debug('UseCart.refetchGetCartItems', `resp = ${updateCart?.length}`);

      //Error handling
      if (!updateCart) {
        ClientLogger.error('UseCart.refetchGetCartItemsResp', `Unable to refetch cartItems`, resp);
        return false;
      }
      return true;
    }
  };

  const syncOfflineCartStore = async () => {
    DEBUG && ClientLogger.debug('UseCart.syncOfflineCartStore', `Fetching data from offlineCart`);

    const cartItemsInBrowserCart = await ClientStorage.getCartItems();

    DEBUG && ClientLogger.debug('UseCart.syncOfflineCartStore', `cartItemsInBrowserCart`, DEBUG, cartItemsInBrowserCart);

    if (!cartItemsInBrowserCart.length) {
      DEBUG && ClientLogger.log('UseCart.syncOfflineCartStore', 'browser cart is empty');
      cartContext.updateCartState({
        success: false,
        state: 'idle',
        message: {
          header: '',
          info: '',
        },
      });
      return true;
    }

    // Merging cart items from browser cart to server cart
    for (const cartItem of cartItemsInBrowserCart) {
      const result = await addCartItem(cartItem);

      DEBUG && ClientLogger.debug('UseCart.syncOfflineCartStore', 'cart Item added to server cart = ', DEBUG, cartItem);

      if (!result) {
        ClientLogger.error('UseCart.syncOfflineCartStore', 'could not add this item to server cart', cartItem);
        cartContext.updateCartState({
          success: true,
          state: 'failed',
          message: {
            header: 'Oops!',
            info: 'There was an error adding this item to your account.',
          },
        });
        return false;
      }

      const resp = await ClientStorage.removeCartItemInBrowserCart(cartItem.variantId);

      if (!resp) {
        ClientLogger.error('UseCart.syncOfflineCartStore', 'could not remove this item for browser cart', cartItem);
        cartContext.updateCartState({
          success: true,
          state: 'failed',
          message: {
            header: 'Oops!',
            info: 'There was an error adding this item to your account.',
          },
        });
        return false;
      }

      DEBUG && ClientLogger.debug('UseCart.syncOfflineCartStore', 'cart Item removed from browser cart = ', DEBUG, cartItem);
    }
    cartContext.updateCartState({
      success: true,
      state: 'completed',
      message: {
        header: 'Welcome back!',
        info: 'We noticed there are items in your cart from your last visit. These have been merged with the new items you’ve selected.',
      },
    });
    return true;
  };

  /**
   *
   * @param successCallback
   */
  const startCheckout = async (): Promise<boolean> => {
    const location = 'useCart.startCheckout';
    if (isLoggedIn) {
      DEBUG && ClientLogger.debug(location, `startCheckout started email=${email}`);
      const resp = await api.cartServices.startCheckout(email);
      DEBUG && ClientLogger.debug(location, 'startCheckout response', resp);

      if (resp.data?.startCheckout.success) {
        const windowRef = window.open(resp.data.startCheckout.redirectUrl || '', 'checkOut', 'width=500,height=800');
        if (!windowRef) {
          return false;
        }
      } else {
        ClientLogger.error(location, 'startCheckout failed', resp);
      }
    } else {
      DEBUG && ClientLogger.debug(location, `offlineCheckout started cartItems = `, cartContext.cartData);
      const resp = await api.cartServices.startCheckoutAnon(cartContext.cartData);
      DEBUG && ClientLogger.debug(location, `offlineCheckoutResp = `, resp);

      if (resp.data?.startCheckoutAnon.success) {
        const windowRef = window.open(resp.data.startCheckoutAnon.redirectUrl || '', 'offlineCheckout', 'width=500,height=800');
        if (!windowRef) {
          return false;
        }

        // Remove items from cart
        const items = cartContext.cartData;
        for (const item of items) {
          await removeCartItem(item.variantId);
        }
      } else {
        ClientLogger.error(location, 'startOfflineCheckout failed', resp);
      }
    }
    return true;
  };

  /**
   * returns a Promise of a bool that indicates if Thank You page should be shown
   */
  const waitForOrderCompletion = (): Promise<boolean> => {
    const location = 'waitForOrderCompletion';
    DEBUG && ClientLogger.debug(location, `offlineCheckout started cartItems = `, cartContext.cartData);
    return new Promise(resolve => {
      if (isLoggedIn) {
        const POLL_INTERVAL_MS = 5000; // every 5 seconds
        const MAX_POLLS = 6 * 20; // Stop after 10 minutes
        let count = 0;
        const intervalId = setInterval(async () => {
          const messages = await api.userMessagesService.userMessagesGet(UserMessageType.ORDER_RECEIVED);
          DEBUG && ClientLogger.debug(location, 'useEffect', messages);
          count++;
          if (count >= MAX_POLLS) {
            // Stop polling after the allotted time - saves on unnecessary calls to the server
            clearInterval(intervalId);
          }
          const userMessagesGet = messages.data?.userMessagesGet;
          if (userMessagesGet && userMessagesGet.userMessages.length > 0) {
            // We got an order confirmation message
            // Stop polling
            clearInterval(intervalId);
            await useQueryResult.refetch();

            // Check to make sure they aren't stale and ack them all
            let isFresh = false;
            for (const message of userMessagesGet.userMessages) {
              if (differenceInMinutes(new Date(), parseISO(message.createdAt)) < 30) {
                isFresh = true;
              }
              if (message.payload) {
                try {
                  const parsed: OrderReceivedPayload = JSON.parse(message.payload);
                  for (const lineItem of parsed.lineItems) {
                    if (lineItem.ppvVideoIdPurchased) {
                      ClientLogger.warning(
                        'waitForOrderCompletion.Message parsed',
                        `Todo: Give user a choice to navigate to videoId ${lineItem.ppvVideoIdPurchased}`
                      );
                    }
                  }
                } catch (e) {
                  setError({ errors: [`Error parsing payload ${message.payload}`], error: e });
                }
              }
              const ackResp = await api.userMessagesService.userMessageAck(message.id, api.state.deviceId);
              if (ackResp.data?.userMessageAck.success !== true) {
                setError({ graphQLErrors: ackResp.errors, commonResponseErrorDetails: ackResp.data?.userMessageAck.errorMessages });
              }
            }
            await updateCartItemsContext();
            resolve(isFresh);

            if (userMessagesGet.errorMessages) {
              setError({ commonResponseErrorDetails: userMessagesGet.errorMessages });
              clearInterval(intervalId);
              await updateCartItemsContext();
              resolve(false);
            }
          } else if (messages.errors) {
            setError({ graphQLErrors: messages.errors });
            clearInterval(intervalId);
            await updateCartItemsContext();
            resolve(false);
          }
        }, POLL_INTERVAL_MS);
      } else {
        updateCartItemsContext().finally(() => {
          resolve(true); // Off-line assume, order has been processed
        });
      }
    });
  };

  const useQueryResult = useQuery<QueryType['getCartItems'], QuerygetCartItemsArgs>(
    api.cartServices.CART_ITEMS_QUERY,
    api.getUserQueryOptions<QuerygetCartItemsArgs>(0, !isLoggedIn, { email: api.state.email })
  );

  useEffect(() => {
    DEBUG && ClientLogger.debug('UseCart.initialize updateCartItemsContext useEffect invoked', `resp =`, DEBUG);
    updateCartItemsContext().then(items => {
      DEBUG && ClientLogger.debug('UseCart.initialize updateCartItemsContext', `resp =`, DEBUG, items);
    });
  }, [useQueryResult, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      DEBUG && ClientLogger.debug('UseCart.syncOfflineCart', `started`);
      syncOfflineCartStore().then(result => {
        if (!result) {
          ClientLogger.error('UseCart.syncOfflineCartStore', 'failed');
        }
        DEBUG && ClientLogger.debug('UseCart.syncOfflineCart', `completed`);
      });
    }
  }, [isLoggedIn]);

  if (!isBrowser) {
    return {
      loading: false,
      error,
      cartItems: [] as CartItem[],
      addCartItem,
      removeCartItem,
      updateCartItem,
      startCheckout,
      waitForOrderCompletion,
    };
  }

  return {
    loading: useQueryResult?.loading,
    error,
    cartItems: (cartContext.cartData || []) as CartItem[],
    addCartItem,
    removeCartItem,
    updateCartItem,
    startCheckout,
    waitForOrderCompletion,
  };
};
