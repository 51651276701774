import React from 'react';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as ReactFacebookLogin from 'react-facebook-login';
import classNames from 'classnames';
import facebookIcon from '@iconify/icons-ion/logo-facebook';
import { Icon } from '@iconify/react';

import { BrandUtil } from '@sharedLib/util/Brand';
import { buttonProvider, buttonProviderAT, facebookIcon as facebookIconStyle, text as textStyle } from './styles.module.scss';

interface FacebookLoginButtonProps extends Omit<ReactFacebookLogin.ReactFacebookLoginProps, 'appId' | 'fields' | 'render'> {
  text?: string;
  className?: string;
  disabled?: boolean;
}

interface RenderProps {
  onClick: () => void;
}

export const FacebookLoginButton = ({ className, text, disabled, ...restProps }: FacebookLoginButtonProps) => {
  const currentBrand = BrandUtil.getBrandInfoFromBranch().brandId;
  const variableName = `GATSBY_FB_ID`;
  const facebookId = process.env.GATSBY_FB_ID;
  if (!facebookId) {
    throw new Error(`Missing process.env.${variableName}`);
  }

  return (
    <FacebookLogin
      appId={facebookId}
      fields="name,email,picture,first_name,last_name,middle_name,short_name,name_format"
      scope="public_profile,email"
      render={(renderProps: RenderProps) => (
        <button
          data-cy="facebookLogin"
          className={classNames(currentBrand === 'at' ? buttonProviderAT : buttonProvider, className)}
          type="button"
          onClick={renderProps.onClick}
          disabled={disabled}
        >
          <div className={facebookIconStyle}>
            <Icon icon={facebookIcon} />
          </div>
          <p className={textStyle}>{text}</p>
        </button>
      )}
      {...restProps}
    />
  );
};
