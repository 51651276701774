import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { boldFont, largeText, videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ExperimentInput } from '@sharedLib/index';

export const ExperimentImport = () => {
  const api = usePvepApi();

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');

  const [experiments, setExperiments] = useState<ExperimentInput[]>([]);

  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');
  const [branchMessage, setBranchMessage] = useState<{ branch: string; timestamp: number } | undefined>(undefined);

  ///
  /// Write all experiments to db
  ///
  const seedExperiments = async (experiments: ExperimentInput[]) => {
    const seedResp = await api.seedExperiments(experiments);
    if (seedResp.data?.seedExperiments.success) {
      setConfirmation('Seeding complete.');
    } else {
      setConfirmation('');
      setUploadErrors([
        'Something has gone wrong when attempting to seed this database.',
        JSON.stringify(seedResp.data?.seedExperiments.errorMessages),
      ]);
    }
  };

  return (
    <div>
      <div className={classNames(videoListItem, boldFont)}>
        WARNING: For this tool to work properly, please add all strategy data first. Using this tool will overwrite all current experiment
        data. It is to be used to seed new branches and in local development.
      </div>

      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          if (branchMessage?.branch) {
            await seedExperiments(experiments);
          }
          resetForm();
          setExperiments([]);
          setBranchMessage(undefined);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const experimentDataRaw = JSON.parse(dataString);

                        if (!experimentDataRaw.experiments) {
                          return;
                        }

                        setExperiments(experimentDataRaw.experiments);
                        setBranchMessage({
                          branch: experimentDataRaw.branch,
                          timestamp: experimentDataRaw.timestamp,
                        });
                        setError('');
                      } catch (e) {
                        setBranchMessage(undefined);
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {experiments.length > 0 && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                IMPORT ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {experiments.length > 0 && <div className={videoListItem}>This file contains {experiments.length} experiments.</div>}

      {branchMessage && (
        <div className={videoListItem}>
          <div className={largeText}>
            <div>
              This data is from branch: <span className={boldFont}>{branchMessage.branch}</span>
            </div>

            <div>
              This data was exported on: <span className={boldFont}>{new Date(branchMessage.timestamp).toString()}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
