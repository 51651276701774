import React, { useState, useRef } from 'react';
import { SocialMediaIcons } from '@components/SocialMediaIcons/SocialMediaIcons';
import { InlineIcon, Icon } from '@iconify/react';
import { Collection, Video, BrandUtil, ShopifyProduct, DatabaseVideo, DatabaseSeries } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import classNames from 'classnames';
import { ProductImg, VideoImg } from '@components/Img';

import {
  productButton,
  iconText,
  productIconText,
  heightProductIcon,
  Modal,
  copyInput,
  modalBackgroundOverride,
  shareIconBase,
  iconContainer,
  shareIcon,
  shareCard,
  closeButton,
  shareIconsContainer,
  shareTitle,
  shareInfoContainer,
  shareImageContainer,
  shareDetailsContainer,
  shareTextGroup,
  shareText,
  shareLinkContainer,
  shareLinkIconContainer,
  shareLinkIcon,
  shareSuccessIconContainer,
} from './styles.module.scss';

const iconPropsSmall = {
  height: '32px',
};

interface Props {
  type?: string;
  isCompact?: boolean;
  shareBtnClass?: string;
  urlPath?: string;
  shareUrl?: string;
  video?: DatabaseVideo;
  product?: ShopifyProduct;
  productPrice?: string;
  modalCallback?: (isOpen: boolean) => void;
}

export const Share = ({ type, shareBtnClass, urlPath, video, product, productPrice, modalCallback }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const [showModal, setShowModal] = useState(false);
  const analyticsCapture = useAnalyticsCapture();

  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef<any>(null);

  const copyToClipboard = (e: any) => {
    if (!document.queryCommandSupported('copy')) {
      return;
    }

    if (textAreaRef.current?.select) {
      textAreaRef.current.select();
    }
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess(true);

    analyticsCapture.shareEvent({
      type: type || '',
      videoId: video?.id,
      productId: type === 'product' ? urlPath : undefined,
      searchString: type === 'search' ? urlPath?.substring(3) : undefined,
      shareMethod: 'link',
      videoName: video?.title,
      productName: product?.title,
    });
  };

  const getVideoImageUrl = (item: DatabaseVideo) => {
    const baseImageUrl = brandInfo.imageBaseUrl;
    const thumbnailUrl = item.thumbnailUrl ? `${baseImageUrl}/videos/${item.thumbnailUrl}` : `${brandInfo.siteUrl}/fallback.png`;
    return thumbnailUrl;
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setShowModal(true);
          modalCallback && modalCallback(true);
        }}
        onTouchStart={() => {
          setShowModal(true);
          modalCallback && modalCallback(true);
        }}
        className={type !== 'product' ? shareBtnClass : productButton}
      >
        <p className={type !== 'product' ? iconText : `${productIconText} is-justify-content-flex-end`}>
          <span className="shareText">Share</span>
          <InlineIcon
            className={classNames('ml-2', shareIconBase, type === 'product' ? heightProductIcon : '')}
            icon="fa-solid:share"
            {...iconPropsSmall}
          />
        </p>
      </button>
      {showModal && (
        <div className={Modal}>
          <div
            className={modalBackgroundOverride}
            onClick={() => {
              setShowModal(false);
              setCopySuccess(false);
              modalCallback && modalCallback(false);
            }}
          />
          <div className={shareCard}>
            <div className={shareIconsContainer}>
              <div className={shareTitle}>Share</div>
              <SocialMediaIcons
                product={product}
                video={video}
                shareUrl={`${isBrowser && brandInfo.siteUrl}/${type}/${urlPath}`}
                isCentered
                iconClass={shareIcon}
                containerClass={iconContainer}
              />
            </div>
            <div className={shareInfoContainer}>
              {type === 'product' && urlPath && product && (
                <div className={shareDetailsContainer}>
                  <div className={shareImageContainer}>
                    <ProductImg product={product} />
                  </div>
                  <div className={shareTextGroup}>
                    <div className={shareTitle}>{product.title}</div>
                    <div className={shareText}>{productPrice}</div>
                  </div>
                </div>
              )}
              {type === 'video' && video && (
                <div className={shareDetailsContainer}>
                  <div className={shareImageContainer}>
                    <img src={getVideoImageUrl(video)} alt="" />
                  </div>
                  <div className={shareTextGroup}>
                    <div className={shareTitle}>{video.title}</div>
                  </div>
                </div>
              )}
              {type === 'search' && urlPath && (
                <div className={shareDetailsContainer}>
                  <div className={shareImageContainer}>
                    <Icon icon="eva:search-fill" />
                  </div>
                  <div className={shareTextGroup}>
                    <div className={shareTitle}>Search Results</div>
                    <div className={shareText}>
                      {`"${urlPath.substring(0, 3) === '?q=' ? decodeURIComponent(urlPath.substring(3)) : urlPath}"`}
                    </div>
                  </div>
                </div>
              )}

              <div className={shareLinkContainer}>
                {!copySuccess && (
                  <div className={shareLinkIconContainer} onClick={copyToClipboard}>
                    <Icon icon="heroicons-solid:link" className={shareLinkIcon} />
                  </div>
                )}
                {copySuccess && (
                  <div className={shareSuccessIconContainer}>
                    <Icon icon="eva:checkmark-fill" className={shareLinkIcon} />
                  </div>
                )}

                <input readOnly className={copyInput} ref={textAreaRef} value={`${isBrowser && brandInfo.siteUrl}/${type}/${urlPath}`} />
              </div>
            </div>

            <button
              className={`delete ${closeButton}`}
              onClick={() => {
                setShowModal(false);
                setCopySuccess(false);
                modalCallback && modalCallback(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
