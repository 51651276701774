/* eslint-disable import/extensions */
import React from 'react';
import classNames from 'classnames';
import { stepperProgressBar } from './styles.module.scss';

interface Props {
  totalSteps: number;
  stepsCompleted: number;
}

const ProgressBar = ({ totalSteps, stepsCompleted }: Props) => {
  const progress = (stepsCompleted / totalSteps) * 100;
  return (
    <div className={classNames(stepperProgressBar)}>
      <span style={stepsCompleted === totalSteps ? { width: `${progress}%`, backgroundImage: 'unset' } : { width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
