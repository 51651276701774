import { CartItemAddInput, CartItemUpdateInput } from './gql-types';
import { MutationType, QueryType } from './api-types';
import { getClient } from '@apiClient/usePvepApi';
import { FetchResult } from '@apollo/client';
import { ClientLogger } from '@lib/ClientLogger';
import { CartItem } from '@sharedLib/product';
import gql from 'graphql-tag';

const DEBUG = false;

export class CartServices {
  public static CART_ITEMS_QUERY = gql`
    query getCartItems($email: String!) {
      getCartItems(email: $email) {
        products {
          id
          productId
          variantId
          variantName
          title
          description
          price
          imageUrl
          quantity
          videoId
        }
        success
        errorMessages {
          errorCode
          errorMessage
        }
      }
    }
  `;

  public static async addCartItem(newItem: CartItemAddInput): Promise<FetchResult<MutationType['addCartItem']>> {
    DEBUG && ClientLogger.debug('usePvepApi.addCartItem', `newItem = ${newItem}`);

    return await getClient().mutate({
      variables: {
        newItem,
      },
      mutation: gql`
        mutation addCartItem($newItem: CartItemAddInput) {
          addCartItem(newItem: $newItem) {
            success
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      refetchQueries: ['getCartItems'], // Causes query to be run. Not very efficient but easier than hacking the cache
    });
  }

  public static async removeCartItem(email: string, variantId: string): Promise<FetchResult<MutationType['removeCartItem']>> {
    ClientLogger.debug('usePvepApi.removeCartItem', `email = ${email} variantId = ${variantId}`);

    return await getClient().mutate({
      variables: {
        email,
        variantId,
      },
      mutation: gql`
        mutation removeCartItem($email: String!, $variantId: String!) {
          removeCartItem(email: $email, variantId: $variantId) {
            success
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      refetchQueries: ['getCartItems'], // Causes query to be run. Not very efficient but easier than hacking the cache
    });
  }

  public static async updateCartItem(updatedItem: CartItemUpdateInput): Promise<FetchResult<MutationType['updateCartItem']>> {
    ClientLogger.debug('usePvepApi.updateCartItem', `updateItem = ${JSON.stringify(updatedItem)} `);

    return await getClient().mutate({
      variables: {
        updatedItem,
      },
      mutation: gql`
        mutation updateCartItem($updatedItem: CartItemUpdateInput) {
          updateCartItem(updatedItem: $updatedItem) {
            success
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      refetchQueries: ['getCartItems'], // Causes query to be run. Not very efficient but easier than hacking the cache
    });
  }

  public static async startCheckout(email: string): Promise<FetchResult<MutationType['startCheckout']>> {
    DEBUG && ClientLogger.debug('usePvepApi.startCheckout', `email = ${email}`);

    return await getClient().mutate({
      variables: {
        email,
      },
      mutation: gql`
        mutation startCheckout($email: String!) {
          startCheckout(email: $email) {
            success
            redirectUrl
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      refetchQueries: ['getCartItems'], // Causes query to be run. Not very efficient but easier than hacking the cache
    });
  }

  public static async startCheckoutAnon(offlinecartItems: CartItem[]): Promise<FetchResult<MutationType['startCheckoutAnon']>> {
    DEBUG && ClientLogger.debug('usePvepApi.startCheckoutAnon', `cartItems =`, JSON.stringify(offlinecartItems));
    const items = offlinecartItems.map((i: any) => {
      const pruned = { ...i };
      delete pruned.id;
      delete pruned.__typename;
      return pruned;
    });
    DEBUG && ClientLogger.debug('usePvepApi.startCheckoutAnon', `offlineCheckoutInput =`, items);
    return await getClient().mutate({
      variables: {
        items,
      },
      mutation: gql`
        mutation startCheckoutAnon($items: [CartItemAddInput!]!) {
          startCheckoutAnon(items: $items) {
            success
            redirectUrl
            errorMessages {
              errorCode
              errorMessage
            }
          }
        }
      `,
      context: { noAuth: true },
    });
  }

  public static async userHasPurchasedItem(
    email: string,
    variantId?: string,
    productId?: string
  ): Promise<FetchResult<QueryType['userHasPurchasedItem']>> {
    return await getClient().query({
      query: gql`
        query userHasPurchasedItem($email: String!, $variantId: String, $productId: String) {
          userHasPurchasedItem(email: $email, variantId: $variantId, productId: $productId) {
            success
            purchaseStatus
          }
        }
      `,
      variables: { email, variantId, productId },
      fetchPolicy: 'no-cache',
    });
  }
}
