import React from 'react';
import IndexLayout from '@layouts/index';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { CmsCollectionFile } from 'netlify-cms-core';
import { crowdblinkContainer } from './styles.module.scss';

const SHOW_CONTINUE_WATCHING = false;
const DEBUG = false;

///
/// CMS model
///
export const TicketsCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/ftl/pages/Tickets/ticketsPage.md`,
  label: 'Tickets',
  name: 'tickets',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/ftl/pages/Tickets/TicketsPage.tsx',
    },
    {
      label: 'crowdblinkWidgetUrl',
      name: 'crowdblinkWidgetUrl',
      widget: 'text',
      required: true,
    },
  ],
};

interface Props {
  crowdblinkWidgetUrl: string;
}

const TicketsPage = ({ pageContext }: IPageProps<Props>) => {
  return (
    <IndexLayout noVideo>
      <iframe className={crowdblinkContainer} title="crowdblink" src={pageContext.frontMatter.crowdblinkWidgetUrl} />
    </IndexLayout>
  );
};

export default TicketsPage;
