import { ClientLogger } from '@lib/ClientLogger';
import { useVideoState, VideoPlayerSize, VideoStateContext } from '@lib/useVideoState';
import { useVideoPageData } from '@lib/video-query/useVideoData';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import ResizeObserver from 'react-resize-observer';
import { BrandUtil, DatabaseVideo, VideoSource, AdWithProduct, Experiment, Strategy, AdStrategy, ExperimentUtil } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { isEmbedded } from '@src/lib/embedded';
import { usePvepApi } from '@apiClient/usePvepApi';
import { useProductData } from '@lib/shopify/useProductData';
import { v4 as uuidv4 } from 'uuid';

import {
  videoPlaceHolderSmall,
  videoPlaceHolderMedium,
  videoPlaceHolderLarge,
  videoPlaceHolderSmallEmbedded,
  videoPlayerNotShown,
  videoWrapper,
  videoPlaceHolderEmbed,
  videoPlaceHolderMediumBanner,
} from './styles.module.scss';

interface Props {
  videoId: string;
}

const DEBUG = false;

export const VideoPlaceHolder = ({ videoId }: Props) => {
  let { video } = useVideoPageData(videoId);
  const api = usePvepApi();
  const allProductData = useProductData().getAllProducts();
  const divRef = useRef<HTMLDivElement | null>(null);
  const { state } = useContext(VideoStateContext);
  const videoState = useVideoState();
  const size = state.videoPlayerSize;
  const brandInfo = BrandUtil.getSiteInfo();

  useEffect(() => {
    videoState.showPlayNext(false);
    state.showingPlayNext = false;
    loadVideo(videoId);
  }, [videoId]);

  const loadVideo = async (videoId: string) => {
    const availableProductIds = allProductData.map(product => product.id);

    if (api.state.useRuntimeData) {
      // get db video data
      let dbVideo: DatabaseVideo | undefined;
      const videoResp = await api.getDatabaseVideo(videoId);
      if (videoResp.data?.getDatabaseVideo.success && videoResp.data.getDatabaseVideo.video) {
        dbVideo = videoResp.data.getDatabaseVideo.video;
      }

      // if uploaded, get signed url to view
      if (dbVideo?.type === VideoSource.UPLOADED) {
        const urlResp = await api.getSignedVideoUrl(`${brandInfo.videoHostingConfig.baseUrl}/${dbVideo.url}`);
        if (urlResp.data?.getSignedVideoUrl.success) {
          dbVideo.url = urlResp.data.getSignedVideoUrl.url;
        }
      }

      // initialize ads array
      let newAdPlacements: AdWithProduct[] = [];
      let adStrategy: AdStrategy | undefined;
      let allAdStrategies: AdStrategy[] | undefined;
      // get experiment data, if any
      const experimentResp = await api.getExperimentDataByVideo(videoId);
      if (
        experimentResp.data?.getExperimentDataByVideo.success &&
        experimentResp.data.getExperimentDataByVideo.experiment &&
        experimentResp.data.getExperimentDataByVideo.strategies
      ) {
        const experiment = experimentResp.data.getExperimentDataByVideo.experiment as Experiment;
        const strategies = experimentResp.data.getExperimentDataByVideo.strategies as Strategy[];

        // get ad strategies
        allAdStrategies = ExperimentUtil.mapToAdStrategies({
          experiment,
          strategies,
          products: allProductData,
        });

        // check for matching preview state
        if (api.state.useAdStrategyPreview) {
          const previewStrategyId = api.state.useAdStrategyPreview.strategyId;
          const previewExperimentId = api.state.useAdStrategyPreview.experimentId;
          adStrategy = allAdStrategies.find(str => str.strategyId === previewStrategyId && str.experimentId === previewExperimentId);
        }

        // if no preview found, get strategy corresponding to user cohort
        if (!adStrategy) {
          const userId = api.state.jwt?.sub || api.getDeviceId() || uuidv4();
          adStrategy = ExperimentUtil.getStrategyForUser(userId, allAdStrategies);
        }

        // map products to ads
        if (adStrategy) {
          // check if strategy contains recommended ads, and add them
          const recommendedAds = adStrategy.ads.filter(ad => ad.isRecommendation);
          if (recommendedAds.length) {
            adStrategy = await addRecommendedProducts(adStrategy);
          }

          newAdPlacements = ExperimentUtil.mapAdsWithProducts(adStrategy.ads, allProductData);
        }
        newAdPlacements = newAdPlacements.filter(ad => availableProductIds.includes(ad.productId));
      }

      if (dbVideo) {
        videoState.load({ video: dbVideo, adPlacements: newAdPlacements, currentAdStrategy: adStrategy, adStrategies: allAdStrategies });
      }
    } else if (video) {
      // if uploaded, get signed url to view
      if (video.type === VideoSource.UPLOADED) {
        const urlResp = await api.getSignedVideoUrl(`${brandInfo.videoHostingConfig.baseUrl}/${video.url}`);
        if (urlResp.data?.getSignedVideoUrl.success) {
          video.url = urlResp.data.getSignedVideoUrl.url;
        }
      }

      // get strategy corresponding to user cohort
      const userId = api.state.jwt?.sub || api.getDeviceId() || uuidv4();
      let adStrategy = ExperimentUtil.getStrategyForUser(userId, video.adStrategies || []);

      // map products to ads
      let adPlacements: AdWithProduct[] = [];
      if (adStrategy) {
        // check if strategy contains recommended ads, and add them
        const recommendedAds = adStrategy.ads.filter(ad => ad.isRecommendation);
        if (recommendedAds.length) {
          adStrategy = await addRecommendedProducts(adStrategy);
        }

        adPlacements = ExperimentUtil.mapAdsWithProducts(adStrategy.ads, allProductData);
      }
      adPlacements = adPlacements.filter(ad => availableProductIds.includes(ad.productId));

      videoState.load({ video, adPlacements, currentAdStrategy: adStrategy });
    }
  };

  ///
  /// Adds recommended products to ad strategy, replacing placeholders
  ///
  const addRecommendedProducts = async (adStrategy: AdStrategy): Promise<AdStrategy> => {
    // get recommendations
    const recommendResp = await api.getRecommendedProducts(api.getDeviceId() || uuidv4(), api.state.isLoggedIn);

    if (!recommendResp.data?.getRecommendedProducts.success) {
      ClientLogger.error(
        'VideoPlaceholder.addRecommendedProducts',
        JSON.stringify(recommendResp.data?.getRecommendedProducts.errorMessages)
      );
      adStrategy.ads = adStrategy.ads.filter(ad => !ad.isRecommendation); // filter out recommended as they were not returned
      return adStrategy;
    }

    if (!recommendResp.data?.getRecommendedProducts.recommendations.length) {
      ClientLogger.error('VideoPlaceholder.addRecommendedProducts', 'No recommendations were returned');
      adStrategy.ads = adStrategy.ads.filter(ad => !ad.isRecommendation); // filter out recommended as they were not returned
      return adStrategy;
    }

    // swap recommended product ids with placeholders
    const recommendations = recommendResp.data.getRecommendedProducts.recommendations;
    let recommendationCounter = 0;
    const adsWithRecommendations = adStrategy.ads.map(ad => {
      const recommended = recommendations[recommendationCounter];
      recommendationCounter++;
      if (recommendationCounter >= recommendations.length) {
        recommendationCounter = 0;
      }
      return {
        ...ad,
        productId: recommended.id,
      };
    });
    adStrategy.ads = adsWithRecommendations;

    return adStrategy;
  };

  // this will tell videoplayer how far down the page the placeholder is
  const boundTop = divRef?.current?.getBoundingClientRect().top;
  useEffect(() => {
    if (divRef?.current && isBrowser) {
      const placeholderPosition = divRef.current.getBoundingClientRect().top + window.scrollY;
      if (placeholderPosition !== videoState.state.placeholderPosition) {
        videoState.setPlaceholderPosition(divRef.current.getBoundingClientRect().top + window.scrollY);
      }
    }
  }, [boundTop]);

  let mediumClass = videoPlaceHolderMedium;
  if (isEmbedded()) {
    mediumClass = videoPlaceHolderEmbed;
  }

  return (
    <>
      <div
        ref={divRef}
        id="video-placeholder"
        className={classnames(
          size === VideoPlayerSize.SMALL_FLOAT ? videoPlaceHolderSmall : undefined,
          size === VideoPlayerSize.MEDIUM_EMBEDDED
            ? brandInfo.videoPlayerConfig.useBannerAds
              ? videoPlaceHolderMediumBanner
              : mediumClass
            : undefined,
          size === VideoPlayerSize.LARGE_FULLSCREEN ? videoPlaceHolderLarge : undefined,
          size === VideoPlayerSize.SMALL_EMBEDDED ? videoPlaceHolderSmallEmbedded : undefined,
          size === VideoPlayerSize.NOT_SHOWN ? videoPlayerNotShown : undefined,
          videoWrapper
        )}
      >
        <ResizeObserver
          onReflow={rect => {
            ClientLogger.debug('ResizeObserver', 'onReflow', DEBUG, { rect, size, sizeName: VideoPlayerSize[size] });
            videoState.setCoords({
              size: { width: Math.round(rect.width), height: Math.round(rect.height) },
              position: { x: rect.x, y: rect.y },
            });
          }}
        />
      </div>
    </>
  );
};
