import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@components/Buttons';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Tabs } from '@components/Tabs';
import {
  noWrapButton,
  adminSubText,
  flexRow,
  adminText,
  adminTitle,
  adminInput,
  adminPadBottom,
  collectionListEpisode,
  dragHandle,
  collectionEpisodeRow,
  adminMarginBottom,
  errorMessage as errorStyle,
  collectionButtonGroup,
  applyFlex,
  videoMetadataActiveTab,
  videoMetadataTabs,
  videoMetadataInactiveTab,
  sliderContainer,
  videoSliderPage,
  sliderImage,
  sliderItem,
  sliderItemTitle,
  closeButton,
  extraSliderItem,
  extraSliderIcon,
  sliderPoolContainer,
  metadataFormField,
  adminCheckbox,
  distributionRow,
} from './styles.module.scss';
import { BrandUtil, DatabaseVideo, DatabaseSeries, DatabaseCategory, DatabaseCategoryItem, ascendingOrder } from '@sharedLib/index';

interface Props {
  editingCategoryId: string;
  videos: DatabaseVideo[];
  series: DatabaseSeries[];
  nextRank?: number | null;
}

type TabsHandle = React.ElementRef<typeof Tabs>;

const categoryValidationSchema = yup.object().shape({
  title: yup.string().required('This is required.'),
  isAvailable: yup.boolean().required('This is required.'),
  isFree: yup.boolean().required('This is required.'),
  isFeatured: yup.boolean().required('This is required.'),
});

export const DatabaseCategoryForm = ({ editingCategoryId, videos, series, nextRank }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);

  const [initialCategory, setInitialCategory] = useState<DatabaseCategory | undefined>(undefined);

  const api = usePvepApi();
  const tabsRef = useRef<TabsHandle>(null);

  useEffect(() => {
    if (editingCategoryId) {
      getCategory(editingCategoryId);
    } else {
      setLoading(false);
    }
  }, [editingCategoryId]);

  const getCategory = async (categoryId: string) => {
    setLoading(true);
    const categoryResp = await api.getDatabaseCategoryById(categoryId);
    if (categoryResp.data?.getDatabaseCategoryById.success && categoryResp.data.getDatabaseCategoryById.category) {
      const databaseCategoryRaw = categoryResp.data.getDatabaseCategoryById.category;
      const newItems = databaseCategoryRaw.items
        .map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          return newItem;
        })
        .sort((a, b) => ascendingOrder(a.rank, b.rank));
      const databaseCategory: DatabaseCategory = {
        id: databaseCategoryRaw.id,
        title: databaseCategoryRaw.title,
        isAvailable: databaseCategoryRaw.isAvailable,
        isFree: databaseCategoryRaw.isFree,
        isFeatured: databaseCategoryRaw.isFeatured,
        thumbnailUrl: databaseCategoryRaw.thumbnailUrl,
        rank: databaseCategoryRaw.rank,
        items: newItems,
      };

      setInitialCategory(databaseCategory);
      setLoadingError('');
    } else {
      setLoadingError(
        JSON.stringify(categoryResp.data?.getDatabaseCategoryById.errorMessages) ||
          'Something went wrong loading the slider from the database.'
      );
      setInitialCategory(undefined);
    }
    setLoading(false);
  };

  const writeCategory = async (values: {
    title: string;
    isAvailable: boolean;
    isFree: boolean;
    isFeatured: boolean;
    items: DatabaseCategoryItem[];
  }) => {
    // if already has rank, preserve, if not, write new rank
    let rank = initialCategory?.rank || nextRank;
    if (values.isFeatured) {
      // featured categories do not have rank
      rank = null;
    }

    const category: DatabaseCategory = {
      id: initialCategory?.id || '',
      title: values.title,
      isAvailable: values.isAvailable,
      isFree: values.isFree,
      isFeatured: values.isFeatured,
      rank,
      items: values.items.map((item, i) => {
        return {
          ...item,
          rank: i,
        };
      }),
    };

    const writeResp = await api.writeDatabaseCategory(category);
    if (writeResp.data?.writeDatabaseCategory.success && writeResp.data.writeDatabaseCategory.categoryId) {
      setMessage('Data saved successfully.');
      setErrorMessage('');

      await getCategory(writeResp.data.writeDatabaseCategory.categoryId);
    } else {
      setMessage('');
      setErrorMessage(JSON.stringify(writeResp.data?.writeDatabaseCategory.errorMessages) || 'Something went wrong, please try again.');
    }
  };

  const getVideoImage = (videoId: string) => {
    const baseUrl = brandInfo.imageBaseUrl;
    const video = videos.find(vid => vid.id === videoId);

    const url = video?.thumbnailUrl;

    return (
      <div className={sliderImage}>
        <img src={url ? `${baseUrl}/videos/${url}` : `${brandInfo.siteUrl}/fallback.png`} alt="" />
      </div>
    );
  };

  const getVideoTitle = (videoId: string) => {
    const video = videos.find(vid => vid.id === videoId);
    return video?.title || '';
  };

  const getSeriesImage = (seriesId: string) => {
    const baseUrl = brandInfo.imageBaseUrl;
    const serie = series.find(ser => ser.id === seriesId);
    const url = serie?.thumbnailUrl;
    return (
      <div className={sliderImage}>
        <img src={url ? `${baseUrl}/series/${url}` : `${brandInfo.siteUrl}/fallback.png`} alt="" />
      </div>
    );
  };

  const getSeriesTitle = (seriesId: string) => {
    const serie = series.find(ser => ser.id === seriesId);
    return serie?.title || '';
  };

  return (
    <div className={videoSliderPage}>
      {loading && <div className={adminSubText}>Loading...</div>}
      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!loading && !loadingError && (
        <Formik
          initialValues={{
            title: initialCategory?.title || '',
            isAvailable: initialCategory ? initialCategory.isAvailable : true,
            isFree: initialCategory?.isFree || false,
            isFeatured: initialCategory?.isFeatured || false,
            items: initialCategory?.items || [],
          }}
          enableReinitialize
          validateOnChange={false}
          validationSchema={categoryValidationSchema}
          onSubmit={async values => {
            await writeCategory(values);
          }}
        >
          {props => (
            <>
              <div className={flexRow}>
                <div className={adminTitle}>{initialCategory ? 'EDIT SLIDER' : 'ADD SLIDER'}</div>
                <div className={classNames(applyFlex, collectionButtonGroup)}>
                  <div>
                    <Button className={noWrapButton} onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                      SAVE ALL
                    </Button>
                  </div>
                  {errorMessage && <div className={errorStyle}>{errorMessage}</div>}
                  {message && <div>{message}</div>}
                </div>
              </div>
              <div className={adminPadBottom}>
                <div>Title</div>
                <Field
                  className={adminInput}
                  value={props.values.title}
                  onChange={props.handleChange}
                  id="title"
                  name="title"
                  placeholder="Title"
                />
                {props.touched.title && props.errors.title && <div className={errorStyle}>{props.errors.title}</div>}
              </div>

              <div>
                <div className={adminTitle}>Distribution</div>
              </div>
              <div className={distributionRow}>
                <div>
                  <div>Visibility</div>
                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isAvailable"
                      checked={props.values.isAvailable}
                      className={adminCheckbox}
                      onChange={() => {
                        if (!props.values.isAvailable) {
                          props.setFieldValue('isAvailable', true);
                        }
                      }}
                    />
                    <div>Public</div>
                  </label>

                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isAvailable_private"
                      checked={!props.values.isAvailable}
                      className={adminCheckbox}
                      onChange={() => {
                        if (props.values.isAvailable) {
                          props.setFieldValue('isAvailable', false);
                        }
                      }}
                    />
                    <div>Private</div>
                  </label>
                </div>

                <div>
                  <div>Availability</div>
                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isFree"
                      checked={props.values.isFree}
                      className={adminCheckbox}
                      onChange={() => {
                        if (!props.values.isFree) {
                          props.setFieldValue('isFree', true);
                        }
                      }}
                    />
                    <div>Anyone</div>
                  </label>

                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isFree_private"
                      checked={!props.values.isFree}
                      className={adminCheckbox}
                      onChange={() => {
                        if (props.values.isFree) {
                          props.setFieldValue('isFree', false);
                        }
                      }}
                    />
                    <div>Subscribers</div>
                  </label>
                </div>
              </div>

              <div>
                <div className={adminTitle}>Featured</div>
              </div>
              <div className={distributionRow}>
                <div>
                  <label className={metadataFormField}>
                    <Field
                      type="checkbox"
                      name="isFeatured"
                      checked={props.values.isFeatured}
                      className={adminCheckbox}
                      onChange={() => {
                        props.setFieldValue('isFeatured', !props.values.isFeatured);
                      }}
                    />
                    <div>Featured Category</div>
                  </label>
                </div>
              </div>

              <div>
                <div className={adminTitle}>Slider</div>
              </div>

              <div
                className={classNames(adminMarginBottom, sliderContainer)}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDragEnter={e => {
                  e.preventDefault();
                }}
                onDrop={e => {
                  e.preventDefault();

                  const seriesId = e.dataTransfer.getData('series');
                  if (seriesId) {
                    const newItem: DatabaseCategoryItem = {
                      id: '',
                      rank: props.values.items.length,
                      seriesId,
                      categoryId: initialCategory?.id || '',
                    };

                    props.setFieldValue('items', [...props.values.items, newItem]);
                  }

                  const videoId = e.dataTransfer.getData('video');
                  if (videoId) {
                    const newItem: DatabaseCategoryItem = {
                      id: '',
                      rank: props.values.items.length,
                      videoId,
                      categoryId: initialCategory?.id || '',
                    };

                    props.setFieldValue('items', [...props.values.items, newItem]);
                  }
                }}
              >
                {props.values.items.map((categoryItem, i) => (
                  <div
                    className={sliderItem}
                    key={i}
                    draggable
                    onDragStart={e => {
                      e.dataTransfer.setData('itemIndex', i.toString());
                      const el = e.target as HTMLDivElement;
                      el.style.opacity = '0.2';
                    }}
                    onDragEnd={e => {
                      e.preventDefault();
                      const el = e.target as HTMLDivElement;
                      el.style.opacity = '1';
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    onDragEnter={e => {
                      e.preventDefault();
                    }}
                    onDrop={e => {
                      e.preventDefault();

                      const indexText = e.dataTransfer.getData('itemIndex');
                      if (indexText) {
                        const index = parseInt(indexText);
                        let tempItems = [...props.values.items];
                        const draggedItem = tempItems.splice(index, 1);
                        tempItems = [...tempItems.slice(0, i), ...draggedItem, ...tempItems.slice(i)];
                        props.setFieldValue('items', tempItems);
                      }
                    }}
                  >
                    {categoryItem.videoId && <div className={sliderItemTitle}>{getVideoTitle(categoryItem.videoId)}</div>}
                    {categoryItem.videoId && getVideoImage(categoryItem.videoId)}

                    {categoryItem.seriesId && <div className={sliderItemTitle}>{getSeriesTitle(categoryItem.seriesId)}</div>}
                    {categoryItem.seriesId && getSeriesImage(categoryItem.seriesId)}

                    <button
                      className={`delete ${closeButton}`}
                      onClick={() => {
                        const newItems = [...props.values.items];
                        newItems.splice(i, 1);
                        props.setFieldValue('items', newItems);
                      }}
                    />
                  </div>
                ))}

                <div className={extraSliderItem}>
                  <Icon icon="bi:plus-circle" className={extraSliderIcon} />
                  <div>Drag and Drop</div>
                </div>
              </div>

              <Tabs
                ref={tabsRef}
                className={videoMetadataTabs}
                tabRootClassName={videoMetadataInactiveTab}
                activeClassName={videoMetadataActiveTab}
                labels={['Videos', 'Collections']}
              >
                <div className={sliderPoolContainer}>
                  {videos.map((video, i) => (
                    <div className={classNames(adminMarginBottom, flexRow, collectionEpisodeRow)} key={i}>
                      <div className={classNames(adminText)}>{i + 1}</div>
                      <div
                        className={classNames(collectionListEpisode, flexRow)}
                        draggable
                        onDragStart={e => {
                          e.dataTransfer.setData('video', video.id);
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '0.2';
                        }}
                        onDragEnd={e => {
                          e.preventDefault();
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '1';
                        }}
                      >
                        <div>{video.title}</div>
                        <div className={dragHandle}>
                          <Icon icon="akar-icons:drag-vertical" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={sliderPoolContainer}>
                  {series.map((series, i) => (
                    <div className={classNames(adminMarginBottom, flexRow, collectionEpisodeRow)} key={i}>
                      <div className={classNames(adminText)}>{i + 1}</div>
                      <div
                        className={classNames(collectionListEpisode, flexRow)}
                        draggable
                        onDragStart={e => {
                          e.dataTransfer.setData('series', series.id);
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '0.2';
                        }}
                        onDragEnd={e => {
                          e.preventDefault();
                          const el = e.target as HTMLDivElement;
                          el.style.opacity = '1';
                        }}
                      >
                        <div>{series.title}</div>
                        <div className={dragHandle}>
                          <Icon icon="akar-icons:drag-vertical" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tabs>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};
