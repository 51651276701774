import React from 'react';
import IndexLayout from '@layouts/index';
import classNames from 'classnames';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { CmsCollectionFile } from 'netlify-cms-core';
import {
  badgeContainer,
  badgeImage,
  bannerContainer,
  bannerImage,
  lineupContainer,
  lineupImage,
  partnerImage,
  partnerImageContainer,
  partnerImageRow,
  partnerImageSection,
  partnerOverlay,
  partnersContainer,
  partnersSection,
  partnerTitle,
  setTimeContainer,
  setTimeImage,
} from './styles.module.scss';

const SHOW_CONTINUE_WATCHING = false;
const DEBUG = false;

///
/// CMS model
///
export const DayCampCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/ftl/pages/DayCamp/dayCamp.md`,
  label: 'Day Camp',
  name: 'day_camp',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/ftl/pages/DayCamp/DayCamp.tsx',
    },
  ],
};

const DayCamp = () => {
  return (
    <IndexLayout noVideo>
      <div className={badgeContainer}>
        <img className={badgeImage} src="./FTL_assets/ftlkidsbadge.png" alt="" />
      </div>

      <div className={bannerContainer}>
        <img className={bannerImage} src="./FTL_assets/ftlkidsbanner.png" alt="" />
      </div>

      <div className={lineupContainer}>
        <img className={lineupImage} src="./FTL_assets/ftlkidslineup.png" alt="" />
      </div>

      <div className={setTimeContainer}>
        <img className={setTimeImage} src="./FTL_assets/dayCamp/daycamp_lineup.png" alt="" />
      </div>

      <div className={partnersSection}>
        <div className={partnerImageSection}>
          <div className={partnerImageRow}>
            <div className={partnerImageContainer}>
              <a href={`https://splashnboots.com/`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>SPLASH'N BOOTS</div>
                </div>
                <img src="./FTL_assets/dayCamp/splash_boots.webp" alt="" />
              </a>
            </div>
            <div className={partnerImageContainer}>
              <a href={`https://www.youtube.com/channel/UCKK0Ls3-3u9JMpIKIXPVM0w`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>RECESS THERAPY</div>
                </div>
                <img src="./FTL_assets/dayCamp/recess_therapy.webp" alt="" />
              </a>
            </div>
          </div>
          <div className={partnerImageRow}>
            <div className={partnerImageContainer}>
              <a href={`https://ultimutts.ca/`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>ULTIMUTTS</div>
                </div>
                <img src="./FTL_assets/dayCamp/ultimutts.webp" alt="" />
              </a>
            </div>
            <div className={partnerImageContainer}>
              <a href={`https://www.njackobacko.com/`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>NJACKO BACKO</div>
                </div>
                <img src="./FTL_assets/dayCamp/njacko_backo.webp" alt="" />
              </a>
            </div>
          </div>
          <div className={partnerImageRow}>
            <div className={partnerImageContainer}>
              <a href={`https://www.blankcanvases.org/`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>BLANK CANVASES</div>
                </div>
                <img src="./FTL_assets/dayCamp/blank_canvas.webp" alt="" />
              </a>
            </div>
            <div className={partnerImageContainer}>
              <a href={`https://www.hoopersonic.com/`} target="_blank" rel="noreferrer">
                <div className={partnerOverlay}>
                  <div className={partnerTitle}>HOOPERSONIC</div>
                </div>
                <img src="./FTL_assets/dayCamp/hoopersonics.webp" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className={partnersContainer}>
          <div>
            <img className={partnerImage} src="./FTL_assets/mediapartnerwhite1.png" alt="" />
          </div>
          <div>
            <img className={partnerImage} src="./FTL_assets/mediapartnerwhite2.png" alt="" />
          </div>
          <div>
            <img className={partnerImage} src="./FTL_assets/mediapartnerwhite3.png" alt="" />
          </div>
        </div>
      </div>
    </IndexLayout>
  );
};

export default DayCamp;
