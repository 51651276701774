import { getClient } from '@apiClient/usePvepApi';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import { MutationType, QueryType } from './api-types';

export class UserMessagesService {
  public static USER_MESSAGES_GET_QUERY = gql`
    query userMessagesGet($messageType: String, $deviceId: String) {
      userMessagesGet(messageType: $messageType, deviceId: $deviceId) {
        success
        errorMessages {
          errorCode
          errorMessage
        }
        userMessages {
          id
          createdAt
          messageType
          deviceId
          payload
        }
      }
    }
  `;

  public static async userMessagesGet(messageType?: string, deviceId?: string): Promise<ApolloQueryResult<QueryType['userMessagesGet']>> {
    return getClient().query({
      query: UserMessagesService.USER_MESSAGES_GET_QUERY,
      fetchPolicy: 'network-only',
      variables: { messageType, deviceId },
    });
  }

  public static async userMessageAck(
    messageId: string,
    deviceId: string,
    payload?: string | any
  ): Promise<FetchResult<MutationType['userMessageAck']>> {
    return getClient().mutate({
      mutation: gql`
        mutation userMessageAck($messageId: String!, $deviceId: String!, $payload: String) {
          userMessageAck(messageId: $messageId, deviceId: $deviceId, payload: $payload) {
            success
            errorMessages {
              errorCode
              errorMessage
            }
            userMessages {
              id
              createdAt
              messageType
              deviceId
              payload
            }
          }
        }
      `,
      variables: { messageId, deviceId, payload: typeof payload !== 'string' ? JSON.stringify(payload) : payload },
    });
  }
}
