import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { offColorText, boldFont, largeText, videoListItem, smallText, extraLargeText } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { usePvepApi } from '@apiClient/usePvepApi';
import { VideoCollectionSeedData } from './VideoCollectionExport';
import { DatabaseSeries, DatabaseSeason, DatabaseCategory } from '@sharedLib/index';

export const VideoCollectionImport = () => {
  const api = usePvepApi();

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');

  const [seasons, setSeasons] = useState<DatabaseSeason[]>([]);
  const [series, setSeries] = useState<DatabaseSeries[]>([]);
  const [categories, setCategories] = useState<DatabaseCategory[]>([]);

  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState('');
  const [branchMessage, setBranchMessage] = useState<{ branch: string; timestamp: number; bucket: string } | undefined>(undefined);

  ///
  /// Write all video collections to db
  ///
  const seedVideoCollectionDatabase = async (
    sourceBranch: string,
    sourceBucket: string,
    categories: DatabaseCategory[],
    series: DatabaseSeries[],
    seasons: DatabaseSeason[]
  ) => {
    const seedResp = await api.seedVideoCollectionDatabase(sourceBranch, sourceBucket, categories, series, seasons);
    if (seedResp.data?.seedVideoCollectionDatabase.success) {
      setConfirmation('Seeding complete.');
    } else {
      setConfirmation('');
      setUploadErrors([
        'Something has gone wrong when attempting to seed this database.',
        JSON.stringify(seedResp.data?.seedVideoCollectionDatabase.errorMessages),
      ]);
    }
  };

  return (
    <div>
      <div className={classNames(videoListItem, boldFont)}>
        WARNING: For this tool to work properly, please add all video data first. Using this tool will overwrite all current video
        collection data. It is to be used to seed new branches and in local development.
      </div>

      <Formik
        initialValues={{
          file: '',
        }}
        onSubmit={async (_, { resetForm }) => {
          if (branchMessage?.branch) {
            await seedVideoCollectionDatabase(branchMessage?.branch, branchMessage.bucket, categories, series, seasons);
          }
          resetForm();
          setCategories([]);
          setSeasons([]);
          setSeries([]);
          setBranchMessage(undefined);
        }}
      >
        {props => (
          <div>
            <Field
              innerRef={fileUploadRef}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.currentTarget.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = function readFile(event) {
                    if (event.target) {
                      let dataString = event.target.result as string;
                      dataString = dataString.replace('data:application/json;base64,', '');
                      dataString = atob(dataString);
                      try {
                        const collectionDataRaw = JSON.parse(dataString);

                        if (!collectionDataRaw.seasons || !collectionDataRaw.categories || !collectionDataRaw.series) {
                          return;
                        }

                        setCategories(collectionDataRaw.categories);
                        setSeasons(collectionDataRaw.seasons);
                        setSeries(collectionDataRaw.series);

                        setBranchMessage({
                          branch: collectionDataRaw.branch,
                          timestamp: collectionDataRaw.timestamp,
                          bucket: collectionDataRaw.bucket,
                        });
                        setError('');
                      } catch (e) {
                        setBranchMessage(undefined);
                        setError('Error parsing JSON file.');
                        return;
                      }
                    }
                  };
                }
              }}
              id="fileUpload"
              type="file"
              name="fileUpload"
              accept="application/json"
            />

            {error && <div className={videoListItem}>{error}</div>}
            {confirmation && <div className={videoListItem}>{confirmation}</div>}
            {uploadErrors.length > 0 && uploadErrors.map(error => <div className={videoListItem}>{error}</div>)}

            {(categories.length > 0 || series.length > 0 || seasons.length > 0) && (
              <Button onClick={props.handleSubmit} disabled={props.isSubmitting} className={videoListItem}>
                IMPORT ALL TO DB
              </Button>
            )}
          </div>
        )}
      </Formik>

      {branchMessage && (
        <div className={videoListItem}>
          <div className={largeText}>
            <div>
              This data is from branch: <span className={boldFont}>{branchMessage.branch}</span>
            </div>

            <div>
              This data was exported on: <span className={boldFont}>{new Date(branchMessage.timestamp).toString()}</span>
            </div>
          </div>
        </div>
      )}

      {categories.length > 0 && <div className={classNames(videoListItem, extraLargeText, boldFont)}>Categories</div>}
      {categories.map((cat, i) => (
        <div className={videoListItem} key={i}>
          <div className={largeText}>{cat.title}</div>
        </div>
      ))}

      {series.length > 0 && <div className={classNames(videoListItem, extraLargeText, boldFont)}>Series</div>}
      {series.map((ser, i) => (
        <div className={videoListItem} key={i}>
          <div className={largeText}>{ser.title}</div>
        </div>
      ))}

      {seasons.length > 0 && <div className={classNames(videoListItem, extraLargeText, boldFont)}>Seasons</div>}
      {seasons.map((season, i) => (
        <div className={videoListItem} key={i}>
          <div className={largeText}>{season.title}</div>
        </div>
      ))}
    </div>
  );
};
