import React from 'react';

export const ObjectRecognitionIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.87179 0H0V9.87179H1.79487V1.79487H9.87179V0Z" fill="currentColor" />
      <path d="M35 9.87179L35 0L25.1282 -4.3151e-07L25.1282 1.79487L33.2051 1.79487L33.2051 9.87179L35 9.87179Z" fill="currentColor" />
      <path d="M25.1282 35L35 35L35 25.1282L33.2051 25.1282L33.2051 33.2051L25.1282 33.2051L25.1282 35Z" fill="currentColor" />
      <path
        d="M1.29453e-06 25.1282L0 35L9.87179 35L9.87179 33.2051L1.79487 33.2051L1.79487 25.1282L1.29453e-06 25.1282Z"
        fill="currentColor"
      />
      <path
        d="M18.24 7.01909C18.0525 6.94359 17.8433 6.94359 17.6558 7.01909L8.28296 10.786L17.9479 14.6697L27.6128 10.786L18.24 7.01909ZM28.9415 11.9522L18.7331 16.0553V28.5569L28.9415 24.4539V11.9522ZM17.1626 28.5585V16.0537L6.9543 11.9522V24.4555L17.1626 28.5585ZM17.0731 5.55304C17.6347 5.32737 18.2611 5.32737 18.8227 5.55304L30.0189 10.0538C30.1644 10.1124 30.2892 10.2135 30.3771 10.344C30.465 10.4745 30.512 10.6284 30.512 10.786V24.4555C30.5118 24.7708 30.4176 25.0788 30.2415 25.3399C30.0654 25.6009 29.8156 25.8029 29.5241 25.9199L18.24 30.4554C18.0525 30.5309 17.8433 30.5309 17.6558 30.4554L6.37321 25.9199C6.08149 25.8032 5.83129 25.6012 5.65493 25.3402C5.47856 25.0791 5.38411 24.771 5.38379 24.4555V10.786C5.38383 10.6284 5.43082 10.4745 5.51871 10.344C5.6066 10.2135 5.73136 10.1124 5.87693 10.0538L17.0731 5.55304Z"
        fill="currentColor"
      />
    </svg>
  );
};
