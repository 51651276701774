import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import React, { useContext } from 'react';
import { useVideoState, VideoPlayerSize } from '@src/lib/useVideoState';
import { useVideo, useVideoParentCollections } from '@src/lib/video-query/useVideoData';
import { BrandUtil } from '@sharedLib/index';
import { isMobile, isTablet, isMobilePortrait } from '@src/lib/responsive';

import {
  breadCrumbContainerWrapper,
  breadCrumbContainer,
  breadCrumbSeparator,
  breadCrumbItem,
  breadCrumbLastItem,
  applyVerticalAlign,
} from './styles.module.scss';

const DEBUG = false;

interface IProps {
  isVideoPage?: boolean;
  seriesCrumb?: BreadCrumb;
  productCrumb?: BreadCrumb;
}

interface BreadCrumb {
  label: string;
  path?: string;
  isVideoPath?: boolean;
}

export const BreadCrumbs = ({ isVideoPage, seriesCrumb, productCrumb }: IProps) => {
  const nav = useContext(NavStateContext);
  const pages = [...nav.navState.pageData];
  const brandInfo = BrandUtil.getSiteInfo();
  const mobile = isMobile();
  const mobilePortrait = isMobilePortrait();
  const tablet = isTablet();

  ClientLogger.debug('BreadCrumbs', 'render', DEBUG, { pages });
  const videoState = useVideoState();
  const playerSize = videoState.state.videoPlayerSize;
  const { video } = videoState.state;
  const videoJson = useVideo(video?.id || '');
  const videoParentCollections = useVideoParentCollections(video?.id);

  // only show breadcrumbs when a video is playing, or on series page, for now
  if ((!video || playerSize === VideoPlayerSize.NOT_SHOWN) && !seriesCrumb) {
    return <></>;
  }

  const handleClick = (crumb: BreadCrumb) => {
    if (!crumb.path) return;

    if (crumb.isVideoPath) {
      if (isVideoPage) {
        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
      } else {
        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
        nav.push({ path: crumb.path, title: crumb.label });
      }
    }

    nav.push({ path: crumb.path, title: crumb.label, state: { preventAutoplay: true } });
  };

  const getHomeTitle = (): string => {
    // check if alternate title for home page exists
    let homeTitle = 'Home';
    for (const link of brandInfo.headerConfig.desktop) {
      if (link.path === '/app') {
        homeTitle = link.label || '';
        break;
      }
    }
    return homeTitle;
  };

  const getVideoCrumbs = (videoId: string): BreadCrumb[] => {
    // get video and parents data
    const videoParents = videoParentCollections.series;

    // may be multiple video parents, check if nav history contains one of them
    // if not, use first returned parent or none at all
    let canonicalParent;
    if (videoParents.length) {
      canonicalParent = { label: videoParents[0].title, path: `/series/${videoParents[0].id}` };

      // check history in reverse
      for (const page of pages.reverse()) {
        if (videoParents.findIndex(collection => collection.title === page.title) !== -1) {
          canonicalParent = { label: page.title, path: page.path };
          break;
        }
      }
    }

    const retCrumbs: BreadCrumb[] = [];

    // add home unless mobile portrait
    // add home if there is no canonical parent
    if (!mobilePortrait || !canonicalParent) {
      retCrumbs.push({
        label: getHomeTitle(),
        path: '/app',
      });
    }

    // if on mobile product page, get rid of series crumb
    if (!(mobile && productCrumb)) {
      retCrumbs.push({
        label: canonicalParent?.label || '',
        path: canonicalParent?.path || undefined,
      });
    }

    // if on mobile or tablet product page, dont show video crumb
    // as video is already visible and playing
    if (!((mobile || tablet) && productCrumb)) {
      let videoLabel = videoJson?.label || videoJson?.title;
      if (mobilePortrait && videoLabel && videoLabel.length > 20) {
        videoLabel = `${videoLabel.slice(0, 17)}...`;
      }
      retCrumbs.push({
        label: videoLabel || '',
        path: `/video/${videoId}`,
        isVideoPath: true,
      });
    }

    // dont add featured products crumb when mobile or tablet
    // do add it if on a product page
    if (!(mobile || tablet) || productCrumb) {
      retCrumbs.push({
        label: mobile ? 'Featured' : 'Featured Products',
        path: productCrumb ? `/video/${videoId}` : undefined,
      });
    }

    return retCrumbs;
  };

  const allCrumbs: BreadCrumb[] = [];

  // if a video is present and not hidden
  if (video && playerSize !== VideoPlayerSize.NOT_SHOWN) {
    const videoCrumbs = getVideoCrumbs(video.id);
    allCrumbs.push(...videoCrumbs);
  } else if (seriesCrumb) {
    // run when on series page, push series crumb directly
    allCrumbs.push({
      label: getHomeTitle(),
      path: '/app',
    });
    allCrumbs.push(seriesCrumb);
  }

  // if a product crumb exists, then we are currently on the product page
  // clicking featured products brings back to the video page with minimized player
  if (productCrumb) {
    if (mobilePortrait && productCrumb.label.length > 30) {
      productCrumb.label = `${productCrumb.label.slice(0, 17)}...`;
    }
    allCrumbs.push(productCrumb);
  }

  DEBUG && ClientLogger.debug('Breadcrumbs', `render crumbs`, DEBUG, { allCrumbs, isVideoPage, productCrumb, seriesCrumb });
  const crumbs = allCrumbs.filter(crumb => crumb.label !== '');

  return (
    <div className={breadCrumbContainerWrapper}>
      <div className={breadCrumbContainer}>
        {crumbs.map((crumb, i) => {
          const isLastItem = i === crumbs.length - 1;

          return (
            <div key={i} className={applyVerticalAlign}>
              <div onClick={() => handleClick(crumb)} className={isLastItem || !crumb.path ? breadCrumbLastItem : breadCrumbItem}>
                {crumb.label}
              </div>
              {!isLastItem && <div className={breadCrumbSeparator}>{'>'} </div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
