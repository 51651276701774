import React, { useContext } from 'react';
import { Img } from '@components/Img';
import { NavStateContext } from '@lib/navContext';
import { useHomeResource } from '@src/pageComponents/Home/useHomeResources';
import { DivButton } from '@components//Buttons';
import { VideoPlayButton } from '@components/VideoPreviewCard';
import { featuredVideoContainer, logoContainer, logo, heroContent as heroContentStyle } from './styles.module.scss';
import {
  largeVideoCard,
  videoActions,
  largeVideoButton,
  videoOverlay,
  videoThumbnail,
  largeVideoTitle,
  largeVideoDescription,
} from '@components/VideoCard/styles.module.scss';
import { BrandUtil, Util, DatabaseVideo } from '@sharedLib/index';

interface Props {
  video: DatabaseVideo;
}

export const FeaturedVideo = ({ video }: Props) => {
  const resource = useHomeResource();
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();

  const handleClick = () => {
    nav.push({ path: `/video/${video.id}`, title: video.label || video.title });
  };

  const baseImageUrl = brandInfo.imageBaseUrl;
  const thumbnailUrl = video.thumbnailUrl ? `${baseImageUrl}/videos/${video.thumbnailUrl}` : `${brandInfo.siteUrl}/fallback.png`;

  return (
    <div className={featuredVideoContainer}>
      <div className={logoContainer}>
        <div className={logo}>
          <Img childImageSharp={resource.heroImage.childImageSharp} />
        </div>
        <div>{resource.content && <div className={heroContentStyle}>{resource.content}</div>}</div>
      </div>
      <div className={largeVideoCard} data-cy-video-card-id={video.id}>
        <img className={videoThumbnail} src={thumbnailUrl} title={video.title} alt={video.title} />

        <DivButton className={videoOverlay} onClick={handleClick}>
          <div className={videoActions}>
            <p className={largeVideoTitle}>{video.label || video.title}</p>
            <VideoPlayButton iconProps={{ height: '90px' }} className={largeVideoButton} />
            <p className={largeVideoDescription}>{video.description}</p>
          </div>
        </DivButton>
      </div>
    </div>
  );
};
