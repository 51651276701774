import { Ad, AdStrategy } from '../product';

export enum VideoSource {
  VIMEO_OTT = 'vimeo-ott',
  YOUTUBE = 'youtube',
  CORE_VIMEO = 'core-vimeo',
  UPLOADED = 'uploaded',
}

export type ItemType = 'category' | 'movie' | 'series' | 'season' | 'video';

export function isCollection(itemType: ItemType): boolean {
  return !(itemType === 'video');
}

interface VideoBase {
  id: string; // Gatsby forces this to be a string - https://github.com/gatsbyjs/gatsby/pull/17072
  videoSource: VideoSource;
  title: string;
  description: string;
  is_available: boolean;
  url: string;
  localSourceImage: string; // Relative path of locally copied image file
  metadata?: {
    extUrl?: string;
    extSource?: VideoSource;
    overlayIcon?: string;
    season_name?: string;
    series_name?: string;
    season_number?: number;
  };
  adStrategies: AdStrategy[];
}

export interface YouTubeVideo extends VideoBase {
  youTubeId: string;
}

export interface Video extends VideoBase {
  duration?: {
    formatted: string;
    seconds: number;
  };
  episode_number?: number;
  geo_available?: string;
  geo_unavailable?: string;
  is_free: boolean;
  live_status?: string;
  live_video?: boolean;
  name: string;
  overlayIcon?: string;
  scheduled_at?: string | null;
  season_number?: number;
  short_description: string;
  status?: string;
  tags?: any;
  time_available?: string | null;
  time_unavailable?: string | null;
  tracks?: {
    subtitles: string[];
  };
  type: string;
}

export interface DatabaseVideo {
  id: string;
  externalId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  title: string;
  label?: string | null;
  description: string;
  shortDescription?: string | null;
  url: string;
  isAvailable: boolean;
  isFree: boolean;
  type: string;
  thumbnailData?: string | null;
  thumbnailUrl: string;
  adStrategies?: AdStrategy[];
}
