import React, { ReactNode } from 'react';
import { searchContainer } from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const SearchContainer = ({ children }: Props) => {
  return <div className={searchContainer}>{children}</div>;
};

export default SearchContainer;
