import React, { useState } from 'react';
import { videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { BrandUtil, ExperimentInput } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';

export interface ExperimentSeedData {
  branch: string;
  timestamp: number;
  experiments: ExperimentInput[];
}

export const ExperimentExport = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);

    // export data
    const experiments: ExperimentInput[] = [];
    const experimentResp = await api.getExperiments();
    if (experimentResp.data?.getExperiments.success) {
      const databaseExperiments: ExperimentInput[] = experimentResp.data.getExperiments.experiments.map(exp => {
        const items = exp.items.map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          delete newItem.ranges;
          return newItem;
        });
        exp.items = items;

        const newExp: any = exp;
        delete newExp.__typename;
        return newExp;
      });

      experiments.push(...databaseExperiments);
    }

    const seedData: ExperimentSeedData = {
      branch: brandInfo.branchId,
      timestamp: Date.now(),
      experiments,
    };

    const blob = new Blob([JSON.stringify(seedData, null, 2)], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const downloadEl = document.createElement('a');
    downloadEl.download = `${brandInfo.branchId}-experiments-${Date.now()}`;
    downloadEl.href = href;
    downloadEl.click();

    setLoading(false);
  };

  return (
    <div>
      <Button onClick={() => handleExport()} disabled={loading} className={videoListItem}>
        EXPORT EXPERIMENTS
      </Button>
    </div>
  );
};
