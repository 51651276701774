import React from 'react';
import IndexLayout from '@layouts/index';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { CmsCollectionFile } from 'netlify-cms-core';
import {
  pageContainer,
  artistContainer,
  artistTitle,
  artistImage,
  artistPlayerContainer,
  setTimeContainer,
  setTimeImage,
} from './styles.module.scss';

const DEBUG = false;

///
/// CMS model
///
export const LineupCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/ftl/pages/Lineup/lineup.md`,
  label: 'Lineup',
  name: 'lineup',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/ftl/pages/Lineup/Lineup.tsx',
    },
  ],
};

const artists = [
  {
    name: 'NATHANIEL RATELIFF & THE NIGHTSWEATS',
    imageSrc: './FTL_assets/lineup/nathaniel_rateliff.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/4qKpLkR911SUlnd4HAtF79?utm_source=generator',
  },
  {
    name: 'PEACH PIT',
    imageSrc: './FTL_assets/lineup/peach_pit.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/6fC2AcsQtd9h4BWELbbire?utm_source=generator',
  },
  {
    name: 'KURT VILE & THE VIOLATORS',
    imageSrc: './FTL_assets/lineup/kurt_vile.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/5gspAQIAH8nJUrMYgXjCJ2?utm_source=generator',
  },
  {
    name: 'LUCY DACUS',
    imageSrc: './FTL_assets/lineup/lucy_dacus.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/07D1Bjaof0NFlU32KXiqUP?utm_source=generator',
  },
  {
    name: 'THE HALLUCI NATION',
    imageSrc: './FTL_assets/lineup/halluci_nation.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/2jlWF9ltd8UtoaqW0PxY4z?utm_source=generator',
  },
  {
    name: 'VALLEY',
    imageSrc: './FTL_assets/lineup/valley.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/7blXVKBSxdFZsIqlhdViKc?utm_source=generator',
  },
  {
    name: 'BUSTY AND THE BASS',
    imageSrc: './FTL_assets/lineup/busty_bass.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/4XMc1qHObZ7aXQrH5MmbjK?utm_source=generator',
  },
  {
    name: 'RHEOSTATICS',
    imageSrc: './FTL_assets/lineup/rheostatics.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/2PRgEe72c7syu2aGdTcX4m?utm_source=generator',
  },
  {
    name: 'THE RURAL ALBERTA ADVANTAGE',
    imageSrc: './FTL_assets/lineup/rural_alberta.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/0v6gOfGBclTSGu6agfXb23?utm_source=generator',
  },
  {
    name: 'HAVIAH MIGHTY',
    imageSrc: './FTL_assets/lineup/haviah_mighty.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/3UROQ34SGxV7h71Z3Gqp8u?utm_source=generator',
  },
  {
    name: 'PIERRE KWENDERS',
    imageSrc: './FTL_assets/lineup/pierre_kwenders.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/04B6sMoIopTgUAQM3dcSxP?utm_source=generator',
  },
  {
    name: 'DAISY THE GREAT',
    imageSrc: './FTL_assets/lineup/daisy_great.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/0SrIPejckovMwhrN3MZFPB?utm_source=generator',
  },
  {
    name: 'GEORGIA HARMER',
    imageSrc: './FTL_assets/lineup/georgia_harmer.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/3I7KBuz60UYfMzBbPcqrU4?utm_source=generator',
  },
  {
    name: 'OMBIIGIZI',
    imageSrc: './FTL_assets/lineup/ombiigizi.webp',
    playlistSrc: 'https://open.spotify.com/embed/artist/6M9etLgzbkyne0y9cvxGAG?utm_source=generator',
  },
];

const Lineup = () => {
  return (
    <IndexLayout noVideo>
      <div className={pageContainer}>
        <div className={setTimeContainer}>
          <img className={setTimeImage} src="./FTL_assets/lineup/lineup_times.png" alt="" />
        </div>

        {artists.map((artist, i) => (
          <React.Fragment key={i}>
            <div className={artistTitle}>{artist.name}</div>
            <div className={artistContainer}>
              <div className={artistImage}>
                <img src={artist.imageSrc} alt="" />
              </div>
              <div className={artistPlayerContainer}>
                <iframe
                  src={artist.playlistSrc}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </IndexLayout>
  );
};

export default Lineup;
