import React, { useState } from 'react';
import { videoListItem } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { BrandUtil, DatabaseSeries, DatabaseSeason, DatabaseCategory } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';

export interface VideoCollectionSeedData {
  branch: string;
  timestamp: number;
  bucket: string;
  categories: DatabaseCategory[];
  series: DatabaseSeries[];
  seasons: DatabaseSeason[];
}

export const VideoCollectionExport = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    // export data

    const allSeasons: DatabaseSeason[] = [];
    const allSeries: DatabaseSeries[] = [];
    const allCategories: DatabaseCategory[] = [];

    const categoryResp = await api.getDatabaseCategories();
    if (categoryResp.data?.getDatabaseCategories.success && categoryResp.data.getDatabaseCategories.categories) {
      const dbCats = categoryResp.data.getDatabaseCategories.categories.map(cat => {
        const newCat: any = cat;
        delete newCat.__typename;

        const catItems = cat.items.map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          return newItem;
        });
        newCat.items = catItems;

        return newCat;
      });
      allCategories.push(...dbCats);
    }

    const seriesResp = await api.getDatabaseSeries();
    if (seriesResp.data?.getDatabaseSeries.success && seriesResp.data.getDatabaseSeries.series) {
      const dbSeries = seriesResp.data.getDatabaseSeries.series.map(ser => {
        const newSeries: any = ser;
        delete newSeries.__typename;

        const serItems = ser.items.map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          return newItem;
        });
        newSeries.items = serItems;

        return newSeries;
      });
      allSeries.push(...dbSeries);
    }

    const seasonsResp = await api.getDatabaseSeasons();
    if (seasonsResp.data?.getDatabaseSeasons.success && seasonsResp.data.getDatabaseSeasons.seasons) {
      const dbSeasons = seasonsResp.data.getDatabaseSeasons.seasons.map(season => {
        const newSeason: any = season;
        delete newSeason.__typename;

        const seasonItems = season.items.map(item => {
          const newItem: any = item;
          delete newItem.__typename;
          return newItem;
        });
        newSeason.items = seasonItems;

        return newSeason;
      });
      allSeasons.push(...dbSeasons);
    }

    const seedData: VideoCollectionSeedData = {
      branch: brandInfo.branchId,
      bucket: brandInfo.imageBucketName,
      timestamp: Date.now(),
      categories: allCategories,
      series: allSeries,
      seasons: allSeasons,
    };

    const blob = new Blob([JSON.stringify(seedData, null, 2)], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const downloadEl = document.createElement('a');
    downloadEl.download = `${brandInfo.branchId}-video-collection-export-${Date.now()}`;
    downloadEl.href = href;
    downloadEl.click();

    setLoading(false);
  };

  return (
    <div>
      <Button onClick={() => handleExport()} disabled={loading} className={videoListItem}>
        EXPORT ALL COLLECTION DATA
      </Button>
    </div>
  );
};
