import React from 'react';
import classnames from 'classnames';
import { InfoArea } from '@components/Carousel/InfoArea';
import { DatabaseSeries, DatabaseVideo, BrandUtil, Util } from '@sharedLib/index';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { container, imageBoth, imageContainer, imageGradient, image } from './styles.module.scss';

const DEBUG = false;

interface Props {
  carouselItems: {
    item: DatabaseSeries | DatabaseVideo;
    type: 'video' | 'series';
  }[];
}

export const FeaturedCarousel = ({ carouselItems }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const baseImageUrl = brandInfo.imageBaseUrl;

  return (
    <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
      {carouselItems.map((carouselItem, i) => {
        const isVideo = carouselItem.type === 'video';
        const thumbnailUrl = carouselItem.item.thumbnailUrl
          ? `${baseImageUrl}/${isVideo ? 'videos' : 'series'}/${carouselItem.item.thumbnailUrl}`
          : `${brandInfo.siteUrl}/fallback.png`;

        return (
          <div key={i}>
            <div className={classnames(container)}>
              <InfoArea item={carouselItem.item} type={carouselItem.type} />
              <div className={classnames(imageContainer)}>
                <div className={classnames(imageBoth, imageGradient)} />
                <img className={classnames(imageBoth, image)} src={thumbnailUrl} alt="" />
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};
