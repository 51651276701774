import { Util } from './Util';

interface SocialIcon {
  url: string;
  useInFooterAndSeries: boolean;
  useInShare: boolean;
}

export interface SocialIcons {
  embed?: SocialIcon;
  facebook?: SocialIcon;
  twitter?: SocialIcon;
  instagram?: SocialIcon;
  linkedIn?: SocialIcon;
  email?: SocialIcon;
}

export interface Brand {
  title: string;
  description: string;
  keywords: string;
  author: {
    name: string;
    email: string;
  };
  brandImage: string;
  homePageConfig: {
    homeBannerLayout: HomeBannerLayouts;
  };
  embeddedPlayerConfig: {
    title: string;
  };
  adminConfig: {
    hasObjectRecognition: boolean;
  };
  isPPVCapable: boolean;
  cloudFrontDistributions?: { [branchId: string]: DistributionId };
  userSettings: UserSettings;
  contactUsConfig: {
    toEmail: string;
  };
  headerConfig: {
    desktop: HeaderItem[];
    tablet?: HeaderItem[];
    mobile?: HeaderItem[];
  };
  footerConfig: {
    items: FooterItem[][];
  };
  logoRedirectUrl?: string;
  productNeedsRegistration: boolean;
  videoNeedsRegistration: boolean;
  videoPlayerConfig: {
    useDescriptionInVideo: boolean;
    upNext: {
      showEpisodeNumber: boolean;
    };
    useBannerAds: boolean;
  };
  videoHostingConfig: {
    bucketName: string;
    baseUrl: string;
    signingKeyId: string;
  };
  analyticsConfig: {
    brandUsesAnalytics: boolean;
    accountId: string;
    prodAnalyticsBucket: string;
  };
  recommendationConfig: {
    hasRecommendations: boolean;
    serviceAccount: string;
    accountId: string;
    servingConfig: string;
  };
  feedbackConfig: {
    hasFeedback: boolean;
    logoStaticPath: string;
    generalMessage: string;
    generalItems: string[];
    specificItems: string[];
  };
  tagManagerConfig: {
    hasTagManager: boolean;
    managerId: string;
  };
  usesVimeoOtt: boolean;
  hasSignup: boolean;
  freeMembershipOnSignup: boolean;
  socialMedia: SocialIcons;
  htmlHeaders?: [{ name: string; content: string }];
  cartIconVisibility: {
    userLoggedIn: boolean;
    userNotLoggedIn: boolean;
  };
  enableFacebookLogin: boolean;
}

type DistributionId = string;

export interface UserSettings {
  account: boolean;
  membership: boolean;
  payment: boolean;
}

interface HeaderItem {
  type: 'button' | 'account' | 'search' | 'cart' | 'hamburger' | 'login';
  label?: string;
  path?: string;
  newTab?: boolean;
  displayAt?: number;
  onlyLoggedIn?: boolean;
  onlyLoggedOut?: boolean;
  hideOnRoute?: boolean;
  subItems?: {
    label: string;
    path: string;
    displayAt?: number;
  }[];
}

interface FooterItem {
  path: string;
  label: string;
  dataLabel?: string;
}

export interface BrandInstance extends Brand {
  siteUrl: string;
  host: string;
  protocol: string;
  branchId: string;
  serverUrl: string;
  brandId: string;
  hooksUrl: string;
  bucketName: string;
  branchType: 'develop' | 'staging' | 'master';
  environment: 'dev' | 'prod';
  certificateName: string;
  targetDomain: string;
  serverPath: string;
  hooksPath: string;
  productsSearchIndexName: string;
  episodesSearchIndexName: string;
  pathPrefix: string | undefined;
  siteServer: string;
  imageBucketName: string;
  imageBaseUrl: string;
}

export interface BrandInstanceServer extends BrandInstance {
  fusionAuthAppId: string;
  fusionAuthTenantId: string;
  fusionAuthAppSecret: string;
  fusionAuthJwtSecret: string;
  fusionAuthJwtKeyId: string;
  vhxApiKey: string;
  vhxProductId: string;
  googleId: string;
  googleSecret: string;
  googleServiceKey: string;
  faceBookId: string;
  // shopifyApiKey: string;
  shopifyPassword: string;
  shopifyShopName: string;
  shopifySecret: string;
  shopifyStoreFrontToken: string;
  segmentWriteKey: string;
  emailHost: string;
  emailPort: string;
  emailUsername: string;
  emailPassword: string;
  vimeoToken: string;
  videoSigningKey: string;
}

export interface Brands {
  [brandId: string]: Brand;
}

const defaultBrand = 'at';

export enum HomeBannerLayouts {
  CAROUSEL = 'carousel',
  PPV_PRODUCT = 'ppv_product',
  FEATURED_VIDEO = 'featured_video',
}

export const brands: Brands = {
  at: {
    title: 'Alibi Tech',
    description: 'Alibi Technologies, merging content and ecommerce into one video experience!',
    keywords: 'technology, media, e-commerce',
    author: {
      name: 'Alibi Tech Team',
      email: 'info@alibitech.com',
    },
    brandImage: 'favicon.png',
    homePageConfig: {
      homeBannerLayout: HomeBannerLayouts.CAROUSEL,
    },
    embeddedPlayerConfig: {
      title: 'Alibi Tech',
    },
    adminConfig: {
      hasObjectRecognition: true,
    },
    isPPVCapable: false,
    cloudFrontDistributions: {
      master: 'EIUP8SETXW34X',
    },
    userSettings: {
      account: true,
      membership: false,
      payment: false,
    },
    contactUsConfig: {
      toEmail: 'admin@alibitech.com',
    },
    headerConfig: {
      desktop: [
        {
          type: 'search',
          onlyLoggedIn: true,
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
          onlyLoggedIn: true,
        },
        {
          type: 'login',
          onlyLoggedOut: true,
        },
      ],
    },
    footerConfig: {
      items: [
        [
          {
            path: '/about-us',
            label: 'About Us',
            dataLabel: 'aboutUs',
          },
          {
            path: '/contact-us',
            label: 'Contact Us',
            dataLabel: 'contactUs',
          },
        ],
        [
          {
            path: '/privacy',
            label: 'Privacy',
            dataLabel: 'privacy',
          },
          {
            path: '/terms-and-conditions',
            label: 'Terms and Conditions',
            dataLabel: 'termsAndConditions',
          },
        ],
      ],
    },
    productNeedsRegistration: true,
    videoNeedsRegistration: true,
    videoPlayerConfig: {
      useDescriptionInVideo: false,
      upNext: {
        showEpisodeNumber: true,
      },
      useBannerAds: false,
    },
    videoHostingConfig: {
      bucketName: 'at-pvep-videos',
      baseUrl: 'https://d7cw9irrz9gvp.cloudfront.net',
      signingKeyId: 'K24DT86X99T5C3',
    },
    analyticsConfig: {
      brandUsesAnalytics: true,
      accountId: '65c22517-73bf-11ec-8be8-0a89208d1b92',
      prodAnalyticsBucket: 'from-segment-alibitech.com',
    },
    recommendationConfig: {
      hasRecommendations: false,
      serviceAccount: '',
      accountId: '',
      servingConfig: '',
    },
    feedbackConfig: {
      hasFeedback: false,
      logoStaticPath: '',
      generalMessage: '',
      generalItems: [],
      specificItems: [],
    },
    tagManagerConfig: {
      hasTagManager: false,
      managerId: '',
    },
    usesVimeoOtt: true,
    hasSignup: true,
    freeMembershipOnSignup: false,
    socialMedia: {
      facebook: {
        url: 'https://www.facebook.com/',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      twitter: {
        url: 'https://twitter.com/',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      email: {
        url: '',
        useInShare: true,
        useInFooterAndSeries: false,
      },
      linkedIn: {
        url: 'https://www.linkedin.com/company/alibitechnologies',
        useInShare: false,
        useInFooterAndSeries: false,
      },
      instagram: {
        url: 'https://www.instagram.com/',
        useInShare: false,
        useInFooterAndSeries: true,
      },
    },
    htmlHeaders: [{ name: 'facebook-domain-verification', content: 'qt6kfrgtxjmfs9hmymfbaug62eisam' }],
    cartIconVisibility: {
      userLoggedIn: true,
      userNotLoggedIn: false,
    },
    enableFacebookLogin: true,
  },
  blue: {
    title: 'Blue Mountain',
    description: `Blue Mountain - Ontario's Summer & Winter Destination`,
    keywords: 'winter, summer, mountain, ski, resort, vacation',
    author: {
      name: 'Alibi Tech Team',
      email: 'info@alibitech.com',
    },
    brandImage: 'favicon.png',
    homePageConfig: {
      homeBannerLayout: HomeBannerLayouts.FEATURED_VIDEO,
    },
    embeddedPlayerConfig: {
      title: 'Blue Mountain',
    },
    adminConfig: {
      hasObjectRecognition: false,
    },
    isPPVCapable: false,
    userSettings: {
      account: true,
      membership: false,
      payment: false,
    },
    contactUsConfig: {
      toEmail: 'admin@alibitech.com',
    },
    headerConfig: {
      desktop: [
        {
          type: 'search',
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
        },
      ],
    },
    footerConfig: {
      items: [
        [
          {
            path: '/about-us',
            label: 'About Us',
            dataLabel: 'aboutUs',
          },
          {
            path: '/contact-us',
            label: 'Contact Us',
            dataLabel: 'contactUs',
          },
        ],
        [
          {
            path: '/privacy',
            label: 'Privacy',
            dataLabel: 'privacy',
          },
          {
            path: '/terms-and-conditions',
            label: 'Terms and Conditions',
            dataLabel: 'termsAndConditions',
          },
        ],
      ],
    },
    productNeedsRegistration: false,
    videoNeedsRegistration: false,
    videoPlayerConfig: {
      useDescriptionInVideo: false,
      upNext: {
        showEpisodeNumber: false,
      },
      useBannerAds: false,
    },
    videoHostingConfig: {
      bucketName: '',
      baseUrl: '',
      signingKeyId: '',
    },
    analyticsConfig: {
      brandUsesAnalytics: false,
      accountId: '',
      prodAnalyticsBucket: '',
    },
    recommendationConfig: {
      hasRecommendations: false,
      serviceAccount: '',
      accountId: '',
      servingConfig: '',
    },
    feedbackConfig: {
      hasFeedback: false,
      logoStaticPath: '',
      generalMessage: '',
      generalItems: [],
      specificItems: [],
    },
    tagManagerConfig: {
      hasTagManager: false,
      managerId: '',
    },
    usesVimeoOtt: true,
    hasSignup: false,
    freeMembershipOnSignup: true,
    socialMedia: {
      facebook: {
        url: 'https://www.facebook.com/BlueMtnResort',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      twitter: {
        url: 'https://twitter.com/bluemtnresort',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      email: {
        url: '',
        useInShare: true,
        useInFooterAndSeries: false,
      },
      linkedIn: {
        url: 'https://www.linkedin.com/',
        useInShare: false,
        useInFooterAndSeries: false,
      },
      instagram: {
        url: 'https://www.instagram.com/bluemtnresort/',
        useInShare: false,
        useInFooterAndSeries: true,
      },
    },
    cartIconVisibility: {
      userLoggedIn: true,
      userNotLoggedIn: true,
    },
    enableFacebookLogin: true,
  },
  elmo: {
    title: 'El Mocambo',
    description: 'Content, custom research and experiences driving the global cannabis industry',
    keywords: 'cannabis, premium, e-commerce, video',
    author: {
      name: 'El Mocambo',
      email: 'info@elmocambo.ca',
    },
    brandImage: 'favicon.png',
    homePageConfig: {
      homeBannerLayout: HomeBannerLayouts.PPV_PRODUCT,
    },
    embeddedPlayerConfig: {
      title: 'Elmo',
    },
    adminConfig: {
      hasObjectRecognition: false,
    },
    isPPVCapable: true,
    userSettings: {
      account: true,
      membership: false,
      payment: false,
    },
    contactUsConfig: {
      toEmail: 'admin@elmocambo.ca',
    },
    headerConfig: {
      desktop: [
        {
          type: 'button',
          path: '/app',
          label: 'Stage',
        },
        {
          type: 'button',
          path: 'https://shop.elmocambo.com/',
          label: 'Shop',
          newTab: true,
        },
        {
          type: 'search',
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'login',
          onlyLoggedOut: true,
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
        },
      ],
    },
    footerConfig: {
      items: [
        [
          {
            path: '/about-us',
            label: 'About Us',
            dataLabel: 'aboutUs',
          },
          {
            path: '/contact-us',
            label: 'Contact Us',
            dataLabel: 'contactUs',
          },
        ],
        [
          {
            path: '/privacy',
            label: 'Privacy',
            dataLabel: 'privacy',
          },
          {
            path: '/terms-and-conditions',
            label: 'Terms and Conditions',
            dataLabel: 'termsAndConditions',
          },
        ],
      ],
    },
    productNeedsRegistration: false,
    videoNeedsRegistration: false,
    videoPlayerConfig: {
      useDescriptionInVideo: false,
      upNext: {
        showEpisodeNumber: true,
      },
      useBannerAds: false,
    },
    videoHostingConfig: {
      bucketName: '',
      baseUrl: '',
      signingKeyId: '',
    },
    analyticsConfig: {
      brandUsesAnalytics: false,
      accountId: '',
      prodAnalyticsBucket: '',
    },
    recommendationConfig: {
      hasRecommendations: false,
      serviceAccount: '',
      accountId: '',
      servingConfig: '',
    },
    feedbackConfig: {
      hasFeedback: false,
      logoStaticPath: '',
      generalMessage: '',
      generalItems: [],
      specificItems: [],
    },
    tagManagerConfig: {
      hasTagManager: false,
      managerId: '',
    },
    usesVimeoOtt: true,
    hasSignup: true,
    freeMembershipOnSignup: true,
    socialMedia: {
      facebook: {
        url: 'https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Felmocambotoronto%2F',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      twitter: {
        url: 'https://twitter.com/theelmocambo?lang=en',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      email: {
        url: '',
        useInShare: true,
        useInFooterAndSeries: false,
      },
      linkedIn: {
        url: 'https://www.linkedin.com/',
        useInShare: false,
        useInFooterAndSeries: false,
      },
      instagram: {
        url: 'https://www.instagram.com/theelmocambo/?hl=en',
        useInShare: false,
        useInFooterAndSeries: true,
      },
    },
    cartIconVisibility: {
      userLoggedIn: true,
      userNotLoggedIn: true,
    },
    enableFacebookLogin: true,
  },
  ftl: {
    title: 'Field Trip',
    description: 'Field Trip Music & Arts Festival - a multi-disciplinary, cross-generational event unlike any other',
    keywords: 'music, premium, e-commerce, video',
    author: {
      name: 'Field Trip',
      email: 'info@fieldtriplife.com',
    },
    brandImage: 'shareIcon.png',
    cloudFrontDistributions: {
      'ftl-master': 'E36IZINRSNXWCO',
    },
    homePageConfig: {
      homeBannerLayout: HomeBannerLayouts.FEATURED_VIDEO,
    },
    embeddedPlayerConfig: {
      title: 'FieldTrip',
    },
    adminConfig: {
      hasObjectRecognition: false,
    },
    isPPVCapable: false,
    userSettings: {
      account: true,
      membership: false,
      payment: false,
    },
    contactUsConfig: {
      toEmail: 'info@fieldtriplife.com',
    },
    headerConfig: {
      desktop: [
        {
          type: 'search',
        },
        {
          type: 'button',
          path: '/tickets',
          label: 'Tickets',
          displayAt: new Date('Mar 23, 2022 10:00:00 GMT-0400').valueOf(),
        },
        {
          type: 'button',
          path: '/lineup',
          label: 'Lineup',
        },
        {
          type: 'button',
          path: '/volunteers',
          label: 'Volunteer',
        },
        {
          type: 'button',
          path: '/day-camp',
          label: 'DAY CAMP',
        },
        {
          type: 'button',
          path: '/faqs',
          label: 'FAQ',
          displayAt: new Date('Mar 23, 2022 10:00:00 GMT-0400').valueOf(),
        },
        {
          type: 'button',
          path: '/app',
          label: 'Play',
        },
        {
          type: 'button',
          path: '/product-collection/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTI3NTY4MDkwMg==/',
          label: 'Shop',
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
        },
      ],
      tablet: [
        {
          type: 'search',
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'hamburger',
          subItems: [
            {
              path: '/tickets',
              label: 'TICKETS',
              displayAt: new Date('Mar 23, 2022 10:00:00 GMT-0400').valueOf(),
            },
            {
              path: '/lineup',
              label: 'LINEUP',
            },
            {
              path: '/volunteers',
              label: 'VOLUNTEER',
            },
            {
              path: '/day-camp',
              label: 'DAY CAMP',
            },
            {
              path: '/faqs',
              label: 'FAQ',
              displayAt: new Date('Mar 23, 2022 10:00:00 GMT-0400').valueOf(),
            },
            {
              path: '/app',
              label: 'PLAY',
            },
            {
              path: '/product-collection/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTI3NTY4MDkwMg==/',
              label: 'SHOP',
            },
          ],
        },
      ],
    },
    footerConfig: {
      items: [
        [
          {
            path: '/privacy',
            label: 'Privacy',
            dataLabel: 'privacy',
          },
          {
            path: '/contact-us',
            label: 'Contact Us',
            dataLabel: 'contactUs',
          },
        ],
        [
          {
            path: '/purchase-agreement',
            label: 'Purchase Agreement',
            dataLabel: 'purchaseAgreement',
          },
          {
            path: '/terms-and-conditions',
            label: 'Terms and Conditions',
            dataLabel: 'termsAndConditions',
          },
        ],
      ],
    },
    logoRedirectUrl: '/',
    productNeedsRegistration: false,
    videoNeedsRegistration: false,
    videoPlayerConfig: {
      useDescriptionInVideo: true,
      upNext: {
        showEpisodeNumber: false,
      },
      useBannerAds: false,
    },
    videoHostingConfig: {
      bucketName: 'ftl-pvep-videos',
      baseUrl: 'https://d32tafwmw9mns7.cloudfront.net',
      signingKeyId: 'K3UAGLRLRMU77W',
    },
    analyticsConfig: {
      brandUsesAnalytics: true,
      accountId: '652564bd-ef96-11eb-a7e0-0a89208d1b92',
      prodAnalyticsBucket: 'from-segment-fieldtriplife.com',
    },
    recommendationConfig: {
      hasRecommendations: true,
      serviceAccount: 'retail-service-account@watchhabitat.iam.gserviceaccount.com',
      accountId: '934836453146',
      servingConfig: 'ftl-recommended-config',
    },
    feedbackConfig: {
      hasFeedback: true,
      logoStaticPath: 'FTL_assets/ftllogocolor.png',
      generalMessage: 'Please rate your overall experience on FieldTripLife.com',
      generalItems: ['Excellent', 'Good', 'Average', 'Poor', 'Terrible'],
      specificItems: ['Festival Information', 'Shopping', 'Ticketing', 'Website', 'Bug, Other'],
    },
    tagManagerConfig: {
      hasTagManager: true,
      managerId: 'GTM-57XRNGQ',
    },
    usesVimeoOtt: false,
    hasSignup: false,
    freeMembershipOnSignup: true,
    socialMedia: {
      facebook: {
        url: 'https://www.facebook.com/fieldtriplife',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      twitter: {
        url: 'https://twitter.com/fieldtrip',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      email: {
        url: '',
        useInShare: true,
        useInFooterAndSeries: false,
      },
      linkedIn: {
        url: 'https://www.linkedin.com/',
        useInShare: false,
        useInFooterAndSeries: false,
      },
      instagram: {
        url: 'https://www.instagram.com/fieldtriplife/',
        useInShare: false,
        useInFooterAndSeries: true,
      },
    },
    cartIconVisibility: {
      userLoggedIn: true,
      userNotLoggedIn: true,
    },
    enableFacebookLogin: true,
  },
  habitat: {
    title: 'Watch Habitat',
    description: 'A premium subscription lifestyle Streaming and Ecommerce platform.',
    keywords: 'lifestyle, premium, e-commerce, video',
    author: {
      name: 'The Watch Habitat Team',
      email: 'info@watchhabitat.com',
    },
    brandImage: 'favicon.png',
    cloudFrontDistributions: {
      'habitat-master': 'E39W9I4I9MPL0Q',
    },
    homePageConfig: {
      homeBannerLayout: HomeBannerLayouts.CAROUSEL,
    },
    embeddedPlayerConfig: {
      title: 'Habitat',
    },
    adminConfig: {
      hasObjectRecognition: false,
    },
    isPPVCapable: false,
    userSettings: {
      account: true,
      membership: true,
      payment: false,
    },
    contactUsConfig: {
      toEmail: 'admin@watchhabitat.com',
    },
    headerConfig: {
      desktop: [
        {
          type: 'search',
          onlyLoggedIn: true,
        },
        {
          type: 'account',
          onlyLoggedIn: true,
        },
        {
          type: 'cart',
          path: '/cart',
          label: 'Cart',
          onlyLoggedIn: true,
        },
        {
          type: 'login',
          onlyLoggedOut: true,
        },
      ],
    },
    footerConfig: {
      items: [
        [
          {
            path: '/about-us',
            label: 'About Us',
            dataLabel: 'aboutUs',
          },
          {
            path: '/contact-us',
            label: 'Contact Us',
            dataLabel: 'contactUs',
          },
        ],
        [
          {
            path: '/privacy',
            label: 'Privacy',
            dataLabel: 'privacy',
          },
          {
            path: '/terms-and-conditions',
            label: 'Terms and Conditions',
            dataLabel: 'termsAndConditions',
          },
        ],
      ],
    },
    productNeedsRegistration: true,
    videoNeedsRegistration: true,
    videoPlayerConfig: {
      useDescriptionInVideo: false,
      upNext: {
        showEpisodeNumber: true,
      },
      useBannerAds: false,
    },
    videoHostingConfig: {
      bucketName: 'habitat-pvep-videos',
      baseUrl: 'https://dn6yqp5dzbwp9.cloudfront.net',
      signingKeyId: 'KXOU882ERUHZ4',
    },
    analyticsConfig: {
      brandUsesAnalytics: false,
      accountId: '',
      prodAnalyticsBucket: '',
    },
    recommendationConfig: {
      hasRecommendations: false,
      serviceAccount: '',
      accountId: '',
      servingConfig: '',
    },
    feedbackConfig: {
      hasFeedback: false,
      logoStaticPath: '',
      generalMessage: '',
      generalItems: [],
      specificItems: [],
    },
    tagManagerConfig: {
      hasTagManager: false,
      managerId: '',
    },
    usesVimeoOtt: true,
    hasSignup: true,
    freeMembershipOnSignup: false,
    socialMedia: {
      facebook: {
        url: 'https://www.facebook.com/',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      twitter: {
        url: 'https://twitter.com/',
        useInShare: true,
        useInFooterAndSeries: true,
      },
      email: {
        url: '',
        useInShare: true,
        useInFooterAndSeries: false,
      },
      linkedIn: {
        url: 'https://www.linkedin.com/',
        useInShare: false,
        useInFooterAndSeries: false,
      },
      instagram: {
        url: 'https://www.instagram.com/',
        useInShare: false,
        useInFooterAndSeries: true,
      },
    },
    cartIconVisibility: {
      userLoggedIn: true,
      userNotLoggedIn: false,
    },
    enableFacebookLogin: false,
  },
};

export class BrandUtil {
  public static getSiteInfo(brandId: string | undefined = undefined, gitBranchId: string | undefined = undefined): BrandInstance {
    if (!gitBranchId) {
      gitBranchId = BrandUtil.getBranchId();
    }
    if (!gitBranchId) {
      throw new Error('Cannot find gitBranchId');
    }
    if (!brandId) {
      brandId = process.env.GATSBY_BRAND_ID;
    }
    if (!brandId) {
      brandId = BrandUtil.getBrandInfoFromBranch().brandId;
    }
    if (!brandId) {
      throw new Error('Cannot find brandId');
    }
    const isLocal = Util.isLocal();
    const isStaging = gitBranchId.indexOf('staging') !== -1;
    const isMaster = gitBranchId.indexOf('master') !== -1;
    let protocol: string = '';
    let host: string = '';
    let brandUsesDomain = false;
    let brandUsesPathPrefix = true;
    if (isMaster) {
      switch (brandId) {
        case 'at':
          protocol = 'https';
          host = 'www.alibitech.com';
          brandUsesDomain = true;
          brandUsesPathPrefix = false;
          break;
        case 'ftl':
          protocol = 'https';
          host = 'www.fieldtriplife.com';
          brandUsesDomain = true;
          brandUsesPathPrefix = false;
          break;
        case 'habitat':
          protocol = 'https';
          host = 'www.watchhabitat.com';
          brandUsesDomain = true;
          brandUsesPathPrefix = false;
          break;
        default:
          protocol = 'https';
          host = 'builds.alibitech.com';
          brandUsesDomain = false;
          break;
      }
    }
    if (!host) {
      protocol = 'https';
      host = `builds.pvep.net`;
    }
    const siteServerProtocol = process.env.GATSBY_SITE_SERVER_PROTOCOL || 'http';
    const siteServer = isLocal ? `${siteServerProtocol}://localhost:8000` : `${protocol}://${host}`;
    const siteUrl = isLocal || brandUsesDomain ? siteServer : `${siteServer}/${gitBranchId}`; //only use git path for dev env or default prod domain

    let baseBrandData = brands[brandId];
    if (!baseBrandData) {
      console.warn(`Missing brand '${brandId}' in brands. Using defaults as base brand data.`);
      baseBrandData = brands[defaultBrand];
    }
    if (isMaster && isStaging) {
      throw new Error(`calculatedPairs - cannot have both master and staging in branch name ${gitBranchId}`);
    }
    let branchType: 'develop' | 'staging' | 'master' | undefined = undefined;
    if (!isMaster && !isStaging) {
      branchType = 'develop';
    }
    if (isStaging) {
      branchType = 'staging';
    }
    if (isMaster) {
      branchType = 'master';
    }
    if (!branchType) {
      throw new Error('calculatedPairs - could not compute branchType');
    }

    let environment: 'dev' | 'prod';
    if (isMaster || gitBranchId === 'augusto-dev' || gitBranchId === 'ken-dev-prod') {
      environment = 'prod';
    } else {
      environment = 'dev';
    }
    const certificateName = environment === 'dev' ? '*.pvep.net' : '*.api.alibitech.com';
    const domain = environment === 'dev' ? 'api.pvep.net' : 'v1.api.alibitech.com';

    const testingUrlVar = process.env.NGROK_WEBHOOK_URL;
    const serverPort = process.env.GATSBY_LOCAL_SERVER_PORT || '3000';
    const serverPath = `${gitBranchId}/api/graphql`;
    const hooksPath = `${gitBranchId}/api/hooks`;
    const serverUrl = isLocal ? `http://localhost:${serverPort}/${serverPath}` : `https://${domain}/${serverPath}`;
    const hooksUrl = isLocal ? `${testingUrlVar || `'http://localhost:${serverPort}/`}${hooksPath}` : `https://${domain}/${hooksPath}`;

    const episodesSearchIndexName = `${brandId}_${branchType}_episodes`;
    const productsSearchIndexName = `${brandId}_${branchType}_products`;
    const pathPrefix = isLocal || !brandUsesPathPrefix ? undefined : gitBranchId;

    // Add CloudFront distribution if it is a non production branch
    if (branchType !== 'master') {
      if (!baseBrandData.cloudFrontDistributions) {
        baseBrandData.cloudFrontDistributions = {};
      }
      baseBrandData.cloudFrontDistributions[gitBranchId] = 'E1PE6NVXWFFHAG'; // ToDo: make this a configuration
    } else {
      // set default master cloudfront dist if a custom one is not specified
      if (!baseBrandData.cloudFrontDistributions) {
        baseBrandData.cloudFrontDistributions = {};
        baseBrandData.cloudFrontDistributions[gitBranchId] = 'E2SHUOK8387R7H';
      }
    }

    let bucketName = '';
    if (!isMaster && pathPrefix) {
      bucketName = 'ui-pvep';
    } else if (isMaster && pathPrefix) {
      bucketName = 'master-builds-pvep';
    } else if (!pathPrefix) {
      bucketName = BrandUtil.getS3BucketName(gitBranchId);
    }

    let imageBucketName = 'ui-pvep-images';
    if (isMaster) {
      imageBucketName = 'master-pvep-images';
    }
    let imageBaseUrl = isLocal ? `${siteUrl}/images/${brandId}` : `https://${imageBucketName}.s3.amazonaws.com/${gitBranchId}`;

    return {
      ...baseBrandData,
      targetDomain: domain,
      certificateName,
      branchType,
      environment,
      host,
      protocol,
      siteUrl,
      siteServer,
      branchId: gitBranchId,
      serverUrl,
      brandId,
      hooksUrl,
      bucketName,
      serverPath,
      hooksPath,
      episodesSearchIndexName,
      productsSearchIndexName,
      pathPrefix,
      imageBucketName,
      imageBaseUrl,
    };
  }

  private static getS3BucketName(gitBranchId: string): string {
    return `${gitBranchId}-ui-pvep`;
  }

  public static getSiteInfoServer(brandId: string): BrandInstanceServer {
    return {
      ...BrandUtil.getSiteInfo(brandId),
      fusionAuthAppId: BrandUtil.getBrandEnvVarValue('FUSIONAUTH_APP_ID', brandId),
      fusionAuthTenantId: BrandUtil.getBrandEnvVarValue('FUSIONAUTH_TENANT_ID', brandId),
      fusionAuthAppSecret: BrandUtil.getBrandEnvVarValue('FUSIONAUTH_APP_SECRET', brandId),
      fusionAuthJwtSecret: BrandUtil.getBrandEnvVarValue('FUSIONAUTH_JWT_SECRET', brandId),
      fusionAuthJwtKeyId: BrandUtil.getBrandEnvVarValue('FUSIONAUTH_JWT_KEY_ID', brandId),
      vhxApiKey: BrandUtil.getBrandEnvVarValue('VHX_API_KEY', brandId),
      vhxProductId: BrandUtil.getBrandEnvVarValue('VHX_PRODUCT_ID', brandId),
      googleId: BrandUtil.getBrandEnvVarValue('GATSBY_GOOGLE_ID', brandId),
      googleSecret: BrandUtil.getBrandEnvVarValue('GOOGLE_SECRET', brandId),
      googleServiceKey: BrandUtil.getBrandEnvVarValue('GOOGLE_SERVICE_KEY', brandId, false),
      faceBookId: BrandUtil.getBrandEnvVarValue('GATSBY_FB_ID', brandId),
      // shopifyApiKey: BrandUtil.getBrandEnvVarValue('SFY_API_KEY', brandId),
      shopifyPassword: BrandUtil.getBrandEnvVarValue('SFY_PASSWORD', brandId),
      shopifyShopName: BrandUtil.getBrandEnvVarValue('SFY_SHOP_NAME', brandId),
      shopifySecret: BrandUtil.getBrandEnvVarValue('SFY_SECRET', brandId),
      shopifyStoreFrontToken: BrandUtil.getBrandEnvVarValue('SFY_SF_TOKEN', brandId),
      segmentWriteKey: BrandUtil.getBrandEnvVarValue('SEGMENT_WRITE_KEY', brandId, false),
      emailHost: BrandUtil.getBrandEnvVarValue('EMAIL_HOST', brandId, false),
      emailPort: BrandUtil.getBrandEnvVarValue('EMAIL_PORT', brandId, false),
      emailUsername: BrandUtil.getBrandEnvVarValue('EMAIL_USERNAME', brandId, false),
      emailPassword: BrandUtil.getBrandEnvVarValue('EMAIL_PASSWORD', brandId, false),
      vimeoToken: BrandUtil.getBrandEnvVarValue('VIMEO_ACCESS_TOKEN', brandId, false),
      videoSigningKey: BrandUtil.getBrandEnvVarValue('VIDEO_SIGNING_KEY', brandId, false),
    };
  }

  public static getBrandInfoFromBranch(branchId: string | undefined = undefined): BrandInstance {
    if (!branchId) {
      branchId = BrandUtil.getBranchId();
    }
    const hyphenPosition = branchId.indexOf('-');
    if (hyphenPosition === -1) {
      return BrandUtil.getSiteInfo(defaultBrand, branchId);
    }
    const firstSegment = branchId.substr(0, hyphenPosition);
    if (brands[firstSegment]) {
      return BrandUtil.getSiteInfo(firstSegment, branchId);
    }
    return BrandUtil.getSiteInfo(defaultBrand, branchId);
  }

  public static getBranchId(): string {
    const processEnvBranch = process.env.BRANCH;
    const processEnvGatsbyGitBranch = process.env.GATSBY_GIT_BRANCH;
    const gitBranchId = processEnvBranch || processEnvGatsbyGitBranch;
    if (!gitBranchId) {
      throw new Error('Missing Environment variable BRANCH || GATSBY_GIT_BRANCH');
    }
    return gitBranchId;
  }

  public static getAllBrands(): BrandInstance[] {
    return Object.keys(brands).map(id => BrandUtil.getSiteInfo(id));
  }

  private static getBrandEnvVarValue(baseName: string, brandId: string, throwOnError: boolean = true): string {
    const envVarName = `${baseName}_${brandId.toLocaleUpperCase()}`;
    if (!process.env[envVarName]) {
      const baseNameValue = process.env[baseName];
      if (baseNameValue) {
        return baseNameValue; // Netlify builds may not define all brands
      }
      if (throwOnError) {
        throw new Error(`Cannot find environment variable ${envVarName} or ${baseName}`);
      }
      console.warn(`Cannot find environment variable ${envVarName} or ${baseName}`);
      return '';
    }
    // @ts-ignore
    return process.env[envVarName];
  }
}
