import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { ButtonStyles } from './Button';
import * as styles from './styles.module.scss';

interface Props {
  style?: ButtonStyles;
  children?: ReactNode;
  disabled?: boolean;
  dataCY?: string;
}

// TO DO: convert styles to es modules, need to refactor style=
export const SubmitButton: React.FC<Props> = ({ dataCY, disabled, style = ButtonStyles.Primary, children }) => (
  <button
    data-cy={dataCY || 'submit'}
    disabled={disabled}
    className={classnames(styles.button, styles[style], styles.buttonProvider)}
    type="submit"
  >
    {children}
  </button>
);
