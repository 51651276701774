import React, { useEffect, useState } from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Formik } from 'formik';
import {
  noWrapButton,
  editorVideoLabel,
  adminSubText,
  flexRow,
  adminText,
  videoCollectionListRow,
  videoCollectionListText,
  videoCollectionListBack,
  videoCollectionAdd,
  videoCollectionAddRow,
  videoCollectionAddContainer,
  videoCollectionDelete,
  videoCollectionDeleteContainer,
  videoCollectionDeleteCard,
  videoSliderPage,
  categorySectionSeperator,
  adminTitle,
  dragHandle,
  categorySectionHeader,
  categoryDraggableTitle,
  categoryDraggable,
} from './styles.module.scss';
import { alphabeticalOrder, DatabaseVideo, DatabaseSeries, DatabaseCategory, ascendingOrder } from '@sharedLib/index';
import { DatabaseCategoryForm } from './DatabaseCategoryForm';
import { ConfirmCard } from '@components/ConfirmCard';

export const DatabaseCategoryList = () => {
  const [editingCategory, setEditingCategory] = useState<{ id: string; editing: boolean } | undefined>(undefined);

  const [featuredCategories, setFeaturedCategories] = useState<DatabaseCategory[]>([]);
  const [allCategories, setAllCategories] = useState<DatabaseCategory[]>([]);
  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);

  const [dataLoadingError, setDataLoadingError] = useState('');
  const [dataLoading, setDataLoading] = useState(true);

  const [pendingRemoval, setPendingRemoval] = useState<string | undefined>(undefined);
  const [deletingCategory, setDeletingCategory] = useState(false);
  const [deletingError, setDeletingError] = useState('');

  const [allVideos, setAllVideos] = useState<DatabaseVideo[]>([]);
  const [allSeries, setAllSeries] = useState<DatabaseSeries[]>([]);

  const [orderMessage, setOrderMessage] = useState('');

  const api = usePvepApi();

  useEffect(() => {
    if (!editingCategory) {
      getCategories();
    }
  }, [editingCategory]);

  useEffect(() => {
    getVideoAndSeriesData();
  }, []);

  const getVideoAndSeriesData = async () => {
    setDataLoading(true);

    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos = videoResp.data.getDatabaseVideos.videos
        .map(video => {
          const newVid: any = video;
          delete newVid.__typename;
          return newVid;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setAllVideos(databaseVideos);
      setDataLoadingError('');
    } else {
      setAllVideos([]);
      setDataLoadingError(
        JSON.stringify(videoResp.data?.getDatabaseVideos.errorMessages) || 'Could not load related video data. Please try again.'
      );
    }

    const seriesResp = await api.getDatabaseSeries();
    if (seriesResp.data?.getDatabaseSeries.success && seriesResp.data.getDatabaseSeries.series) {
      const databaseSeries = seriesResp.data.getDatabaseSeries.series
        .map(series => {
          const newSeries: any = series;
          delete newSeries.__typename;
          return newSeries;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setAllSeries(databaseSeries);
      setDataLoadingError('');
    } else {
      setAllSeries([]);
      setDataLoadingError(
        JSON.stringify(seriesResp.data?.getDatabaseSeries.errorMessages) || 'Could not load related video data. Please try again.'
      );
    }

    setDataLoading(false);
  };

  const getCategories = async () => {
    setLoading(true);
    const categoryResp = await api.getDatabaseCategories();
    if (categoryResp.data?.getDatabaseCategories.success && categoryResp.data.getDatabaseCategories.categories) {
      const databaseCategories = categoryResp.data.getDatabaseCategories.categories
        .map(category => {
          const newCat: any = category;
          delete newCat.__typename;

          const newCatItems = category.items.map(item => {
            const newItem: any = item;
            delete newItem.__typename;
            return newItem;
          });
          newCat.items = newCatItems;

          return newCat;
        })
        .sort((a, b) => ascendingOrder(a.rank, b.rank));

      const featuredCats = databaseCategories.filter(cat => cat.isFeatured);
      const allCats = databaseCategories.filter(cat => !cat.isFeatured);

      setAllCategories(allCats);
      setFeaturedCategories(featuredCats);
      setLoadingError('');
    } else {
      setLoadingError(
        JSON.stringify(categoryResp.data?.getDatabaseCategories.errorMessages) ||
          'Something went wrong loading the collections from the database.'
      );
      setFeaturedCategories([]);
      setAllCategories([]);
    }
    setLoading(false);
  };

  const deleteCategory = async (categoryId: string, isFeatured: boolean) => {
    setDeletingCategory(true);
    const deleteResp = await api.deleteDatabaseCategory(categoryId);
    if (deleteResp.data?.deleteDatabaseCategory.success) {
      // if deleting featured, edit featured list
      if (isFeatured) {
        const newCatList = [...featuredCategories];
        const index = newCatList.findIndex(cat => cat.id === categoryId);
        if (index !== -1) {
          newCatList.splice(index, 1);
        }
        setFeaturedCategories(newCatList);
      } else {
        // not featured, edit sortable list
        const newCatList = [...allCategories];
        const index = newCatList.findIndex(cat => cat.id === categoryId);
        if (index !== -1) {
          newCatList.splice(index, 1);
        }
        setAllCategories(newCatList);
      }
    } else {
      setDeletingError(
        JSON.stringify(deleteResp.data?.deleteDatabaseCategory.errorMessages) || 'Could not delete this collection. Please try again.'
      );
    }
    setDeletingCategory(false);
  };

  ///
  /// Set new rank for categories according to position
  ///
  const reorderCategories = async (categories: DatabaseCategory[]) => {
    setOrderMessage('');
    const newCats: DatabaseCategory[] = categories.map((cat, i) => {
      return {
        ...cat,
        rank: i + 1,
      };
    });
    const reorderResp = await api.reorderDatabaseCategories(newCats);
    if (reorderResp.data?.reorderDatabaseCategories.success) {
      setOrderMessage('Saved successfully');
    } else {
      setOrderMessage(`Save unsuccessful: ${JSON.stringify(reorderResp.data?.reorderDatabaseCategories.errorMessages)}`);
    }
  };

  return (
    <div className={videoSliderPage}>
      {loading && <div className={adminSubText}>Loading categories...</div>}
      {dataLoading && <div className={adminSubText}>Loading video and series data...</div>}

      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!dataLoading && dataLoadingError && <div className={adminSubText}>{dataLoadingError}</div>}

      {!loading && !dataLoading && !editingCategory?.editing && (
        <Formik
          initialValues={{
            categories: allCategories,
          }}
          enableReinitialize
          onSubmit={async values => {
            await reorderCategories(values.categories);
          }}
        >
          {props => (
            <div>
              <div className={classNames(flexRow, videoCollectionAddRow)}>
                <div className={videoCollectionAddContainer} onClick={() => setEditingCategory({ id: '', editing: true })}>
                  <Icon icon="bi:plus-circle" className={videoCollectionAdd} />
                  <div className={adminSubText}>ADD NEW SLIDER</div>
                </div>

                <div>
                  <Button className={noWrapButton} onClick={props.handleSubmit}>
                    SAVE ORDER
                  </Button>
                  {orderMessage && <div>{orderMessage}</div>}
                </div>
              </div>

              <div className={classNames(flexRow, categorySectionHeader)}>
                <div className={adminTitle}>Featured Categories</div>
              </div>

              {featuredCategories.map((category, i) => (
                <div className={classNames(flexRow, videoCollectionListRow)} key={i}>
                  <div className={classNames(adminText, videoCollectionListText)}>{category.title}</div>
                  <Button className={noWrapButton} onClick={() => setEditingCategory({ id: category.id, editing: true })}>
                    EDIT SLIDER
                  </Button>
                  <div
                    className={classNames(flexRow, adminSubText, editorVideoLabel, videoCollectionDeleteContainer)}
                    onClick={() => setPendingRemoval(category.id)}
                  >
                    <Icon icon="fa-solid:trash-alt" className={videoCollectionDelete} />
                    <div>Delete</div>
                  </div>
                  <div className={videoCollectionDeleteCard}>
                    {pendingRemoval === category.id && (
                      <ConfirmCard
                        title={'Delete Collection'}
                        content={'Do you really want to delete this collection? This process cannot be undone.'}
                        confirmCta={'Delete'}
                        disabled={deletingCategory}
                        onConfirm={async () => {
                          await deleteCategory(pendingRemoval, true);
                        }}
                        onCancel={() => setPendingRemoval(undefined)}
                        errorMessage={deletingError}
                      />
                    )}
                  </div>
                </div>
              ))}

              <div className={categorySectionSeperator}></div>

              <div>
                <div className={classNames(flexRow, categorySectionHeader)}>
                  <div className={adminTitle}>Sortable Categories</div>
                </div>

                {props.values.categories.map((category, i) => (
                  <div
                    className={classNames(flexRow, videoCollectionListRow)}
                    key={i}
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    onDragEnter={e => {
                      e.preventDefault();
                    }}
                    onDrop={e => {
                      e.preventDefault();
                      const index = parseInt(e.dataTransfer.getData('text'));
                      if (index === i) {
                        return;
                      } else {
                        let tempItems = [...props.values.categories];
                        const draggedItem = tempItems.splice(index, 1);
                        tempItems = [...tempItems.slice(0, i), ...draggedItem, ...tempItems.slice(i)];
                        props.setFieldValue('categories', tempItems);
                      }
                    }}
                  >
                    <div
                      className={classNames(categoryDraggable, adminText, videoCollectionListText)}
                      draggable
                      onDragStart={e => {
                        e.dataTransfer.setData('text', i.toString());
                        const el = e.target as HTMLDivElement;
                        el.style.opacity = '0.2';
                      }}
                      onDragEnd={e => {
                        e.preventDefault();
                        const el = e.target as HTMLDivElement;
                        el.style.opacity = '1';
                      }}
                    >
                      <div className={dragHandle}>
                        <Icon icon="akar-icons:drag-vertical" />
                      </div>
                      <div className={categoryDraggableTitle}>{category.title}</div>
                    </div>

                    <Button className={noWrapButton} onClick={() => setEditingCategory({ id: category.id, editing: true })}>
                      EDIT SLIDER
                    </Button>
                    <div
                      className={classNames(flexRow, adminSubText, editorVideoLabel, videoCollectionDeleteContainer)}
                      onClick={() => setPendingRemoval(category.id)}
                    >
                      <Icon icon="fa-solid:trash-alt" className={videoCollectionDelete} />
                      <div>Delete</div>
                    </div>
                    <div className={videoCollectionDeleteCard}>
                      {pendingRemoval === category.id && (
                        <ConfirmCard
                          title={'Delete Collection'}
                          content={'Do you really want to delete this collection? This process cannot be undone.'}
                          confirmCta={'Delete'}
                          disabled={deletingCategory}
                          onConfirm={async () => {
                            await deleteCategory(pendingRemoval, false);
                          }}
                          onCancel={() => setPendingRemoval(undefined)}
                          errorMessage={deletingError}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Formik>
      )}

      {!loading && !dataLoading && editingCategory?.editing && (
        <>
          <div className={videoCollectionListBack}>
            <Icon icon="bi:arrow-left" onClick={() => setEditingCategory(undefined)} />
          </div>
          <DatabaseCategoryForm
            editingCategoryId={editingCategory.id}
            videos={allVideos}
            series={allSeries}
            nextRank={allCategories.length + 1}
          />
        </>
      )}
    </div>
  );
};
