import { VideoStateContext, VideoStateProvider } from '@lib/useVideoState';
import React, { useContext } from 'react';
import { VideoPlayer } from '@components/VideoPlayer';
import { Helmet } from 'react-helmet';

const VideoLayout: React.FC = ({ children }) => {
  const { state } = useContext(VideoStateContext);

  return (
    <VideoStateProvider>
      <Helmet>
        <script src="https://cdn.vhx.tv/assets/player.js" />
        <script type="text/javascript" src="https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1" />
        <title>{state.video?.title}</title>
      </Helmet>
      {children}
      <VideoPlayer />
    </VideoStateProvider>
  );
};

export default VideoLayout;
