import { graphql, useStaticQuery } from 'gatsby';
export interface Images {
  relativePath: string;
  name: string;
  childImageSharp: any;
}

export function useFluidImage(): Images[] {
  const images = useStaticQuery(graphql`
    query allFile {
      allFile(filter: { ext: { in: [".jpg", ".png", ".jpeg"] }, relativePath: { glob: "images/**" } }) {
        nodes {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  return images.allFile.nodes;
}
