import { BrandUtil } from '@sharedLib/util';
import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch';
import SearchContainer from './SearchContainer';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';

interface Props {
  setSearchState: (on: boolean) => void;
}

const Search = ({ setSearchState }: Props) => {
  const { productsSearchIndexName, episodesSearchIndexName } = BrandUtil.getSiteInfo();
  const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID || '', process.env.GATSBY_ALGOLIA_SEARCH_KEY || '');
  const [query, setQuery] = useState(``);
  const [episodeHits, setEpisodeHits] = useState();
  const [productHits, setProductHits] = useState();
  const [numberOfEpisodeHits, setNumberOfEpisodeHits] = useState();
  const [numberOfProductHits, setNumberOfProductHits] = useState();

  const queries = [
    {
      indexName: episodesSearchIndexName,
      query,
      params: { typoTolerance: false },
    },
    {
      indexName: productsSearchIndexName,
      query,
      params: { typoTolerance: false },
    },
  ];

  useEffect(() => {
    if (query) {
      searchClient.multipleQueries(queries).then(({ results }: any) => {
        setEpisodeHits(results[0].hits);
        setNumberOfEpisodeHits(results[0].nbHits);
        setProductHits(results[1].hits);
        setNumberOfProductHits(results[1].nbHits);
      });
    }
  }, [query]);
  return (
    <SearchContainer>
      <SearchBox dataCY="searchInput" setQuery={setQuery} query={query} setSearchState={setSearchState} />
      {episodeHits !== undefined && productHits !== undefined ? (
        <SearchResult
          dataCY="searchResults"
          query={query}
          episodeHits={episodeHits || []}
          productHits={productHits || []}
          numberOfEpisodeHits={numberOfEpisodeHits || 0}
          numberOfProductHits={numberOfProductHits || 0}
        />
      ) : (
        ''
      )}
    </SearchContainer>
  );
};

export default Search;
