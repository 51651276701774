import React from 'react';
import xOutline from '@iconify/icons-teenyicons/x-outline';
import { Icon } from '@iconify/react';
import { searchHeader, searchBox, searchBoxContainer, searchText, searchClose } from './styles.module.scss';

interface Props {
  query: string;
  setQuery: (query: string) => void;
  setSearchState: (on: boolean) => void;
  dataCY?: string;
}

const SearchBox = ({ setQuery, query, setSearchState, dataCY }: Props) => {
  return (
    <div>
      <div className={searchHeader} />
      <div className={searchBoxContainer}>
        <div className={searchBox}>
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            data-cy={dataCY}
            autoFocus
            className={searchText}
            type="text"
            placeholder="Search anything"
            onChange={e => {
              setQuery(e.target.value);
            }}
            value={query}
          />
          <div
            className={searchClose}
            onClick={() => {
              setQuery('');
              setSearchState(false);
            }}
          >
            <Icon icon={xOutline} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
