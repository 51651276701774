import { Icon } from '@iconify/react';
import classNames from 'classnames';
import React from 'react';
import outlinePlayCircleOutline from '@iconify/icons-ic/outline-play-circle-outline';

import { videoPlayButton } from './styles.module.scss';

interface Props {
  onClick?: () => void;
  className?: string;
  iconProps: {
    height: string;
  };
}

export const VideoPlayButton = ({ onClick, className, iconProps }: Props) => {
  return (
    <button data-cy="videoPlayButton" className={classNames(videoPlayButton, className)} type="button" onClick={onClick}>
      <Icon icon={outlinePlayCircleOutline} {...iconProps} />
    </button>
  );
};
