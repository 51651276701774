import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

interface Props {
  message?: string;
  messages?: string[];
  className?: string;
}

// Create an argument to toggle between message field & standard field
export const ErrorDisplay: React.FC<Props> = ({ className, message, messages }) => {
  const all = message ? [message] : messages || [];
  return (
    <>
      {all.map(m => (
        <div key={m} className={classnames('is-size-5', className)}>
          {m}
        </div>
      ))}
    </>
  );
};
