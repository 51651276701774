import { BrandUtil } from '@sharedLib/util';
import React from 'react';
import { Helmet } from 'react-helmet';
import { layoutRoot } from './styles.module.scss';

const LayoutRoot: React.FC = ({ children }) => {
  const brand = BrandUtil.getSiteInfo();
  const brandSpecificHeaders = brand.htmlHeaders || [];
  const brandImage = `${brand.siteUrl}/data/processed/images/${brand.brandImage}`;

  const meta = [
    { name: 'title', content: brand.title },
    { name: 'description', content: brand.description },
    { name: 'image', content: brandImage },
    { name: 'url', content: brand.siteUrl },
    { property: 'og:type', content: 'website' },
    { name: ' title', property: 'og:title', content: brand.title },
    { property: 'og:site_name', content: brand.title },
    { name: 'description', property: 'og:description', content: brand.description },
    { name: ' image', property: 'og:image', content: brandImage },
    { property: 'og:url', content: brand.siteUrl },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:url', content: brand.siteUrl },
    { name: 'twitter:title', content: brand.title },
    { name: 'twitter:description', content: brand.description },
    { name: 'twitter:image', content: brandImage },
    { itemProp: 'name', content: brand.title },
    { itemProp: 'description', content: brand.description },
    { itemProp: 'image', content: brandImage },
    { name: 'keywords', content: brand.keywords },
    ...brandSpecificHeaders,
  ];
  return (
    <>
      <Helmet title={brand.title} meta={meta}>
        {brand.tagManagerConfig.hasTagManager && brand.branchType === 'master' && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',"${brand.tagManagerConfig.managerId}");
        `}</script>
        )}
      </Helmet>

      {brand.tagManagerConfig.hasTagManager && brand.branchType === 'master' && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${brand.tagManagerConfig.managerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}

      <div className={layoutRoot}>{children}</div>
    </>
  );
};
export default LayoutRoot;
