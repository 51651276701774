import { ClientLogger } from '@lib/ClientLogger';
import React, { useEffect, useState, useContext } from 'react';
import { ProductImg } from '@components/Img';
import { VideoSource, DatabaseVideo, AdWithProduct } from '@sharedLib/index';
import { Toast } from '@src/components/Toast';
import { useProductResource } from '@src/templates/product/useProductResource';

import classNames from 'classnames';
import { isMobile, isMobilePortrait } from '@src/lib/responsive';
import { NavStateContext } from '@lib/navContext';
import { useAnalyticsCapture } from '@src/lib/AnalyticsCapture';
import { FeaturedProductsBanner } from './FeaturedProductsBanner';
import {
  previousAdOverlaySmall,
  previousAdOverlay,
  previousAdImage,
  shopOverlayAnimation,
  shopOverlayContainer,
  shopOverlayContainerHidden,
  shopOverlayImageContainer,
  shopText,
  overlayControlsContainer,
  overlayYoutubeContainer,
  overlayControlsFull,
  bannerOverlayContainer,
  bannerOverlayImagePlaceholder,
  shopOverlayImageButtonBorder,
  shopButtonOverlayProductOpen,
  shopOverlayImage,
  overlayVimeoContainer,
  overlayVimeoOTTContainer,
  shopTextHidden,
} from './styles.module.scss';
import { ProductOverlay } from './ProductOverlay';
import { useVideoState } from '@lib/useVideoState';

interface VideoAdPlacementProps {
  ads: AdWithProduct[] | null;
  video?: DatabaseVideo;
  staticAds: AdWithProduct[] | null;
}

const DEBUG = false;

export const ShopNowOverlay = ({ ads, video, staticAds }: VideoAdPlacementProps) => {
  const nav = useContext(NavStateContext);
  const [ad, setAd] = useState<AdWithProduct | undefined>(undefined);
  const [featuredAds, setFeaturedAds] = useState<AdWithProduct[]>([]);
  const [bannerOpen, setBannerOpen] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [previousAd, setPrevious] = useState<AdWithProduct | undefined>(undefined);
  const [productOpen, setProductOpen] = useState(false);
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const content = useProductResource();
  const analyticsCapture = useAnalyticsCapture();
  const videoState = useVideoState();

  const mobile = isMobile();
  const mobilePortrait = isMobilePortrait();

  // do not open product card on mobile
  useEffect(() => {
    if (mobile) {
      setProductOpen(false);
    }
  }, [mobile]);

  useEffect(() => {
    ClientLogger.debug('ShopNowOverlay', 'received ads', DEBUG, { ads });
    if (ads?.length) {
      const filterAds = ads.filter(ad => ad.display === 'block' && ad.rank === 1);
      if (filterAds.length) {
        // only change ads with timestamp if banner is closed
        if (!bannerOpen) {
          // set previous ad for transition if an ad already exists
          if (ad && ad.id !== filterAds[0].id) {
            setPrevious(ad);
          }

          setAd(filterAds[0]);
        }

        // push onto featured array to be used in banner
        if (featuredAds.findIndex(ad => ad.id === filterAds[0].id) === -1) {
          const newFeatured = [...featuredAds];
          newFeatured.unshift(filterAds[0]); // add to start of array
          setFeaturedAds(newFeatured);
        }
      }
    } else if (staticAds?.length) {
      // there are no individual or timed ads, check if there are any static ones to feature in the icon
      if (!bannerOpen) {
        // set previous ad for transition if an ad already exists
        if (ad && ad.id !== staticAds[0].id) {
          setPrevious(ad);
        }
        setAd(staticAds[0]);
      }
    }
  }, [ads]);

  useEffect(() => {
    ClientLogger.debug('ShopNowOverlay', 'video id changed', DEBUG, { videoId: video?.id });
    setFeaturedAds([]);
    setAd(undefined);
    setBannerOpen(false);
  }, [video?.id]);

  // animation transition -- timeout to match scss animation-duration
  useEffect(() => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 1000);
  }, [ad?.id]);

  const handleIconClick = () => {
    if (bannerOpen) {
      // on mobile open the product page, else use the card
      if (mobilePortrait) {
        if (ad) {
          nav.push({
            path: `/product/${ad.productId}`,
            title: ad.product.title,
          });
        } else {
          ClientLogger.error('ShopNowOverlay', 'Could not open product page, no ad detected');
        }
      } else {
        setProductOpen(!productOpen);
      }
    } else {
      if (ad) {
        analyticsCapture.productHit('shop-now', ad?.product, { adStrategy: videoState.state.currentAdStrategy });
      } else {
        ClientLogger.error('ShopNowOverlay', 'No ad detected');
      }
      setBannerOpen(true);
    }
  };

  // handle product click from the open products banner
  const handleProductClick = (productId: string) => {
    if (!staticAds || !ads) {
      return;
    }
    const selectedProduct = [...staticAds, ...ads]?.find(ad => ad.productId === productId);

    if (selectedProduct) {
      // set previous ad for transition if an ad already exists
      if (ad && ad.productId !== selectedProduct.productId) {
        setPrevious(ad);
      }
      setAd(selectedProduct);
      analyticsCapture.productHit('view-in-video', selectedProduct.product, { adStrategy: videoState.state.currentAdStrategy });

      // on mobile open the product page, else use the card
      if (mobilePortrait) {
        nav.push({
          path: `/product/${selectedProduct.productId}`,
          title: selectedProduct.product.title,
        });
      } else {
        setProductOpen(true);
      }
    }
  };

  // show message when product is added to cart
  const handleAddToCart = () => {
    setToastStatus(true);
    setTimeout(() => {
      setToastStatus(false);
    }, 5000);
  };

  if (!ad) {
    return <></>;
  }

  return (
    <>
      <div
        className={classNames(
          overlayControlsContainer,
          video?.type === VideoSource.YOUTUBE && overlayYoutubeContainer,
          video?.type === VideoSource.CORE_VIMEO && overlayVimeoContainer,
          video?.type === VideoSource.UPLOADED && overlayVimeoContainer,
          video?.type === VideoSource.VIMEO_OTT && overlayVimeoOTTContainer,
          bannerOpen ? overlayControlsFull : ''
        )}
      >
        <div
          className={classNames(shopOverlayContainer, mobile && bannerOpen ? shopOverlayContainerHidden : '')}
          onClick={() => handleIconClick()}
        >
          <div className={!bannerOpen ? shopText : shopTextHidden}>CLICK to SHOP</div>
          {!(mobile && bannerOpen) && <div className={shopOverlayImageButtonBorder} />}

          {!(mobile && bannerOpen) && (
            <div className={shopOverlayImageContainer} data-cy="shop-now-overlay">
              {transitioning && (
                <div className={classNames(shopOverlayAnimation)}>
                  <div className={classNames(previousAdOverlay)} />
                  <div className={classNames(previousAdOverlaySmall)} />
                  <div className={classNames(previousAdImage)}>{previousAd && <ProductImg product={previousAd.product} />}</div>
                </div>
              )}
              <ProductImg product={ad.product} className={shopOverlayImage} />
              <div className={shopOverlayImageContainer}>
                <ProductImg product={ad.product} className={shopOverlayImage} />
              </div>

              {productOpen && bannerOpen && <div className={shopButtonOverlayProductOpen}>Close</div>}
            </div>
          )}
        </div>
        <div className={classNames(bannerOverlayContainer)}>
          {!mobile && <div className={bannerOverlayImagePlaceholder} />}
          <ProductOverlay productId={ad.productId} open={productOpen} addToCartCallback={() => handleAddToCart()} />
          <FeaturedProductsBanner
            featuredAds={featuredAds}
            closeButtonCallback={() => setBannerOpen(false)}
            staticAds={staticAds}
            productSelectCallback={handleProductClick}
            addToCartCallback={() => handleAddToCart()}
            internalProductCards={mobile && !mobilePortrait}
          />
        </div>
      </div>
      <Toast toastStyle="info" show={toastStatus} message={content.addProductToastMessage} />
    </>
  );
};
