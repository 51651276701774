import React, { ReactNode } from 'react';
import classnames from 'classnames';

import { container } from './styles.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

const Container = ({ children, className }: Props) => <div className={classnames(container, className)}>{children}</div>;

export default Container;
