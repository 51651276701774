import { useLayoutResource } from '@layouts/useLayoutResource';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { isMobilePortrait } from '@lib/responsive';
import { useVideoState, VideoPlayerSize } from '@lib/useVideoState';
import classNames from 'classnames';
import { BrandUtil } from '@sharedLib/index';
import { SocialIconsSeriesOrFooter as SocialIcons } from '@components/SocialMediaIcons/SocialMediaIcons';
import {
  footerContainer,
  topFooter,
  centerFooterContainer,
  centerFooter,
  footerLinks,
  linkList,
  link,
  socialIconsLandingPage,
  socialIcons,
  bottomFooterContainer,
  bottomFooter,
  footerBarTitle,
  sectionDivider,
} from './styles.module.scss';

interface FooterProps {
  noMargins?: boolean;
  landing?: boolean;
  productPage?: boolean;
  noSocialOnFooter?: boolean;
}

const DEBUG = false;

const Footer = ({ noMargins, landing, noSocialOnFooter, productPage }: FooterProps) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const videoState = useVideoState();
  const resource = useLayoutResource();
  const { footer } = resource;
  const mobilePortrait: boolean = isMobilePortrait();
  const nav = useContext(NavStateContext);
  const [clickCount, setClickCount] = useState(0);

  const navToInfoEasterEggClick = (count: number) => {
    DEBUG && ClientLogger.debug('Footer.navToInfoEasterEggClick', 'click');
    if (count > 2) {
      nav.replace({ path: '/info' });
    }
    setClickCount(count + 1);
  };

  if (videoState.state.videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN) {
    return <></>;
  }

  const renderDesktop = () => {
    return (
      <div className={centerFooterContainer}>
        <div className={classNames(centerFooter)}>
          <div className={footerLinks}>
            {brandInfo.footerConfig.items.map((group, i) => (
              <ul className={linkList} key={i}>
                {group.map((item, j) => (
                  <li className={link} key={j}>
                    <Link data-cy={item.dataLabel} to={item.path}>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          {!landing && !noSocialOnFooter && (
            <div data-cy="socialIcons" className={socialIcons}>
              <SocialIcons />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className={centerFooterContainer}>
        <div className={classNames(centerFooter)}>
          {!landing && (
            <div data-cy="socialIcons" className={socialIcons}>
              <SocialIcons />
            </div>
          )}

          {!landing && !noSocialOnFooter && <hr className={sectionDivider} />}
          <div className={footerLinks}>
            {brandInfo.footerConfig.items.map((group, i) => (
              <ul className={linkList} key={i}>
                {group.map((item, j) => (
                  <li className={link} key={j}>
                    <Link data-cy={item.dataLabel} to={item.path}>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <footer className={classNames(footerContainer, noMargins && noMargins, landing && landing, productPage && productPage)}>
      <>
        {landing && !noSocialOnFooter && (
          <div className={topFooter}>
            <div data-cy="socialIcons" className={classNames(socialIconsLandingPage)}>
              <SocialIcons />
            </div>
          </div>
        )}

        {!mobilePortrait && renderDesktop()}
        {mobilePortrait && renderMobile()}

        <div className={bottomFooterContainer} onClick={() => navToInfoEasterEggClick(clickCount)}>
          <div className={bottomFooter}>
            <div data-cy="copyright" className={footerBarTitle}>
              {footer}
            </div>
          </div>
        </div>
      </>
    </footer>
  );
};

export default Footer;
