import React, { ReactNode } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';
// To Do remove styles[style] and refactor sass import to Es modules
export enum ButtonStyles {
  Primary = 'primary',
  Transparent = 'transparent',
  Greyscale = 'greyscale',
}

interface ButtonProps {
  onClick?: () => void;
  filled?: boolean;
  style?: ButtonStyles;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  href?: string;
  target?: string;
  type?: 'submit' | 'reset' | 'button';
  refs?: any;
  dataCY?: any;
}

interface DivButtonProps {
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

export const DivButton = ({ onClick, className, ...restProps }: DivButtonProps) => {
  return <div className={className} tabIndex={0} role="button" onClick={onClick} onKeyPress={onClick} {...restProps} />;
};

export const Button = ({
  onClick,
  style = ButtonStyles.Primary,
  className,
  disabled = false,
  type = 'button',
  href,
  target,
  children,
  refs,
  dataCY,
}: ButtonProps) => {
  const onClickButton = () => {
    if (onClick) onClick();
  };

  const buttonElement = (
    <button
      disabled={disabled}
      ref={refs}
      className={classNames(styles.button, styles[style], className)}
      type={type}
      onClick={onClickButton}
      data-cy={dataCY}
    >
      {children}
    </button>
  );

  return href ? (
    <a href={href} target={target}>
      {buttonElement}
    </a>
  ) : (
    <>{buttonElement}</>
  );
};
