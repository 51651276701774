import React from 'react';
import Step from './Step';
import ProgressBar from './ProgressBar';
import StepperLabel from './StepperLabel';
import StepperContainer from './StepperContainer';

interface Props {
  currentStep: string;
  steps: { step: string; label: string }[];
}

const Stepper = ({ currentStep, steps }: Props) => {
  const stepsCompleted = steps.findIndex(x => x.step === currentStep);
  const totalSteps = steps.length;
  return (
    <StepperContainer>
      <ProgressBar totalSteps={totalSteps} stepsCompleted={stepsCompleted + 1} />
      <Step steps={steps} stepsCompleted={stepsCompleted} />
      <StepperLabel steps={steps} stepsCompleted={stepsCompleted} />
    </StepperContainer>
  );
};

export default Stepper;
