import React, { useEffect, useState } from 'react';
import { Button } from '@components/Buttons';
import { Strategy, alphabeticalOrder } from '@sharedLib/index';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as styles from './styles.module.scss';
import { ConfirmCard } from '@components/ConfirmCard';
import { StrategyEditor } from './StrategyEditor';

const DEBUG = false;

export const StrategyList = () => {
  const api = usePvepApi();
  const [editorOpen, setEditorOpen] = useState(false);
  const [editingStrategy, setEditingStrategy] = useState<Strategy | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState('');
  const [strategies, setStrategies] = useState<Strategy[]>([]);

  const [pendingRemoval, setPendingRemoval] = useState<string | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingError, setDeletingError] = useState('');

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [duplicationError, setDuplicatingError] = useState('');

  useEffect(() => {
    getStrategies();
  }, []);

  ///
  /// get strategies from database
  ///
  const getStrategies = async () => {
    setLoading(true);
    const strategyResp = await api.getStrategies();
    if (strategyResp.data?.getStrategies.success) {
      const databaseStrategies: Strategy[] = strategyResp.data.getStrategies.strategies
        .map(strat => {
          const ias = strat.individualAssignments.map(ia => {
            const newIa: any = ia;
            delete newIa.__typename;
            return newIa;
          });

          const mas = strat.multipleAssignments.map(ma => {
            const newMa: any = ma;
            delete newMa.__typename;
            return newMa;
          });

          const cas = strat.collectionAssignments.map(ca => {
            const newCa: any = ca;
            delete newCa.__typename;
            return newCa;
          });

          strat.individualAssignments = ias;
          strat.multipleAssignments = mas;
          strat.collectionAssignments = cas;
          const newStrat: any = strat;
          delete newStrat.__typename;
          return newStrat;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setStrategies(databaseStrategies);
      setLoadingError('');
    } else {
      const errors = strategyResp.data?.getStrategies.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setLoadingError(errorMessage || 'Something went wrong loading the strategies from the database.');
      setStrategies([]);
    }
    setLoading(false);
  };

  ///
  /// duplicate strategy
  ///
  const duplicateStrategy = async (strategyId: string) => {
    setIsDuplicating(true);

    const duplicateResp = await api.duplicateStrategy(strategyId);
    if (duplicateResp.data?.duplicateStrategy.success) {
      setDuplicatingError('');
      await getStrategies();
    } else {
      const errors = duplicateResp.data?.duplicateStrategy.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setDuplicatingError(errorMessage || 'Could not duplicate strategy. Please try again.');
    }

    setIsDuplicating(false);
  };

  ///
  /// delete strategy
  ///
  const deleteStrategy = async (strategyId: string) => {
    setIsDeleting(true);

    const deleteResp = await api.deleteStrategy(strategyId);
    if (deleteResp.data?.deleteStrategy.success) {
      const newStratList = [...strategies];
      const index = newStratList.findIndex(strategy => strategy.id === strategyId);
      if (index !== -1) {
        newStratList.splice(index, 1);
      }
      setStrategies(newStratList);
      setDeletingError('');
    } else {
      const errors = deleteResp.data?.deleteStrategy.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setDeletingError(errorMessage || 'Could not delete this strategy. Please try again.');
    }

    setIsDeleting(false);
  };

  return (
    <div className={styles.strategyContainer}>
      {loading && <div>Loading...</div>}
      {!loading && loadingError && <div>{loadingError}</div>}
      {!loading && duplicationError && <div>{duplicationError}</div>}
      {!loading && !editorOpen && (
        <div>
          <div className={styles.strategyListRow}>
            <div className={styles.addContainer} onClick={() => setEditorOpen(true)}>
              <Icon icon="bi:plus-circle" className={styles.addButton} />
              <div className={styles.subtitleText}>CREATE STRATEGY</div>
            </div>
          </div>

          {strategies.map(strategy => (
            <div className={styles.strategyListRow} key={strategy.id}>
              <div className={classNames(styles.strategyListTitle, styles.titleText)}>{strategy.title}</div>

              <div className={styles.strategyListEdit}>
                <Button
                  onClick={() => {
                    setEditingStrategy(strategy);
                    setEditorOpen(true);
                  }}
                >
                  EDIT
                </Button>
              </div>

              <div className={styles.strategyListDelete}>
                <div
                  className={styles.iconButton}
                  onClick={() => {
                    setPendingRemoval(strategy.id);
                    setDeletingError('');
                  }}
                >
                  <Icon icon="fa-solid:trash-alt" />
                  <div>Delete</div>
                </div>

                <div className={styles.strategyListDeleteCard}>
                  {pendingRemoval === strategy.id && (
                    <ConfirmCard
                      title={'Delete Strategy'}
                      content={'Do you really want to delete this strategy? This process cannot be undone.'}
                      confirmCta={'Delete'}
                      disabled={isDeleting}
                      onConfirm={async () => {
                        await deleteStrategy(pendingRemoval);
                      }}
                      onCancel={() => {
                        setPendingRemoval(undefined);
                        setDeletingError('');
                      }}
                      errorMessage={deletingError}
                    />
                  )}
                </div>
              </div>

              <div className={styles.strategyListDuplicate}>
                <div
                  className={styles.iconButton}
                  onClick={async () => {
                    if (!isDuplicating) {
                      await duplicateStrategy(strategy.id);
                    }
                  }}
                >
                  <Icon icon={'akar-icons:copy'} />
                  <div>Duplicate</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {editorOpen && (
        <StrategyEditor
          initialStrategy={editingStrategy}
          onClose={async () => {
            setEditingStrategy(undefined);
            setEditorOpen(false);
            getStrategies();
          }}
        />
      )}
    </div>
  );
};
