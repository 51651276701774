import React from 'react';
import { containerError, iconError } from './styles.module.scss';

interface Props {
  errorMessage: React.ReactNode;
}

export const FormError = ({ errorMessage }: Props) => {
  return (
    <div data-cy="formError" className={containerError}>
      <div data-cy={errorMessage} className={iconError} />
      {errorMessage}
    </div>
  );
};
