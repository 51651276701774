import React from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { noWrapButton, adminPadBottom, errorMessage as errorStyle, defaultSelectControl } from './styles.module.scss';
import './react-select-styles.scss';
import { DatabaseVideo } from '@sharedLib/index';

interface Props {
  onSubmit: (id: string, title: string) => void;
  onCancel: () => void;
  videos: DatabaseVideo[];
}

const videoSchema = yup.object().shape({
  id: yup.string().required('This is required.'),
});

export const AddVideoWidget = ({ onSubmit, onCancel, videos }: Props) => {
  const videoOptions = videos.map(video => {
    return {
      value: video.id,
      label: video.title,
    };
  });

  return (
    <Formik
      initialValues={{
        id: '',
      }}
      validationSchema={videoSchema}
      onSubmit={values => {
        const video = videos.find(video => video.id === values.id);
        onSubmit(values.id, video?.title || '');
      }}
    >
      {props => (
        <div>
          <div className={adminPadBottom}>
            <Select
              value={videoOptions.filter(opt => opt.value === props.values.id)}
              name={`id`}
              onChange={opt => {
                props.setFieldValue(`id`, opt?.value);
              }}
              placeholder="Select a video"
              isClearable
              options={videoOptions}
              className={defaultSelectControl}
              classNamePrefix="videoSelect"
            />
            {props.touched.id && props.errors.id && <div className={errorStyle}>{props.errors.id}</div>}
          </div>

          <Button className={noWrapButton} onClick={props.handleSubmit}>
            ADD VIDEO
          </Button>
          <Button style={ButtonStyles.Greyscale} className={noWrapButton} onClick={() => onCancel()}>
            CANCEL
          </Button>
        </div>
      )}
    </Formik>
  );
};
