import { AdStrategy, ShopifyProduct, Ad, AdWithProduct, Experiment, Strategy, RECOMMENDED_PRODUCT_PLACEHOLDER } from '../product';

export class ExperimentUtil {
  ///
  /// convert uuid to percentage -- use to sort users into cohorts - should be uuid v4 to be evenly distributed
  ///
  public static uuidToPercentage(uuid: string): number {
    const trimmed = uuid
      .split('-')
      .join('')
      .toLowerCase();

    //confirm string is hex
    const matches = trimmed.match(/^[0-9a-f]+$/);
    if (!matches) {
      console.error(`ExperimentUtil.uuidToPercentage`, `Expected hex uuid, received ${uuid}.`);
      return -1;
    }

    // convert to int
    const idNum = parseInt(trimmed, 16);
    const MAX_UUID = Math.pow(2, 128);

    //divide by max for percentage
    const percent = (idNum / MAX_UUID) * 100;
    return percent;
  }

  ///
  /// Select cohort
  ///
  public static getStrategyForUser(userId: string, strategies: AdStrategy[]): AdStrategy | undefined {
    const userPercent = ExperimentUtil.uuidToPercentage(userId);

    for (const strategy of strategies) {
      for (const range of strategy.ranges) {
        if (range.start <= userPercent && userPercent < range.end) {
          return strategy;
        }
      }
    }

    return undefined;
  }

  ///
  /// Add shopify products onto ads array
  ///
  public static mapAdsWithProducts(ads: Ad[], products: ShopifyProduct[]): AdWithProduct[] {
    const mappedProducts: AdWithProduct[] = ads.map(ad => {
      const product = products.find(product => product.id === ad.productId);
      if (product) {
        return { ...ad, product };
      }
      console.error(`ExperimentUtil.mapAdsWithProducts`, `Could not find product ${ad.productId}`);
      return { ...ad, product: {} as ShopifyProduct };
    });
    return mappedProducts;
  }

  ///
  /// Map experiments and strategies to adStrategies
  ///
  public static mapToAdStrategies(inputs: { experiment: Experiment; strategies: Strategy[]; products: ShopifyProduct[] }): AdStrategy[] {
    const { experiment, strategies, products } = inputs;

    const adStrategies: AdStrategy[] = [];

    for (const expItem of experiment.items) {
      // find related strategy
      const expStrategy = strategies.find(strat => strat.id === expItem.strategyId);
      const expRanges = expItem.ranges.map(range => {
        return {
          start: range.start,
          end: range.end,
        };
      });

      const adStrategy: AdStrategy = {
        experimentId: experiment.id,
        experimentTitle: experiment.title,
        strategyId: expItem.strategyId,
        strategyTitle: expStrategy ? expStrategy.title : '',
        rangePercentage: expItem.rangePercentage,
        ranges: expRanges,
        ads: [],
      };
      const ads: Ad[] = [];

      // if strategy not found, push empty ads array and continue to next
      if (!expStrategy) {
        adStrategies.push(adStrategy);
        continue;
      }

      // for each collection assignment, add the product collection
      const collectionAssignments = expStrategy.collectionAssignments;
      for (const collectionAssignment of collectionAssignments) {
        const productsInCollection = products.filter(p => p.collectionIds.includes(collectionAssignment.productCollectionId));

        // add all products in product collection
        for (const product of productsInCollection) {
          if (collectionAssignment.adType === 'featured') {
            const newAd: Ad = {
              id: `cfp_${ads.length}`,
              type: 'featured',
              rank: collectionAssignment.rank || 99999,
              name: collectionAssignment.name,
              startTimeIndex: 0,
              productId: product.id,
              reasonData: {
                assignmentType: 'collectionAssignment',
                assignmentId: collectionAssignment.id,
              },
            };
            ads.push(newAd);
          }
        }
      }

      // initialize start time and interval
      const multipleAssignmentInterval = expStrategy.bannerInterval || 15;
      let highestIndividualStartTime = -multipleAssignmentInterval;

      // for each individual assignment, add product
      const individualAssignments = expStrategy.individualAssignments.filter(ia => ia.status === 'active');
      for (const individualAssignment of individualAssignments) {
        // get related product
        let product = products.find(p => p.id === individualAssignment.productId);
        if (!product && individualAssignment.isRecommendation) {
          product = RECOMMENDED_PRODUCT_PLACEHOLDER;
        }

        if (!product) {
          continue;
        }

        // add to ads array
        ads.push({
          id: `i_${ads.length}`,
          type: individualAssignment.adType,
          name: individualAssignment.name,
          rank: individualAssignment.rank,
          startTimeIndex: individualAssignment.startTimeIndex,
          productId: product.id,
          reasonData: {
            assignmentType: 'individualAssignment',
            assignmentId: individualAssignment.id,
          },
          isRecommendation: individualAssignment.isRecommendation || undefined,
        });

        //update end time of individual assignments
        if (individualAssignment.startTimeIndex > highestIndividualStartTime) {
          highestIndividualStartTime = individualAssignment.startTimeIndex;
        }
      }

      // for each multiple assignment, add products in collection
      const multipleAssignments = expStrategy.multipleAssignments.sort((a, b) => a.rank - b.rank);
      let multipleProductsAdded = 0;
      for (const multipleAssignment of multipleAssignments) {
        // get products
        const productsInCollection = products.filter(p => p.collectionIds.includes(multipleAssignment.productCollectionId));

        for (const product of productsInCollection) {
          // add to banner
          if (multipleAssignment.adType === 'banner') {
            const newAd: Ad = {
              id: `m_${ads.length}`,
              type: 'banner',
              rank: 1,
              name: multipleAssignment.name,
              startTimeIndex: highestIndividualStartTime + (multipleProductsAdded + 1) * multipleAssignmentInterval, // every interval after individual are finished
              productId: product.id,
              reasonData: {
                assignmentType: 'multipleAssignment',
                assignmentId: multipleAssignment.id,
              },
            };
            ads.push(newAd);
          } else if (multipleAssignment.adType === 'banner_static') {
            const newAd: Ad = {
              id: `ms_${ads.length}`,
              type: 'banner_static',
              rank: multipleAssignment.rank,
              name: multipleAssignment.name,
              startTimeIndex: 0, //static ads populate banner at video start
              productId: product.id,
              reasonData: {
                assignmentType: 'multipleAssignment',
                assignmentId: multipleAssignment.id,
              },
            };
            ads.push(newAd);
          } else {
            // add to featured products
            const newAd: Ad = {
              id: `mfp_${ads.length}`,
              type: 'featured',
              rank: multipleAssignment.rank,
              name: multipleAssignment.name,
              startTimeIndex: 0,
              productId: product.id,
              reasonData: {
                assignmentType: 'multipleAssignment',
                assignmentId: multipleAssignment.id,
              },
            };
            ads.push(newAd);
          }
          multipleProductsAdded++;
        }
      }

      // Plug ads into video strategy
      adStrategy.ads = ads;
      adStrategies.push(adStrategy);
    }

    return adStrategies;
  }
}
