import React, { useEffect, useState } from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { usePvepApi } from '@apiClient/usePvepApi';
import {
  noWrapButton,
  editorVideoLabel,
  adminSubText,
  flexRow,
  adminText,
  videoMetadataList,
  videoCollectionListRow,
  videoCollectionListText,
  videoCollectionListBack,
  videoCollectionAdd,
  videoCollectionAddRow,
  videoCollectionAddContainer,
  videoCollectionDelete,
  videoCollectionDeleteContainer,
  videoCollectionDeleteCard,
} from './styles.module.scss';
import { alphabeticalOrder, DatabaseSeries } from '@sharedLib/index';
import { DatabaseCollectionForm } from './DatabaseCollectionForm';
import { ConfirmCard } from '@components/ConfirmCard';

export const DatabaseCollectionList = () => {
  const [editingCollection, setEditingCollection] = useState<{ id: string; editing: boolean } | undefined>(undefined);
  const [allSeries, setAllSeries] = useState<DatabaseSeries[]>([]);
  const [loadingError, setLoadingError] = useState('');
  const [loading, setLoading] = useState(true);
  const [pendingRemoval, setPendingRemoval] = useState<string | undefined>(undefined);
  const [deletingSeries, setDeletingSeries] = useState(false);
  const [deletingError, setDeletingError] = useState('');

  const api = usePvepApi();

  useEffect(() => {
    if (!editingCollection) {
      getSeries();
    }
  }, [editingCollection]);

  const getSeries = async () => {
    setLoading(true);
    const seriesResp = await api.getDatabaseSeries();
    if (seriesResp.data?.getDatabaseSeries.success && seriesResp.data.getDatabaseSeries.series) {
      const databaseSeries = seriesResp.data.getDatabaseSeries.series
        .map(series => {
          const newSeries: any = series;
          delete newSeries.__typename;
          return newSeries;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));

      setAllSeries(databaseSeries);
      setLoadingError('');
    } else {
      setLoadingError(
        JSON.stringify(seriesResp.data?.getDatabaseSeries.errorMessages) ||
          'Something went wrong loading the collections from the database.'
      );
      setAllSeries([]);
    }
    setLoading(false);
  };

  const deleteSeries = async (seriesId: string) => {
    setDeletingSeries(true);

    const deleteResp = await api.deleteDatabaseSeries(seriesId);
    if (deleteResp.data?.deleteDatabaseSeries.success) {
      const newSeriesList = [...allSeries];
      const index = newSeriesList.findIndex(series => series.id === seriesId);
      if (index !== -1) {
        newSeriesList.splice(index, 1);
      }
      setAllSeries(newSeriesList);
    } else {
      setDeletingError(
        JSON.stringify(deleteResp.data?.deleteDatabaseSeries.errorMessages) || 'Could not delete this collection. Please try again.'
      );
    }

    setDeletingSeries(false);
  };

  return (
    <div className={videoMetadataList}>
      {loading && <div className={adminSubText}>Loading...</div>}
      {!loading && loadingError && <div className={adminSubText}>{loadingError}</div>}
      {!loading && !editingCollection && (
        <div className={classNames(flexRow, videoCollectionAddRow)}>
          <div className={videoCollectionAddContainer} onClick={() => setEditingCollection({ id: '', editing: true })}>
            <Icon icon="bi:plus-circle" className={videoCollectionAdd} />
            <div className={adminSubText}>ADD NEW COLLECTION</div>
          </div>
        </div>
      )}
      {!loading &&
        !editingCollection?.editing &&
        allSeries.map((series, i) => (
          <div className={classNames(flexRow, videoCollectionListRow)} key={i}>
            <div className={classNames(adminText, videoCollectionListText)}>{series.title}</div>
            <Button className={noWrapButton} onClick={() => setEditingCollection({ id: series.id, editing: true })}>
              EDIT COLLECTION
            </Button>
            <div
              className={classNames(flexRow, adminSubText, editorVideoLabel, videoCollectionDeleteContainer)}
              onClick={() => setPendingRemoval(series.id)}
            >
              <Icon icon="fa-solid:trash-alt" className={videoCollectionDelete} />
              <div>Delete</div>
            </div>
            <div className={videoCollectionDeleteCard}>
              {pendingRemoval === series.id && (
                <ConfirmCard
                  title={'Delete Collection'}
                  content={'Do you really want to delete this collection? This process cannot be undone.'}
                  confirmCta={'Delete'}
                  disabled={deletingSeries}
                  onConfirm={async () => {
                    await deleteSeries(pendingRemoval);
                  }}
                  onCancel={() => setPendingRemoval(undefined)}
                  errorMessage={deletingError}
                />
              )}
            </div>
          </div>
        ))}
      {!loading && editingCollection?.editing && (
        <>
          <div className={videoCollectionListBack}>
            <Icon icon="bi:arrow-left" onClick={() => setEditingCollection(undefined)} />
          </div>
          <DatabaseCollectionForm editingSeriesId={editingCollection.id} />
        </>
      )}
    </div>
  );
};
