import React from 'react';
import classNames from 'classnames';
import { stepContainer, stepLabel, stepCompletedLabel } from './styles.module.scss';

interface Props {
  steps: { step: string; label: string }[];
  stepsCompleted: number;
}

const StepperLabel = ({ steps, stepsCompleted }: Props) => {
  return (
    <div className={stepContainer}>
      {steps.map((step, index) => {
        return (
          <div key={step.step} className={classNames(stepLabel, index <= stepsCompleted ? stepCompletedLabel : '')}>
            {step.label}
          </div>
        );
      })}
    </div>
  );
};

export default StepperLabel;
