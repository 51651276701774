import React from 'react';
import classNames from 'classnames';

import { h1, h2, h3, h4, h5, body1, body2 } from './styles.module.scss';

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1' | 'body2';

interface IProps {
  children: React.ReactNode;
  variant: TypographyVariant;
  className?: string;
}

export function Typography({ children, variant, className }: IProps) {
  switch (variant) {
    case 'h1':
      return <div className={classNames(h1, className)}>{children}</div>;
    case 'h2':
      return <div className={classNames(h2, className)}>{children}</div>;
    case 'h3':
      return <div className={classNames(h3, className)}>{children}</div>;
    case 'h4':
      return <div className={classNames(h4, className)}>{children}</div>;
    case 'h5':
      return <div className={classNames(h5, className)}>{children}</div>;
    case 'body1':
      return <div className={classNames(body1, className)}>{children}</div>;
    case 'body2':
      return <div className={classNames(body2, className)}>{children}</div>;
    default:
      return <></>;
  }
}
