/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: any;
}

export interface AccessToken {
  __typename?: 'AccessToken';
  accessToken: Scalars['String'];
  authServiceResponse: Scalars['Int'];
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export enum AdStatusType {
  active = 'active',
  paused = 'paused'
}

export enum AdType {
  banner = 'banner',
  banner_static = 'banner_static',
  featured = 'featured'
}

export interface AddSeriesInput {
  description: Scalars['String'];
  thumbnail?: InputMaybe<ThumbnailInput>;
  title: Scalars['String'];
}

export interface AuthPayload {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
}

export interface BucketContents {
  __typename?: 'BucketContents';
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
}

export interface CartItemAddInput {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  variantId?: InputMaybe<Scalars['String']>;
  variantName?: InputMaybe<Scalars['String']>;
}

export interface CartItemUpdateInput {
  email?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  variantId?: InputMaybe<Scalars['String']>;
}

export interface Category {
  __typename?: 'Category';
  description: Scalars['String'];
  id: Scalars['String'];
  items: Array<CategoryItem>;
  name: Scalars['String'];
}

export interface CategoryItem {
  __typename?: 'CategoryItem';
  id: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
}

export interface CheckSubscriptionResponse {
  __typename?: 'CheckSubscriptionResponse';
  commonResponse: CommonResponse;
  isSubscribed: Scalars['Boolean'];
  subscription: Scalars['String'];
}

export interface CollectionAssignment {
  __typename?: 'CollectionAssignment';
  adType: AdType;
  id: Scalars['String'];
  name: Scalars['String'];
  productCollectionId: Scalars['String'];
  rank: Scalars['Int'];
}

export interface CollectionAssignmentInput {
  adType: AdType;
  id: Scalars['String'];
  name: Scalars['String'];
  productCollectionId: Scalars['String'];
  rank: Scalars['Int'];
}

export interface Collections {
  __typename?: 'Collections';
  categories: Array<Category>;
  errorMessages?: Maybe<Array<ErrorDetails>>;
  series: Array<Serie>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface CommonResponse {
  __typename?: 'CommonResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface CompressS3ImageResponse {
  __typename?: 'CompressS3ImageResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  thumbnailUrl: Scalars['String'];
}

export interface Coupon {
  __typename?: 'Coupon';
  code: Scalars['String'];
}

export interface DatabaseCategoriesResp {
  __typename?: 'DatabaseCategoriesResp';
  categories?: Maybe<Array<DatabaseCategory>>;
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseCategory {
  __typename?: 'DatabaseCategory';
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  items: Array<DatabaseCategoryItem>;
  rank?: Maybe<Scalars['Int']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface DatabaseCategoryInput {
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  items: Array<DatabaseCategoryItemInput>;
  rank?: InputMaybe<Scalars['Int']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface DatabaseCategoryItem {
  __typename?: 'DatabaseCategoryItem';
  categoryId: Scalars['String'];
  id: Scalars['String'];
  rank: Scalars['Int'];
  seriesId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
}

export interface DatabaseCategoryItemInput {
  categoryId: Scalars['String'];
  id: Scalars['String'];
  rank: Scalars['Int'];
  seriesId?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
}

export interface DatabaseCategoryResp {
  __typename?: 'DatabaseCategoryResp';
  category?: Maybe<DatabaseCategory>;
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseCategoryWriteResp {
  __typename?: 'DatabaseCategoryWriteResp';
  categoryId?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseMultiSeriesResp {
  __typename?: 'DatabaseMultiSeriesResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  series?: Maybe<Array<DatabaseSeries>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseSeason {
  __typename?: 'DatabaseSeason';
  id: Scalars['String'];
  items: Array<DatabaseSeasonItem>;
  title: Scalars['String'];
}

export interface DatabaseSeasonInput {
  id: Scalars['String'];
  items: Array<DatabaseSeasonItemInput>;
  title: Scalars['String'];
}

export interface DatabaseSeasonItem {
  __typename?: 'DatabaseSeasonItem';
  id: Scalars['String'];
  rank: Scalars['Int'];
  seasonId: Scalars['String'];
  videoId: Scalars['String'];
}

export interface DatabaseSeasonItemInput {
  id: Scalars['String'];
  rank: Scalars['Int'];
  seasonId: Scalars['String'];
  videoId: Scalars['String'];
}

export interface DatabaseSeasonResp {
  __typename?: 'DatabaseSeasonResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  season?: Maybe<DatabaseSeason>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseSeasonsResp {
  __typename?: 'DatabaseSeasonsResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  seasons?: Maybe<Array<DatabaseSeason>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseSeries {
  __typename?: 'DatabaseSeries';
  description: Scalars['String'];
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  items: Array<DatabaseSeriesItem>;
  shortDescription: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface DatabaseSeriesInfoResp {
  __typename?: 'DatabaseSeriesInfoResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  seasons?: Maybe<Array<DatabaseSeason>>;
  series?: Maybe<DatabaseSeries>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  videos?: Maybe<Array<DatabaseVideo>>;
}

export interface DatabaseSeriesInput {
  description: Scalars['String'];
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  items: Array<DatabaseSeriesItemInput>;
  shortDescription: Scalars['String'];
  thumbnailData?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface DatabaseSeriesItem {
  __typename?: 'DatabaseSeriesItem';
  id: Scalars['String'];
  rank: Scalars['Int'];
  seasonId: Scalars['String'];
  seriesId: Scalars['String'];
}

export interface DatabaseSeriesItemInput {
  id: Scalars['String'];
  rank: Scalars['Int'];
  seasonId: Scalars['String'];
  seriesId: Scalars['String'];
}

export interface DatabaseSeriesResp {
  __typename?: 'DatabaseSeriesResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  series?: Maybe<DatabaseSeries>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseSeriesWriteResp {
  __typename?: 'DatabaseSeriesWriteResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  seriesId?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface DatabaseVideo {
  __typename?: 'DatabaseVideo';
  createdAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  url: Scalars['String'];
}

export interface DatabaseVideoInput {
  description: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  thumbnailData?: InputMaybe<Scalars['String']>;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
}

export interface DatabaseVideoResp {
  __typename?: 'DatabaseVideoResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  video?: Maybe<DatabaseVideo>;
}

export interface DatabaseVideosResp {
  __typename?: 'DatabaseVideosResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  videos: Array<DatabaseVideo>;
}

export enum ErrorCode {
  EMAIL_ADDRESS_INVALID = 'EMAIL_ADDRESS_INVALID',
  EMAIL_ADDRESS_IN_USE = 'EMAIL_ADDRESS_IN_USE',
  INVALID_COUPON_CODE = 'INVALID_COUPON_CODE',
  NOT_CODED = 'NOT_CODED',
  PPV_UNAUTHORIZED = 'PPV_UNAUTHORIZED',
  REQUESTED_VIDEO_NOT_FOUND = 'REQUESTED_VIDEO_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

export interface ErrorDetails {
  __typename?: 'ErrorDetails';
  errorCode: ErrorCode;
  errorMessage?: Maybe<Scalars['String']>;
}

export interface Experiment {
  __typename?: 'Experiment';
  id: Scalars['String'];
  items: Array<ExperimentItem>;
  title: Scalars['String'];
  videoCollectionId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
}

export interface ExperimentInput {
  id: Scalars['String'];
  items: Array<ExperimentItemInput>;
  title: Scalars['String'];
  videoCollectionId?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
}

export interface ExperimentItem {
  __typename?: 'ExperimentItem';
  experimentId: Scalars['String'];
  id: Scalars['String'];
  rangePercentage: Scalars['Float'];
  ranges: Array<ExperimentRange>;
  strategyId: Scalars['String'];
}

export interface ExperimentItemInput {
  experimentId: Scalars['String'];
  id: Scalars['String'];
  rangePercentage: Scalars['Float'];
  strategyId: Scalars['String'];
}

export interface ExperimentRange {
  __typename?: 'ExperimentRange';
  end: Scalars['Float'];
  experimentItemId: Scalars['String'];
  id: Scalars['String'];
  start: Scalars['Float'];
}

export interface ExperimentStrategyResp {
  __typename?: 'ExperimentStrategyResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  experiment?: Maybe<Experiment>;
  strategies?: Maybe<Array<Strategy>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface ExperimentWriteResp {
  __typename?: 'ExperimentWriteResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  experimentId: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface ExperimentsResp {
  __typename?: 'ExperimentsResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  experiments: Array<Experiment>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface ExternalVideoData {
  __typename?: 'ExternalVideoData';
  description?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['Int']>;
  seasonNumber?: Maybe<Scalars['Int']>;
  seriesId?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface ExternalVideoDataResp {
  __typename?: 'ExternalVideoDataResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  video?: Maybe<ExternalVideoData>;
}

export interface ForgotPasswordResponse {
  __typename?: 'ForgotPasswordResponse';
  changePasswordId: Scalars['String'];
  commonResponse: CommonResponse;
}

export interface GetJobsResponse {
  __typename?: 'GetJobsResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  jobs: Array<Job>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface GetMessagesResponse {
  __typename?: 'GetMessagesResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  userMessages: Array<UserMessage>;
}

export interface GetProcessedObjectDataResponse {
  __typename?: 'GetProcessedObjectDataResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  labels: Array<ProcessedObjectLabel>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  timeline: Array<ProcessedObjectTimeline>;
}

export interface GetS3BucketContentsResponse {
  __typename?: 'GetS3BucketContentsResponse';
  contents: Array<BucketContents>;
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface GetS3UploadUrlResponse {
  __typename?: 'GetS3UploadUrlResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  url: Scalars['String'];
}

export interface GetSQSMessagesResponse {
  __typename?: 'GetSQSMessagesResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  messages: Array<SQSMessage>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface ImageResponse {
  __typename?: 'ImageResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  imageData: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface IndividualAssignment {
  __typename?: 'IndividualAssignment';
  adType: AdType;
  id: Scalars['String'];
  isRecommendation?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productId: Scalars['String'];
  rank: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  startTimeIndex: Scalars['Int'];
  status: AdStatusType;
}

export interface IndividualAssignmentInput {
  adType: AdType;
  id: Scalars['String'];
  isRecommendation?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productId: Scalars['String'];
  rank: Scalars['Int'];
  source?: InputMaybe<Scalars['String']>;
  startTimeIndex: Scalars['Int'];
  status: AdStatusType;
}

export interface Job {
  __typename?: 'Job';
  brandId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  jobId: Scalars['String'];
  jobType: Scalars['String'];
  payload?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
}

export interface JobStatus {
  __typename?: 'JobStatus';
  jobId: Scalars['String'];
  jobStatus: Scalars['String'];
}

export enum MessageCode {
  NOT_CODED = 'NOT_CODED'
}

export interface MessageDetails {
  __typename?: 'MessageDetails';
  code: MessageCode;
  message?: Maybe<Scalars['String']>;
}

export interface MultipleAssignment {
  __typename?: 'MultipleAssignment';
  adType: AdType;
  id: Scalars['String'];
  name: Scalars['String'];
  productCollectionId: Scalars['String'];
  rank: Scalars['Int'];
}

export interface MultipleAssignmentInput {
  adType: AdType;
  id: Scalars['String'];
  name: Scalars['String'];
  productCollectionId: Scalars['String'];
  rank: Scalars['Int'];
}

export interface Mutation {
  __typename?: 'Mutation';
  addCartItem: CommonResponse;
  compressS3Image: CompressS3ImageResponse;
  consoleTest: CommonResponse;
  createEmptyCommit: CommonResponse;
  createOneCoupon: CommonResponse;
  deleteDatabaseCategory: CommonResponse;
  deleteDatabaseSeason: CommonResponse;
  deleteDatabaseSeries: CommonResponse;
  deleteDatabaseVideo: CommonResponse;
  deleteExperiment: CommonResponse;
  deleteOneCoupon: CommonResponse;
  deleteS3Video: CommonResponse;
  deleteStrategy: CommonResponse;
  duplicateStrategy: StrategyWriteResp;
  forgotPassword: ForgotPasswordResponse;
  giveFeedback: CommonResponse;
  login: SignUpResponse;
  logout: CommonResponse;
  rebuildBranch: CommonResponse;
  removeCartItem: CommonResponse;
  reorderDatabaseCategories: CommonResponse;
  requestMetadataUpdate: CommonResponse;
  saveCategory: SaveCollectionResult;
  saveSeason: SaveCollectionResult;
  saveSeries: CommonResponse;
  seedExperiments: CommonResponse;
  seedStrategies: CommonResponse;
  seedVideoCollectionDatabase: CommonResponse;
  seedVideoDatabase: CommonResponse;
  signup: SignUpResponse;
  softDeleteUserAccount: CommonResponse;
  startCheckout: StartCheckoutResponse;
  startCheckoutAnon: StartCheckoutResponse;
  startObjectRecognition: StartObjectDetectionResponse;
  submitContactForm: CommonResponse;
  submitEmailListForm: CommonResponse;
  subscribe: CommonResponse;
  unsubscribe: UnsubscribeResponse;
  updateCartItem: CommonResponse;
  updateSeries: CommonResponse;
  updateUserInfo: CommonResponse;
  updateUserPassword: CommonResponse;
  updateUserPasswordFromChangePasswordId: CommonResponse;
  updateVideo: CommonResponse;
  userMessageAck: GetMessagesResponse;
  verifyEmail: CommonResponse;
  writeDatabaseCategory: DatabaseCategoryWriteResp;
  writeDatabaseSeason: CommonResponse;
  writeDatabaseSeries: CommonResponse;
  writeDatabaseSeriesAndSeasons: DatabaseSeriesWriteResp;
  writeDatabaseVideo: CommonResponse;
  writeExperiment: ExperimentWriteResp;
  writeIncludedProductCollections: CommonResponse;
  writeStrategy: StrategyWriteResp;
}


export interface MutationaddCartItemArgs {
  newItem?: InputMaybe<CartItemAddInput>;
}


export interface MutationcompressS3ImageArgs {
  bucket: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  keyPath: Scalars['String'];
}


export interface MutationcreateOneCouponArgs {
  code?: InputMaybe<Scalars['String']>;
}


export interface MutationdeleteDatabaseCategoryArgs {
  categoryId: Scalars['String'];
}


export interface MutationdeleteDatabaseSeasonArgs {
  seasonId: Scalars['String'];
}


export interface MutationdeleteDatabaseSeriesArgs {
  seriesId: Scalars['String'];
}


export interface MutationdeleteDatabaseVideoArgs {
  videoId: Scalars['String'];
}


export interface MutationdeleteExperimentArgs {
  experimentId: Scalars['String'];
}


export interface MutationdeleteOneCouponArgs {
  code?: InputMaybe<Scalars['String']>;
}


export interface MutationdeleteS3VideoArgs {
  fileName?: InputMaybe<Scalars['String']>;
}


export interface MutationdeleteStrategyArgs {
  strategyId: Scalars['String'];
}


export interface MutationduplicateStrategyArgs {
  strategyId: Scalars['String'];
}


export interface MutationforgotPasswordArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationgiveFeedbackArgs {
  details?: InputMaybe<Scalars['String']>;
  feedback: Scalars['String'];
  type: Scalars['String'];
}


export interface MutationloginArgs {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationremoveCartItemArgs {
  email?: InputMaybe<Scalars['String']>;
  variantId?: InputMaybe<Scalars['String']>;
}


export interface MutationreorderDatabaseCategoriesArgs {
  categories: Array<DatabaseCategoryInput>;
}


export interface MutationrequestMetadataUpdateArgs {
  justLabels?: InputMaybe<Scalars['Boolean']>;
  justProducts?: InputMaybe<Scalars['Boolean']>;
  justVideos?: InputMaybe<Scalars['Boolean']>;
  onlyOneBrand?: InputMaybe<Scalars['String']>;
  skipImages?: InputMaybe<Scalars['Boolean']>;
}


export interface MutationsaveCategoryArgs {
  title?: InputMaybe<Scalars['String']>;
}


export interface MutationsaveSeasonArgs {
  SeasonInput?: InputMaybe<SeasonInput>;
}


export interface MutationsaveSeriesArgs {
  AddSeriesInput?: InputMaybe<AddSeriesInput>;
}


export interface MutationseedExperimentsArgs {
  experiments: Array<ExperimentInput>;
}


export interface MutationseedStrategiesArgs {
  strategies: Array<StrategyInput>;
}


export interface MutationseedVideoCollectionDatabaseArgs {
  categories: Array<DatabaseCategoryInput>;
  seasons: Array<DatabaseSeasonInput>;
  series: Array<DatabaseSeriesInput>;
  sourceBranch: Scalars['String'];
  sourceBucket: Scalars['String'];
}


export interface MutationseedVideoDatabaseArgs {
  sourceBranch: Scalars['String'];
  sourceBucket: Scalars['String'];
  videos: Array<DatabaseVideoInput>;
}


export interface MutationsignupArgs {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationsoftDeleteUserAccountArgs {
  userInput?: InputMaybe<UserDeleteInput>;
}


export interface MutationstartCheckoutArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationstartCheckoutAnonArgs {
  items: Array<CartItemAddInput>;
}


export interface MutationstartObjectRecognitionArgs {
  bucket?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
}


export interface MutationsubmitContactFormArgs {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}


export interface MutationsubmitEmailListFormArgs {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}


export interface MutationsubscribeArgs {
  code: Scalars['String'];
}


export interface MutationunsubscribeArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationupdateCartItemArgs {
  updatedItem?: InputMaybe<CartItemUpdateInput>;
}


export interface MutationupdateSeriesArgs {
  seriesData: SeriesInput;
}


export interface MutationupdateUserInfoArgs {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
}


export interface MutationupdateUserPasswordArgs {
  currentPassword?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationupdateUserPasswordFromChangePasswordIdArgs {
  changePasswordId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationupdateVideoArgs {
  VideoInput?: InputMaybe<VideoInput>;
}


export interface MutationuserMessageAckArgs {
  deviceId: Scalars['String'];
  messageId: Scalars['String'];
  payload?: InputMaybe<Scalars['String']>;
}


export interface MutationverifyEmailArgs {
  verificationId?: InputMaybe<Scalars['String']>;
}


export interface MutationwriteDatabaseCategoryArgs {
  category: DatabaseCategoryInput;
}


export interface MutationwriteDatabaseSeasonArgs {
  season: DatabaseSeasonInput;
}


export interface MutationwriteDatabaseSeriesArgs {
  series: DatabaseSeriesInput;
}


export interface MutationwriteDatabaseSeriesAndSeasonsArgs {
  seasons: Array<DatabaseSeasonInput>;
  series: DatabaseSeriesInput;
}


export interface MutationwriteDatabaseVideoArgs {
  video: DatabaseVideoInput;
}


export interface MutationwriteExperimentArgs {
  experiment: ExperimentInput;
}


export interface MutationwriteIncludedProductCollectionsArgs {
  content?: InputMaybe<Scalars['String']>;
}


export interface MutationwriteStrategyArgs {
  strategy: StrategyInput;
}

export interface OAuthToken {
  __typename?: 'OAuthToken';
  accessToken: Scalars['String'];
  authServiceResponse: Scalars['Int'];
  errorMessages?: Maybe<Array<ErrorDetails>>;
  expiresIn: Scalars['Int'];
  idToken: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
  userId: Scalars['String'];
}

export interface ObjectBoundingBox {
  __typename?: 'ObjectBoundingBox';
  height: Scalars['Float'];
  left: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
}

export interface ProcessedObjectInstance {
  __typename?: 'ProcessedObjectInstance';
  boxes: Array<ObjectBoundingBox>;
  timeStamp: Scalars['Int'];
}

export interface ProcessedObjectLabel {
  __typename?: 'ProcessedObjectLabel';
  instances: Array<ProcessedObjectInstance>;
  name: Scalars['String'];
  timeStamps: Array<Scalars['Int']>;
}

export interface ProcessedObjectTimeline {
  __typename?: 'ProcessedObjectTimeline';
  labels: Array<Scalars['String']>;
  timeStamp: Scalars['Int'];
}

export interface ProviderToken {
  __typename?: 'ProviderToken';
  authServiceResponse: Scalars['Int'];
  email: Scalars['String'];
  errorMessages?: Maybe<Array<ErrorDetails>>;
  firstName: Scalars['String'];
  isSignup?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
  token: Scalars['String'];
}

export interface Query {
  __typename?: 'Query';
  coupons: Array<Coupon>;
  exchangeProviderCodeForAuth: ProviderToken;
  exchangeRefreshTokenForJwtToken: AccessToken;
  getAllDatabaseSeriesInfo: DatabaseSeriesInfoResp;
  getCartItems: cartItemList;
  getCollections: Collections;
  getDatabaseCategories: DatabaseCategoriesResp;
  getDatabaseCategoriesByVideoId: DatabaseCategoriesResp;
  getDatabaseCategoryById: DatabaseCategoryResp;
  getDatabaseSeasonById: DatabaseSeasonResp;
  getDatabaseSeasons: DatabaseSeasonsResp;
  getDatabaseSeasonsByVideoId: DatabaseSeasonsResp;
  getDatabaseSeries: DatabaseMultiSeriesResp;
  getDatabaseSeriesById: DatabaseSeriesResp;
  getDatabaseSeriesByVideoId: DatabaseMultiSeriesResp;
  getDatabaseVideo: DatabaseVideoResp;
  getDatabaseVideos: DatabaseVideosResp;
  getExperimentDataByVideo: ExperimentStrategyResp;
  getExperiments: ExperimentsResp;
  getExternalVideoData: ExternalVideoDataResp;
  getIncludedProductCollections: ShopifyProductCollectionResponse;
  getObjectRecognitionData: CommonResponse;
  getObjectRecognitionJobs: GetJobsResponse;
  getPPVData: VideoPPVData;
  getProcessedObjectData: GetProcessedObjectDataResponse;
  getProductCollectionsFromShopify: ShopifyProductCollectionResponse;
  getRecommendedProducts: RecommendedProductResp;
  getS3BucketContents: GetS3BucketContentsResponse;
  getS3UploadUrl: GetS3UploadUrlResponse;
  getSQSMessages: GetSQSMessagesResponse;
  getSignedVideoUrl: SignedUrlResponse;
  getStrategies: StrategiesResp;
  getStrategy: StrategyResp;
  getUniqueVideoId: VideoIdResp;
  getVideoBucketContents: GetS3BucketContentsResponse;
  getVideoImageFile: ImageResponse;
  getVideoToken: VhxVideo;
  getVideosFile: Videos;
  getWatchList: VhxVideoWatching;
  isSubscribed: CheckSubscriptionResponse;
  me: User;
  queryObjectRecognitionStatus: QueryObjectRecognitionResponse;
  refreshFourIDataToken: TokenResponse;
  resendEmailVerification: CommonResponse;
  serverInfo: ServerInfo;
  userHasPurchasedItem: UserHasPurchasedItemResponse;
  userMessagesGet: GetMessagesResponse;
}


export interface QueryexchangeProviderCodeForAuthArgs {
  provider: Scalars['String'];
  token: Scalars['String'];
}


export interface QuerygetAllDatabaseSeriesInfoArgs {
  seriesId: Scalars['String'];
}


export interface QuerygetCartItemsArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface QuerygetDatabaseCategoriesByVideoIdArgs {
  videoId: Scalars['String'];
}


export interface QuerygetDatabaseCategoryByIdArgs {
  categoryId: Scalars['String'];
}


export interface QuerygetDatabaseSeasonByIdArgs {
  seasonId: Scalars['String'];
}


export interface QuerygetDatabaseSeasonsByVideoIdArgs {
  videoId: Scalars['String'];
}


export interface QuerygetDatabaseSeriesByIdArgs {
  seriesId: Scalars['String'];
}


export interface QuerygetDatabaseSeriesByVideoIdArgs {
  videoId: Scalars['String'];
}


export interface QuerygetDatabaseVideoArgs {
  videoId: Scalars['String'];
}


export interface QuerygetExperimentDataByVideoArgs {
  videoId: Scalars['String'];
}


export interface QuerygetExternalVideoDataArgs {
  platform: Scalars['String'];
  videoId: Scalars['String'];
}


export interface QuerygetObjectRecognitionDataArgs {
  brandId?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  jobId?: InputMaybe<Scalars['String']>;
}


export interface QuerygetObjectRecognitionJobsArgs {
  brandId?: InputMaybe<Scalars['String']>;
}


export interface QuerygetPPVDataArgs {
  email?: InputMaybe<Scalars['String']>;
  videoId: Scalars['Int'];
}


export interface QuerygetProcessedObjectDataArgs {
  brandId?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
}


export interface QuerygetRecommendedProductsArgs {
  anonymousId: Scalars['String'];
}


export interface QuerygetS3BucketContentsArgs {
  brandId?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
}


export interface QuerygetS3UploadUrlArgs {
  bucket?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
}


export interface QuerygetSQSMessagesArgs {
  queueUrl?: InputMaybe<Scalars['String']>;
}


export interface QuerygetSignedVideoUrlArgs {
  url: Scalars['String'];
}


export interface QuerygetStrategyArgs {
  strategyId: Scalars['String'];
}


export interface QuerygetVideoImageFileArgs {
  fileName: Scalars['String'];
}


export interface QuerygetVideoTokenArgs {
  videoId: Scalars['Int'];
}


export interface QuerygetWatchListArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface QuerymeArgs {
  email: Scalars['String'];
}


export interface QueryqueryObjectRecognitionStatusArgs {
  jobIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}


export interface QueryuserHasPurchasedItemArgs {
  email: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
  variantId?: InputMaybe<Scalars['String']>;
}


export interface QueryuserMessagesGetArgs {
  deviceId?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
}

export interface QueryObjectRecognitionResponse {
  __typename?: 'QueryObjectRecognitionResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  jobs: Array<JobStatus>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface RecommendedProduct {
  __typename?: 'RecommendedProduct';
  id: Scalars['String'];
}

export interface RecommendedProductResp {
  __typename?: 'RecommendedProductResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  recommendations: Array<RecommendedProduct>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface SQSMessage {
  __typename?: 'SQSMessage';
  body: Scalars['String'];
  id: Scalars['String'];
  receiptHandle: Scalars['String'];
}

export interface SaveCollectionResult {
  __typename?: 'SaveCollectionResult';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  id?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface Season {
  __typename?: 'Season';
  description: Scalars['String'];
  episodes_count: Scalars['Int'];
  id: Scalars['String'];
  is_available: Scalars['Boolean'];
  is_featured: Scalars['Boolean'];
  items?: Maybe<Array<Video>>;
  metadata: SeasonMetadata;
  name: Scalars['String'];
  position: Scalars['Int'];
  season_number: Scalars['Int'];
  short_description: Scalars['String'];
  slug: Scalars['String'];
  thumbnail: SeasonThumbnail;
  type: Scalars['String'];
}

export interface SeasonInput {
  id: Scalars['String'];
  items: Array<InputMaybe<Scalars['String']>>;
  series_id?: InputMaybe<Scalars['String']>;
}

export interface SeasonMetadata {
  __typename?: 'SeasonMetadata';
  season_number: Scalars['Int'];
  useInBrands: Scalars['String'];
}

export interface SeasonThumbnail {
  __typename?: 'SeasonThumbnail';
  blurred: Scalars['String'];
  large: Scalars['String'];
  medium: Scalars['String'];
  small: Scalars['String'];
  source: Scalars['String'];
}

export interface Serie {
  __typename?: 'Serie';
  categories: Array<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  items: Array<Season>;
  localThumbnail: Scalars['String'];
  name: Scalars['String'];
}

export interface SeriesInput {
  categoryIds: Array<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  seasons: Array<SeasonInput>;
  thumbnail?: InputMaybe<ThumbnailInput>;
  title: Scalars['String'];
}

export interface ServerInfo {
  __typename?: 'ServerInfo';
  awsAccessKeyId: Scalars['String'];
  branch: Scalars['String'];
  branchType: Scalars['String'];
  buildId: Scalars['String'];
  commit: Scalars['String'];
  commonResponse: CommonResponse;
  dbConnectTest: Scalars['String'];
  environment: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isLoggedIn: Scalars['Boolean'];
  serverBuildDate: Scalars['String'];
}

export interface ShopifyProductCollection {
  __typename?: 'ShopifyProductCollection';
  id: Scalars['String'];
  title: Scalars['String'];
}

export interface ShopifyProductCollectionResponse {
  __typename?: 'ShopifyProductCollectionResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  productCollections: Array<ShopifyProductCollection>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface SignUpResponse {
  __typename?: 'SignUpResponse';
  commonResponse: CommonResponse;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  token: Scalars['String'];
}

export interface SignedUrlResponse {
  __typename?: 'SignedUrlResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  url: Scalars['String'];
}

export interface StartCheckoutResponse {
  __typename?: 'StartCheckoutResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  redirectUrl?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface StartObjectDetectionResponse {
  __typename?: 'StartObjectDetectionResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  jobId: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface StrategiesResp {
  __typename?: 'StrategiesResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  strategies: Array<Strategy>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface Strategy {
  __typename?: 'Strategy';
  bannerInterval?: Maybe<Scalars['Int']>;
  collectionAssignments: Array<CollectionAssignment>;
  id: Scalars['String'];
  individualAssignments: Array<IndividualAssignment>;
  multipleAssignments: Array<MultipleAssignment>;
  title: Scalars['String'];
}

export interface StrategyInput {
  bannerInterval?: InputMaybe<Scalars['Int']>;
  collectionAssignments: Array<CollectionAssignmentInput>;
  id: Scalars['String'];
  individualAssignments: Array<IndividualAssignmentInput>;
  multipleAssignments: Array<MultipleAssignmentInput>;
  title: Scalars['String'];
}

export interface StrategyResp {
  __typename?: 'StrategyResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  strategy?: Maybe<Strategy>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface StrategyWriteResp {
  __typename?: 'StrategyWriteResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  strategyId: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface Thumbnail {
  __typename?: 'Thumbnail';
  fileName: Scalars['String'];
  source: Scalars['String'];
}

export interface ThumbnailInput {
  data?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  previousFileName?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
}

export interface TokenResponse {
  __typename?: 'TokenResponse';
  accessToken: Scalars['String'];
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface UnsubscribeResponse {
  __typename?: 'UnsubscribeResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  plan?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface User {
  __typename?: 'User';
  accountStatus?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  subscriptionChannel?: Maybe<Scalars['String']>;
  subscriptionExpiry?: Maybe<Scalars['String']>;
  vhxId?: Maybe<Scalars['Int']>;
}

export interface UserDeleteInput {
  deleteAccountReasons?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
}

export interface UserHasPurchasedItemResponse {
  __typename?: 'UserHasPurchasedItemResponse';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  purchaseStatus: Scalars['Boolean'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface UserMessage {
  __typename?: 'UserMessage';
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  messageType: Scalars['String'];
  /** JSON as a string */
  payload?: Maybe<Scalars['String']>;
}

export interface VhxVideo {
  __typename?: 'VhxVideo';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  ppvProductId?: Maybe<Scalars['String']>;
  ppvProductTitle?: Maybe<Scalars['String']>;
  ppvVariantId?: Maybe<Scalars['String']>;
  ppvVariantTitle?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  token: Scalars['String'];
}

export interface VhxVideoWatching {
  __typename?: 'VhxVideoWatching';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  items: Array<video>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface Video {
  __typename?: 'Video';
  categories: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  episode?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  is_available: Scalars['Boolean'];
  is_free: Scalars['Boolean'];
  metadata: VideoMetadata;
  name: Scalars['String'];
  season_id?: Maybe<Scalars['String']>;
  seriesData: Array<VideoSeriesData>;
  series_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
}

export interface VideoIdResp {
  __typename?: 'VideoIdResp';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  id: Scalars['String'];
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface VideoInput {
  categories: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  episode?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  is_available: Scalars['Boolean'];
  is_free: Scalars['Boolean'];
  metadata: VideoMetadataInput;
  name: Scalars['String'];
  season_id?: InputMaybe<Scalars['String']>;
  seriesData: Array<InputMaybe<VideoSeriesDataInput>>;
  series_id?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<ThumbnailInput>;
  title: Scalars['String'];
  type?: Scalars['String'];
  url: Scalars['String'];
}

export interface VideoMetadata {
  __typename?: 'VideoMetadata';
  extSource: Scalars['String'];
  extUrl: Scalars['String'];
}

export interface VideoMetadataInput {
  extSource: Scalars['String'];
  extUrl: Scalars['String'];
}

export interface VideoPPVData {
  __typename?: 'VideoPPVData';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  ppvProductId?: Maybe<Scalars['String']>;
  ppvProductTitle?: Maybe<Scalars['String']>;
  ppvVariantId?: Maybe<Scalars['String']>;
  ppvVariantTitle?: Maybe<Scalars['String']>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface VideoSeriesData {
  __typename?: 'VideoSeriesData';
  episodeId: Scalars['Int'];
  seasonId: Scalars['String'];
  seriesId: Scalars['String'];
}

export interface VideoSeriesDataInput {
  episodeId: Scalars['Int'];
  seasonId: Scalars['String'];
  seriesId: Scalars['String'];
}

export interface Videos {
  __typename?: 'Videos';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
  videos: Array<Video>;
}

export interface cartItem {
  __typename?: 'cartItem';
  description: Scalars['String'];
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  price: Scalars['String'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  title: Scalars['String'];
  variantId: Scalars['String'];
  variantName: Scalars['String'];
  videoId?: Maybe<Scalars['String']>;
}

export interface cartItemList {
  __typename?: 'cartItemList';
  errorMessages?: Maybe<Array<ErrorDetails>>;
  products?: Maybe<Array<cartItem>>;
  /** True if request was successful */
  success: Scalars['Boolean'];
}

export interface video {
  __typename?: 'video';
  id: Scalars['Int'];
}
