import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { main, landing } from './styles.module.scss';

interface Props {
  children?: ReactNode;
  footerStyle?: boolean;
}

const LayoutMain = ({ children, footerStyle }: Props) => {
  return <main className={classNames(main, footerStyle ? landing : '')}>{children}</main>;
};

export default LayoutMain;
