import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { ClientLogger } from '@lib/ClientLogger';
import classNames from 'classnames';
import googleIcon from '@iconify/icons-flat-color-icons/google';
import { Icon } from '@iconify/react';
import { BrandUtil } from '@sharedLib/util/Brand';
import { buttonProvider, buttonProviderAT, googleIcon as googleIconStyle, text as textStyle } from './styles.module.scss';

interface GoogleLoginButtonProps {
  text?: string;
  className?: string;
  disabled?: boolean;
  onSuccess: (response: any) => void;
  onFailure: (message?: string | null) => void;
}

export const GoogleLogin = ({ text, className, disabled, onSuccess, onFailure }: GoogleLoginButtonProps) => {
  const currentBrand = BrandUtil.getBrandInfoFromBranch().brandId;

  const handleSuccess = async (response: any) => {
    const authCode = response?.code;
    if (!authCode) {
      ClientLogger.error('GoogleLogin:handleError', response);
      onFailure('Google did not return an auth code.');
      return;
    }

    onSuccess(response);
  };

  const handleError = (response: any) => {
    ClientLogger.error('GoogleLogin:handleError', response);
    onFailure('Login with Google failed.');
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
    flow: 'auth-code',
  });

  return (
    <button
      data-cy="googleLogin"
      className={classNames(currentBrand === 'at' ? buttonProviderAT : buttonProvider, className)}
      type="button"
      onClick={() => loginWithGoogle()}
      disabled={disabled}
    >
      <div className={googleIconStyle}>
        <Icon icon={googleIcon} />
      </div>
      <p className={textStyle}>{text}</p>
    </button>
  );
};
