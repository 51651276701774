import React, { useContext, useRef } from 'react';
import { Video, BrandUtil, DatabaseVideo } from '@sharedLib/index';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { isDesktop, isTablet, isMobile } from '@lib/responsive';
import { VideoImg } from '@components/Img';
import { DivButton } from '@components/Buttons';
import useScrollButtons from '@lib/useScrollButtons';
import outlinePlayCircleOutline from '@iconify/icons-ic/outline-play-circle-outline';
import rightOutlined from '@iconify/icons-ant-design/right-outline';
import leftOutlined from '@iconify/icons-ant-design/left-outline';
import { NavStateContext } from '@src/lib/navContext';
import { Share } from '@components/Share/Share';

import {
  videoCardThumbnail,
  videoPlayButton,
  seriesVideoCard,
  videoCardImage,
  playButton,
  videoTitle,
  seriesText,
  seriesDescription,
  searchListContainerHorizontal,
  textHeader,
  searchListGrid,
  left,
  scrollButton,
  right,
  buttonIcon,
  videoCardImageThumb,
  shareButton,
} from './styles.module.scss';

interface Props {
  query: string;
  episodeHits: [];
  numberOfEpisodeHits: number;
}

interface EpisodeProps {
  video: DatabaseVideo;
  query: string;
}

interface ButtonProps {
  onClick?: () => void;
  className?: string;
}

const iconProps = {
  height: '60px',
};

const VideoPlayButton = ({ onClick, className }: ButtonProps) => {
  return (
    <button className={classNames(videoPlayButton, className)} type="button" onClick={onClick}>
      <Icon icon={outlinePlayCircleOutline} {...iconProps} />
    </button>
  );
};

const Episode = ({ video }: EpisodeProps) => {
  const useDesktop = isDesktop();
  const useTablet = isTablet();
  const nav = useContext(NavStateContext);
  const navData = nav.navState.pageData;

  const brandInfo = BrandUtil.getSiteInfo();
  const baseImageUrl = brandInfo.imageBaseUrl;
  const thumbnailUrl = video.thumbnailUrl ? `${baseImageUrl}/videos/${video.thumbnailUrl}` : `${brandInfo.siteUrl}/fallback.png`;

  const goToVideoPage = () => {
    nav.setRoot({ path: `/video/${video.id}/`, title: video.label || video.title });
  };

  return (
    <div>
      <div className={seriesVideoCard}>
        <div className={videoCardImage} onClick={goToVideoPage}>
          {(useDesktop || useTablet) && <VideoPlayButton className={playButton} />}
          <img src={thumbnailUrl} className={videoCardImageThumb} alt="" />
        </div>
        <div className={seriesText}>
          <div className={videoTitle}>{video.label || video.title}</div>
          <div className={seriesDescription}>{video.description}</div>
        </div>
      </div>
    </div>
  );
};

const SearchEpisodes = ({ episodeHits, numberOfEpisodeHits, query }: Props) => {
  const useMobile = isMobile();

  const videoListElement = useRef<HTMLDivElement>(null);

  const { showScrollButtons, onScrollButtonClick } = useScrollButtons(videoListElement, { scrollPercentage: 0.65, roundToNearest: 223 });
  return (
    <div className={classNames(searchListContainerHorizontal)}>
      <header className={textHeader}>
        <div>Shows - {numberOfEpisodeHits} found</div>
        <Share type="search" urlPath={`?q=${encodeURIComponent(query)}`} shareBtnClass={shareButton} />
      </header>
      {Boolean(numberOfEpisodeHits) && (
        <div ref={videoListElement} data-cy="searchEpisodes" className={searchListGrid}>
          {episodeHits.map((hit: any, i: number) => {
            return <Episode query={query} video={hit.node} key={i} />;
          })}
        </div>
      )}
      {showScrollButtons && !useMobile && Boolean(numberOfEpisodeHits) && (
        <>
          <DivButton
            className={classNames(scrollButton, left)}
            onClick={() => {
              onScrollButtonClick(true);
            }}
          >
            <Icon icon={leftOutlined} className={buttonIcon} />
          </DivButton>
          <DivButton
            className={classNames(scrollButton, right)}
            onClick={() => {
              onScrollButtonClick(false);
            }}
          >
            <Icon icon={rightOutlined} className={buttonIcon} />
          </DivButton>
        </>
      )}
    </div>
  );
};

export default SearchEpisodes;
