import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { VideoPlayerSize, useVideoState } from '@lib/useVideoState';
import React, { KeyboardEvent, useContext, useState } from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import classnames from 'classnames';
import fullScreen from '@iconify/icons-ic/fullscreen';
import roundCloseFullscreen from '@iconify/icons-ic/round-close-fullscreen';
import outlineArrowBackIos from '@iconify/icons-ic/outline-arrow-back-ios';
import pictureInPictureExitFill from '@iconify/icons-ri/picture-in-picture-exit-fill';
import { Share } from '@components/Share/Share';
import { BrandUtil } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { useVideoParentCollections } from '@src/lib/video-query/useVideoData';
import { PLAYER_EVENT } from '@components/VideoPlayer/VideoPlayer';
import { CastController } from './CastController';
import { isEmbedded } from '@src/lib/embedded';
import { CartIcon } from '@components/Cart/CartIcon';

import {
  flexColumn,
  flexRow,
  playerControlContainer,
  roundTheCorners,
  leftButtonGroup,
  iconText,
  browseText,
  centerButtonGroup,
  centerText,
  smallCenterText,
  titleText,
  rightButtonGroup,
  theatreContainer,
  theatreIcon,
  closeFullScreenContainer,
  playerControlButton,
  shareButton,
  fullScreenContainer,
  cartButton,
  cartCounter,
} from './styles.module.scss';
import './videojs-styles.scss'; // to overrwrite videojs default css settings

interface Props {
  playing: boolean;
  size: VideoPlayerSize;
  episodeNumber?: number;
  seasonNumber?: number;
  videoState: any;
  onUserAction: (event: PLAYER_EVENT) => void;
}

const iconPropsSmall = {
  height: '32px',
};

const DEBUG = false;

export const VideoJSPlayerControls = ({ playing, size, episodeNumber, seasonNumber, videoState, onUserAction }: Props) => {
  const nav = useContext(NavStateContext);

  const seriesNavObject = nav.navState.pageData && nav.navState.pageData.filter(page => page.path.includes('series'));
  const seriesTitle = seriesNavObject && seriesNavObject[0] && seriesNavObject[0].title;
  const { useDescriptionInVideo } = BrandUtil.getSiteInfo().videoPlayerConfig;

  const [modalOpen, setModalOpen] = useState(false);
  const videoStateHook = useVideoState();
  const { state } = videoState;
  const videoId = state?.video?.id;
  const videoParents = useVideoParentCollections(videoId).series;

  const keyHandler = (event: KeyboardEvent) => {
    DEBUG && ClientLogger.debug('VideoJSPlayerControls', 'event', DEBUG, event);
  };

  const handleClick = (event: any, playerEvent: PLAYER_EVENT) => {
    event.stopPropagation();
    onUserAction(playerEvent);
  };

  const handleBack = () => {
    let canonicalParent;
    if (videoId) {
      const pages = [...nav.navState.pageData];
      // may be multiple video parents, check if nav history contains one of them
      if (videoParents.length) {
        canonicalParent = { label: videoParents[0].title, path: `/series/${videoParents[0].id}` };
        // check history in reverse
        for (const page of pages.reverse()) {
          if (videoParents.findIndex(collection => collection.title === page.title) !== -1) {
            canonicalParent = { label: page.title, path: page.path };
            break;
          }
        }
      }
    }

    if (canonicalParent) {
      nav.push({ path: canonicalParent.path, title: canonicalParent.label });
    } else {
      nav.push({ path: '/app', title: 'Home' });
    }
  };

  DEBUG && ClientLogger.debug('VideoJSPlayerControls render', `playing=${playing}`, DEBUG);

  return (
    <div
      className={classnames(
        modalOpen ? '' : 'customVideoJS',
        playerControlContainer,
        size === VideoPlayerSize.SMALL_EMBEDDED ? roundTheCorners : ''
      )}
    >
      <div className={leftButtonGroup}>
        {!isEmbedded() && (
          <button
            className={classnames(playerControlButton)}
            type="button"
            onClick={e => {
              if (size === VideoPlayerSize.LARGE_FULLSCREEN) {
                handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN);
              }
              handleBack();
            }}
            onTouchStart={e => {
              if (size === VideoPlayerSize.LARGE_FULLSCREEN) {
                handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN);
              }
              handleBack();
            }}
            onKeyDown={keyHandler}
          >
            <p className={iconText}>
              <InlineIcon icon={outlineArrowBackIos} {...iconPropsSmall} /> <span className={browseText}>Browse</span>
            </p>
          </button>
        )}
      </div>

      <div className={centerButtonGroup}>
        <div className={classnames(centerText, size === VideoPlayerSize.SMALL_EMBEDDED ? smallCenterText : '')}>
          {!useDescriptionInVideo && seasonNumber && episodeNumber && (
            <div className={titleText}>
              {seriesTitle && `${seriesTitle}, `}
              Season {seasonNumber}, Episode {episodeNumber}
            </div>
          )}
          {useDescriptionInVideo && state?.video?.description && <div className={titleText}>{state?.video?.description}</div>}
        </div>
      </div>

      <div className={rightButtonGroup}>
        {size === VideoPlayerSize.SMALL_EMBEDDED ? (
          <div
            className={classnames(theatreContainer)}
            onClick={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
            onTouchStart={e => handleClick(e, PLAYER_EVENT.MEDIUM_SCREEN)}
            onKeyDown={keyHandler}
          >
            <Icon icon={pictureInPictureExitFill} className={theatreIcon} />
          </div>
        ) : (
          <>
            {size === VideoPlayerSize.LARGE_FULLSCREEN ? (
              <div
                className={classnames(closeFullScreenContainer)}
                onClick={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                onTouchStart={e => handleClick(e, PLAYER_EVENT.EXIT_FULL_SCREEN)}
                onKeyDown={keyHandler}
              >
                <Icon icon={roundCloseFullscreen} {...iconPropsSmall} />
              </div>
            ) : (
              <div className={flexColumn}>
                <div className={flexRow}>
                  <Share
                    type="video"
                    video={state?.video}
                    urlPath={state?.video?.id}
                    shareBtnClass={classnames(playerControlButton, shareButton)}
                    modalCallback={isOpen => setModalOpen(isOpen)}
                  />
                  {typeof cast !== 'undefined' && typeof chrome !== 'undefined' && <CastController video={state.video} />}
                  <div
                    className={classnames(fullScreenContainer)}
                    onClick={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onTouchStart={e => handleClick(e, PLAYER_EVENT.REQUEST_FULL_SCREEN)}
                    onKeyDown={keyHandler}
                  >
                    <Icon icon={fullScreen} {...iconPropsSmall} />
                  </div>
                </div>

                {isEmbedded() && (
                  <div className={flexRow}>
                    <CartIcon
                      className={cartButton}
                      counterClassName={cartCounter}
                      onClick={() => videoStateHook.setEmbeddedCartOpen(true)}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
