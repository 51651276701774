export class Util {
  public static checkEnvVar(varName: string, debug: boolean = false) {
    const val = process.env[varName];
    if (debug) {
      console.log(`process.env.${varName} is set`);
    }
    if (!val) {
      console.error(`process.env.${varName} is falsy`);
    }
  }

  public static getEnvVar(varName: string, debug: boolean = false): string {
    const val = process.env[varName];
    if (debug) {
      console.log(`process.env.${varName} is ${val}`);
    }
    if (!val) {
      throw new Error(`Missing environment variable ${varName}`);
    }
    return val;
  }

  public static getBrandId(): string {
    const brandId = process.env.GATSBY_BRAND_ID;
    if (!brandId) {
      throw new Error('Missing environment variable GATSBY_BRAND_ID');
    }
    return brandId;
  }

  public static getBranch(): string {
    const branchId = process.env.GATSBY_GIT_BRANCH;
    if (!branchId) {
      throw new Error('Missing environment variable GATSBY_GIT_BRANCH');
    }
    return branchId;
  }

  public static isJestTest(): boolean {
    return (process.env.NODE_ENV || '').toLocaleLowerCase() === 'test';
  }

  public static isLocal(): boolean {
    const env = process.env.GATSBY_NODE_ENV || 'production';
    return env === 'development';
  }

  static convertShopifyTextIdToNumber(
    atobFunction: (encoded: string) => string,
    id: string | undefined,
    assertTypeCheck?: 'Product' | 'Collection' | 'ProductVariant'
  ): number | undefined {
    return Util.convertShopifyTextIdToNumberAndString(atobFunction, id, assertTypeCheck).id;
  }

  static convertShopifyTextIdToNumberAndString(
    atobFunction: (encoded: string) => string,
    id: string | undefined,
    assertTypeCheck?: 'Product' | 'Collection' | 'ProductVariant'
  ): { id: number | undefined; encoded: string | undefined } {
    if (!id) {
      return { id: undefined, encoded: undefined };
    }
    const prefix = `Shopify__${assertTypeCheck}__`;
    const len = prefix.length;
    if (id.substr(0, len) === prefix) {
      id = id.substr(len);
    }
    const shopifyText = atobFunction(id);
    const array = shopifyText.split('/');
    if (array.length !== 5) {
      const msg = `Unexpected format id= ${id} shopify text= ${shopifyText}`;
      console.error('ShopifyUtil.convertTextIdToNumber', 'Error converting text to number', msg);
      throw new Error(msg);
    }
    if (assertTypeCheck) {
      if (array[3] !== assertTypeCheck) {
        const msg = `Unexpected type id= ${id} shopify text= ${shopifyText} expected ${assertTypeCheck} got ${array[3]}`;
        console.error('ShopifyUtil.convertTextIdToNumber', 'Error converting text to number', msg);
        throw new Error(msg);
      }
      if (array[2] !== 'shopify') {
        const msg = `Unexpected type id= ${id} shopify text= ${shopifyText} expected 'shopify' in position 2 got ${array[2]}`;
        console.error('ShopifyUtil.convertTextIdToNumber', 'Error converting text to number', msg);
        throw new Error(msg);
      }
    }
    return { id: Number(array[4]), encoded: id };
  }

  static extractShopifyNumber(id: string): number {
    // expects string like gid://shopify/Product/2298557694006
    const num = Number(id.split('/').pop());
    if (isNaN(num)) {
      return 0;
    }
    return num;
  }

  static convertShopifyNumberToString(
    btoaFunction: (toEncode: string) => string,
    id: number | undefined,
    typeOfObject: 'Product' | 'Collection' | 'ProductVariant',
    includeLeader: boolean = true
  ): string | undefined {
    if (!id) {
      return undefined;
    }
    const prefix = `gid://shopify/${typeOfObject}/`;
    return `${includeLeader ? `Shopify__${typeOfObject}__` : ''}${btoaFunction(`${prefix}${String(id)}`)}`;
  }

  static sanitizeEmail(email: string | null | undefined): string {
    if (!email) {
      return '';
    }
    return email.toLowerCase().trim();
  }

  //
  // Extension to mime type
  //
  static extToMime = (ext: string) => {
    ext = ext.toLowerCase();
    switch (ext) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'webp':
        return 'image/webp';
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      default:
        return ext;
    }
  };
}

export const ascendingOrder = (a: any, b: any) => a - b;

export const alphabeticalOrder = (a: any, b: any) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

export const shuffleSort = (a: number, b: number) => randomFromSeed(a) - randomFromSeed(b);

const randomFromSeed = (seed: number) => {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

//
// Take seconds and return a timestring of hh:mm:ss format
//
export const formatFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

//
// Take a hh:mm:ss or mm:ss or ss timestring and get seconds
//
export const formatToSeconds = (timeString: string) => {
  let parts = timeString.split(':');
  const length = parts.length;
  let seconds = 0;
  if (parts.length > 3) {
    parts = parts.slice(-3);
  }
  for (let i = 0; i < length; i++) {
    seconds += parseInt(parts[i]) * Math.pow(60, length - i - 1);
  }
  return seconds;
};
