import React from 'react';
import { page } from './styles.module.scss';

interface Props {
  className?: string;
}

const Page: React.FC<Props> = ({ children }) => <div className={`${page} is-paddingless is-marginless`}>{children}</div>;

export default Page;
