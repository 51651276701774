import React from 'react';
import IndexLayout from '@layouts/index';
import { defaultFields, IPageProps } from '@src/cms/cms-types';
import { CmsCollectionFile } from 'netlify-cms-core';
import ReactMarkdown from 'react-markdown';

import { pageContainer, pageContent } from './styles.module.scss';

const DEBUG = false;

///
/// CMS model
///
export const PurchaseAgreementCMS: CmsCollectionFile = {
  file: `/packages/ui/src/data/brands/ftl/pages/PurchaseAgreement/purchaseAgreementPage.md`,
  label: 'Purchase Agreement',
  name: 'purchaseAgreement',
  fields: [
    ...defaultFields('none', 'layout'),
    {
      label: 'Component Path',
      name: 'componentPath',
      widget: 'hidden',
      default: 'src/data/brands/ftl/pages/PurchaseAgreement/PurchaseAgreementPage.tsx',
    },
    {
      label: 'Content',
      name: 'content',
      widget: 'markdown',
    },
  ],
};

interface Props {
  content: string;
}

const PurchaseAgreementPage = ({ pageContext }: IPageProps<Props>) => {
  return (
    <IndexLayout noVideo>
      <div className={pageContainer}>
        <ReactMarkdown className={pageContent} children={pageContext.frontMatter.content} />
      </div>
    </IndexLayout>
  );
};

export default PurchaseAgreementPage;
