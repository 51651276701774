import { Images } from '@components/Img/useFluidImage';
import { IFrontMatterLayout } from '@layouts/model';
import { usePageImage } from '@lib/usePageImage';
import { graphql, useStaticQuery } from 'gatsby';

interface IFrontMatterLayoutWithImages extends IFrontMatterLayout {
  logoImage: Images;
  appBackgroundImage: Images;
}

export function useLayoutResource(): IFrontMatterLayoutWithImages {
  // alternatively, we could import the md file
  const markdowns = useStaticQuery(graphql`
    {
      allMarkdownRemark(limit: 1000) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              templateKey
              slug
              footer
              logo
              appBackground
            }
          }
        }
      }
    }
  `);
  const resource = markdowns.allMarkdownRemark.edges.find((edge: any) => edge.node.frontmatter.slug === 'layout')?.node?.frontmatter;
  const logoImage = usePageImage(resource.logo || '');
  const appBackgroundImage = usePageImage(resource.appBackground || '');

  if (!resource) {
    return { logoImage: {}, appBackgroundImage: {} } as any;
  }
  return { ...resource, logoImage, appBackgroundImage };
}
