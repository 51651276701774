import React, { useState } from 'react';
import { Button, ButtonStyles } from '@components/Buttons';
import { Strategy, Experiment, ExperimentInput, ExperimentItemInput } from '@sharedLib/index';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as styles from './styles.module.scss';
import { ConfirmCard } from '@components/ConfirmCard';
import { Formik, Field, FieldArray, getIn } from 'formik';
import Select from 'react-select';
import * as yup from 'yup';
import cuid from 'cuid';

const DEBUG = false;

interface Props {
  experiment?: Experiment;
  strategies: Strategy[];
  videoId?: string;
  videoCollectionId?: string;
  defaultTitle?: string;
}

interface ExperimentFormItem {
  id: string;
  strategyId: string;
  rangePercentage: number;
}

const experimentValidation = yup.object().shape({
  title: yup.string().required('This is required.'),
  items: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      strategyId: yup.string().required('Please select a strategy.'),
      rangePercentage: yup
        .number()
        .typeError('Not a number.')
        .required('Please enter a number.')
        .max(100, 'Must be between 0-100.')
        .min(0, 'Must be between 0-100.'),
    })
  ),
});

export const ExperimentEditor = ({ experiment, strategies, videoId, videoCollectionId, defaultTitle }: Props) => {
  const api = usePvepApi();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [currentExperimentItems, setCurrentExperimentItems] = useState(experiment?.items || []);
  const [currentExperimentId, setCurrentExperimentId] = useState(experiment?.id || '');
  const [showEditor, setShowEditor] = useState(Boolean(experiment));

  const [pendingRemoval, setPendingRemoval] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const strategySelectOptions = strategies.map(str => {
    return {
      value: str.id,
      label: str.title,
    };
  });

  ///
  /// Write experiment
  ///
  const writeExperiment = async (values: { title: string; items: ExperimentFormItem[] }) => {
    const experimentId = currentExperimentId || cuid();
    const experimentItems: ExperimentItemInput[] = values.items.map(item => {
      return {
        id: item.id,
        strategyId: item.strategyId,
        rangePercentage: Number(item.rangePercentage),
        experimentId,
      };
    });

    const input: ExperimentInput = {
      id: experimentId,
      title: values.title,
      videoId,
      videoCollectionId,
      items: experimentItems,
    };

    const writeResp = await api.writeExperiment(input);
    if (writeResp.data?.writeExperiment.success) {
      setError('');
      setMessage('Saved successfully.');
      setCurrentExperimentId(writeResp.data.writeExperiment.experimentId);
    } else {
      const errors = writeResp.data?.writeExperiment.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setError(errorMessage || 'Could not save successfully. Please try again.');
      setMessage('');
    }
  };

  ///
  /// Delete experiment
  ///
  const deleteExperiment = async (experimentId: string) => {
    setIsDeleting(true);

    const deleteResp = await api.deleteExperiment(experimentId);
    if (deleteResp.data?.deleteExperiment.success) {
      setCurrentExperimentId('');
      setCurrentExperimentItems([]);
      setShowEditor(false);
      setDeleteError('');
      setMessage('');
    } else {
      const errors = deleteResp.data?.deleteExperiment.errorMessages || [];
      const errorMessage = errors.map(err => err.errorMessage).join(' ');
      setDeleteError(errorMessage || 'Could not delete this strategy assignment. Please try again.');
    }

    setIsDeleting(false);
  };

  ///
  /// get initial experiment items for the form
  ///
  const getInitialItems = (): ExperimentFormItem[] => {
    const initialItems: ExperimentFormItem[] = currentExperimentItems.map(item => {
      return {
        id: item.id,
        strategyId: item.strategyId,
        rangePercentage: item.rangePercentage,
      };
    });
    return initialItems;
  };

  return (
    <>
      {!showEditor && (
        <div className={styles.experimentAdd} onClick={() => setShowEditor(true)}>
          <Icon icon="bi:plus-circle" />
          <div>ADD STRATEGY ASSIGNMENT</div>
        </div>
      )}
      {showEditor && (
        <div className={classNames('card', styles.experimentCard)}>
          <Formik
            initialValues={{
              title: experiment?.title || defaultTitle || '',
              items: getInitialItems(),
            }}
            validationSchema={experimentValidation}
            validateOnChange={false}
            onSubmit={async values => {
              if (!values.items.length) {
                setMessage('');
                setError('Add at least one strategy.');
                return;
              }

              let totalPercentage = 0;
              for (const item of values.items) {
                totalPercentage = totalPercentage + Number(item.rangePercentage);
              }

              if (totalPercentage !== 100) {
                setMessage('');
                setError('Percentages must add up to 100.');
                return;
              }

              await writeExperiment(values);
            }}
          >
            {props => (
              <>
                <div className={styles.experimentTitleInput}>
                  <Field
                    className={classNames(styles.experimentInput)}
                    value={props.values.title}
                    onChange={props.handleChange}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                  {props.touched.title && props.errors.title && <div className={styles.experimentEditorError}>{props.errors.title}</div>}
                </div>

                <FieldArray name="items">
                  {arrayHelpers => (
                    <>
                      {props.values.items.map((_item, i) => {
                        return (
                          <div className={styles.experimentItemRow} key={i}>
                            <div>
                              <Select
                                value={strategySelectOptions.filter(opt => opt.value === props.values.items[i].strategyId)}
                                name={`items.${i}.strategyId`}
                                onChange={opt => {
                                  props.setFieldValue(`items.${i}.strategyId`, opt?.value);
                                }}
                                placeholder={'Choose Strategy'}
                                options={strategySelectOptions}
                                className={styles.experimentSelect}
                                classNamePrefix="productSelect"
                              />

                              <div className={styles.experimentEditorError}>
                                {getIn(props.touched, `items.${i}.strategyId`) ? getIn(props.errors, `items.${i}.strategyId`) : ' '}
                              </div>
                            </div>

                            <div className={styles.experimentPercentRow}>
                              <div className={styles.experimentPercentContainer}>
                                <input
                                  className={styles.experimentInput}
                                  value={props.values.items[i].rangePercentage}
                                  onChange={e => {
                                    const isNumeric = e.target.value.match(/^[0-9]+$/);
                                    if (e.target.value.length && !isNumeric) {
                                      return;
                                    }
                                    props.handleChange(e);
                                  }}
                                  id={`items.${i}.rangePercentage`}
                                  name={`items.${i}.rangePercentage`}
                                />

                                <div className={styles.experimentPercentLabel}>%</div>

                                <div className={styles.experimentEditorError}>
                                  {getIn(props.touched, `items.${i}.rangePercentage`)
                                    ? getIn(props.errors, `items.${i}.rangePercentage`)
                                    : ' '}
                                </div>
                              </div>

                              <div className={styles.experimentItemDelete} onClick={() => arrayHelpers.remove(i)}>
                                <Icon icon="fa-solid:trash-alt" />
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className={styles.experimentAddContainer}>
                        <div
                          className={styles.experimentAdd}
                          onClick={() =>
                            arrayHelpers.push({
                              id: cuid(),
                              strategyId: '',
                              rangePercentage: 0,
                            })
                          }
                        >
                          <Icon icon="bi:plus-circle" />
                          <div>ADD STRATEGY</div>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>

                <div className={styles.experimentButtonRow}>
                  <div>
                    {currentExperimentId && (
                      <>
                        <div className={styles.deleteButton} onClick={() => setPendingRemoval(currentExperimentId)}>
                          <Icon icon="fa-solid:trash-alt" />
                          <div>Delete</div>
                        </div>

                        <div className={styles.deleteCard}>
                          {pendingRemoval === currentExperimentId && (
                            <ConfirmCard
                              title={'Delete Strategy Assignment'}
                              content={'Do you really want to delete this strategy assignment? This process cannot be undone.'}
                              confirmCta={'Delete'}
                              disabled={isDeleting}
                              onConfirm={async () => {
                                await deleteExperiment(pendingRemoval);
                              }}
                              onCancel={() => {
                                setPendingRemoval('');
                                setDeleteError('');
                              }}
                              errorMessage={deleteError}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div>
                    <Button onClick={props.handleSubmit} disabled={props.isSubmitting}>
                      SAVE
                    </Button>

                    {!currentExperimentId && (
                      <Button className={styles.cancelButton} style={ButtonStyles.Greyscale} onClick={() => setShowEditor(false)}>
                        CANCEL
                      </Button>
                    )}
                  </div>
                </div>

                {error && <div className={styles.experimentEditorError}>{error}</div>}
                {message && <div>{message}</div>}
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
