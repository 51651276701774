import { Img } from '@components/Img';
import { useLayoutResource } from '@layouts/useLayoutResource';
import React from 'react';
import { backgroundImage, backgroundImageWrapper, backgroundOverlay } from './styles.module.scss';

const Background: React.FC = () => {
  const resource = useLayoutResource();

  return (
    <div className={backgroundImageWrapper}>
      <Img childImageSharp={resource.appBackgroundImage.childImageSharp} className={backgroundImage} alt={'Background Image'} />
      <div className={backgroundOverlay} />
    </div>
  );
};

export default Background;
